import * as SecurityQuestions_en from './SecurityQuestions.en.js';
import * as SecurityQuestions_es from './SecurityQuestions.es.js';

const SecurityQuestionsTuple = [
  {
    type: 'SecurityQuestions',
    language: 'en',
    object: SecurityQuestions_en.default,
  },
  {
    type: 'SecurityQuestions',
    language: 'es',
    object: SecurityQuestions_es.default,
  },
];

export default SecurityQuestionsTuple;

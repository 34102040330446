// @flow

// Vendor
import * as React from 'react';
import { useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import replace from 'string-replace-to-array';

// Actions
import { storeContent } from '../../../reducers/content/contentActions';

// Helpers
import { selectSelectedLanguage } from '../../../reducers/language/languageSelectors';

// Types
type ContentProps = {
  type: string,
  id?: string,
  children?: Node,
  args?: Array<string>,
  agencyName?: String,
  passIdToChildren?: Boolean
};

const getJSONContent = (type, language) => {
  try {
    return require(`../../../lib/content/${type}/${type}.${language}.js`)
      .default;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('content file not found', e);
    return false;
  }
};

const Text = ({ children }: { children: React.Node }) => <>{children}</>;

const Content = ({ type, id, children, args, agencyName, passIdToChildren }: ContentProps) => {
  const dispatch = useDispatch();
  const content = useSelector(state => state.content);
  let language = useSelector(selectSelectedLanguage).toLowerCase();

  useEffect(() => {
    if (!content[`${type}_${language}`]) {
      let content = getJSONContent(type, language);
      if (!content) content = getJSONContent(type, 'en');
      else {
        dispatch(storeContent({ type, language, content }));
        if (language !== 'en' && !content[`${type}_en`])
          dispatch(
            storeContent({
              type,
              language: 'en',
              content: getJSONContent(type, 'en'),
            })
          );
      }
    }
  }, [language]);

  // Waiting for content to be ready, this assumes that the content file will always exist
  if (!content[`${type}_${language}`]) return null;

  // Ability to return content type as object in render props. Note html is not parsed and args are not processed.
  if (children && !id) return children(content[`${type}_${language}`]);

  // Try falling back to english if id for language is not found
  if (
    (!id && !content[`${type}_${language}`]) ||
    !(content[`${type}_${language}`] || {})[id]
  ) {
    language = 'en';
  }

  let text =
    args && args.length
      ? args.reduce(
          (acc, curr, index) => acc.replace(`{{${index + 1}}}`, curr),
          content[`${type}_${language}`][id]
        )
      : content[`${type}_${language}`][id];
  text = replace(
    text,
    /{{Link:\[(.+)\]\(([a-zA-Z0-9\-@:%_+.~#?&/=;]+)\)}}/g,
    (match, captureText, captureLocation) => (
      <Link to={captureLocation}>{captureText}</Link>
    )
  );

  if (agencyName) {
    text[0] = agencyName
  }

  if (passIdToChildren && children) {
    return children(text[0])
  }

  text = text.map((t, i) => (
    <Text key={`t${i}`}>{typeof t === 'string' ? parse(t) : t}</Text>
  ));

  if (children) return children(text);
  return text;
};

export default memo<ContentProps>(Content);

//@flow

// Vendors
import React, { useEffect, useState } from 'react';

// Components
import {
  Form,
  InputSelect,
  Content,
  InputRadioGroup,
  InputText,
} from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import {
  validate,
  inputSelfServiceValidationSchema,
} from '../../../../../lib/validation';

type Props = {
  onSubmit: Function,
  setToggle: Function,
  defaultValues: Object,
};

const InputSelfService = ({ onSubmit, setToggle, defaultValues }: Props) => {
  const triggerBack = () => {
    setToggle();
  };
  const triggerSubmit = (values, formikActions) => {
    onSubmit(values, formikActions);
  };

  return (
    <Form
      formikConfig={{
        initialValues: defaultValues,
        onSubmit: (values, formikActions: FormikActions) =>
          triggerSubmit(values, formikActions),
        validationSchema: inputSelfServiceValidationSchema,
      }}
      render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
        <>
          <label>SSO Mechanism</label>
          <InputSelect
            formikProps={formikProps}
            className="input-metadata input-metadata-select"
            name="dropdownOption"
            options={[
              {
                versionId: '0',
                id: 'saml',
                name: 'saml',
                moreInfo: 'Option 1 info',
              },
            ]}
            optionLabelKey="name"
            optionValueKey="id"
            emptyOptionLabel="Select one..."
          />
          <FormError />

          <InputRadioGroup
            label={<dt>Select Type</dt>}
            label={<dt>Enter URL</dt>}
            name="selectType"
            formikProps={formikProps}
            options={[
              //  COMMENTED TEMPORARILY
              // {
              //   id: `file`,
              //   label: 'File',
              //   value: 'file',

              // },
              // {
              //   id: `url`,
              //   label: 'Url',
              //   value: 'url',
              // },
            ]}
          />

          {(formikProps.values.selectType === 'url' ||
            formikProps.values.selectType === 'file') && (
            <>
              <InputText
                className="input-metadata"
                name="metadataUrl"
                //  COMMENTED TEMPORARILY - NEED TO UNCOMMENT ONCE FILE OPTION IS ADDED
                // label={
                //   formikProps.values.selectType === 'url'
                //     ? 'Metadata Url'
                //     : 'Metadata File'
                // }
                label="Metadata Url"
                formikProps={formikProps}
              />
           
            </>
          )}
          <FormActions>
            <FormActionsItem>
              <ButtonPrimary
                formikProps={formikProps}
                type="submit"
                isLoading={formikProps.isSubmitting}
                loadingContent={<Content type="Labels" id="Saving" />}
              >
                Save
              </ButtonPrimary>
            </FormActionsItem>
            <FormActionsItem>
              <Button onClick={triggerBack} disabled={formikProps.isSubmitting}>
                <Content type="Labels" id="Back" />
              </Button>
            </FormActionsItem>
          </FormActions>
        </>
      )}
    />
  );
};

export default InputSelfService;

import * as yup from 'yup';
import { commonRules } from '../rules';

export const confirmEmailValidationSchema = yup.object().shape({
  email: commonRules.email(),
  confirmEmail: commonRules.emailMatching('email'),
});

export const captureIdentityTOASSchema = yup.object().shape({
  fName: commonRules.firstName,
  lName: commonRules.lastName,
  dlNumber: commonRules.driverLicense,
  auditNum: commonRules.auditNumber,
  dob: commonRules.dob,
  lastFourSSN: commonRules.lastFourSSN,
  terms: commonRules.requiredCheckbox,
});

export const captureIdentityIDologySchema = yup.object().shape({
  fName: commonRules.firstName,
  lName: commonRules.lastNameMin2Chars,
  streetAddress: commonRules.streetAddress,
  city: commonRules.city,
  state: commonRules.state,
  zipFive: commonRules.zipFive(),
  dob: commonRules.dob,
  lastFourSSN: commonRules.lastFourSSN,
  terms: commonRules.requiredCheckbox,
});

export const captureIdentityQuestionsSchema = yup.object().shape({
  answer: commonRules.identityQuestion,
});

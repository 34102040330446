// Vendors
import * as yup from 'yup';
import ERRORS from '../../errorCopy';
import { REGEX } from '../common';

//should change licenseNumber to cappsId with cappsId added in
//schemaAgencies.js
export const cpa = {
  APP_RULES: {
    cpaln: {
      linkService: {
        CAPPSID: yup
          .string()
          .trim()
          .min(11, ERRORS.FIELDS.MIN)
          .max(11, ERRORS.FIELDS.MAX)
          .matches(REGEX.NUMERIC, ERRORS.FIELDS.NUMERIC_ONLY)
          .required(ERRORS.FIELDS.CAPPSID.REQUIRED)
      }
    }
  }
};

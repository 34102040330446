//@flow

import { REQUEST_TYPES } from '.';
import { APP_TRANSACTION_IDS } from '../reducers/app/appActions';

// Example Request for Payment
// const examplePaymentRequest = {
//   userKey: '513',
//   agencyId: '4',
//   appId: '3',
//   profileInfo: {
//     profileName: 'Multiple Licenses',
//     licenseNumber: 'MT047040',
//     licenseType: 'Licensed Massage Therapist',
//     name: 'SHERRY CARTER',
//     licenseData: {
//       licenseCode: '5801',
//       renewalApplicationId: '13442796'
//     }
//   },
//   transactionInfo: {
//     paymentAccountId: 'fbf339d8-b476-4dee-a3d3-aa4f010df7af',
//     paymentMethod: 'credit',
//     renewalFee: {
//       fee: '75.00',
//       lateFee: '37.50',
//       feeTotal: '112.50'
//     },
//     feeCodes: [
//       {
//         key: 'FEECODE1',
//         value: '0120'
//       },
//       {
//         key: 'FEECODE2',
//         value: '1808'
//       }
//     ]
//   },
//   transactionId: 'SUBMIT_RENEWAL_PAYMENT'
// };

export const submitPayment = (
  paymentData: Object,
  basicInfo: Object
): RequestOptions => ({
  type: `[API] Payment Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body: {
    userKey: basicInfo.userKey,
    agencyId: basicInfo.agencyId,
    appId: basicInfo.appId,
    profileInfo: basicInfo.selectedLicense,
    transactionInfo: {
      ...paymentData,
      renewalFee: basicInfo.renewalFee,
      feeCodes: basicInfo.feeCodes,
    },
    transactionId: APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
  },
});

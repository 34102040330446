//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputText, Content } from '../../../../common';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName First Name
 */
const InputFirstName = ({ className, ...props }: Props) => (
  <InputText {...props} className={classnames('input-first-name', className)} />
);

InputFirstName.defaultProps = {
  label: <Content type="Labels" id="FirstName" />,
  name: 'fName',
  autoComplete: 'fName',
};

export default InputFirstName;

//@flow

// Vendors
import React, { type Node, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DateTime, Duration } from 'luxon';

// Actions
import { openModal, closeModal } from '../../reducers/modal/modalActions';
import {
  endSession,
  updateSession,
} from '../../reducers/session/sessionActions';
import { keepAliveRequest } from '../../backend';

// Selectors
import { selectSessionData } from '../../reducers/session/sessionSelectors';

// Components
import SessionWarningModal from '../common/Modals/SessionWarningModal/SessionWarningModal';
import { PasswordExpiringCountdown } from '../common';

type Props = {
  sessionData: {
    latestAccessTime: string,
    maxIdleExpirationTime: string,
    maxSessionExpirationTime: string,
  },
  closeModal: Function,
  openModal: Function,
  keepAliveRequest: Function,
  updateSession: Function,
  endSession: Function,
  children: Node,
};

type State = {
  latestAccessTime: string | null,
  warningDuration: Duration,
};

class SessionManager extends PureComponent<Props, State> {
  duration = 2;

  state = {
    latestAccessTime: null,
    warningDuration: Duration.fromObject({ minutes: this.duration }),
  };

  meaningfulTime = this.state.warningDuration.as('milliseconds');
  meaningfulTimeout: Function;
  warningCountdown: Function;
  warningTimer: Function;
  sessionTimer: Function;

  meaninfulActionTriggered = event => {
    if (!this.isMeaningfulEvent(event)) return false;
    this.removeEventListeners();
    this.keepAlive();
  };

  constructor() {
    super();
    // This is needed to help clear event listeners within a class.
    this.meaninfulActionTriggered = this.meaninfulActionTriggered.bind(this);
  }

  initializeCountdown() {
    this.setState({
      warningDuration: Duration.fromObject({ minutes: this.duration }),
    });

    this.warningCountdown = setInterval(
      this.decrementWarningDuration.bind(this),
      1000
    );
    this.warningTimer = setTimeout(
      this.endSession.bind(this),
      this.meaningfulTime
    );
  }

  decrementWarningDuration() {
    this.setState({
      warningDuration: this.state.warningDuration.minus({ second: 1 }),
    });
  }

  stopCountdown() {
    clearInterval(this.warningCountdown);
    clearTimeout(this.warningTimer);
  }

  initializeMeaningfulTimeout() {
    clearTimeout(this.meaningfulTimeout);
    this.meaningfulTimeout = setTimeout(
      this.beginMeaningfulWatch.bind(this),
      this.meaningfulTime
    );
  }

  beginMeaningfulWatch() {
    this.removeEventListeners();
    this.addEventListeners();
    clearTimeout(this.meaningfulTimeout);
  }

  addEventListeners() {
    window.addEventListener('scroll', this.meaninfulActionTriggered, true);
    window.addEventListener('click', this.meaninfulActionTriggered, true);
    window.addEventListener('focusin', this.meaninfulActionTriggered, true);
  }

  removeEventListeners() {
    window.removeEventListener('scroll', this.meaninfulActionTriggered, true);
    window.removeEventListener('click', this.meaninfulActionTriggered, true);
    window.removeEventListener('focusin', this.meaninfulActionTriggered, true);
  }

  isRecent(ISO: string) {
    return (
      DateTime.local().toMillis() - DateTime.fromISO(ISO).toMillis() <
      this.meaningfulTime
    );
  }

  isMeaningfulEvent(event: any) {
    if (!event) return false;
    return (
      event.type === 'scroll' ||
      (event.type === 'click' &&
        (event.target.nodeName === 'BUTTON' ||
          event.target.nodeName === 'A')) ||
      (event.type === 'focusin' && event.target.nodeName === 'INPUT')
    );
  }

  keepAlive() {

         //get the user Oauth header
   const guid =  window.sessionStorage.getItem("guid");
   const userName = window.sessionStorage.getItem("userName");
   const uid = window.sessionStorage.getItem("_id");
 
   const userHeaders = {
     guid: guid,
     userName: userName,
     _id: uid
   }
    this.props.closeModal('SessionWarningModal');
    this.initializeMeaningfulTimeout();
    this.props.keepAliveRequest(userHeaders).then(({ body, response }) => {
      //  This is needed to manually trigger an update for componentWillReceiveProps
      if (body.status === 'SUCCESS')
        this.props.updateSession(response.headersObject);
    });
  }

  beginSessionWarning() {
    this.removeEventListeners();
    this.props.openModal('SessionWarningModal');
  }

  endSession() {
    this.removeEventListeners();
    clearTimeout(this.meaningfulTimeout);
    clearInterval(this.warningCountdown);
    clearTimeout(this.warningTimer);
    clearTimeout(this.sessionTimer);
    this.props.endSession();
  }

  resetSessionTimer(sessionData) {
    clearTimeout(this.sessionTimer);
    this.setState({ latestAccessTime: sessionData.latestAccessTime });
    this.sessionTimer = setTimeout(
      this.beginSessionWarning.bind(this),
      DateTime.fromISO(sessionData.maxIdleExpirationTime)
        .minus({ minutes: this.duration, seconds: 1 })
        .toMillis() - DateTime.local().toMillis()
    );
  }

  componentDidMount() {
    this.initializeMeaningfulTimeout();
  }

  UNSAFE_componentWillReceiveProps() {
    const { sessionData } = this.props;
    if (!sessionData || !sessionData.latestAccessTime) return false;
    if (
      sessionData.latestAccessTime !== this.state.latestAccessTime &&
      this.isRecent(sessionData.latestAccessTime)
    )
      this.resetSessionTimer(sessionData);
  }

  componentWillUnmount() {
    clearTimeout(this.meaningfulTimeout);
    clearTimeout(this.sessionTimer);
  }

  render() {
    return (
      <>
        {this.props.children}
        <SessionWarningModal
          duration={this.state.warningDuration}
          onOpen={this.initializeCountdown.bind(this)}
          onClose={this.stopCountdown.bind(this)}
          keepAlive={this.keepAlive.bind(this)}
        />
      
      </>
    );
  }
}



const mapStateToProps = state => ({ sessionData: selectSessionData(state) });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators(
    { openModal, closeModal, keepAliveRequest, endSession, updateSession },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputRadioGroup, Content } from '../../../..';

// Styles
import './input-group-mfa-options.scss';

// Types
type Props = {
  className?: string,
  options: Array<Object>,
  optionValueKey: string,
  formikProps: FormikProps,
  inverse: boolean,
  forgotPasswordFlow: boolean,
};

/**
 * @visibleName MFA Options
 */
const InputGroupMFAOptions = ({
  inverse,
  options,
  optionValueKey,
  className,
  formikProps,
  forgotPasswordFlow,
  ...props
}: Props) => {
  if (!options) return null;
  const labelOptions = {
    SMS: { text: <Content type="MFA" id="RadioSMS" /> },
    SMTP: { text: <Content type="MFA" id="RadioSMPT" /> },
    QA: { text: '' },
    YK: { text: <Content type="YK" id="RadioYK" /> },
    AA: { text: <Content type="MFA" id="RadioAA"/>},
    CALL: {text: ''},
  };

  const getLabel = (value) => (
    <>
      {typeof value === 'string' ? ` ${value}` : ''}
    </>
  );
  const getLabelForgotPassword = ({ type, value }: { type: string, value: string }) => (
    <>
      {labelOptions[type].text}
      {typeof value === 'string' ? ` ${value}` : ''}
    </>
  );
  const optionsSorted = options.reduce((acc, curr) => {
    const option = {
      id: `MFAOption${curr[optionValueKey]}`,
      name: 'MFAOption',
      label: forgotPasswordFlow ? getLabelForgotPassword(curr) : getLabel(curr.value),
      value: curr,
    };

    // TODO: Fix the way we are sorting these options to avoid empty items into the array...
    if (curr.type === 'SMS') acc[0] = option;
    if (curr.type === 'CALL') acc[1] = option;
    if (curr.type === 'SMTP') acc[2] = option;
    if (curr.type === 'QA') acc[3] = option;
    if (curr.type === 'YK') acc[4] = option;
    if (curr.type === 'AA') acc[5] = option;


    return acc;
  }, []);



  return (
    <>
     <InputRadioGroup
      {...props}
      name="MFAOption"
      className={classnames('input-group-mfa-options', className)}
      options={optionsSorted}
      optionValueKey={optionValueKey}
      inverse={inverse}
      formikProps={formikProps}
    />
    
    </>
  );
};

export default InputGroupMFAOptions;

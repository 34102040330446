
//actions
import { push } from 'connected-react-router';

// Consts
export const NAVBAR_SEARCH_FORM_TOGGLE = '[NAVBAR] Toggle Search';
export const NAVBAR_TAKEOVER_CONTENT_SET = '[NAVBAR] Set Takeover Nav Content';


export const toggleSearchForm = open => (dispatch, getState) => {
  const { navbar } = getState();
  const payload = typeof open === 'boolean' ? open : !navbar.searchActive;
  const bodyClassList = document.body.classList;

  if (document.body && payload) bodyClassList.add('searchActive');
  else bodyClassList.remove('searchActive');

  return dispatch({ type: NAVBAR_SEARCH_FORM_TOGGLE, payload });
};

export const goToAcount = () => dispatch => {
  return dispatch(push(`/account`));
};

export const goToEmployeePage = () => dispatch => {
  return dispatch(push(`/employee`));
};

export const goToSelfService = () => dispatch => {
  return dispatch(push(`/self-service`));
};

 
export const setTakeoverNavContent = payload => dispatch =>
  dispatch({ type: NAVBAR_TAKEOVER_CONTENT_SET, payload });



import { REQUEST_TYPES } from '.';
import bulletinConfig from '../bulletinConfig';


function determineEnvironment() {
  const hostname = window.location.hostname;

  if (hostname === 'dev.myaccess.texas.gov') {
    return 'dev';
  } else if (hostname === 'sbx.myaccess.texas.gov') {
    return 'test';
  } else if (hostname === 'stage.myaccess.texas.gov') {
    return 'stage';
  } else if (hostname === 'myaccess.texas.gov') {
    return 'prod';
  } else {
    // Default to a fallback environment if the hostname doesn't match any known domains
    return 'prod';
  }
}

const environment = determineEnvironment(); // Replace with your logic

const bulletinPass = bulletinConfig[environment];



export const MessageBoxRequest = (
    url: string
  ) => ({
    type: '[MESSAGE BOX] FETCHING MESSAGE BOX',
    url,
    location: "",
    APIMethod: REQUEST_TYPES.POST, // Assuming REQUEST_TYPES is properly defined elsewhere
    headers: {
      'Content-Type': 'application/json',
      'X-OpenAM-Username': 'txt-api-user@test.com',
      'X-OpenAM-SharedSecret': bulletinPass,
      'Accept-API-Version': 'resource=2.0,protocol=1.0'
    },
   
});

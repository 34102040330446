export default {
  SecurityQuestionLabel: `Pregunta`,
  SecurityQuestion_empty: `Selecciona una pregunta...`,
  SecurityQuestion_4XrgMtHu1azsu1lv: `¿En qué ciudad te encuentras con su cónyuge / pareja?`,
  SecurityQuestion_8CwzOnpgYb32tTnb: `Como un niño, lo que era su comida o refrigerio favorito?`,
  SecurityQuestion_Anvi389SneigoanEN: `¿Quién fue tu héroe de la infancia?`,
  SecurityQuestion_DLGB3K1u07CkU3iM: `Como un niño, ¿cuál era su juego favorito?`,
  SecurityQuestion_Hwncvaoin308asdn: `¿Dónde está su lugar favorito de vacaciones?`,
  SecurityQuestion_J0259fCwkaIq8MxFf: `¿En qué ciudad su madre y el padre de mes?`,
  SecurityQuestion_Kn39NWEoacbiwoap: `¿Cuál era su apodo de la infancia?`,
  SecurityQuestion_M48NsciaenId80LS: `¿En qué ciudad o pueblo fue tu primer trabajo?`,
  SecurityQuestion_MG293OnwoIVawe8: `¿Cuál era el nombre de tu primera mascota?`,
  SecurityQuestion_N4gke02Endvoa348: `¿Cuál fue el último nombre de un maestro de escuela primaria?`,
  SecurityQuestion_N6wojnF4C1sqBqsV: `Cuál era tu materia favorita en la escuela?`,
  SecurityQuestion_NtnPqDChOo5eXoT5: `¿Cuál era el nombre de la calle en la que se crió en?`,
  SecurityQuestion_Oh3Mgnw09qw3Pnw: `¿Cuál fue el vehículo que conducía favorita?`,
  SecurityQuestion_PXnFwlYtkUuASJN1: `¿Cuál fue el primer concierto al que fuiste?`,
  SecurityQuestion_RweNQj7Ljr5ysOIM: `¿Cuál es tu bebida favorita?`,
  SecurityQuestion_V5SnwoiWNd892PM: `¿Cuál es el nombre de su amigo de la infancia favorito?`,
  SecurityQuestion_hDo390NvwoagmHp: `¿Cuál era el nombre de un campamento de verano que asistió?`,
  SecurityQuestion_mNVwaong289NDw: `¿Qué quieres ser cuando seas grande?`,
  SecurityQuestion_pNw4820nvSgPqm3: `Lo escuela asistió a la escuela secundaria?`,
};

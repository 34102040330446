import LinkService from '../LinkService/LinkService.en.js';

const AgencyName = 'Texas Board of Nursing';
const AgencyShortName = 'Board of Nursing';
const BNLRCriminalList = `<li>been arrested and have a pending criminal charge?</li>
<li>been convicted of a misdemeanor?</li>
<li>been convicted of a felony?</li>
<li>pled nolo contendere, no contest, or guilty?</li>
<li>received deferred adjudication?</li>
<li>been placed on community supervision or court-ordered probation, whether or not adjudicated guilty?</li>
<li>been sentenced to serve jail, prison time, or court-ordered confinement?</li>
<li>been granted pre-trial diversion?</li>
<li>been cited or charged with any viliation of the law?</li>
<li>been subject of a court-martial; Article 15 viliation; or received any form of military judgement/punishment/action?</li>`;

const BNLRExpungedTitle = `Expunged and Sealed Offenses`;
const BNLRExpungedContent = `Has any licensing authority ever refused to issue you a license or ever revoked, annulled, cancelled,
accepted surrender of, suspended, placed on probation, refused to renew a license, certificate, or
multi-state privilege held by you now or previously, or ever fined, censured, reprimanded, or otherwise
disciplined you? (You may exclude disciplinary actions issued by the Texas Board of Nursing and
disciplinary actions previously disclosed to the Texas Board of Nursing on an initial licensure or renewal
application.)`;
const BNLRNonDisclosureTitle = `Orders of Non-Disclosure`;
const BNLRNonDisclosureContent = `Pursuant to Tex. Gov't Code §552.142(b), if you have criminal matters that are the subject of an order of
non-disclosure you are not required to reveal those criminal matters on this form. However, a criminal
matter that is the subject of an order of non-disclosure may become a character and fitness issue.
Pursuant to Gov't Code chapter 411, the Texas Nursing Board is entitled to access criminal history record
information that is the subject of an order of non-disclosure. If the Board discovers a criminal matter
that is the subject of an order of non-disclosure, even if you properly did not reveal that matter, the
Board may require you to provide information about any conduct that raises issues of character and
fitness.`;

export default {
  AgencyName,
  AgencyShortName,
  bnlr_AppName: 'Board of Nursing License Renewal',
  bnlr_AppShortName: 'Nursing License Renewal',
  bnlr_LinkService_LookupTitle: LinkService.LookupTitle,
  bnlr_LinkService_ConfirmTitleDefault: 'Your license has been located.',
  bnlr_LinkService_ConfirmDescriptionDefault:
    'Please review and confirm that this is the correct license to link to your account.',
  bnlr_LinkService_SuccessTitle: `Success! Your license is now linked to your account.`,
  bnlr_LookupLicense_Title: `Let's start by finding your eligible license to renew.`,
  bnlr_LookupLicense_ContentA: `Ready to renew your license? Review the&nbsp;`,
  bnlr_LookupLicense_ContentLink: `eligibility page`,
  bnlr_LookupLicense_ContentB: `&nbsp;to find out what you need before continuing.`,
  bnlr_LookupLicense_SubmitText: `Find My License`,
  bnlr_ConfirmLicense_Title: 'Please confirm your license information.',
  bnlr_LinkService_ConfirmTitleLinked: 'License already linked.',
  bnlr_LinkService_ConfirmDescriptionLinked:
    'This license is already linked and can be accessed in {{Link:[your dashboard](/)}}.',
  bnlr_ConfirmLicense_NotWithinWindowTitle: `We've located your license, but you are not within the renewal window.`,
  bnlr_ConfirmLicense_NotWithinWindowContent:
    'The license renewal window is open for 60 days before the expiration date.',
  bnlr_ConfirmLicense_LookupAnother: `If this isn't the right license, look up a&nbsp;`,
  bnlr_ConfirmLicense_LookupAnotherButton: 'different one',
  bnlr_ConfirmLicense_LookupAnotherPunctuation: '.',
  bnlr_ConfirmLicense_LinkReason:
    'Link your account to get reminders when your license is ready to be renewed.',
  bnlr_VerifyEligibility_Title: 'Verify eligibility.',
  bnlr_VerifyEligibility_LegalAcknowledgement: `I acknowledge that this electronic form is a legal document. I understand that no one else may submit this
  form on my behalf and that I am accountable and responsible for the accuracy of any answer or statement on
  this form. I attest that I meet all the requirements to practice for the type of renewal requested, as
  listed in 22 Texas Administrative Code, ch. 216 (CE). Further, I understand that it is a violation of the
  22 Texas Administrative Code § 217.12(6)(I) and Penal Code § 37.10, to submit a false statement to a
  governmental agency.`,
  bnlr_VerifyEligibility_APRNRequirementNote:
    'APRNs must also meet all requirements listed in 221.8 (APRN); and 222.3 (LPA).',
  bnlr_VerifyEligibility_CriminalIntro: `Have you, within the past 24 months or since your last renewal, for any criminal offense, including those
  pending appeal:`,
  bnlr_VerifyEligibility_CriminalList: BNLRCriminalList,
  bnlr_VerifyEligibility_Exclusion: `You may only exclude Class C misdemeanor traffic violations or offenses previously disclosed to the Texas
  Board of Nursing on an initial or renewal application.`,
  bnlr_VerifyEligibility_ExpungedTitle: BNLRExpungedTitle,
  bnlr_VerifyEligibility_ExpungedContent: BNLRExpungedContent,
  bnlr_VerifyEligibility_NonDisclosureTitle: BNLRNonDisclosureTitle,
  bnlr_VerifyEligibility_NonDisclosureContent: BNLRNonDisclosureContent,
  bnlr_VerifyEligibility_GrandJuryTarget: `Are you currently the target or subject of a grand jury or governmental agency investigation?`,
  bnlr_VerifyEligibility_Licensing: `Has any licensing authority ever refused to issue you a license or ever revoked, annulled, cancelled,
  accepted surrender of, suspended, placed on probation, refused to renew a license, certificate, or
  multi-state privilege held by you now or previously, or ever fined, censured, reprimanded, or otherwise
  disciplined you? (You may exclude disciplinary actions issued by the Texas Board of Nursing and
  disciplinary actions previously disclosed to the Texas Board of Nursing on an initial licensure or renewal
  application.)`,
  bnlr_VerifyEligibility_DiagnosedDisorders: `In the past five (5) years, have you been diagnosed with or treated or hospitalized for schizophrenia
  and/or psychotic disorders, bipolar disorder, paranoid personality disorder, antisocial personality
  disorder, or borderline personality disorder which impaired or does impair your behavior, judgement, or
  ability to function in school or work? (You may answer "No" if you have completed and/or are in compliance
  with TPAPN for mental illness OR you've previously disclosed to the Texas Board of Nursing and have
  remained compliant with your treatment regime and have had no further hospitalization since disclosure.)`,
  bnlr_VerifyEligibility_Addiction: `In the past 5 years, have you been addicted or treated for the use of alcohol or any other drug? (You may
    answer "No" if you have completed and/or are in compliance with TPAPN.)`,
  bnlr_Payment_Title: 'Payment',
  bnlr_IneligibleOnline_Title: `Unfortunately, we're not able to renew your license online.`,
  bnlr_IneligibleOnline_Reason: `At least one of the responses on the eligibility page disqualify you from renewing online. Please return a
  completed paper renewal form to the Board of Nursing before your license expiration date.`,
  bnlr_IneligibleOnline_RequirementsLink: `View the eligibility requirements here.`,
  bnlr_IneligibleOnline_SendFormsTo: ` <p><strong>Send forms to:</strong><br />333 Guadalupe<br />Suite 3-460<br />Austin, TX 78701-3944</p>`,
  bnlr_IneligibleOnline_TimelyRenewalTitle: `Timely Renewal`,
  bnlr_IneligibleOnline_TimelyRenewalContent: `Processing time is two weeks. To avoid late fees, renewals must be postmarked no later than the last day of the month in which your nursing license expires.`,
  bnlr_IneligibleOnline_LVNDownload: `LVN: Licensed Vocational Nurse Renewal Form`,
  bnlr_IneligibleOnline_RNDownload: `RN: Registered Nurse Renewal Form`,
  bnlr_IneligibleOnline_RNAPRNDownload: `RN/APRN: Registered Nurse with Advanced Practice Recognition Renewal Form`,
  bnlr_IneligibleOnline_RNLVNDownload: `RN/LVN Dual License: Licensed Vocational Nurse and Registered Nurse Renewal Form`,
  bnlr_IneligibleOnline_VolunteerDownload: `Volunteer Retired Nurse Authorization Form`,
  bnlr_IneligibleOnline_GracePeriodTitle: `Grace period for nursing licenses not eligible for online renewal.`,
  bnlr_IneligibleOnline_GracePeriodContent: ` Nurses who submit the timely renewal application and correct minimum payment, postmarked no later than the last
  day of the month the license is due to expire are exempt from TOC § 301.301(f) and may continue to practice for a
  period of up to one month from the license expiration date while the renewal is in process. The Renewal Department
  will communicate with the nurse regarding any outstanding licensure issues (i.e. need for a TGSLC release letter,
  compliance with continuing competency audit etc). If all renewal requirements are not met within the subsequent
  month, the license will be placed on delinquent status. During the grace period, the website will reflect that the
  license is current though the expiration date has passed. The BON website will be updated to reflect the future
  expiration date once the renewal process is completed. A nurse who does not submit the timely renewal application
  and correct payment, postmarked on/before the license expiration date, must follow the delinquent renewal process
  and no grace period will apply.`,
  bnlr_IneligibleOnline_DelinquentTitle: `Delinquent Renewal`,
  bnlr_IneligibleOnline_DelinquentContent: `In order to renew a license in Delinquent status, proof of continuing nursing education submitted with your
  renewal form is required.`,
  bnlr_IneligibleOnline_LVNDelinquentDownload: `LVN: Delinquent (Expired) Licensed Vocational Nurse Renewal Form`,
  bnlr_IneligibleOnline_RNDelinquentDownload: `RN: Delinquent (Expired) Registered Nurse Renewal Form`,
  bnlr_IneligibleOnline_RNAPRNDelinquentDownload: `RN/APRN: Delinquent (Expired) Registered Nurse with Advanced Practice Recognition Renewal Form`,
  bnlr_IneligibleOnline_RNLVNDelinquentDownload: `RN/LVN Dual License: Licensed Vocational Nurse and Registered Nurse Renewal Form`,
  bnlr_IneligibleOnline_ReactivationTitle: `Reactivation`,
  bnlr_IneligibleOnline_ReactivationContent: `In order to renew a license in Inactive status, proof of continuing nursing education submitted with your renewal form is required. Proof of continuing nursing education is required to renew a Inactive license.`,
  bnlr_IneligibleOnline_LVNReactivationDownload: `LVN: Reactivation form for a Licensed Vocational Nurse`,
  bnlr_IneligibleOnline_RNReactivationDownload: `RN: Reactivation form for a Registered Nurse`,
  bnlr_IneligibleOnline_RNAPRNReactivationDownload: `RN/APRN: Reactivation form for a Registered Nurse with Advanced Practice Authorization`,
  bnlr_IneligibleOnline_RNLVNReactivationDownload: `APRN Only: Reactivation form for Inactive/Delinquent Advanced Practice Recognition`,
  bnlr_EligibilityRequirements_PageTitle: `Board of Nursing License Renewal Eligibility Requirements`,
  bnlr_EligibilityRequirements_Title: `Online Renewal Eligibility Requirements`,
  bnlr_EligibilityRequirements_Subtitle: `You may renew online unless one of the following is true:`,
  bnlr_EligibilityRequirements_EligibilityList: `<li>The Nurse has defaulted on a Texas Guaranteed Student Loan.</li>
  <li>The Nurse has not met the mandatory continuing nursing education (CNE) requirements and/or has been selected
    for a CNE Audit. Proof of continuing nursing education must be received in our office in order for the license
    to be released or renewed.</li>
  <li>The Nurse has been selected for a Criminal Background Check and the process has not been completed.</li>
  <li>The Nurse is recognized as an Advanced Practice Registered Nurse and has not met the mandatory APRN audit
    requirements. Proof of current national certification for each type of approved recognition must be received
    in our office in order for the license to be released for renewal.</li>
  <li>The Nurse's primary state of residence is a compact state other than Texas. Check here to see more information regarding the Nurse Licensure Compact and to check if your state is included.</li>
  <li>The license number and/or last four digits of the Social Security Number entered do not match the information on the Boards files.</li>
  <li>been subject of a court-martial; Article 15 violation; or received any form of military judgement/punishment/action?</li>`,
  bnlr_EligibilityRequirements_EligibilityIssuesTitle: `Eligibility Issues`,
  bnlr_EligibilityRequirements_EligibilityIssuesContent: `Have you, within the past 24 months or since your last renewal, for any criminal offense, including those pending appeal:`,
  bnlr_EligibilityRequirements_CriminalList: BNLRCriminalList,
  bnlr_EligibilityRequirements_ExpungedTitle: BNLRExpungedTitle,
  bnlr_EligibilityRequirements_ExpungedContent: BNLRExpungedContent,
  bnlr_EligibilityRequirements_NonDisclosureTitle: BNLRNonDisclosureTitle,
  bnlr_EligibilityRequirements_NonDisclosureContent: BNLRNonDisclosureContent,
  bnlr_EligibilityRequirements_GrandJuryTarget: `Are you currently the target or subject of a grand jury or governmental agency investigation?`,
  bnlr_EligibilityRequirements_Licensing: `Has any licensing authority ever refused to issue you a license or ever revoked, annulled, cancelled,
  accepted surrender of, suspended, placed on probation, refused to renew a license, certificate, or
  multi-state privilege held by you now or previously, or ever fined, censured, reprimanded, or otherwise
  disciplined you? (You may exclude disciplinary actions issued by the Texas Board of Nursing and
  disciplinary actions previously disclosed to the Texas Board of Nursing on an initial licensure or renewal
  application.)`,
  bnlr_EligibilityRequirements_DiagnosedDisorders: `*In the past five (5) years, have you been diagnosed with or treated or hospitalized for schizophrenia
  and/or psychotic disorders, bipolar disorder, paranoid personality disorder, antisocial personality
  disorder, or borderline personality disorder which impaired or does impair your behavior, judgement, or
  ability to function in school or work? (You may answer "No" if you have completed and/or are in compliance
  with TPAPN for mental illness OR you've previously disclosed to the Texas Board of Nursing and have
  remained compliant with your treatment regime and have had no further hospitalization since disclosure.)`,
  bnlr_EligibilityRequirements_Addiction: `*In the past 5 years, have you been addicted or treated for the use of alcohol or any other drug? (You may
    answer "No" if you have completed and/or are in compliance with TPAPN.)`,
  bnlr_EligibilityRequirements_Footnote: `*Pursuant to the Occupations Code §301.207, information, including diagnosis and treatment, regarding an
    individual's physical or mental condition, intemperate use of drugs or alcohol, or chemical dependency and
    information regarding an individual's criminal history is confidential to the same extent that information
    collected as part of an investigation is confidential under the Occupations Code §301.466.`,
};

export default {
  SecurityQuestionLabel: 'Question',
  SecurityQuestion_empty: 'Select a question...',
  SecurityQuestion_4XrgMtHu1azsu1lv:
    'In what city did you meet your spouse/significant other?',
  SecurityQuestion_8CwzOnpgYb32tTnb:
    'As a child, what was your favorite food or snack?',
  SecurityQuestion_Anvi389SneigoanEN: 'Who was your childhood hero?',
  SecurityQuestion_DLGB3K1u07CkU3iM: 'As a child, what was your favorite game?',
  SecurityQuestion_Hwncvaoin308asdn: 'Where is your favorite vacation spot?',
  SecurityQuestion_J0259fCwkaIq8MxFf:
    'In what city did your mother and father meet?',
  SecurityQuestion_Kn39NWEoacbiwoap: 'What was your childhood nickname?',
  SecurityQuestion_M48NsciaenId80LS: 'In what city or town was your first job?',
  SecurityQuestion_MG293OnwoIVawe8: 'What was the name of your first pet?',
  SecurityQuestion_N4gke02Endvoa348:
    'What was the last name of an elementary school teacher?',
  SecurityQuestion_N6wojnF4C1sqBqsV:
    'What was your favorite subject in school?',
  SecurityQuestion_NtnPqDChOo5eXoT5:
    'What was the name of the street you grew up on?',
  SecurityQuestion_Oh3Mgnw09qw3Pnw: 'What was the favorite vehicle you drove?',
  SecurityQuestion_PXnFwlYtkUuASJN1: 'What was the first concert you attended?',
  SecurityQuestion_RweNQj7Ljr5ysOIM: 'What is your favorite beverage?',
  SecurityQuestion_V5SnwoiWNd892PM:
    'What is the name of your favorite childhood friend?',
  SecurityQuestion_hDo390NvwoagmHp:
    'What was the name of a summer camp you attended?',
  SecurityQuestion_mNVwaong289NDw: 'What did you want to be when you grew up?',
  SecurityQuestion_pNw4820nvSgPqm3:
    'What school did you attend for middle school?',
};

//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Download } from 'texkit/dist/components/Download';
import Content from '../../Content/Content';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { closeModal } from '../../../../reducers/modal/modalActions';
import { push } from 'connected-react-router';

// Styles
import './redirect-new-tab-modal.scss';

type Props = {
  modal: Object,
  closeModal: Function,
};

const RedirectNewTabModal = ({ modal: { data }, closeModal }) => {
  const dispatch = useDispatch();

  const leaveWebsite = () => {
    closeModal('RedirectNewTabModal', {
      onClose: () => window.open(data.txURL),
    });
  };
  return (
    <Modal
      aria-labelledby="Just a heads up modal."
      name="RedirectNewTabModal"
      className="redirect-new-tab-modal"
      disableCloseButton={true}
    >
      <div className="flex-row">
        <div className="redirect-new-tab-modal-header redirect-new-tab-modal-content">
          <div className="redirect-new-tab-modal-content-overflow" tabIndex="0">
            <h2 className="redirect-new-tab-modal-header-title" align="center">
              <Content type="Modals" id="JustAHeadsUp" />
            </h2>
            <p className="redirect-new-tab-modal-header-content" align="center">
              <Content type="Modals" id="YoureLeavingThe" />
              <Content type="Common" id="SiteName" />
              <Content type="Modals" id="AndOpeningAnExternalWebsite" />
            </p>
          </div>
          <br />
          <div align="center">
            <ButtonPrimary onClick={() => leaveWebsite()}>
              <Content type="Modals" id="OkLetsGo" />
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedirectNewTabModal);

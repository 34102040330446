import { SITE_DATA } from '../../data';

export default {
  WelcomeTitle: `Bienvenido, `,
  WelcomeDescription: `Sólo tenemos unos pocos pasos para asegurarse que su ${SITE_DATA.SITE_NAME} experiencia es seguro y conveniente.`,
  SecurityQuestionsTitle: `Configurar las preguntas de seguridad.`,
  SecurityQuestionsDescription: `Por favor seleccione tres preguntas de seguridad para que podamos verificar su identidad si alguna vez hay un problema con su cuenta.`,
  PhoneTitle: `Por favor, compruebe su número de teléfono móvil.`,
  PhoneDescription: `Esto se usará para confirmar su identidad durante el inicio de sesión y para la recuperación de la contraseña.`,
  CompleteTitle: `Obtener el máximo provecho de su cuenta.`,
  CompleteDescription: `Enlace sus licencias y registros existentes para recibir recordatorios de fechas importantes y renovaciones simplifed.`,
  GovEmploymentStatusTitle: `¿Es usted un empleado del sector público?`,
  GovEmploymentStatusDescription: `Si usted es un empleado del gobierno estatal o local, es posible que tenga disponible una funcionalidad adicional.`,
  GovEmailTitle: `Como empleado del sector público, verifique su correo electrónico de trabajo.`,
  GovEmailDescription: `Esto se usará para verificar su estado y elegibilidad para ciertas funciones. Ingrese su correo electrónico de trabajo a continuación para recibir un código de verificación.`,
  GovEmailVerificationTitle:
    'Por favor verifique su correo electrónico de trabajo.',
  Field_GovernmentDomain:
    'Asegúrese de que la dirección de correo electrónico que está ingresando sea un correo electrónico gubernamental verificado.',
  LaterAccountSettings:
    'Lo haré más tarde a través de la configuración de la cuenta',
};

//@flow

// Vendors
import React, { type Node } from 'react';
import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './hero-employee.scss';

// Types
type Props = {
  className?: string,
  children: Node
};

const HeroEmployee = ({ className, children }: Props) => (
  <header className={classnames('hero-employee', className)} role="banner">
    <Container className="hero-employee-container">
      <div className="hero-employee-body">{children}</div>
    </Container>
  </header>
);

export default HeroEmployee;

//@flow

// Vendors
import React from 'react';

// Components
import { Content } from '../..';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Helpers
import { formatAsMoney } from '../../../lib/helpers';

const FeeSummary = ({ feeInfo }: Object) => {
  if (!feeInfo) return null;

  const renewalFeeSummaryCardListItems = [
    {
      label: <Content type="Labels" id="RenewalFee" />,
      value: formatAsMoney(feeInfo.fee),
    },
    {
      label: <Content type="Labels" id="TotalAmountDue" />,
      value: formatAsMoney(feeInfo.feeTotal),
    },
  ];

  if (feeInfo.lateFee)
    renewalFeeSummaryCardListItems.splice(1, 0, {
      label: <Content type="Labels" id="LateFee" />,
      value: formatAsMoney(feeInfo.lateFee),
    });

  return (
    <SummaryCard>
      <SummaryCard.List items={renewalFeeSummaryCardListItems} />
    </SummaryCard>
  );
};

export default FeeSummary;

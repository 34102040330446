// Actions
import { signOut } from '../auth/authActions';
import { storeAlertInfo } from '../alert/alertActions';
import { closeModal } from '../modal/modalActions';

export const SESSION_UPDATED = '[SESSION] Updated';
export const SESSION_ENDED = '[SESSION] Ended';

export const updateSession = data => dispatch =>
  dispatch({
    type: SESSION_UPDATED,
    payload: {
      latestAccessTime: data.latestAccessTime || data.latestaccesstime,
      maxIdleExpirationTime:
        data.maxIdleExpirationTime || data.maxidleexpirationtime,
      maxSessionExpirationTime:
        data.maxSessionExpirationTime || data.maxsessionexpirationtime,
    },
  });

export const endSession = () => async dispatch => {
  dispatch(closeModal('SessionWarningModal'));
  await dispatch(signOut());
  dispatch(
    storeAlertInfo({
      type: 'warning',
      title: { id: 'SessionExpiredTitle' },
      message: { id: 'SignInAgain' },
      closeable: true,
    })
  );
  dispatch({ type: SESSION_ENDED });
};

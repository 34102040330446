// Data
import { SITE_DATA } from '../../data';

export default {
  Exception_Default: `Something went wrong. Please try again. If you continue to experience this issue, please contact your organization’s support team for assistance. Error Reference: {{1}}`,
  Exception_RefreshPage: `Something went wrong. Refresh the page and try again. If you continue to experience this issue, please contact your organization’s support team for assistance.`,
  Exception_UserNotFound: `There is an issue with your account, please contact your organization's support team for assistance.`,
  Exception_LicenseNotFound: `We couldn't find your license, please check your information and try again.`,
  Exception_AgencyNotFound: `We couldn't find your agency-records, please check your information and try again.`,
  Exception_ApplicationNotFound: `We couldn't find any applications linked to your accout.`,
  Exception_ContactNotVerified:
    'You need to verify a mobile phone to perform this function.',
  Exception_SecurityQuestionDupe: 'Each security question must be different.',
  Exception_IncorrectAnswers:
    'One or more of your answers are incorrect. Please try again.',
  Exception_OTPVerify: `That doesn't look right. Please try again. If you've received multiple verification codes, use the most recent one.`,
  Exception_OTPExpired: `The code has expired, click on "Resend code" to try again.`,
  Exception_MessageExceeded:
    'There have been too many unsuccessful attempts. Please try again later.',
  Exception_InvalidCredentials:
    'The password you entered does not match our records.',
  Exception_PasswordHistory: `Your new password must be one that you haven't used previously.`,
  Exception_PersonalCredentials: `You cannot use any PII.`,
  Personal_Attr_Error: `You cannot use any PII.`,
  Exception_PaymentFailed: `Your payment could not be processed. Please check your card details and try again.`,
  Exception_OTPSendFail:
    'A message could not be sent to {{1}}. Please try a different {{2}}.',
  Exception_VerifyAppLinkFail: `We couldn't find your license. Please try again.`,
  Exception_IDologyFail: 'Error with IDology: {{1}}',
  Exception_UserEmailExists:
    'That email address is already in use. Please use a different email address.',
  Exception_WorkEmailInUse:
    'Your email address must be different from your work email address. Please use another email address',
  Exception_UserRecordExists:
    'The information provided matches an active user. Try signing in.',
  Field_Required: 'This field is required.',
  Field_YesNoRequired: 'Please select either Yes or No.',
  Field_Min: 'The minimum character length is not satisfied for this field.',
  Field_MinWithLength: 'Please enter at least {{1}} characters.',
  Field_Max: 'You have exceeded the maximum character length for this field.',
  Field_MaxWithLength: 'Please do not enter more than {{1}} characters.',
  Field_InvalidChars:
    'You have entered invalid characters. Please change and try again.',
  Field_Sorry: `Sorry! That doesn't look right. Please try again.`,
  Field_IncorrectMFACode: `Sorry! That is the incorrect code.`,
  Field_NoSpecialChars: 'Please do not enter any special characters.',
  Field_AlphanumericOnly: 'Please enter only letters or numbers.',
  Field_NumericOnly: 'Please enter only numbers.',
  Field_PasswordStrength:
    'Password must contain a minimum of eight and a maximum of 32 characters and also include at least one uppercase letter, one lowercase letter, one number, and one special character.',
  Field_MetadataURL: 'Please enter a valid URL.',
  Field_MetadataURL_Required: 'URL Required.',
  Field_Metadata_Required: '!This field is required.',
  Field_Password_Invalid_Special_Char:
    'Password must not contain any invalid special characters. Please use a special character from the below list.',
  Field_Password_Invalid_Special_Char_Accounts:
    'Password must not contain any invalid special characters. Please use a special character from the following list: (. [ @ ! # $ ^ & + = ] ( _ -  ) {  }).',
  Field_AlphabeticalOnly:
    'Please do not enter any numbers or special characters.',
  Field_RequireLicenseOrAddress:
    'Either driver license information or address expected.',
  Field_IncorrectFormat: 'Incorrect format provided.',
  Field_SpecialCharacters:
    'Only the following special characters are allowed: [.@!#$^&+=](_-){}',
  Field_ExactDigits: 'Please enter exactly {{1}} numbers.',
  Field_SelectOption: 'Please select an option.',
  Field_DateFormat: 'Please enter a valid date.',
  Field_DatePast: 'The date must occur in the past.',
  Field_AllowedList: 'Only the following are allowed: {{1}}',
  Field_EmailInvalid: 'Please enter a valid email address.',
  Field_EmailRequired: 'Please enter an email address.',
  Field_EmailNoAccount: 'Sorry! This email does not have an account.',
  Field_EmailMatchingRequired: 'Please enter a matching email address.',
  Field_PasswordMin: 'Please use at least eight characters for your password.',
  Field_PasswordMax: 'Please enter a password with fewer than 128 characters.',
  Field_PasswordRequired: 'Please enter a password.',
  Field_PasswordIncorrect:
    'Sorry! The email and password you entered is incorrect.',
  Field_MatchingPasswordMismatch: 'Please enter a matching password.',
  Field_MatchingPasswordRequired: 'Please confirm your password.',
  Field_NewPasswordRequired: 'Please enter a new password.',
  Field_NewPasswordUnique: `Your new password can't be the same as your current password.`,
  Field_CurrentPasswordRequired: 'Please enter your current password.',
  Field_ContactCodeMin: 'Please enter a code with six digits.',
  Field_ContactCodeRequired: 'Please enter the six-digit code we sent you.',
  Field_ContactCodeAuthAppRequired: 'Please enter the six-digit code from your app',
  Field_PhoneValid: 'Please enter a valid phone number.',
  Field_PhoneTypeRequired: 'Please select a valid phone type.',
  Field_FirstNameRequired: 'Please enter your first name.',
  Field_LastNameRequired: 'Please enter your last name.',
  Field_CappsIdRequired: 'Please enter your CAPPS ID.',
  Field_DriverLicenseMin:
    'Please enter a valid driver license or state ID number.',
  Field_DriverLicenseRequired:
    'Please enter your driver license or state ID number.',
  Field_AuditNumberMin:
    'Please enter a valid audit number. Click on the information link below to learn more.',
  Field_AuditNumberRequired:
    'Please enter your audit number. Click on the information link below to learn more.',
  Field_DOBMin: 'The date you entered falls outside of the accepted range.',
  Field_DOBMax: 'You must be at least 18 years of age to continue.',
  Field_DOBRequired: 'Please enter your date of birth.',
  Field_CC_NameRequired: 'Please enter the name on your card.',
  Field_CC_NumberValid: 'Please enter a valid credit card number.',
  Field_CC_NumberRequired: 'Please enter your credit card number.',
  Field_CC_ExpirationMin: 'Please enter future date.',
  Field_CC_ExpirationRequired: 'Field required.',
  Field_CC_CVVValid: 'Please enter valid number',
  Field_CC_CVVRequired: 'Field required.',
  Field_ACH_NameOnCheckRequired: 'Please enter the name on your check.',
  Field_ACH_ABARoutingRequired: 'Please enter the ABA Routing number.',
  Field_ACH_ABARoutingConfirm:
    'Please make sure you have entered the ABA Routing number correctly.',
  Field_ACH_AccountNumberRequired: 'Please enter the account number.',
  Field_ACH_AccountNumberConfirm:
    'Please make sure you have entered the account number correctly.',
  Field_LastFourSSNMin: 'Please enter four digits.',
  Field_LastFourSSNRequired: 'Field required.',
  Field_TermsRequired: 'You must accept the terms and conditions to continue.',
  Field_Address_StreetAddressRequired: 'Please enter a valid street address.',
  Field_Auth_TypeRequired: 'Please enter a Auth Type',
  Field_Metadata_URLRequired: 'Please enter a type',
  Field_Metadata_FileRequired: 'Please enter a type',
  Field_Address_CityRequired: 'Please enter a city.',
  Field_Address_CountryRequired: 'Please enter a country.',
  Field_Address_StateRequired: 'Please select a state.',
  Field_Address_ZipFiveDigits: 'Please enter a five-digit zip code.',
  Field_Address_ZipFiveRequired: 'Please enter a zip code.',
  Field_Address_ForeignRequired: 'Please enter a foreign address.',
  Field_SecurityQuestions_AnswerMin:
    'For your security, answers must be at least three characters.',
  Field_SecurityQuestions_AnswerRequired: 'Please enter an answer.',
  Field_SecurityQuestions_QuestionRequired: 'Please select an option.',
  Field_IdentityQuestionRequired: 'Please select an answer.',
  Field_MFAOptionsRequired: 'Please select an account verification method.',
  Field_ProgramSelectRequired: 'Please select a Board/Program.',
  Field_LicenseSelectRequired: 'Please select a License.',
  Field_LicenseNumberRequired: 'Please enter your license number.',
  Field_PaymentMethodRequired: 'Please select a payment method.',
  ServiceDownModal_Title: 'Houston, we have a problem.',
  ServiceDownModal_Content:
    'services are unavailable at this time. Please try again after',
  ServerErrorModal_Title: 'Houston, we have a problem.',
  ServerErrorModal_Content: `Please try again. If you continue to experience this issue, please contact your organization’s support team for assistance.`,
  SessionTimedOut: `Your authentication session timed out. Please refresh and try signing in again.`,
  ProblemSendingCode: `There was a problem sending your code. Please refresh and try signing in again.`,
  PasswordExpiryErrorTitle: 'This is awkward…',
  PasswordExpiryErrorDesc: `<p class="lead">We're sorry but your link has expired. </p>`,
  PasswordExpiryErrorDesc2: `<p class="lead">Please contact your organization's support team for assistance.</p>`,
  Not_Enrolled:
    'Your account is not enrolled. Please click on the enrollment link sent to your work email.',
  PasswordLessThanOneDay:  'We can’t reset your password at this time because it was changed within the last 24 hours. Please try again later or contact your Administrator.',
};

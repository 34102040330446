// Helpers
import {
  to,
  callbacksWithSelectedMFAIndex,
  callbacksWithMFACode,
  callbacksWithMFACodeResend,
  updateWithDeviceDetails,
  updateWithDeviceDetailsAgain,
  updateWithRememberThisDevice,
  requiresMFACode,
  callbacksWithSelectedSecurityKey,
  requireMFABackOptions,
  getDeviceDetails,
  requireAuthMFABackOptions,
  officialAuthMFABackOptions,
  securityKeyMFABack,
  updateWithDeviceProfileDetails,
  updateCaptchaScore
} from '../../lib/helpers';
import { gaActions } from '../gaActionTypes';

// Requests
import {
  loginRequest,
  loginMFARequest,
  loginSuccessRequest,
  oauthloginRequest,
  userDefaultMFAOptions,
  oauthaccessTokenRequest,
  userInfoRequest,
  userOauthToken,
  captchaRequest,
  captchaLogRequest
} from '../../backend/requests';

// Actions
import { getSession } from '../auth/authActions';
import { storeAlertInfo, clearAlertInfo } from '../alert/alertActions';
import { selectRedirectLocation } from '../auth/authSelectors';
import {
  selectLoginMFACallbacks,
  selectLoginMFABody,
  selectRememberThisDevice,
  selectSelectedMFAOption,
  selectDefaultMFAOption,
  selectSecurityKeyOption,
  selectMFAOptions,
  selectLoginTokenId,
} from './loginSelectors';
import { checkRequestFailure, checkOTPError } from '../../lib/validation';
import { getUser, captchaUserVerify } from '../user/getUser/getUserActions';
import { openModal } from '../../reducers/modal/modalActions';
import * as errorActions from '../../reducers/error/errorActions';
import Axios from 'axios';


//modal
import { AccountDisabled } from '../../components';
import { async } from 'regenerator-runtime';


// Login flow actions
export const LOGIN_SUBMITTED = `[LOGIN] ${gaActions.submit} Login Submitted`;
export const LOGIN_DEVICE_DETAILS_SENT = `[LOGIN] Device Details Sent`;
export const LOGIN_MFA_OPTION_SENT = `[LOGIN] ${gaActions.submit} MFA Option Sent`;
export const LOGIN_MFA_SECOND_OPTION_SENT = `[LOGIN] ${gaActions.submit} MFA Second Option Sent`;
export const LOGIN_MFA_CODE_RESENT = `[LOGIN] ${gaActions.click} MFA Code Resent`;
export const LOGIN_MFA_CODE_SENT = `[LOGIN] ${gaActions.submit} MFA Code Sent`;
export const LOGIN_MFA_CODE_SENT_AGAIN = `[LOGIN] ${gaActions.submit} MFA Code Sent again`;
export const LOGIN_REMEMBER_DEVICE_SENT = `[LOGIN] Remember Device Sent`;
export const LOGIN_SUCCESS = `[LOGIN] ${gaActions.success} Login Success`;
export const LOGIN_FAILURE = `[LOGIN] ${gaActions.failure} Login Failure`;
export const LOGIN_DEVICE_DETAILS_SECOND = `[LOGIN] Second device details sent`;
export const LOGIN_SECOND_OPTION = `[LOGIN] Login MFA option sent`
export const LOGIN_MFA_INDICATOR = `[LOGIN] 2 MFA indicator option`;

// Other login actions
export const LOGIN_STORE_DATA = `[LOGIN] Data Stored`;
export const LOGIN_STORE_EMAIL = '[LOGIN] Email Stored';
export const LOGIN_UPDATE_MESSAGE = '[LOGIN] Message Updated';
export const LOGIN_RESET_MESSAGE = '[LOGIN] Reset Message';
export const LOGIN_SET_STEP = '[LOGIN] Set Step';
export const LOGIN_CLEAR_STATE = '[LOGIN] Clear State';
export const LOGIN_TOKEN= '[LOGIN] token stored';
export const MFA_TYPE = '[LOGIN] MFA type stored';
export const MFA_OPTIONS_SET = '[LOGIN] MFA options back value stored';
export const STORE_USER = '[LOGIN] User Stored';
export const STORE_CHECKBOX = `[LOGIN] Users checkbox display`;
export const SET_CHECKBOX_FALSE = `[LOGIN] User Checkbox set to false`;




// Login Steps
export const LOGIN_STEPS = {
  CREDENTIALS: 'credentials',
  MFA_OPTIONS: 'mfa-options',
  MFA_SECOND_OPTIONS: 'mfa-second-options',
  MFA_CODE: 'mfa-code',
  ADVANCED_SECURITY: 'advanced-security',
  MFA_OPTIONS_BACK: 'mfa-options-back',
  MFA_SECOND_CODE: 'mfa-second-code',
  MFA_SECOND_YUBIKEY: 'mfa-2-yubikey',
};




// NOTE: Login Middleware is used to control the logical flow of these functions.

export const loginStoreData = payload => dispatch =>
  dispatch({ type: LOGIN_STORE_DATA, payload });

export const resetMFACheckox = payload => dispatch => dispatch({type: SET_CHECKBOX_FALSE});


export const loginMFAPreference = (payload) => async (dispatch, getState) => {

    const state = getState();


    const mfaType = state.login.mfaType;
    

    if(mfaType !== null){


      

      const state = getState(); 
      const options = selectMFAOptions(state);
     
    if(options){

        
      var MFAOption = {};

      options.forEach(option => {
        if(mfaType.mfaType === option.type){
          MFAOption = option;
        }
        
      });

      


      


  
      const value = {MFAOption};
    
      
      const selectedMFAOption = value.MFAOption.value;
  
      
      
      const request = {
        body: {
          ...selectLoginMFABody(state),
          callbacks: callbacksWithSelectedMFAIndex({
            selectedMFAOption,
            callbacks: selectLoginMFACallbacks(state),
          }),
        },
      };
    
      
      
      
  
      const [{ body, response }] = await to(
        // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
        dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
      );
    
    
      return dispatch({
        type: LOGIN_MFA_OPTION_SENT,
        payload: { body, selectedMFAOption, type: value.MFAOption.type, },
      });
    
 
    }
  
      
        
        
    
    
    
  }

}


export const oauthAuthorize =  (values) => async (dispatch) => {

  
 //beginning of oauth
 const [abody, aerr] = await to(
  dispatch(userOauthToken(values))
)


dispatch({type: LOGIN_TOKEN, payload: abody.body})


}



export const loginSubmitted = (
  values,
  formikProps,
  token
) => async (dispatch, getState) => {
  




  const [tbody, terr] = await to(
    dispatch(userDefaultMFAOptions(values.email))
  );



  const [capbody, caperr] = await to(
    dispatch(captchaUserVerify(values.email, token))
  );
  
  const capBoolValue = JSON.parse(capbody.body.data);
  
  // if (typeof capBoolValue === 'boolean' && capBoolValue === false) {
  //   console.log('Captcha hit: ' + capBoolValue);
  
  //   formikProps.resetForm({ email: values.email, password: '' });
  //   formikProps.setSubmitting(false);
  //   dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
  //   return;
  // } else if (typeof capBoolValue === 'number' && capBoolValue < 0.2) {
  //   console.log('Captcha hit: ' + capBoolValue);
  
  //   formikProps.resetForm({ email: values.email, password: '' });
  //   formikProps.setSubmitting(false);
  //   dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
  //   return;
  // }
  

  await dispatch(getSession()); // Ensure we have the latest session data and redirectLocation

  

  
//updated captcha
  const [ captchaform , caerr] = await to(
    // dispatch(loginRequest(values, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(captchaRequest('/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))

    );

  
  dispatch({ type: LOGIN_STORE_DATA, payload: captchaform });

  const state = getState();


  let request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: updateCaptchaScore({callbacks: selectLoginMFACallbacks(state), score: capBoolValue }),
    },
  }

  
  const [{ body }, err] = await to(
    //this is the capctha request with the updated request
    dispatch(loginRequest( values, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE', request))
    // dispatch(loginRequest( values, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))

    );

  
  

 

  if(body.failureUrl){
    // account disabled modal
    dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
    formikProps.resetForm({ email: values.email, password: '' });
    formikProps.setSubmitting(false);
    return;
  }
    
  
   

  if (err) {
    formikProps.resetForm({ email: values.email, password: '' });
    formikProps.setSubmitting(false);
    return;
  }

  

  if (!body.errorMessage) {
    dispatch(clearAlertInfo());
    

    const defaultMfa = tbody.body.data;
      var mfaType = null;
  
  
      if(defaultMfa === 'email'){
        mfaType = 'SMTP';
      } else if (defaultMfa === 'voice'){
        mfaType = 'CALL';
      } else if(defaultMfa === 'sms'){
        mfaType = 'SMS';
      } else if( defaultMfa === 'yubikey'){
        mfaType = 'YK';
      } else if(defaultMfa === 'totp'){
        mfaType = 'AA';
      }

      

    dispatch({type: MFA_TYPE, payload:{mfaType}});

 
   
  return  dispatch({ type: LOGIN_SUBMITTED, payload: { body } , values: values, formikProps: formikProps });

  }




  // Login Failures
  formikProps.resetForm({ email: values.email, password: '' });
  formikProps.setSubmitting(false);

  switch (body.failureUrl) {
    case 'User credentials are incorrect':
      formikProps.setStatus({
        serverErrors: {
          email: true,
          password: { id: 'Field_PasswordIncorrect' },
        },
      });
      break;
    case 'User account is locked':
      break;
    default:
      formikProps.setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
      break;
  }
  return  dispatch({ type: LOGIN_SUBMITTED, payload: { body } });
};

export const sendMFAOptionsAgain = () => async (dispatch, getState) => {


  const state = getState();
  dispatch({type: MFA_OPTIONS_SET});

  
  const AuuthApp = state.login.type;

  if(AuuthApp === 'YK'){
    let request = {
      body: {
        ...selectLoginMFABody(state),
        callbacks: securityKeyMFABack({callbacks: selectLoginMFACallbacks(state)}),
      },
    }

    const [{ body, response }] = await to(
      // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
      dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );

      

    return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body } });


  }

  if(AuuthApp === 'AA' ){
    

    let request = {
      body: {
        ...selectLoginMFABody(state),
        callbacks: requireAuthMFABackOptions({callbacks: selectLoginMFACallbacks(state)}),
      },
    }


    const [{ body, response }] = await to(
      // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
      dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );

   return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body } });


       
  }

  

  const request = {
    body:{
      ...selectLoginMFABody(state),
      callbacks: requireMFABackOptions({callbacks: selectLoginMFACallbacks(state)}),
    },
  };


  
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  

  
  return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body } });


}

export const authAppBackDetails = () => async (dispatch, getState) => {

  const state = getState();
  
  let backrequest = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: officialAuthMFABackOptions({callbacks: selectLoginMFACallbacks(state)}),
    },
  }


  
    
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(backrequest, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );


  




  return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body } });

}

export const sendMFAIndicator = (payload, { setSubmitting, resetForm, setStatus }) => async (dispatch, getState) => {
  const state = getState();

  if(selectLoginMFACallbacks(state)) {
    const request = {
      body: {
        ...selectLoginMFABody(state),
        callbacks: await updateWithDeviceDetailsAgain(selectLoginMFACallbacks(state)),
      },
    };

    
    const [{ body, response }] = await to(
      dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );


    switch(body.errorMessage){
      case 'OPUC account not enrolled.':
        //Account is locked pop up modal
        dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        
        break;
      case 'Your password has expired':
         dispatch(openModal('PasswordExpiryModal'));
         resetForm();
         setSubmitting(false);
         return;
         
      case 'User Locked Out.':
        //Account is locked pop up modal
        dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        setSubmitting(false);
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
        break;
      case 'Your account has been disabled':
        dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        setSubmitting(false);
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      case 'Access Denied.':
        dispatch(openModal('AccessDenied', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        setSubmitting(false);
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      default:
        setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
        break;
    }
    

    return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body }  });

  }



}

export const sendDeviceDetailsagain = (payload, formikProps) => async (dispatch, getState) => {
  const state = getState();  

  if (selectLoginMFACallbacks(state)) {
    const request = {
      body: {
        ...selectLoginMFABody(state),
        callbacks: await updateWithDeviceDetailsAgain(selectLoginMFACallbacks(state)),
      },
    };

    
    const [{ body, response }] = await to(
      dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );

   

    if(body.successUrl){
      return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body }  });
    }

    switch(body.errorMessage){
      case 'OPUC account not enrolled.':
        //Account is locked pop up modal
        dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
        formikProps.resetForm();
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      

      case 'Your password has expired':
         dispatch(openModal('PasswordExpiryModal'));
         formikProps.resetForm();
         formikProps.setSubmitting(false);
         return;
         
      case 'User Locked Out.':
        //Account is locked pop up modal
        dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
        formikProps.resetForm();
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
        
      case 'Your account has been disabled':
        dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
        formikProps.resetForm();
      case 'Access Denied.':
        formikProps.dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
        formikProps.resetForm();
      default:
       console.log('error message' + body.errorMessage);
        break;
    }
    if(body.failureUrl){
      // account disabled modal
      dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
    }

    
    if (checkRequestFailure({ body, response }))
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });


      const targetValue = "2 MFA Needed";

      const isValuePresent = body.callbacks.some(
        callback => callback.type === "TextOutputCallback" &&
          callback.output.some(output => output.value === targetValue)
      );
      
  
      if(isValuePresent){
        dispatch({type: STORE_CHECKBOX, payload: {body}});
        return dispatch({ type: LOGIN_MFA_INDICATOR, payload: { body }, formikProps: formikProps});
      }
      

    return dispatch({ type: LOGIN_DEVICE_DETAILS_SENT, payload: { body }  });
  } else {
    return dispatch({
      type: LOGIN_DEVICE_DETAILS_SENT,
      payload: { body: selectLoginMFABody(state) }
    });
  }
};





export const sendDeviceDetails = (values, formikProps) => async (dispatch, getState) => {
  const state = getState();  

  if (selectLoginMFACallbacks(state)) {
    const request = {
      body: {
        ...selectLoginMFABody(state),
        callbacks: await updateWithDeviceDetails(selectLoginMFACallbacks(state)),
      },
    };

    
    const [{ body, response }] = await to(
      dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );

    
    
    
    if (checkRequestFailure({ body, response })){
      // Login Failures
    formikProps.resetForm({ email: values.email, password: '' });
    formikProps.setSubmitting(false);
    
    
  switch (body.errorMessage) {
    case 'Invalid Credentials':
      formikProps.setStatus({
        serverErrors: {
          email: true,
          password: { id: 'Field_PasswordIncorrect' },
        },
      });
      break;

     
      case 'OPUC account not enrolled.':
        //Account is locked pop up modal
        dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
        formikProps.resetForm();
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      
     
    
    case 'User account is locked':
      //Account is locked pop up modal
      dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
      formikProps.resetForm({ email: values.email, password: '' });
      formikProps.setSubmitting(false);
      break;
    case 'User Locked Out.':
      dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
      formikProps.resetForm({ email: values.email, password: '' });
      formikProps.setSubmitting(false);
      break;
    case 'Your account has been disabled':
      dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
      formikProps.resetForm({ email: values.email, password: '' });
      formikProps.setSubmitting(false);
    default:
      formikProps.setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
      break;
  }

  return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });

  
    }
    
     
    return dispatch({ type: LOGIN_DEVICE_DETAILS_SECOND, payload: { body }, formikProps: formikProps });
  } else {
    return dispatch({
      type: LOGIN_DEVICE_DETAILS_SECOND,
      payload: { body: selectLoginMFABody(state) },
      formikProps: formikProps
    });
  }
};

export const oauthValidation = (values) => async (dispatch, getState) => {

 
  // //    oauth routes
  const tokenId = values.tokenId;

  const originUrl = window.location.origin;

 
//http://localhost:3000/LOCAL_API
  let res = await Axios.post(`${originUrl}/sso/oauth2/realms/root/realms/TDIS/authorize?response_type=code&redirect_uri=${originUrl}/&decision=allow&client_id=tdisportal&csrf=${tokenId}&scope=profile%20openid`)

  const url = (new URL(res.request.responseURL)).searchParams;

  // the location code from the response headers 
  const code = url.get('code');


  const [ accessToken, oauthtokenerr ] = await to(
    dispatch(oauthaccessTokenRequest(code, originUrl))
  );



 


  const [ userInfo, userError] = await to(
    dispatch(userInfoRequest(accessToken.body.access_token))
  );


  
  const guid = userInfo.body.guid;
  const userName = userInfo.body.mail;
  const uid = userInfo.body.uid;


  window.sessionStorage.setItem("guid", guid);
  window.sessionStorage.setItem("userName", userName);
  window.sessionStorage.setItem("_id", uid);


  dispatch({type: STORE_USER, payload: userInfo});

  const [{body, response}] = await to(
    dispatch(getUser())
  )

    

  

  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  if (body.status === 'SUCCESS')
    return dispatch({ type: LOGIN_SUCCESS, payload: { body, response } });


// oauth routes end
}

export const loginSuccess = () => async (dispatch, getState) => {
  // const successUrl = getState().login.successUrl;
  // console.log(successUrl);

  // const [{ body, response }] = await to(
  //   dispatch(loginSuccessRequest(successUrl))
  // );

  const [{body, response}] = await to(
    dispatch(getUser())
  )

  

  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  if (body.status === 'SUCCESS')
    return dispatch({ type: LOGIN_SUCCESS, payload: { body, response } });
};

export const UseDefaultMFAOption = () => async (dispatch, getState) => {
  const state = getState();
  const selectedMFAOption = selectDefaultMFAOption(state);
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedMFAIndex({
        selectedMFAOption,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  const [{ body, response }] = await to(
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );
  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  return dispatch({
    type: LOGIN_MFA_OPTION_SENT,
    payload: { body, selectedMFAOption },
  });
};


//gets called when a option is selected
export const MFAOptionSubmitted = (values, { setSubmitting }) => async (
  dispatch,
  getState
) => {


  


  const state = getState();
  const selectedMFAOption = values.MFAOption.value;


  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedMFAIndex({
        selectedMFAOption,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  

  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  if (response.status !== 200 && setSubmitting) setSubmitting(false);


  return dispatch({
    type: LOGIN_MFA_OPTION_SENT,
    payload: { body, selectedMFAOption, type: values.MFAOption.type, },
  });
};


//gets called when second option is selected
export const MFASecondOptionSubmitted = (values, { setSubmitting }) => async (
  dispatch,
  getState
) => {


  


  const state = getState();
  const selectedMFAOption = values.MFAOption.value;


  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedMFAIndex({
        selectedMFAOption,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  

  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  if (response.status !== 200 && setSubmitting) setSubmitting(false);


  return dispatch({
    type: LOGIN_MFA_SECOND_OPTION_SENT,
    payload: { body, selectedMFAOption, type: values.MFAOption.type, },
  });
};

// Security Device Login Action
export const SecurityDeviceLogin = (combined, values) => async (dispatch, getState) => {
  const state = getState();

  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedSecurityKey({
        callbacks: selectLoginMFACallbacks(state),
        combined
      }),
    },
  };
 
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  



  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });  

  return dispatch({
    type: LOGIN_MFA_CODE_SENT,
    payload: { body, rememberThisDevice: values.rememberThisDevice },
  });

}

export const SecurityDevice2Login = (combined, values) => async (dispatch, getState) => {
  const state = getState();

  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithSelectedSecurityKey({
        callbacks: selectLoginMFACallbacks(state),
        combined
      }),
    },
  };
 
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

    



  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });  

    //we have to go back to choices here
    //not active
  // return dispatch({
  //   type: LOGIN_MFA_CODE_SENT,
  //   payload: { body, rememberThisDevice: values.rememberThisDevice },
  // });



  // active
  return dispatch({
    type: LOGIN_SECOND_OPTION,
    payload: { body },
  });

}

export const MFACodeSubmitted = (
  values,
  { setSubmitting, resetForm, setStatus }
) => async (dispatch, getState) => {

  const state = getState();

  const authApp  = state.login.type;

  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithMFACode({
        MFACode: values.code,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  


  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  if(body.header === 'Invalid Passcode.'){
    const state_new = getState();
    const request_new = {
      body: body
    };
    let responseObject = [];
    responseObject = await to(
      dispatch(loginMFARequest(request_new, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );
    
    switch (responseObject[0].body.errorMessage) {

      case 'OPUC account not enrolled.':
        //Account is locked pop up modal
        dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      

      case 'User Locked Out.':
        //Account is locked pop up modal
  
        dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
        dispatch({ type: LOGIN_CLEAR_STATE });
        resetForm();
        setSubmitting(false);
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      case 'Your account has been disabled':
        dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        setSubmitting(false);
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
      default:
        setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
        break;
    }
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: {
        body: responseObject[0].body,
        selectedMFAOption: selectSelectedMFAOption(getState()),
      },
    });
   



  }

  switch (body.errorMessage) {

    case 'OPUC account not enrolled.':
      //Account is locked pop up modal
      dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
      resetForm();
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
    
    case 'User Locked Out.':
      //Account is locked pop up modal

      dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
      dispatch({ type: LOGIN_CLEAR_STATE });
      resetForm();
      setSubmitting(false);
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
    case 'Your account has been disabled':
      dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
      resetForm();
    default:

      setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
      break;
  }




 

  if (response.status !== 200 && setSubmitting) setSubmitting(false);
  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });

  
  if (checkOTPError(body)) {
    const state_new = getState();
    const request_new = {
      body: {
        authId: body.authId,
        callbacks: body.callbacks,
      },
    };
    let responseObject = [];
    responseObject = await to(
      dispatch(loginMFARequest(request_new, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );
    
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: {
        body: responseObject[0].body,
        selectedMFAOption: selectSelectedMFAOption(getState()),
      },
    });
  }

  if (requiresMFACode(body)) {
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: { body, selectedMFAOption: selectSelectedMFAOption(getState()) },
    });
  }



  

    // Find the ChoiceCallback object in the callbacks array
    const choiceCallback = body.callbacks.find(callback => callback.type === "ChoiceCallback");

    // Check if the ChoiceCallback object exists
  if (choiceCallback) {
    // Access the "prompt" value
    const saveDevicePrompt = choiceCallback.output.find(output => output.name === "prompt").value;


    if (saveDevicePrompt === 'Save this device?') {

      //not active
      return dispatch({
        type: LOGIN_MFA_CODE_SENT,
        payload: { body, rememberThisDevice: values.rememberThisDevice, formikProps: { setSubmitting, resetForm, setStatus } },
      });

    }

  }

  
  return dispatch({
    type: LOGIN_SECOND_OPTION,
    payload: { body, rememberThisDevice: values.rememberThisDevice, formikProps: { setSubmitting, resetForm, setStatus } },
  });

};


export const MFASecondCodeSubmitted = (
  values,
  { setSubmitting, resetForm, setStatus }
) => async (dispatch, getState) => {

  const state = getState();

  const authApp  = state.login.type;

  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: callbacksWithMFACode({
        MFACode: values.code,
        callbacks: selectLoginMFACallbacks(state),
      }),
    },
  };

  


  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );

  

  if(body.header === 'Invalid Passcode.'){
    const state_new = getState();
    const request_new = {
      body: body
    };
    let responseObject = [];
    responseObject = await to(
      dispatch(loginMFARequest(request_new, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: {
        body: responseObject[0].body,
        selectedMFAOption: selectSelectedMFAOption(getState()),
      },
    });
    // //this is where we debug the auth app
    // const trequest = {
    //   body: body
    // }

    // console.log(trequest);
  
    // const [ tbody, tresponse ] = await to(
    //   // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    //   dispatch(loginMFARequest(trequest, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    // );
  
    // console.log(tbody);



    // if (resetForm) resetForm();
    // if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    // return;
  }

  switch (body.errorMessage) {



    case 'OPUC account not enrolled.':
      //Account is locked pop up modal
      dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
      resetForm();
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });



    case 'User Locked Out.':
      //Account is locked pop up modal

      dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
      dispatch({ type: LOGIN_CLEAR_STATE });
      resetForm();
      setSubmitting(false);
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
    case 'Your account has been disabled':
      dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
      resetForm();
      setSubmitting(false);
      return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
    default:
      setStatus({ serverErrors: { email: { id: 'Field_PasswordIncorrect' } } });
      break;
  }

 

  if (response.status !== 200 && setSubmitting) setSubmitting(false);
  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });

  
  if (checkOTPError(body)) {
    const state_new = getState();
    const request_new = {
      body: {
        authId: body.authId,
        callbacks: body.callbacks,
      },
    };
    let responseObject = [];
    responseObject = await to(
      dispatch(loginMFARequest(request_new, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    );
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: {
        body: responseObject[0].body,
        selectedMFAOption: selectSelectedMFAOption(getState()),
      },
    });
  }

  if (requiresMFACode(body)) {
    if (resetForm) resetForm();
    if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });
    return dispatch({
      type: LOGIN_MFA_OPTION_SENT,
      payload: { body, selectedMFAOption: selectSelectedMFAOption(getState()) },
    });
  }

  

  // return dispatch({
  //   type: LOGIN_SECOND_OPTION,
  //   payload: { body, rememberThisDevice: values.rememberThisDevice, formikProps: { setSubmitting, resetForm, setStatus } },
  // });

  return dispatch({
    type: LOGIN_MFA_CODE_SENT,
    payload: { body, rememberThisDevice: values.rememberThisDevice, formikProps: { setSubmitting, resetForm, setStatus } },
  });
};


export const sendRememberThisDeviceforKey = () => async (dispatch, getState) => {
  const state = getState();
  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: updateWithRememberThisDevice({
        callbacks: selectLoginMFACallbacks(state),
        rememberThisDevice: selectRememberThisDevice(state),
      }),
    },
  };
    
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );
  
  


  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  return dispatch({ type: LOGIN_REMEMBER_DEVICE_SENT, payload: { body } });
};

export const sendRememberThisDevice = ({  resetForm, setStatus, setSubmitting }) => async (dispatch, getState) => {
  const state = getState();
  
  const request = {
    body: {
      ...selectLoginMFABody(state),
      callbacks: updateWithRememberThisDevice({
        callbacks: selectLoginMFACallbacks(state),
        rememberThisDevice: selectRememberThisDevice(state),
      }),
    },
  };
    

  
  const [{ body, response }] = await to(
    // dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
    dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
  );
  

//error otp for auth app here


switch (body.errorMessage) {

  case 'OPUC account not enrolled.':
        //Account is locked pop up modal
        dispatch(openModal('AccountNotEnrolled', { onOpen: () => console.log('Modal opened') }));
        resetForm();
        return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });



  case 'User Locked Out.':
    //Account is locked pop up modal

    dispatch(openModal('LockoutModal', { onOpen: () => console.log('Modal opened') }));
    dispatch({ type: LOGIN_CLEAR_STATE });
    resetForm();
    setSubmitting(false);
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
    
  case 'Your account has been disabled':
    dispatch(openModal('AccountDisabled', { onOpen: () => console.log('Modal opened') }));
    resetForm();
    setSubmitting(false);
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
}

if(body.header === 'Invalid Passcode.'){
  if (resetForm) resetForm();
  if (setStatus) setStatus({ serverErrors: { code: { id: 'Field_Sorry' } } });

}


  if (checkRequestFailure({ body, response }))
    return dispatch({ type: LOGIN_FAILURE, payload: { body, response } });
  return dispatch({ type: LOGIN_REMEMBER_DEVICE_SENT, payload: { body } });
};

export const loginOTPTimeout = () => async dispatch => {
  dispatch(clearLoginState());
  dispatch(
    storeAlertInfo({
      type: 'warning',
      title: { id: 'SorryTitle' },
      message: { id: 'SignInExpired' },
      closeable: true,
    })
  );
};

export const resendCode = () => (dispatch, getState) => {
  const state = getState();
  const request = {
    body: selectLoginMFABody(state),
  };
  request.body.callbacks = callbacksWithMFACodeResend({
    callbacks: request.body.callbacks,
  });

  return dispatch(loginMFARequest(request, '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=PORTAL_LDAP_TREE'))
};


export const onResendCodeSuccess = ({ body }) => dispatch =>
  dispatch({ type: LOGIN_MFA_CODE_RESENT, payload: { body } });

export const storeEmail = email => dispatch =>
  dispatch({ type: LOGIN_STORE_EMAIL, payload: email });

export const updateLoginMessage = loginMessage => dispatch =>
  dispatch({ type: LOGIN_UPDATE_MESSAGE, payload: loginMessage });

export const resetLoginMessage = () => dispatch =>
  dispatch({ type: LOGIN_RESET_MESSAGE });

export const clearLoginState = () => dispatch =>
  dispatch({ type: LOGIN_CLEAR_STATE });

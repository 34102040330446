// Helpers
import { to } from '../../../lib/helpers';

// Requests
import { userDefaultMFAOptions } from '../../../backend/userRequests';

// Consts
export const USER_GET_DEFAULT_MFA = '[API] Get Default account setting for MFA option Success';

export const getUserMFADefault = (email) => async dispatch => {
  const [{ response, body }] = await to(
    dispatch(userDefaultMFAOptions(email))
  );


  if (!body) return false;
  if (response.status !== 200) return Promise.reject({ response, body });

  dispatch({
    type: USER_GET_DEFAULT_MFA,
    payload: body,
  });

  return Promise.resolve({ response, body });
};

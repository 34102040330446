//@flow

// Vendors
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classnames from 'classnames';

// Components
import { Logo } from '../..';

// Styles
import './navbar-branding.scss';

// Types
type Props = {
  as: string,
  className?: string,
  to?: string,
};

const NavbarBranding = ({ as = 'Link', className, ...props }: Props) => {
  const tags = { Link, NavLink };
  const T = tags[as] || as;

  return (
    <T {...props} className={classnames('navbar-branding', className)}>
      <Logo isSecondary />
    </T>
  );
};

export default NavbarBranding;

export default {
  Payment: `Payment`,
  PageTitle: 'Account Settings',
  Profile: `Profile`,
  GroupMailingAddress: 'Mailing Address',
  NoMailingAddressListed: 'No mailing address listed.',
  Email: 'Email',
  DisplayName: `Display Name`,
  Password: 'Password',
  RegisterNewDevice: 'Register new device',
  AuthenticatorApp: 'Authenticator App',
  SecurityKey: 'Security Key',
  LastUpdated: 'Last updated',
  GroupPhone: 'Phone',
  NoPhoneListed: 'No phone number listed.',
  SecurityQuestions: 'Security questions',
  HaveNotSetUpSecurityQuestions: "You haven't set up any security questions.",
  PasswordAndSecurity: `Password &amp; Security`,
  GovernmentEmploymentStatus: 'Employment Status',
  GovernmentEmploymentStatusTrue: `Yes, I'm a public-sector employee.`,
  GovernmentEmploymentStatusFalse: `No, I'm not a public-sector employee.`,
  GovernmentEmploymentStatusQuestion: `Are you a public-sector employee?`,
  WalletError: `Sorry, there was an error connecting to your wallet. Please try again later.`,
  AdvancedSecurity: 'Advanced Security'
};

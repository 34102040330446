// @flow

// Requests
import {
  getPaymentAccountsRequest,
  getAddPaymentAccountFrameUrlRequest,
  getEditPaymentAccountFrameUrlRequest,
  deletePaymentAccountRequest,
  getPaymentFormFrameUrlRequest,
} from '../../backend/walletRequests';

// Helpers
import { to, getPaymentRoute } from '../../lib/helpers';
import { gaActions } from '../gaActionTypes';
import { submitPayment } from '../../backend/requests';
import { selectAppBasicInfo } from '../app/appSelectors';
import {
  checkRequestFailure,
  handleRequestFailure,
} from '../../lib/validation';
import { selectPaymentState } from './paymentSelectors';
import { bodyHasError, walletErrors } from '../../lib/validation/serverErrors';

// Types
import { type PaymentContextObject } from '../../components/Payment/Payment';
import { selectModalData } from '../modal/modalSelectors';
import { storeAlertInfo } from '../alert/alertActions';
import { selectPathname } from '../route/routeSelectors';
import { replace } from 'connected-react-router';

// Actions
export const GET_PAYMENT_DATA = '[PAYMENT] Get Payment Data';
export const STORE_PAYMENT_DATA = '[PAYMENT] Store Payment Data';
export const CLEAR_PAYMENT_DATA = '[PAYMENT] Clear Payment Data';
export const PAYMENT_SUBMITTED = `[PAYMENT] ${gaActions.submit} Submitted`;

const handlePaymentResponse = (
  {
    body,
    response,
    err,
    formikActions,
  }: { body: any, response: any, err: any, formikActions?: any },
  onSuccess,
  onError
) => (dispatch, getState) => {
  dispatch({ type: PAYMENT_SUBMITTED, payload: body });

  if (err || checkRequestFailure({ response, body })) {
    const pathName = selectPathname(getState());

    if (formikActions) handleRequestFailure(body, formikActions);

    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3008', 'MGMW_EX_3108'] })) {
      if (pathName.includes('review'))
        dispatch(replace(getPaymentRoute('/', pathName)));
      dispatch(
        storeAlertInfo({
          type: 'danger',
          title: { id: 'SorryTitle' },
          message: { type: 'Errors', id: 'Exception_PaymentFailed' },
          closeable: true,
        })
      );
      return Promise.reject({ body, response });
    }

    if (bodyHasError(body, { errorCodes: ['MGMW_E_3102'] })) {
      dispatch(replace(getPaymentRoute('/error', pathName)));
      return Promise.reject({ body, response });
    }

    if (typeof onError === 'function') onError(body);
    return Promise.reject({ body, response });
  }

  if (typeof onSuccess === 'function') onSuccess(body);
  Promise.resolve({ body, response });
};

export function getPaymentAccounts() {
  return async function(dispatch: Function) {
    try {
      const {
        body: { status, data },
      } = await dispatch(getPaymentAccountsRequest());

      dispatch({
        type: STORE_PAYMENT_DATA,
        payload: {
          walletStatus: status,
          paymentAccounts: data,
        },
      });
      return Promise.resolve(data);
    } catch (err) {
      if (bodyHasError(err.body, { containsExactError: walletErrors })) {
        dispatch({
          type: STORE_PAYMENT_DATA,
          payload: {
            walletStatus: 'EXCEPTION',
            paymentAccounts: [],
          },
        });
        return Promise.resolve([]);
      } else {
        Promise.reject(err);
      }
    }
  };
}

export const storePaymentData = (payload: any) => (dispatch: Function) =>
  dispatch({ type: STORE_PAYMENT_DATA, payload });
export const clearPaymentData = () => (dispatch: Function) =>
  dispatch({ type: CLEAR_PAYMENT_DATA });

export const submitPaymentFromAccountList = (
  values: { selectPayment: Object },
  formikActions: FormikActions,
  { onSuccess, onError }: PaymentContextObject
) => async (dispatch: Function, getState: Function) => {
  if (!values.selectPayment) {
    formikActions.setStatus({
      serverErrors: { formError: { id: 'Field_PaymentMethodRequired' } },
    });
    formikActions.setSubmitting(false);
    return;
  }

  const basicInfo = selectAppBasicInfo(getState());
  const [{ body, response }, err] = await to(
    dispatch(submitPayment(values.selectPayment, basicInfo))
  );
  return dispatch(
    handlePaymentResponse(
      { body, response, err, formikActions },
      onSuccess,
      onError
    )
  );
};

export const submitPaymentFromForm = (
  { onSuccess, onError }: PaymentContextObject,
  { savePaymentAccount }: any
) => async (dispatch: Function, getState: Function) => {
  const state = getState();
  const basicInfo = selectAppBasicInfo(state);
  const { paymentToken } = selectPaymentState(state);
  const [{ body, response }, err] = await to(
    dispatch(submitPayment({ paymentToken, savePaymentAccount }, basicInfo))
  );
  return dispatch(
    handlePaymentResponse({ body, response, err }, onSuccess, onError)
  );
};

export const getAddPaymentAccountFrameUrl = () => async (
  dispatch: Function
) => {
  const [{ body }, err] = await to(
    dispatch(getAddPaymentAccountFrameUrlRequest())
  );
  if (!body || err) return Promise.reject(err);
  return Promise.resolve(body.data.url);
};

export const getEditPaymentAccountFrameUrl = () => async (
  dispatch: Function,
  getState: Function
) => {
  const {
    paymentAccount: { paymentAccountId },
  } = selectModalData(getState());
  const [{ body }, err] = await to(
    dispatch(getEditPaymentAccountFrameUrlRequest({ paymentAccountId }))
  );
  if (!body || err) return Promise.reject(err);
  return Promise.resolve(body.data.url);
};

export const deletePaymentAccount = (paymentAccount: PaymentAccount) => async (
  dispatch: Function
) => {
  const [{ body }, err] = await to(
    dispatch(deletePaymentAccountRequest(paymentAccount))
  );
  if (!body || err) return Promise.reject(err);
  return Promise.resolve(paymentAccount);
};

export const getPaymentFormFrameUrl = () => async (dispatch: Function) => {
  const [{ body }, err] = await to(dispatch(getPaymentFormFrameUrlRequest()));
  if (!body || err) return Promise.reject(err);
  dispatch(storePaymentData({ paymentToken: body.data.paymentToken }));
  return Promise.resolve(body.data.url);
};

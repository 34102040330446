//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import QRCode from 'qrcode.react';
import { push } from 'connected-react-router';

// Components

import { Modal } from '../..';
import Content from '../../Content/Content';
import { ButtonPrimary, ButtonInverse } from 'texkit/dist/components/Buttons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InputGroup, InputCode } from '../../..';
import { Form } from '../../../../components';

import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  getMyInitiateSecurityDevices,
  getMyCompleteSecurityDevices,
} from '../../../../reducers/agency/agencyActions';

// Helpers
import { verifyOTPValidationSchema } from '../../../../lib/validation';

// Actions
import {
  resendCode,
  onResendCodeSuccess,
} from '../../../../reducers/login/loginActions';

// Styles
import './advanced-security-modal.scss';

// Types
type Props = {
  className?: string,
  autoFocus: boolean,
  formikProps: FormikProps,
  formikProps: FormikProps,
  modal: Object,
  closeModal: Function,
  setRefreshDevices: Function,
  refreshDevices: boolean,
  showInputField: boolean,
  clearInputTrigger: Function,
};

const AdvancedSecurityQRModal = ({
  className,
  autoFocus = false,
  modal: { data },
  formikProps,
  closeModal,
  setRefreshDevices,
  refreshDevices,
  showInputField,
  clearInputTrigger,
}) => {
  const dispatch = useDispatch();
  const propurl = useSelector(state => state.agency);
  const inputCodeHandler = () => {
    clearInputTrigger(true);
  };
  

  const closeAdvancedSecurityModal = () => {
    setRefreshDevices(!refreshDevices);
    closeModal('AdvancedSecurityQRModal', {
      onClose: () => dispatch(push('/account')),
    });
  };

  const SubmitAuthCode = () => {
    const codeInput = document.getElementById('code').value;

    if (codeInput) {
      dispatch(getMyCompleteSecurityDevices(codeInput, 'AA')).then(response => {
        closeAdvancedSecurityModal();
      });
    }
  };

  return (
    <Modal
      aria-labelledby="Advanced security devices registration modal"
      name="AdvancedSecurityQRModal"
      className="modal-sm advanced-security-qr-modal"
      disableCloseButton={false}
    >
      {data && !showInputField && (
        <>
          <h2 className="h1 modal-title">
            <Content type="Modals" id="ScanQRCode" />
          </h2>
          <p className="advanced-security-modal-qr-desc">
          To register, scan the QR code below with your preferred authenticator app (not camera app). Currently Microsoft and Google Authenticator Apps are being supported.
          </p>
          <QRCode id="abc" value={data.qrCodeValuePassed} />

          <div className="advanced-security-modal-qr-button">
            <ButtonPrimary onClick={inputCodeHandler}>Next</ButtonPrimary>
          </div>
        </>
      )}
      {showInputField && (
        <Form
          className="login-page-mfa-code-form"
          formikConfig={{
            validateOnBlur: false,
            onSubmit: (codeInput, formikActions: FormikActions) =>
              SubmitAuthCode(formikActions),
            initialValues: { code: '', type: 'AA' },
            validationSchema: verifyOTPValidationSchema,
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <FormError />
              <h2 className="h1 modal-title">
                <Content type="Modals" id="VerifyAuthenticatorApp" />
              </h2>
              <p className="advanced-security-modal-qr-desc">
                <Content type="Modals" id="VerifyAuthenticatorAppDesc" />
              </p>
              <InputGroup className={classnames('input-group-code', className)}>
                <InputCode
                  formikProps={formikProps}
                  resendCodeActionCreator={resendCode}
                  onResendCodeSuccess={data => dispatch(SubmitAuthCode(data))}
                  autoFocus={autoFocus}
                />
              </InputGroup>
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    formikProps={formikProps}
                    className="qr-code-button"
                    type="submit"
                    loadingContent={<Content type="Labels" id="Loading" />}
                    isLoading={formikProps.isSubmitting}
                  >
                    <Content type="Labels" id="Submit" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      )}

      {propurl && propurl.pdf && (
        <div>
          <p>Click <a href={propurl.pdf.data} target="_blank">here</a> for registration instructions.</p>
        </div>
      )}
    </Modal>
  );
};
const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSecurityQRModal);

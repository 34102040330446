//@flow

// Vendors
import React from 'react';
import { Duration } from 'luxon';
import { push } from 'connected-react-router';

// Components
import { Modal, FormActions, FormActionsItem, Content } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { ReactComponent as Lock } from '../../../../assets/lockout.svg';
import { useDispatch } from 'react-redux';


//Actions

import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './password-expiringcountdown-modal.scss';





const PasswordExpiringCountdown = () => {
  
  const dispatch = useDispatch();


  

  return (
  
  <div>
  
    <h2 className="h1 modal-title">
      Your password will expire in 7 days
    </h2>
    <div className="modal-content">
      <div className="modal-graphic">
        <Lock role="presentation" />
      </div>
      <div>
        Your password will expire in 7 days if not changed before then. To change your password, please go to Forget Password or change within Account Settings..
      </div>
    </div>
    <FormActions align="center">
      <FormActionsItem fullWidth={true}>
        <ButtonPrimary onClick={() => {{dispatch(push('/account'))}}}  >
          Go to Account Settings
        </ButtonPrimary>
      </FormActionsItem>
    </FormActions>
  </div>
  );
}



export default PasswordExpiringCountdown;

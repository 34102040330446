//@flow

// Vendors
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { resendOTP } from '../../../../../../reducers/OTP/OTPActions';

// Components
import { Content } from '../../../..';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { TextButton } from 'texkit/dist/components/Buttons';

// Types
type Props = {
  resendCodeStatus: string,
  resendOTP: Function,
  OTP: Object,
  resendCodeActionCreator?: Function,
  onResendCodeSuccess?: Function,
};
type State = {
  resendCodeStatus: string,
};

class InputGroupCodeResendCode extends Component<Props & FormikProps, State> {
  state = {
    resendCodeStatus: 'default',
  };

  setResendCodeStatus(resendCodeStatus = 'default') {
    this.setState({ resendCodeStatus });
  }

  handleResendCodeClick() {
    const {
      resendOTP,
      resendCodeActionCreator,
      onResendCodeSuccess,
      OTP,
      channelCode,
      formikProps,
    } = this.props;
    formikProps.resetForm();

   

    resendOTP({
      OTP,
      resendCodeActionCreator,
      onResendCodeSuccess,
      channelCode,
      setResendCodeStatus: this.setResendCodeStatus.bind(this),
      formikProps,
    });
  }

  render() {
    const { resendCodeStatus } = this.state;
    const { formikProps, channelCode } = this.props;

    if (resendCodeStatus === 'sending') {
      const codeInput = document.getElementById('code');
      if (codeInput) codeInput.focus();
    }

    const component = {
      default: (
        <TextButton
          type="button"
          onClick={this.handleResendCodeClick.bind(this)}
          disabled={formikProps.isSubmitting}
        >
          {channelCode !== 'AA' && <Content type="MFA" id="CodeResend" />}
        </TextButton>
      ),
      // TODO: Consider putting this into a button, so it can be a loading.
      sending: <Content type="MFA" id="CodeResending" />,
      success: (
        <>
          <Content type="MFA" id="CodeResent" /> <Icon name="check" />
        </>
      ),
      error: (
        <>
          <Content type="MFA" id="CodeResendError" />{' '}
          <Icon name="exclamation" />
        </>
      ),
    };

    return (
      <div
        className={`input-group-code-resend-code input-group-code-resend-code-${resendCodeStatus}`}
      >
        {component[resendCodeStatus]}
      </div>
    );
  }
}

const mapStateToProps = ({ OTP }): Object => ({ OTP });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ resendOTP }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroupCodeResendCode);

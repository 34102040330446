//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Content from '../../Content/Content';

// Styles
import './license-details-modal.scss';

const LicenseDetailsModal = () => (
  <Modal name="LicenseDetailsModal" className="license-details-modal">
    <h2 className="h1 modal-title">
      <Content type="Modals" id="FindYourID" />
    </h2>
    <div className="modal-content">
      <Content type="Modals" id="LicenseOrIDNumber" />
      <div className="modal-graphic">
        <Content type="Modals">
          {modalContent => (
            <img
              src={publicURL('dl-example.png')}
              alt={modalContent['LicenseDetailsImageAlt']}
            />
          )}
        </Content>
      </div>
      DD/Audit number
    </div>
  </Modal>
);

export default LicenseDetailsModal;

const { DateTime } = require('luxon');

export const convertISOtoLocaleString = date =>
  DateTime.fromISO(date).toFormat('MM/dd/yyyy');

export function historyDateToLocaleString(locale, date) {
  return DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss.u')
    .setLocale(locale)
    .toLocaleString();
}

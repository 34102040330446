//@flow

// Vendors
import React, { type Node } from 'react';
import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './navbar.scss';

// Types
type Props = {
  className?: string,
  children?: Node,
};

const Navbar = ({ className, children }: Props) => (
  <div className={classnames('navbar-wrapper', className)}>
    <nav className="navbar" role="navigation">
      <Container className="navbar-container">{children}</Container>
    </nav>
  </div>
);

export default Navbar;

import { getMFAOptionsFromCallbacks } from '../../lib/helpers';

export const selectLoginMFACallbacks = ({ login }) => login.body.callbacks;
export const selectSecondLoginMFABody = ({login}) => 
  getMFAOptionsFromCallbacks(login.body);
export const selectLoginMFABody = ({ login }) => login.body;
export const selectMFAOptions = ({ login }) =>
  getMFAOptionsFromCallbacks(login.body.callbacks);
export const selectDefaultMFAOption = ({ login }) =>
  getMFAOptionsFromCallbacks(login.body.callbacks)[0].value;
export const selectSelectedMFAOption = ({ login }) => login.selectedMFAOption;
export const selectSecurityKeyOption = ({ login }) => login.body.callbacks[2].input[0].value;
export const selectRememberThisDevice = ({ login }) => login.rememberThisDevice;
export const selectCheckbox = ({login}) => login.checkbox;
export const selectMFAType = ({login}) => login.type;
export const selectRedirectToLocation = ({ router: { location } }) =>
  (location.state || {}).redirectTo;

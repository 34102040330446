import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { commonRules } from '../rules';
import { getAppValidationRulesBySlugs } from '../../helpers';

export const preLookupValidationSchema = yup.object().shape({
  program: yup.object().shape({
    programId: yup.string().required(ERRORS.FIELDS.PROGRAM_SELECT.REQUIRED),
  }),
  license: yup.object().shape({
    licenseCode: yup.string().required(ERRORS.FIELDS.LICENSE_SELECT.REQUIRED),
  }),
});

export const createLinkServiceFormValidationSchema = (
  agency,
  app,
  appParams
) => {
  const appRules = getAppValidationRulesBySlugs(agency.agencySlug, app.appSlug)
    .linkService;
  const validation = appParams.reduce((acc = {}, { name }) => {
    if (commonRules[name]) acc[name] = commonRules[name];
    if (appRules[name]) acc[name] = appRules[name];
    return acc;
  }, {});

  return yup.object().shape({ ...validation });
};

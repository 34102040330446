export default {
  RadioSMS: `Envíe por Texto a `,
  RadioSMPT: `Envíe por Correo Electrónico a `,
  RadioYK: 'Utilice Yubikey',
  RadioAA: 'Usar la aplicación Authenticator',
  VerifyPhone: `Verificar su teléfono.`,
  VerifyEmail: `Verifique su correo electrónico.`,
  VerifyNewPhone: `Por favor, compruebe su nuevo teléfono.`,
  VerifyNewEmail: `Por favor, compruebe su nuevo correo electrónico.`,
  VerifyAA: 'Verifique su aplicación de autenticación',
  CodeSentTo: `Un código de seis dígitos ha sido enviado a &nbsp;`,
  CodeAuthenticator:'Ingrese el código de 6 dígitos de su aplicación de autenticación registrada.',
  CodeYK: 'Por favor inserte su clave de seguridad.',
  CodeSentSpam: `Si no lo ve, favor de revisar su correo basura.`,
  CodeResend: `Reenviar código`,
  CodeResending: `Enviando...`,
  CodeResent: `Enviado`,
  CodeResendError: `Código no envió. Ver a continuación error`,
  EmailChangedTitle: `Su correo electrónico se ha actualizado.`,
  EmailChangedInfo: `Tendrá que cerrar la sesión por motivos de seguridad. La próxima vez, inicie sesión con su nuevo correo electrónico.`,
  EmailChangeWarning: `Después de actualizar el correo electrónico, los métodos de pago tendrán que ser re-agregado.`,
  NewTabWarning: 'Se le redirige a una nueva pestaña',
};

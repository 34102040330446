//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputText, InputState, InputZipCode } from '../..';
import Content from '../../../Content/Content';

// Helpers
import states from '../../../../../lib/data/states';

// Styles
import './input-group-address.scss';

// Types
type Props = {
  className?: string,
  labelPosition?: string,
  disabled: boolean,
  fieldGroupPath?: string,
  formikProps: FormikProps,
  hasLine2?: boolean,
  statesOptions?: Object,
};

/**
 * @visibleName Address
 */
const InputGroupAddress = ({
  fieldGroupPath,
  className,
  disabled,
  labelPosition,
  formikProps,
  hasLine2 = true,
  statesOptions,
  ...props
}: Props) => {
  fieldGroupPath = fieldGroupPath ? `${fieldGroupPath}.` : '';

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-address', className)}
    >
      <InputText
        className="input-street"
        name={`${fieldGroupPath}streetAddress`}
        label={<Content type="Labels" id="StreetAddress" />}
        labelPosition={labelPosition}
        maxLength="280"
        disabled={disabled}
        formikProps={formikProps}
      />
      {hasLine2 && (
        <InputText
          className="input-unit"
          name={`${fieldGroupPath}addressLine2`}
          label={
            labelPosition === 'above' ? '' : <Content type="Labels" id="Unit" />
          }
          labelPosition={labelPosition}
          maxLength="280"
          disabled={disabled}
          formikProps={formikProps}
        />
      )}
      <InputText
        className="input-city"
        name={`${fieldGroupPath}city`}
        label={<Content type="Labels" id="City" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputState
        name={`${fieldGroupPath}state`}
        label={<Content type="Labels" id="State" />}
        labelPosition={labelPosition}
        options={statesOptions || states}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputZipCode
        name={`${fieldGroupPath}zipFive`}
        className="input-zip"
        label={<Content type="Labels" id="Zip" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
    </InputGroup>
  );
};

export default InputGroupAddress;

export default {
  PageTitle: `Configuraciones de la Cuenta`,
  GroupMailingAddress: `Dirección de Envio`,
  NoMailingAddressListed: `No hay ninguna dirección de correo en la lista.`,
  Email: `Correo Electrónico`,
  DisplayName: `Nombre para mostrar`,
  Password: `Contraseña`,
  LastUpdated: `Última actualización`,
  GroupPhone: `Teléfono`,
  NoPhoneListed: `No hay un número de teléfono que aparece.`,
  SecurityQuestions: `Preguntas de seguridad`,
  HaveNotSetUpSecurityQuestions: `No se ha configurado alguna pregunta de seguridad.`,
  PasswordAndSecurity: `Contraseña &amp; Seguridad`,
  RegisterNewDevice: 'Registrar nuevo dispositivo',
  AdvancedSecurity: 'Seguridad avanzada (próximamente)',
  Profile: `Perfil`,
  Payment: `Pago`,
  GovernmentEmploymentStatus: `Estado de Empleo`,
  GovernmentEmploymentStatusTrue: `Sí, soy empleado del sector público.`,
  GovernmentEmploymentStatusFalse: `No, no soy empleado del sector público.`,
  GovernmentEmploymentStatusQuestion: `¿Eres un empleado del sector público?`,
  WalletError: `Lo sentimos, hubo un error al conectarse a su billetera. Por favor, inténtelo de nuevo más tarde.`,
  AdvancedSecurity: 'Seguridad Avanzada'
};

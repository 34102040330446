// @flow

// Vendors
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from '../../../../hooks';

// Actions
import { getPaymentFormFrameUrl } from '../../../../reducers/payment/paymentActions';
import { goBack, push } from 'connected-react-router';

// Helpers
import { selectRenewalFee } from '../../../../reducers/app/appSelectors';
import { selectPathname } from '../../../../reducers/route/routeSelectors';

// Components
import PaymentFrame from '../../PaymentFrame/PaymentFrame';
import { LoadingDots } from '../../../common';
import FeeSummary from '../../PaymentFeeSummary/PaymentFeeSummary';

// Helpers
import { scrollToTop, getPaymentRoute } from '../../../../lib/helpers';
import { clearAlertInfo } from '../../../../reducers/alert/alertActions';

const PaymentForm = () => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const [url, setUrl] = useState();
  const [ignoreFrameError, setIgnoreFrameError] = useState(false);
  const [submitting, setSubmitting] = useState();
  const feeInfo = useSelector(selectRenewalFee);

  useAsyncEffect(() => dispatch(getPaymentFormFrameUrl()).then(setUrl), [
    dispatch,
  ]);

  const handleSuccess = () => {
    dispatch(clearAlertInfo());
    setSubmitting(true);

    // Setting ignoreFrameError to true after a handledSuccess allows us to temporarily catch errors from our
    // /transact call and handle those separately without a frame error throwing a Houston error modal

    setIgnoreFrameError(true);
    dispatch(push(getPaymentRoute('/review', currentPath)));
  };

  const handleError = () => {
    scrollToTop();
    setIgnoreFrameError(false);
    dispatch(getPaymentFormFrameUrl()).then(setUrl);
  };

  const handleCancel = () => {
    dispatch(clearAlertInfo());
    dispatch(goBack());
  };

  return (
    <>
      {feeInfo && <FeeSummary feeInfo={feeInfo} />}
      {submitting ? (
        <LoadingDots />
      ) : (
        <PaymentFrame
          src={url}
          ignoreFrameError={ignoreFrameError}
          onSuccess={handleSuccess}
          onError={handleError}
          onCancel={handleCancel}
        />
      )}
    </>
  );
};

export default PaymentForm;

// @flow

import { REQUEST_TYPES } from '.';

export const setPasswordUserRequest = (
  body?: Object
): RequestOptions => {

  return {
    url: '/user/ui/pwd/setfirst',
    type: `[API] Set Password `,
    body,
    APIMethod: REQUEST_TYPES.POST,
  };
};

export const validateTokenRequest = (
  ftoken?: string
): RequestOptions => ({
  type: `[API] Validate Token`,
  url: `/user/ui/validatetoken/${ftoken || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});

//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Helpers

// Components
import { Modal, Content } from '../..';
import PaymentFrame, {
  PaymentFrameTypes,
} from '../../../Payment/PaymentFrame/PaymentFrame';

// Actions
import { getPaymentAccounts } from '../../../../reducers/payment/paymentActions';
import { closeModal } from '../../../../reducers/modal/modalActions';

import './edit-payment-account-modal.scss';

const EditPaymentAccountModal = () => {
  const dispatch = useDispatch();

  const handleSuccess = () => {
    dispatch(getPaymentAccounts());
    dispatch(closeModal('EditPaymentAccountModal'));
  };

  return (
    <Modal
      name="EditPaymentAccountModal"
      className="edit-payment-account-modal"
      disableOnClickOutside="true"
    >
      <h2 className="h1 modal-title">
        <Content type="Payment" id="EditCardTitle" />
      </h2>
      <PaymentFrame
        type={PaymentFrameTypes.EDIT_ACCOUNT}
        onSuccess={handleSuccess}
        onCancel={() => dispatch(closeModal('EditPaymentAccountModal'))}
      />
    </Modal>
  );
};

export default EditPaymentAccountModal;

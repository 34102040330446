import { tcpa } from '../CPA';
export const capps = {
  agencyCode: '999',
  agencyCode: '999',
  name: 'Centralized Accounting and Payroll/Personnel System',
  shortName: 'CAPPS',
  slug: 'capps',
  // logo: publicURL('agency-logos/cpa.png')

  //the presence of the field below will change the title of capps to tcpa
  tcpa: tcpa
};
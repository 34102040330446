export function formatAsMoney(number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(number);
}

export function getCreditCardType(number) {
  if (number.startsWith('4')) return 'VISA';
  if (number.startsWith('6')) return 'DISCOVER';
  if (number.startsWith('5') || number.startsWith('2')) return 'MASTERCARD';
  if (number.startsWith('34') || number.startsWith('37')) return 'AMEX';
  return 'OTHER';
}

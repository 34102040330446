//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputRadio from './InputRadio';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Inverse Radio Button
 */
const InputRadioInverse = ({ className, ...props }: Props) => (
  <InputRadio
    {...props}
    className={classnames('input-radio-inverse', className)}
  />
);

export default InputRadioInverse;

export default {
  TodoListAction_Renew: 'Renovar',
  TodoListAction_Confirm: 'Confirmar',
  TodoListAction_Attest: 'Confirmar',
  TodoListState_Expired: 'Expirado',
  TodoListState_Todo: 'Debido',
  TodoList_LicensingEntity_publicSector: 'Empleado del Sector Público',
  TodoList_LicensingEntity_tdlr:
    'Departamento de Licencias y Regulación de Tejas',
};

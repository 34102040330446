// Vendors
import { lazy } from 'react';
import PAGE_TYPES from '../pageTypes';

export default [
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'BNLREligibilityRequirements',
    component: lazy(() => import('./BON/BNLR/BNLREligibilityRequirements')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'BNLRPage',
    component: lazy(() => import('./BON/BNLR/BNLRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'TDLRLRPage',
    component: lazy(() => import('./TDLR/TDLRLR/TDLRLRPage')),
  },
  {
    type: PAGE_TYPES.TAKEOVER,
    name: 'CybersecurityPage',
    component: lazy(() => import('./Cybersecurity/CybersecurityPage')),
  },
];

//@flow

// Vendors
import React from 'react';

// Components
import { Modal } from '..';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';
import Content from '../../Content/Content';

// Styles
import './lockout-modal.scss';

const LockoutModal = () => (
    <Modal name="LockoutModal" className="modal-sm lockout-modal">
      <h2 className="h1 modal-title">
        <Content type="Modals" id="AccountTemporarilyLocked" />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <LockoutSVG role="presentation" />
        </div>
        <p>
          <Content type="Modals" id="FourHourLockout" />
        </p>
        <p>
          <Content type="Modals" id="TryAgainLater" />
        </p>
      </div>
    </Modal>
  );


export default LockoutModal;

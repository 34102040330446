//@flow

// Vendor
import React, { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Hooks
import { useOnClickOutside } from '../../../hooks';

// Components
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './dropdown.scss';

// Types
type DropdownMenuItemType = {
  as: string,
  label: string,
  className?: string,
  showCheck?: boolean,
  toggle: Function,
  onClick?: Function,
};
type Props = {
  items: Array<DropdownMenuItemType>,
  id: string,
  toggleComponent: ({ props: Object }) => Node,
  className?: string,
  showCheck?: boolean,
  disableOnBlur: boolean,
  menuPosition: 'bottom-right' | 'bottom-left' | 'top-right' | 'top-left',
};

// Make Accessible
// https://www.smashingmagazine.com/2017/11/building-accessible-menu-systems/
// See the section on True Menus as the bottom of that article
// So the main goal - be able to mouse through the entire dropdown list with just tabs
// need aria-haspopup="true" and aria-expanded={/*boolean*/}
// need role="menu" and role="menuitem"
// Open through space or enter
// when the menu is opened (clicking on the toggle element), we need to focus on the first menu item
// when the menu is opened, we should keep track of the element that toggled the menu open - in state, as ref, or as attr
// when the menu is tabbed through [using tabs] the last item, we should close menu and return focus to the toggle element

const DropdownMenuItem = ({
  label: Label,
  className,
  showCheck,
  toggle,
  onClick,
  ...props
}: DropdownMenuItemType) => {
  if (!Label) return null;

  const onClickHandler = event => {
    if (props.as === 'a') event.preventDefault();
    if (typeof onClick === 'function') onClick(event);
    toggle();
  };

  return (
    <li className={classnames('dropdown-menu-item', className)}>
      <TextButton
        {...props}
        onClick={onClickHandler}
        className="dropdown-menu-item-action"
      >
        {typeof Label === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: Label }} />
        ) : (
          Label
        )}
        {showCheck && <Icon name="check" />}
      </TextButton>
    </li>
  );
};

/**
 * @visibleName Default
 */
const Dropdown = ({
  id,
  items,
  showCheck,
  menuPosition = 'bottom-right',
  toggleComponent: DropdownToggle,
  className,
}: Props) => {
  const ref: { current: any } = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside({
    ref,
    onClickOutside: () => toggle(false),
  });

  if (!items.length) return null;

  const toggle = open =>
    typeof open === 'boolean' ? setOpen(open) : setOpen(!isOpen);

  const onDropdownOpen = () =>
    ref.current.querySelector('.dropdown-menu-item-action').focus();

  const dropdownClasses = classnames('dropdown', className, {
    isOpen,
    hasCheck: showCheck,
    [`dropdown-menu-position-${menuPosition}`]: menuPosition,
  });

  return (
    <div id={id} ref={ref} className={dropdownClasses}>
      {DropdownToggle && (
        <DropdownToggle
          onClick={toggle}
          id={`${id}-toggle`}
          className="dropdown-toggle"
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label="open dropdown menu"
        />
      )}
      <CSSTransition
        in={isOpen}
        onEntered={onDropdownOpen}
        timeout={200}
        classNames="dropdown-menu"
        unmountOnExit
      >
        <ul
          key="dropdown"
          role="menu"
          aria-hidden={!isOpen}
          className="dropdown-menu"
          aria-labelledby={`${id}-toggle`}
        >
          {items.map((item, i) => (
            <DropdownMenuItem
              as="a"
              role="menuitem"
              key={`dropdown-menu-item-${i}`}
              toggle={toggle}
              showCheck={showCheck}
              {...item}
            />
          ))}
        </ul>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputPassword, Content } from '../../../../common';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName SSN
 */
const InputSSN = ({ className, ...props }: Props) => (
  <InputPassword
    {...props}
    mask={[...Array(4)].map(() => /\d/)}
    maxLength={4}
    className={classnames('input-ssn', className)}
  />
);

InputSSN.defaultProps = {
  label: <Content type="Labels" id="SSN" />,
  name: 'lastFourSSN',
  autoComplete: 'lastFourSSN',
};

export default InputSSN;

//@flow

// Vendors
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Components
import { Container, Logo, Content } from '..';

// Styles
import './takeover-nav.scss';

const TakeoverNavHeading = ({
  title,
  subtitle,
}: {
  title: string | { type: string, id: string },
  subtitle?: string | { type: string, id: string },
}) =>
  title ? (
    <>
      <div className="takeover-nav-spacer" />
      <div
        className={classnames('takeover-nav-heading', {
          'takeover-nav-hasSubtitle': subtitle,
        })}
      >
        {subtitle && (
          <span className="takeover-nav-subtitle">
            {typeof subtitle === 'string' ? (
              subtitle
            ) : (
              <Content type={subtitle.type} id={subtitle.id} />
            )}
          </span>
        )}
        <span className="takeover-nav-title">
          {typeof title === 'string' ? (
            title
          ) : (
            <Content type={title.type} id={title.id} />
          )}
        </span>
      </div>
    </>
  ) : null;

// Types

const TakeoverNav = () => {
  const {
    title,
    subtitle,
  }: {
    title: string | { type: string, id: string },
    subtitle?: string | { type: string, id: string },
  } = useSelector(state => state.navbar.takeover || {});

  return (
    <nav className="takeover-nav" role="navigation">
      <Container className="takeover-nav-container">
        <Link
          to="/"
          className="takeover-nav-logo"
          aria-label="View My Dashboard"
        >
          <Logo />
        </Link>
        <TakeoverNavHeading title={title} subtitle={subtitle} />
      </Container>
    </nav>
  );
};

export default TakeoverNav;

export default {
  PageTitle: `Conecte al servicio`,
  SelectAgencyTitle: `Seleccione la agencia que emitió su licencia, ID de empleado o registro.`,
  LookupTitle: `Necesitamos un poco más de información para encontrar su licencia/la agencia.`,
  PreLookupInfo: `Fíjese que todas las licencias asignadas por alguna junta particular o algún programa se mostrara en los resultados de búsqueda.`,
  LookupCustomContent_TDLR: `Asegúrese de que su apellido coincida con su licencia de TDLR, incluido cualquier sufijo o caracteres especiales.`,
  ConfirmTitleDefault: `Hemos localizado su licencia o agencia.`,
  ConfirmDescriptionDefault: `Por favor, revise y confirme que esto es la licencia o la agencia correcta para vincular a su cuenta.`,
  MultipleLicensesConfirmLink: `Todas las licencias o agencia compatibles, incluidas las siguientes, se vincularán al mismo tiempo.`,
  ConfirmWhyLink: `<h2 class="h5">¿Por que debemos conectar las licencias o agencia?</h2><ul><li>Con nuestros recordatorios y alertas importantes no se olvidará de las fechas de renovación.</li><li>Llene las transacciones más rápido con las credenciales guardadas.</li></ul>`,
  ConfirmTitleLinked: `Esta licencia o agencia ya esta conectada.`,
  ConfirmDescriptionLinked: `Esta licencia o agencia esta conectada y puede encontrarse en su cuadro de mandos.`,
};

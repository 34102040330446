// Helpers
import { to } from '../../../lib/helpers';

// Requests
import { userGetUserRequest, userGetUsserRequest, userCaptchaVerifyRequest } from '../../../backend/requests';
import { async } from 'regenerator-runtime';



// Consts
export const USER_GET_USER_SUCCESS_RECEIVED = '[USER] Get User Success';
export const USER_GET_USER_OAUTH_SUCCESS_RECEIVED = '[USER] Get User Success Oauth';
export const USER_STORE_OAUTH = '[USER] User Oauth stored success';

export const getUser = () => async (dispatch, getState) => {
  
  const state = getState();


  if(state.login.user){

    

    const guid = state.login.user.guid;
    const userName = state.login.user.mail;
    const uid = state.login.user.uid;

 

    const headers = {
      "guid": `${guid}`,
      "userName": `${userName}`,
      "_id": `${uid}`
    }

    dispatch({type: USER_STORE_OAUTH, payload: headers})

  const [{ body, response }, err] = await to(dispatch(userGetUsserRequest(headers)));

  if (err) return Promise.reject(err);

  dispatch(storeUser({ body, response }));
  return Promise.resolve({ body, response });

  }
  const guid =  window.sessionStorage.getItem("guid");
  const userName = window.sessionStorage.getItem("userName");
  const uid = window.sessionStorage.getItem("_id");

  const userHeaders = {
    guid: guid,
    userName: userName,
    _id: uid
  }

  const [{ body, response }, err] = await to(dispatch(userGetUsserRequest(userHeaders)));

  if (err) return Promise.reject(err);

  dispatch(storeUser({ body, response }));
  return Promise.resolve({ body, response });
};

export const captchaUserVerify = (email, token) => async (dispatch) => {
  const payload = {userEmail:email, token: token};
  console.log(payload);

  const [body, err] = await to(dispatch(userCaptchaVerifyRequest(payload)));

  return body;

}

export const storeUser = ({ body, response }) => dispatch => {
  const payload = body.data;
  payload.userId = response.headersObject.userid;
  dispatch({ type: USER_GET_USER_SUCCESS_RECEIVED, payload });
};

export const storeOauthUser = (body) => dispatch => {
  console.log(body);
  const payload = body;
  payload.userId = body.uid;
  dispatch({ type: USER_GET_USER_OAUTH_SUCCESS_RECEIVED, payload });
}

//@flow

// Vendors
import React, { type Node } from 'react';
import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './hero.scss';

// Types
type Props = {
  className?: string,
  children: Node,
};

const Hero = ({ className, children }: Props) => (
  <header className={classnames('hero', className)} role="banner">
    <Container className="hero-container">
      <div className="hero-body">{children}</div>
    </Container>
  </header>
);

export default Hero;

import { SITE_DATA } from '../../data';

const marketingContent = {
  default_MarketingTitle: `Texas.gov Digital Identity Solution.`,
  default_MarketingContent: `<p>You can create a universal login and password to be used across multiple agencies and services, access your account dashboard anywhere on any device, and get timely alerts for important due dates so you can worry about more important things, like enjoying the great State of Texas.</p>
  <p>We have big plans for TxT, so keep a lookout for new services and updates on the horizon.</p>`,
  tdlr_MarketingTitle: `Texas.gov Digital Identity Solution.`,
  tdlr_MarketingContent: `<p>We're starting with the Texas Department of Licensing and Regulation to make renewing professional licenses simpler and more efficient.</p>
  <p>You can create a universal login and password, access your account dashboard anywhere on any device, and get timely alerts for important due dates so you can worry about more important things, like enjoying the great State of Texas.</p>`,
  tdis_MarketingTitle: `Texas.gov Digital Identity Solution`,
  tdis_MarketingContent: `<b>The Texas.gov Digital Identity Solution allows authorized Texas government employees to access services and systems.</b>
  <p>To get started, sign in with your enrolled employee account.</p>`,
  agency_MarketingTitle: `Texas.gov Digital Identity Solution.`,
  agency_MarketingContent: `<p>As a public-sector employee for the State of Texas, you are required to confirm completion of your annual cybersecurity training before June 14th, 2020.</p>
 <p>Simply create a TxT account, or sign in if you have an account, to select “Confirm Completion of Cybersecurity Requirement” in your To-Do list. It’s that easy!</p>
  <p>We have big plans for TxT so keep a lookout for new services and updates on the horizon.</p>`,
};

export default {
  ...marketingContent,
  PageTitle: 'Sign In',
  Banner: 'An official website of the Texas state government.',
  BannerLearnMoreButton: 'Learn more',
  BannerLearnMoreTitle1: 'The .gov means it’s official.',
  BannerLearnMoreContent1:
    "Federal government websites often end in .gov or .mil. Before sharing sensitive information, make sure you're on a federal government site.",
  BannerLearnMoreTitle2: 'The https:// means it’s secure.',
  BannerLearnMoreContent2:
    'The https:// ensures that you are connecting to the official website and that any information you provide is encrypted and transmitted securely.',
  Welcome: 'Welcome',
  SignInButton: 'Sign In',
  RememberDevice: 'Remember This Device',
  CreateAccountButton: 'Create Account',
  ForgotPasswordLink: 'Forgot password',
  VerifyMFAOptionsTitle: 'Please verify for security.',
  VerifyMFAOptionsContent:
    'Please choose your authentication method',
  VerifyMFAOptionsSubmit: 'Send',
  VerifyMFALostAccess: ` If you no longer have access to the email address or any of the devices listed above, please contact your organization’s support team for assistance.`,
  Success: `Success!`,
  SuccessfullyEnrolled: 'Your account is enrolled. You can now login.',
};

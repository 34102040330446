//@flow

// Vendors
import React from 'react';

// Components
import {
  ServiceDownModal,
  ServerErrorModal,
  TermsAndConditionsModal,
  DeletePhoneModal,
  RedirectNewTabModal,
  PasswordExpiryModal,
  LockoutModal,
  MFACodeErrorModal,
  AccountDisabled,
  EnrollmentMFAModal,
  AccessDenied,
  AccountNotEnrolled
} from '..';

const GlobalModals = () => (
  <>
    <ServiceDownModal />
    <ServerErrorModal />
    <TermsAndConditionsModal />
    <DeletePhoneModal />
    <RedirectNewTabModal />
    <PasswordExpiryModal />
    <LockoutModal />
    <MFACodeErrorModal />
    <AccountDisabled/>
    <EnrollmentMFAModal/>
    <AccessDenied/>
    <AccountNotEnrolled/>
    {/* Add more global modals here */}
  </>
);

export default GlobalModals;

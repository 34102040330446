//@flow

// Vendors
import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Components
import { Loading } from '../../components';

// Types
type Props = {
  component: any,
  auth: Object,
};

const PrivateRoute = ({ component: RouteComponent, auth, ...props }: Props) =>
  auth.isAuthenticated ? (
    <Route
      render={routeProps => <RouteComponent {...routeProps} {...props} />}
    />
  ) : (
    <Loading />
  );

const mapStateToProps = ({ auth }): Object => ({ auth });

export default connect(mapStateToProps)(PrivateRoute);

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputEmail, Content } from '../../..';

type Props = {
  className?: string,
  hint?: Object,
  formikProps: FormikProps,
};

/**
 * @visibleName Confirm Email
 */
const InputGroupConfirmEmail = ({
  className,
  hint,
  formikProps,
  ...props
}: Props) => (
  <InputGroup
    {...props}
    className={classnames('input-group-confirm-email', className)}
  >
    <InputEmail
      name="email"
      label={<Content type="Labels" id="EnterEmail" />}
      formikProps={formikProps}
      hint={hint}
    />
    <InputEmail
      name="confirmEmail"
      label={<Content type="Labels" id="ConfirmEmail" />}
      formikProps={formikProps}
    />
  </InputGroup>
);

export default InputGroupConfirmEmail;

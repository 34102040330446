import { SITE_DATA } from '../../data';

export default {
  WelcomeTitle: 'Welcome, ',
  WelcomeDescription: `We just have a few short steps to make sure your ${SITE_DATA.SITE_NAME} experience is safe and convenient.`,
  SecurityQuestionsTitle: 'Set up security questions.',
  SecurityQuestionsDescription: `Please choose three security questions so we can verify your identity if there's ever an issue with your account.`,
  PhoneTitle: 'Please verify your mobile phone number.',
  PhoneDescription:
    'This will be used to confirm your identity during sign in and for password recovery.',
  CompleteTitle: 'Get the most out of your account.',
  CompleteDescription:
    'Link your existing licenses and registrations to receive reminders of important dates and simplifed renewals.',
  GovEmploymentStatusTitle: 'Are you a public-sector employee?',
  GovEmploymentStatusDescription: `If you are a state or local government employee, additional functionality may be available to you.`,
  GovEmailTitle: 'As a public-sector employee, please verify your work email.',
  GovEmailDescription:
    'This will be used to verify your status and eligibility for certain functionality. Enter your work email below to receive a verification code.',
  GovEmailVerificationTitle: 'Please verify your work email.',
  Field_GovernmentDomain:
    'Please make sure the email address you are entering is a verified government email.',
  LaterAccountSettings: "I'll do this later via Account Settings",
};

import { setServerErrors, errorsWithOnlyCodes } from './serverErrors';
import { setObjectPropertyByPath } from '../helpers';
export { setServerErrors };
export { errorsWithOnlyCodes };
export { default as ERRORS_MAP } from './errorsMap';
export * from './schemas';

export function validate(values, schema) {
  const validationSchema = schema(values);

  try {
    validationSchema.validateSync(values, { abortEarly: false });
    return {};
  } catch (error) {
    const FIRST_ERROR = 0;
    if (error.inner) {
      return error.inner.reduce((errors, error) => {
        setObjectPropertyByPath(errors, error.path, error.errors[FIRST_ERROR]);
        return errors;
      }, {});
    }
  }
}

export const checkRequestFailure = ({ response, body }) =>
  !body || !!body.errors || !response || response.status !== 200;

export const checkOTPError = body => {
  let otpError = false;
  if (body.callbacks) {
    body.callbacks.forEach(function(callback) {
      if (callback.type === 'TextOutputCallback') {
        callback.output.forEach(function(eachoutput) {
          if (eachoutput.value.includes('invalidOTP')) {
            otpError = true;
          }
        });
      }
    });
  }
  return otpError;
};

export function handleRequestFailure(
  body,
  { setFormikState, setSubmitting },
  errorsMap = {}
) {
  if (body && setFormikState)
    setServerErrors({ body, setFormikState, errorsMap });
  if (setSubmitting) setSubmitting(false);
}

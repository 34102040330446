import { getObjectValueByPath } from '../helpers';
import ERRORS_MAP from './errorsMap';
import { getServerErrorText } from './serverErrorsCodes';

export const walletErrors = [
  'MGMW_EX_4001|||Bad data',
  'MGMW_EX_3100|||Cannot create Ewallet',
  'MGMW_EX_3100|||Wallet Account Error',
  'MGMW_EX_3101|||Wallet Management Error',
];

export function setServerErrors({ body, setFormikState, errorsMap = {} }) {
  if (!body.errors) return false;
  const serverErrors = mapServerErrorsToFormErrors(body.errors, {
    ...ERRORS_MAP.DEFAULT,
    ...errorsMap,
  });
  return setFormikState(state => {
    return { ...state, status: { ...state.status, serverErrors } };
  });
}

export function mapServerErrorsToFormErrors(serverErrors, errorsMap) {
  const errors = Object.entries(errorsMap).reduce((acc, curr) => {
    const fieldName = curr[0];
    const serverErrorPath = curr[1];
    const serverError =
      serverErrorPath && serverErrorPath.constructor === Array
        ? serverErrorPath.reduce((errors, path) => {
            const error = getObjectValueByPath(serverErrors, path);
            return error ? [...errors, ...error] : errors;
          }, [])
        : getObjectValueByPath(serverErrors, serverErrorPath);

    if (serverError && serverError.constructor === Array && serverError.length)
      acc[fieldName] = convertServerErrorToText({
        serverError: serverError[0],
        fieldName,
        errorRef: serverErrors.REF ? serverErrors.REF[0] : null, // TODO: Backend should not be sending REF as an array
      });

    return acc;
  }, {});

  errors.formError = errors.formHelp || errors.formError;
  return errors;
}

export function convertServerErrorToText({ serverError, fieldName, errorRef }) {
  const errorWithArgs = serverError.split('|||');
  const errorCode = fieldName === 'formHelp' ? 'FORM_HELP' : errorWithArgs[0];
  const errorArgs = errorWithArgs[1];
  const errorText = getServerErrorText({
    fieldName,
    errorCode,
    errorArgs,
    errorRef,
  });
  return errorText;
}

export const bodyHasError = (body, options?) => {
  let passwordExpired = false;
  if (body && body.callbacks && options === 'passExpired') {
    body.callbacks.forEach(function(callback) {
      if (callback.type === 'TextOutputCallback') {
        callback.output.forEach(function(eachoutput) {
          if (eachoutput.value.includes('Your password has expired')) {
            passwordExpired = true;
          }
        });
      }
    });
  }
  if (options === 'passExpired') {
    return passwordExpired;
  }

  if (!body || !body.errors || !body.errors.ERROR) return false;

  if (options) {
    const errors = body.errors.ERROR;
    const { exactError, containsExactError, errorCodes } = options;

    if (exactError) {
      return Array.isArray(errors) && errors.includes(exactError);
    }

    if (containsExactError) {
      return errors.some(code => containsExactError.includes(code));
    }

    if (errorCodes) {
      const codes = errorsWithOnlyCodes(errors);
      return codes.some(code => errorCodes.includes(code));
    }
  }
  return true;
};

export function errorsWithOnlyCodes(errors = []) {
  if (!errors) return [];

  if (Array.isArray(errors)) {
    return errors.map(error => error.split('|||')[0]);
  } else if (typeof errors === 'object') {
    return Object.keys(errors);
  }
}

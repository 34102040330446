//@flow

// Vendors
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FocusScope } from '@react-aria/focus';

// Components
import ModalWrapper from './ModalWrapper';
import ModalContent from './ModalContent';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../reducers/modal/modalActions';

// Styles
import './modal.scss';

// Types
type Props = {
  className?: string,
  modal: Object,
  id: string,
  name: string,
  closeModal: Function,
  children: Node,
  onOpen?: Function,
  onClose?: Function,
  disableOnClickOutside?: boolean,
  disableCloseButton?: boolean,
  render?: Function,
};

const ModalClose = props => (
  <ButtonIcon
    {...props}
    type="button"
    icon="x"
    className="modal-close"
    aria-label="Close Modal"
  />
);

const Modal = ({
  id,
  className,
  modal,
  name,
  children,
  closeModal,
  onOpen,
  onClose,
  disableCloseButton,
  ...rest
}: Props) => {
 

  const modalId = id ? id : name;


  return (
    <CSSTransition
      in={modal.activeModal === modalId}
      classNames="modal"
      timeout={300}
      unmountOnExit
    >
      <ModalWrapper onOpen={onOpen} onClose={onClose} aria-modal="true">
        <div id={modalId} className="modal-container">
          <ModalContent
            {...rest}
            id={modalId}
            className={className}
            onClickOutside={() => closeModal(modalId)}
          >
            <FocusScope contain autoFocus>
              {!disableCloseButton && (
                <ModalClose onClick={() => closeModal(modalId)} />
              )}
              <div className="modal-body">{children}</div>
            </FocusScope>
          </ModalContent>
        </div>
        <div className="modal-overlay" />
      </ModalWrapper>
    </CSSTransition>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

// @flow

// Vendors
import React from 'react';

// Components
import { ProgressBar } from '..';

// Styles
import './takeover-page-header.scss';

type TakeoverPageHeaderProps = {
  preTitle: string,
  title: string,
  steps: Array<{ component: Node, id: string }>,
  activeStepId: string,
  hasProgressBar: boolean,
  hasStepCount: boolean,
  ignoreSteps: Array<{ component: Node, id: string }>,
};

const TakeoverPageHeader = ({
  preTitle,
  title,
  steps,
  activeStepId,
  hasProgressBar = false,
  hasStepCount = false,
  ignoreSteps = [],
}: TakeoverPageHeaderProps) => {
  if (ignoreSteps.includes(activeStepId)) return null;
  const filteredSteps = steps.filter(step => !ignoreSteps.includes(step.id));
  const activeStepIndex = filteredSteps.findIndex(
    step => step.id === activeStepId
  );

  return (
    <>
      <div className="takeover-page-header">
        <div>{preTitle}</div>
        <div>
          <strong>{title}</strong>
        </div>
        {hasProgressBar && (
          <ProgressBar
            fraction={(activeStepIndex + 1) / filteredSteps.length}
          />
        )}
      </div>

      {hasStepCount && (
        <p className="takeover-page-header-step-count">
          <strong>Step {activeStepIndex + 1}</strong> of {filteredSteps.length}
        </p>
      )}
    </>
  );
};

export default TakeoverPageHeader;

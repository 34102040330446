//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Styles
import './input-radio.scss';

// Types
type Props = {
  name: string,
  className?: string,
};

/**
 * @visibleName Radio Button
 */
const InputRadio = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    type="radio"
    className={classnames('input-radio', className)}
  />
);

export default InputRadio;

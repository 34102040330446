//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './credit-card-icon.scss';

import { ReactComponent as discover } from '../../../assets/icons/discover.svg';
import { ReactComponent as visa } from '../../../assets/icons/visa.svg';
import { ReactComponent as amex } from '../../../assets/icons/amex.svg';
import { ReactComponent as mastercard } from '../../../assets/icons/mastercard.svg';
import { ReactComponent as blank } from '../../../assets/icons/blankcard.svg';

export const creditCardIcons = {
  discover,
  visa,
  amex,
  mastercard,
  blank,
};

type Props = {
  name: string,
  className?: string,
};

/**
 * @visibleName Default
 */
const CreditCardIcon = ({ name, className, ...props }: Props) => {
  if (!name || !creditCardIcons[name]) return null;

  const Icon = creditCardIcons[name];

  return (
    <span
      {...props}
      className={classnames(`credit-card-icon icon-${name}`, className)}
    >
      <Icon role="presentation" />
    </span>
  );
};

export default CreditCardIcon;

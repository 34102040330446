// used to link CPA agency through CAPPS_ID and Last name
// add TINSS_ID too
// need to change the linkType to id if it does not work

export const cpaln = {
  agencySlug: 'cpa',
  appCode: 'CPA_LN', //LCPA or CPA?
  slug: 'cpaln',
  linkService: {
    linkType: 'license',
    form: {
      labels: {
        CAPPSID: { id: 'agencyUserId' },
        lastName: { id: 'LastName' }
      }
    }
  }
};

export default {
  ACCOUNT: 'Profile Update',
  TDLR: 'Agency Update',
  CYBER: 'Cyber Update',
  UPDT_ADDR: 'Updated Mailing Address ',
  UPDT_EMAIL_PR: 'Updated Primary Email ',
  UPDT_PHONE: 'Updated Phone number ',
  UPDT_PHONE_MB: 'Updated Mobile number',
  PDT_PHONE_OT: 'Updated Other number',
  UPDT_SQ: 'Updated security questions',
  CREAT_AC: 'Created TxT account',
  UPDT_PWD: 'Changed Password ',
  RESET_PWD: 'Reset Password',
  GOV_STATUS: 'Changed Employee status',
  UPDT_GOV_EMAIL: 'Verified work email',
  ATTST_CYBR: 'Completed cybersecurity attestation',
  TDLR_LR: 'Renewed License',
  TDLR_LR_MT: 'Renewed Licensed Massage Therapist',
  TDLR_LR_MI: 'Renewed Massage Therapy Instructor',
  LINK_TDLR_MT: 'Linked Licensed Massage Therapist',
  LINK_TDLR_MI: 'Linked Massage Therapy Instructor',
  UNLINK_TDLR_MT: 'UnLinked Licensed Massage Therapist',
  UNLINK_TDLR_MI: 'UnLinked Massage Therapy Instructor',
  UNLINK_TDLR: 'UnLinked TDLR License',
};

export default {
  RadioSMS: 'Text to',
  RadioSMPT: 'Email to',
  RadioYK: 'Use Yubikey',
  RadioAA: 'Use Authenticator App',
  VerifyPhone: 'Verify your phone.',
  VerifyEmail: 'Verify your email.',
  VerifyNewPhone: 'Please verify your new phone.',
  VerifyNewEmail: 'Please verify your new email.',
  VerifyAA: 'Verify your authenticator app',
  CodeSentTo: 'A six-digit code has been sent to ',
  CodeAuthenticator:'Enter the 6 digit code from your registered Authenticator App.',
  CodeYK: 'Please insert your security key.',
  CodeSentSpam: `If you don't see it, please check your spam folder.`,
  CodeResend: 'Resend code',
  CodeResending: 'Sending...',
  CodeResent: 'Sent!',
  CodeResendError: 'Code not sent. See error below',
  EmailChangedTitle: 'Your email has been updated.',
  EmailChangedInfo: `You'll need to sign out for security purposes. Next time, sign in using your new email.`,
  EmailChangeWarning:
    'After updating your email, payment methods will need to be re-added.',
  NewTabWarning: 'You are being redirected to a new tab',
};

//@flow

// Vendors
import React from 'react';

// Components
import { Modal } from '..';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';
import Content from '../../Content/Content';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';


import {useHistory} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Link } from 'react-router-dom';

import { push } from 'connected-react-router';



// Styles
import './EnrollmentMFAModal.scss';
import { closeModal } from '../../../../reducers/modal/modalActions';

const EnrollmentMFAModal = ({ modal: { data }, closeModal }) => {
    const dispatch = useDispatch();
  
    const handleAccountSettingsClick = () => {
      closeModal('EnrollmentMFAModal', {
        onClose: () => dispatch(push('/account')),
      });
        console.log('/account hit ')
       // Redirect to the account settings page
     
    };
  
    return (
      <Modal name="EnrollmentMFAModal" className="modal-sm lockout-modal">
        <h2 className="h1 modal-title">Register another method</h2>
        <div className="modal-content">
          <div className="modal-graphic">
            <LockoutSVG role="presentation" />
          </div>
          <p>Consider registering a more secure method like an Authenticator App or Mobile Phone Number.</p>
          <p>
          Without another authentication method, you could be denied access to your account in the future. Take care of it now.
          </p>
        </div>

        <div className='Account-button'>
        <ButtonPrimary
          type="submit"
          loadingContent={<Content type="Labels" id="Loading" />}
          onClick={handleAccountSettingsClick} // Use the click handler
          onKeyPress={handleAccountSettingsClick} // Use the same click handler
        >
         Go to Account Settings
        </ButtonPrimary>
        </div>
      </Modal>
    );
  };

  const mapStateToProps = ({ modal }): Object => ({ modal });
  const mapDispatchToProps = (dispatch: Object) =>
    bindActionCreators({ closeModal }, dispatch);
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(EnrollmentMFAModal);

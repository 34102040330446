//@flow

// Vendors
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Content, Form } from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';
import PaymentSelectCardList from '../../PaymentSelectCardList/PaymentSelectCardList';

// Actions
import { goBack } from 'connected-react-router';
import { submitPaymentFromAccountList } from '../../../../reducers/payment/paymentActions';
import { PaymentContext } from '../../Payment';
import { clearAlertInfo } from '../../../../reducers/alert/alertActions';

// Selectors
import { selectPaymentState } from '../../../../reducers/payment/paymentSelectors';
import { selectRenewalFee } from '../../../../reducers/app/appSelectors';
import FeeSummary from '../../PaymentFeeSummary/PaymentFeeSummary';

// Types
type Props = {
  paymentState: PaymentState,
};

const PaymentType = ({ paymentState: { paymentInfo = {} } }: Props) => {
  if (paymentInfo.feeInfo) return <Content type="Labels" id="RenewalFee" />;
  return <></>;
};

const SelectPaymentAccount = () => {
  const dispatch = useDispatch();
  const paymentState = useSelector(selectPaymentState);
  const feeInfo = useSelector(selectRenewalFee);
  const paymentContext = useContext(PaymentContext);

  const onSubmit = (values, formikActions: FormikActions) =>
    dispatch(
      submitPaymentFromAccountList(values, formikActions, paymentContext)
    );

  // Clear alert when page changes
  useEffect(() => () => dispatch(clearAlertInfo()), []);

  return (
    <div className="payment-select-account">
      <h3 className="payment-select-account-title">
        <PaymentType paymentState={paymentState} />
      </h3>
      {feeInfo && <FeeSummary feeInfo={feeInfo} />}
      <h3>
        <Content type="Payment" id="CreditCard" />
      </h3>

      <Form
        className="payment-select-account-form"
        formikConfig={{
          onSubmit,
          initialValues: { selectPayment: '' },
          validationSchema: null,
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
          <>
            <PaymentSelectCardList formikProps={formikProps} />
            <FormError />
            <FormActions>
              <FormActionsItem>
                <ButtonPrimary
                  type="submit"
                  isLoading={formikProps.isSubmitting}
                  loadingContent={<Content type="Labels" id="Submitting" />}
                >
                  <Content type="Payment" id="PayNow" />
                </ButtonPrimary>
              </FormActionsItem>
              <FormActionsItem>
                <Button
                  onClick={() => dispatch(goBack())}
                  disabled={formikProps.isSubmitting}
                >
                  <Content type="Labels" id="Back" />
                </Button>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      />
    </div>
  );
};

export default SelectPaymentAccount;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputSelect, InputPassword } from '../..';

// Styles
import './input-group-security-questions.scss';
import { Content } from '../../../..';

type Props = {
  className?: string,
  questions: Object,
  formikProps: FormikProps,
};

/**
 * @visibleName Security Questions
 */
const InputGroupSecurityQuestions = ({
  questions,
  className,
  formikProps,
  ...props
}: Props) => {
  if (!questions) return null;



  const questionValuesAsArray = Object.entries(formikProps.values)
    .filter(entry => entry[0].startsWith('question'))
    .sort((a, b) => (a[0] === b[0] ? 0 : a[0] < b[0] ? -1 : 1))
    .map(entry => entry[1]);



  const remainingQuestions = questions.filter(
    question =>
      !questionValuesAsArray.find(
        (userSecurityQuestion: any) =>
          userSecurityQuestion.quid === question.quid
      )
  );

  return (
    <Content type="SecurityQuestions">
      {securityQuestionsContent => (
        <InputGroup
          {...props}
          className={classnames('input-group-security-questions', className)}
        >
          {questionValuesAsArray.map((userSecurityQuestion: any, index) => {
            const questionName = `question${index + 1}`;
            const answerName = `answer${index + 1}`;
            const autoFocus = index === 0 ? true : false;

            let options = [];

            if (userSecurityQuestion && userSecurityQuestion.quid)
              options[0] = userSecurityQuestion;

            options = [...options, ...remainingQuestions].map(question => {
              const questionObject = {
                ...question,
                question:
                  securityQuestionsContent[`SecurityQuestion_${question.quid}`],
              };

            
              
              return questionObject;


            });
          
            
            return (
              <InputGroup key={questionName}>
                <InputSelect
                  name={questionName}
                  options={options}
                  label={`${
                    securityQuestionsContent.SecurityQuestionLabel
                  } ${index + 1}`}
                  labelPosition="above"
                  optionLabelKey="question"
                  optionValueKey="quid"
                  emptyOptionLabel={
                    securityQuestionsContent.SecurityQuestion_empty
                  }
                  formikProps={formikProps}
                  autoFocus = {autoFocus}
                />
                
                <InputPassword
                  className="input-security-answer"
                  label={<Content type="Labels" id="Answer" />}
                  name={answerName}
                  formikProps={formikProps}
                />
              </InputGroup>
            );
          })}
        </InputGroup>
      )}
    </Content>
  );
};

export default InputGroupSecurityQuestions;

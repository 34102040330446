//@flow

// Vendors
import React from 'react';
import { push } from 'connected-react-router';

// Components
import { Modal } from '..';
import Content from '../../Content/Content';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../../../../reducers/modal/modalActions';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';

// Styles
import './password-expiry-modal.scss';

type Props = {
  modal: Object,
  closeModal: Function,
};

const PasswordExpiryModal = ({ modal: { data }, closeModal }) => {
  const dispatch = useDispatch();

  const passwordExpiredReset = () => {
    closeModal('PasswordExpiryModal', {
      onClose: () => dispatch(push('/forgot-password')),
    });
  };
  return (
    <Modal
      name="PasswordExpiryModal"
      className="modal-sm password-expiry-modal"
    >
      <h2 className="h1 modal-title">
        <Content type="Modals" id="PasswordExpired" />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <LockoutSVG role="presentation" />
        </div>
        <p>
          <Content type="Modals" id="PasswordExpiredDesc" />
        </p>

        <div align="center">
          <ButtonPrimary onClick={() => passwordExpiredReset()}>
            <Content type="Modals" id="ResetPassword" />
          </ButtonPrimary>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordExpiryModal);

//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Actions
import { openModal } from '../../../../../reducers/modal/modalActions';

// Components
import { InputCheckboxInverse, Content } from '../../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-accept-terms.scss';

// Types
type Props = {
  className?: string,
  formikProps: FormikProps,
  openModal: Function,
};

const AcceptTermsLabel = ({ onClick }: Function) => (
  <>
    <Content type="Labels" id="IAcceptThe" />{' '}
    <TextButton type="button" onClick={onClick}>
      <Content type="Common" id="TermsAndConditions" />
    </TextButton>
    {''}.
  </>
);

/**
 * @visibleName Accept Terms
 */
const InputAcceptTerms = ({
  className,
  formikProps,
  openModal,
  ...props
}: Props) => (
  <InputCheckboxInverse
    {...props}
    name="terms"
    label={
      <AcceptTermsLabel onClick={() => openModal('TermsAndConditionsModal')} />
    }
    checked={formikProps.values.terms === true}
    formikProps={formikProps}
    className={classnames('input-accept-terms', className)}
  />
);

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ openModal }, dispatch);

export default connect(null, mapDispatchToProps)(InputAcceptTerms);

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Zip Code
 */
const InputZipCode = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    type="tel"
    mask={[...Array(5)].map(() => /\d/)}
    className={classnames('input-zip-code', className)}
  />
);

InputZipCode.defaultProps = {
  label: 'Zip Code',
  name: 'zipFive',
};

export default InputZipCode;

//@flow

// Vendors
import React, { useState } from 'react';
import classnames from 'classnames';

// Components
import { TextButton } from 'texkit/dist/components/Buttons';
import Content from '../Content/Content';

// Styles
import './toggle-group.scss';

// Types
type Props = {
  isOpen: boolean,
  open: ({ setToggle: Function }) => Node,
  closed: ({ setToggle: Function }) => Node,
  switchButtonText?: string,
  className?: string,
};

type ToggleGroupSwitchProps = {
  switchButtonText: string,
};

const ToggleGroupSwitch = ({
  switchButtonText = 'Edit',
  ...props
}: ToggleGroupSwitchProps) => {
  return (
    <TextButton className="toggle-group-switch" {...props}>
      <Content type="Labels" id={switchButtonText} />
    </TextButton>
  );
};

/**
 * @visibleName Default
 */
const ToggleGroup = ({
  className,
  closed,
  open,
  switchButtonText,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const groupIsOpen = props.isOpen || isOpen;
  const setToggle = () => setIsOpen(!isOpen);

  return (
    <div className={classnames('toggle-group', className)}>
       {groupIsOpen && open ? open({ setToggle }) : closed({ setToggle })}
      {!groupIsOpen && (
        <ToggleGroupSwitch
          onClick={setToggle}
          switchButtonText={switchButtonText}
        />
        )}
       
    </div>
  );
};

export default ToggleGroup;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputCheckbox from './InputCheckbox';

// Types
type Props = {
  name: string,
  className?: string,
};

/**
 * @visibleName Inverse Checkbox
 */
const InputCheckboxInverse = ({ className, ...props }: Props) => (
  <InputCheckbox
    {...props}
    className={classnames('input-checkbox-inverse', className)}
  />
);

export default InputCheckboxInverse;

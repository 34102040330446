//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword } from '../..';
import Content from '../../../Content/Content';

// Types
type Props = {
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Update Password
 */
const InputGroupUpdatePassword = ({
  className,
  formikProps,
  ...props
}: Props) => (
  <InputGroup
    {...props}
    className={classnames('input-group-update-password', className)}
  >
    <InputPassword
      className="input-current-password"
      label={<Content type="Labels" id="CurrentPassword" />}
      name="currentPassword"
      maxLength="32"
      formikProps={formikProps}
      autoFocus={true}
    />
    <InputPassword
      className="input-new-password"
      label={<Content type="Labels" id="NewPassword" />}
      name="newPassword"
      maxLength="32"
      formikProps={formikProps}
      autoFocus={false}
    />
    <InputPassword
      className="input-confirm-new-password"
      label={<Content type="Labels" id="ConfirmNewPassword" />}
      name="confirmNewPassword"
      maxLength="32"
      formikProps={formikProps}
      autoFocus={false}
    />
  </InputGroup>
);

export default InputGroupUpdatePassword;

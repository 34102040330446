//@flow

// Vendors
import React, { type Node, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

// Components
import { Loading, Content } from '../../../components';

// Actions
import { clearAlertInfo } from '../../../reducers/alert/alertActions';

// Helpers
import { SITE_DATA } from '../../../lib/data';
import {
  scrollToTop,
  focusFirstElement,
  convertContentArrayToString,
} from '../../../lib/helpers';

// Styles
import './page.scss';

// Types
type Props = {
  className?: string,
  isLoading?: boolean,
  children?: Node,
  title?: { id: string, type: string },
};

const PageTitle = ({ title }) => {
  useEffect(() => {
    const pageTitle = title
      ? `${SITE_DATA.SITE_NAME_ABBR} - ${title}`
      : SITE_DATA.SITE_NAME_ABBR;
    document.title = pageTitle;
  }, [title]);
  return null;
};

const Page = ({ className, isLoading, children, title, ...props }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop();
    focusFirstElement();
    return () => dispatch(clearAlertInfo());
  }, []);

  const UpdateTitle = title
    ? typeof title === 'string'
      ? () => <PageTitle title={title} />
      : () => (
          <Content {...title}>
            {pageTitle => (
              <PageTitle title={convertContentArrayToString(pageTitle)} />
            )}
          </Content>
        )
    : () => <PageTitle title={''} />;
  return (
    <div {...props} className={classnames('page', className)} role="main">
      {<UpdateTitle />}
      {isLoading ? <Loading /> : children}
    </div>
  );
};

export default Page;

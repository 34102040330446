import { SITE_DATA } from '../../data';
import LinkService from '../LinkService/LinkService.es.js';

export default {
  AgencyName: `Departamento de Licencias y Regulación de Tejas`,
  AgencyShortName: `Departamento de Licencias y Regulación`,
  tdlrlr_AppName: `Renovación de Licencia TDLR`,
  tdlrlr_AppShortName: `Renovación de Licencia TDLR`,
  tdlrlr_LicenseType_MassageTherapist: `Masajista`,
  tdlrlr_LicenseType_MassageTherapistInstructor: `Instructor terapeuta de masaje`,
  tdlrlr_LinkService_LookupTitle: `${LinkService.LookupTitle}`,
  tdlrlr_LinkService_SuccessTitle: `¡Éxito! Su licencia está vinculada a su cuenta.`,
  tdlrlr_LinkService_ConfirmTitleDefault: 'Se ha localizado su licencia.',
  tdlrlr_LinkService_ConfirmDescriptionDefault:
    'Revise y confirme que esta es la licencia correcta para vincular a su cuenta.',
  tdlrlr_LinkService_ConfirmTitleLinked: `Esta licencia ya esta conectada.`,
  tdlrlr_LinkService_ConfirmDescriptionLinked: `Esta licencia esta conectada y puede encontrarse en su cuadro de mandos.`,
  tdlrlr_ConfirmLicense_StepTitle: `Por favor confirme su información de licencia.`,
  tdlrlr_ConfirmLicense_Content_Header: `<h3>¡Importante!</h3>`,
  tdlrlr_ConfirmLicense_Content_HT: `
  <p>Por ley, debe completar un curso de Capacitación para la prevención de la trata de personas aprobado por la HHSC para renovar la licencia a partir del 1 de septiembre de 2020. Debe completar el curso antes de poder enviar su solicitud de renovación. Una vez que complete el curso, puede volver a iniciar sesión y continuar con la solicitud de renovación.</p>
  <p>Se ofrece al menos un curso de capacitación en prevención de la trata de personas de forma gratuita. Vaya a la página de <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">Capacitación para la prevención de la trata de personas de TDLR para profesionales de la salud</a>, donde puede encontrar más información y un enlace al curso de capacitación.</p>
  <p><em>*Tenga en cuenta que es posible que se le pida que proporcione un comprobante de haber completado un curso aprobado y debe conservar la documentación de finalización del curso.</em></p>
  <hr/>`,
  tdlrlr_ConfirmLicense_Content: `<p style="font-weight:bold;">A partir del 1 de septiembre de 2019, todos los solicitantes de terapeutas de masajes y propietarios de establecimientos (incluidas las renovaciones) deben enviar sus huellas digitales para una revisión nacional de antecedentes penales y aprobar con éxito una verificación de antecedentes penales para ser elegibles para la licencia.</p>
  <p>Después de completar esta solicitud, se le proporcionarán instrucciones para programar una cita de huellas digitales. Además, estas instrucciones se incluirán con su correo electrónico de recibo.</p>`,
  tdlrlr_ReviewProfile_StepTitle: `Revise su Perfil`,
  tdlrlr_ReviewProfile_PersonalInformation_Title: `Informacion Personal`,
  tdlrlr_EligibilityQuestions_StepTitle: `Preguntas de Elegibilidad`,
  tdlrlr_EligibilityQuestions_ConvictionInformation_Title: `Información convicción`,
  tdlrlr_EligibilityQuestions_ContinuingEducation_Title: 'Educación Continua',
  tdlrlr_Training_Requirements_Title: 'Requisitos de formación',
  tdlrlr_EligibilityQuestions_Attestation_Title: `Atestación`,
  tdlrlr_Payment_StepTitle: `Pago`,
  tdlrlr_Payment_RenewalFee_Title: `Precio de Renovación`,
  tdlrlr_Label_DateOfAppliction: `Fecha de aplicación`,
  tdlrlr_Label_MilitaryStatus: `Estatus Militar`,
  tdlrlr_Label_MilitaryNote: `Se le proporcionará un enlace para descargar el formulario de renovación militar, así como las instrucciones para su presentación en el último paso de este proceso. Su presentación renovación no será procesada hasta que este formulario es completado y presentado.`,
  tdlrlr_Label_ActiveMilitary: `¿Su licencia o permiso de su cónyuge expiran mientras se desplegaron en servicio activo?`,
  tdlrlr_Label_FelonyMisdemeanor: `¿Ha sido declarado culpable de algún delito mayor o menor?`,
  tdlrlr_Label_CriminalOffense: `¿Ha presentado una declaración de nolo contendere, una declaración de culpabilidad o recibido una suspensión condicional del proceso por cualquier crimen desde su última renovación?`,
  tdlrlr_Label_ConvictionReason: `Si respondió sí, favor de proveer una breve explicación.`,
  tdlrlr_Label_ContinuingEducation: `¿Ha completado 12 horas de educación continua cualificada desde su última renovación?`,
  tdlrlr_Label_ContinuingEducationDesc: `Si respondió no, favor de proveer una explicación breve (opcional):`,
  tdlrlr_Label_HumanTraffickingCourse:
    'He completado el curso obligatorio sobre trata de personas.',
  tdlrlr_Label_Attestation: `Por este medio certifico que toda la información que he proporcionado es verdadera y correcta. Entiendo que proporcionar información falsa o incorrecta puede constituir una violación de la ley estatal o las reglas de la agencia y puede someter mi licencia a medidas disciplinarias, que pueden incluir la revocación.`,
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_Header:
    'Incapaces de proceder',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_HumanTrafficking:
    'Debe confirmar la finalización de la formación requerida para poder continuar. <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">Haga clic aquí</a> para encontrar más información y un enlace al curso de capacitación.',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_Attestation:
    'Debe confirmar la atestación marcando la casilla de arriba para continuar.',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert:
    'Debe confirmar las dos casillas de verificación anteriores para continuar. <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">Haga clic aquí</a> para encontrar más información y un enlace al curso de capacitación.',
  tdlrlr_Success_Header: `Su Aplicación de Renovación fue Enviada con Éxito.`,
  tdlrlr_Success_ThankYou: `Gracias por usar ${SITE_DATA.SITE_NAME} para renovar su licencia de terapeuta de masaje. Espere hasta dos semanas para que su solicitud sea revisada por el Departamento de Licencias y Regulación de Tejas. Recibirá un correo electrónico una vez que la solicitud haya sido aprobada.`,
  tdlrlr_Success_Survey:
    'Ayúdenos a mejorar realizando una breve encuesta de su experiencia de renovación de licencia.',
  tdlrlr_Success_NextSteps: `Próximos pasos`,
  tdlrlr_Success_NextStepsInfo: `Con el fin de completar su solicitud de renovación se debe <b>descarga, llene y envíe</b> de la siguiente forma(s) a una de las siguientes direcciones.`,
  tdlrlr_Success_MilitaryRenewalForm: `Formulario de renovación militar`,
  tdlrlr_Success_ConvictionQuestionnaire: `Cuestionario convicción`,
  tdlrlr_Success_Audit: `Si usted ha sido notificado por correo que está siendo auditado, favor de enviar prueba de las transcripciones de educación continua a una de las direcciones siguientes.`,
  tdlrlr_Success_SendForm_AddressHeader: `Enviar formularios a:`,
  tdlrlr_Success_SendForm_Address: `Departamento de Licencias y Regulación de Tejas<br/>apartados de correos Box 12157 <br/> Austin, Texas 78711`,
  tdlrlr_Success_DeliverForm_AddressHeader: `En persona:`,
  tdlrlr_Success_DeliverForm_Address: `TDLR oficina principal <br/>920 Colorado St. <br/>Austin, Texas 78701`,
  tdlrlr_Success_Content: `<p><h3>¡Recordatorio importante!</h3>
  <span style="font-weight: bold;">
  A partir del 1 de septiembre de 2019, todos los solicitantes de terapeutas de masajes y propietarios de establecimientos (incluidas las renovaciones) deben haber aprobado con éxito una verificación de antecedentes penales para ser elegibles para la licencia.</span></p>
  <p>Todos los solicitantes (incluidos los propietarios de establecimientos) deben presentar sus huellas digitales para la revisión nacional de los antecedentes penales.</p>`,
  tdlr_fingerprinting_instructions_link: 'Instrucciones de huellas digitales',
  tdlr_fingerprinting_steps: `<ul style="list-style-type:none;margin:0"><li>1. Ve a tomar las huellas digitales</li><li>2. Envíe el recibo de la huella digital por correo electrónico a massage.fingerprint@tdlr.texas.gov</li></ul>`,
  tdlr_fingerprinting_explanation: `TDLR tiene que aprobar su historial criminal con huellas digitales <span style="font-weight:bold;">antes de que</span> se pueda emitir su licencia de masaje. Su licencia se emitirá si se aprueba su historial criminal, de lo contrario, TDLR se comunicará con usted si tiene alguna pregunta.`,
};

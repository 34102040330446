//@flow

// Vendors
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import {
  Modal,
  InputRadio,
  FormActions,
  FormActionsItem,
  Content,
} from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Helpers
import { formatMultiLineAddress } from '../../../../lib/helpers';

// Styles
import './verify-address-modal.scss';
import { states } from '../../../../lib/data/agencyData/BON';

// Types
type Props = {
  id?: string,
  onCancel?: Function,
  onSuccess: Function,
  modal: Object,
  closeModal: Function,
};

function VerifyAddressModal(props: Props) {
  const {
    onSuccess,
    closeModal,
    id = 'VerifyAddressModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions || !data.enteredAddress) return false;
  if (data.enteredAddress.state && data.enteredAddress.state.value)
    data.enteredAddress = {
      ...data.enteredAddress,
      state: data.enteredAddress.state.value,
    };

  const [selectedAddress, setSelectedAddress] = useState(
    data.enteredAddress || null
  );

  function handleChange(event) {
    const { name, value } = event.target;
    if (name && value) setSelectedAddress(JSON.parse(value));
  }

  function handleSuccess() {
    closeModal(id);
    onSuccess({
      ...selectedAddress,
      verifySkip: true,
      state: states.find(state => state.value === selectedAddress.state) || {},
    });
  }

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Content type="Modals" id="ConfirmCorrectAddress" />
      </h2>
      <div className="modal-content">
        <div>
          <span className="verify-address-modal-label">
            {data.addressOptions.length > 1 ? (
              <Content type="Labels" id="SuggestedAddresses" />
            ) : (
              <Content type="Labels" id="SuggestedAddress" />
            )}
          </span>
          {data.addressOptions.map((address, index) => (
            <InputRadio
              key={`verifyAddress${index}`}
              id={`verifyAddress${index}`}
              name="verifyAddress"
              onChange={handleChange}
              label={formatMultiLineAddress(address)}
              value={JSON.stringify(address)}
              checked={
                JSON.stringify(selectedAddress) === JSON.stringify(address)
              }
            />
          ))}
          <span className="verify-address-modal-label">
            <Content type="Modals" id="UseAddressEntered" />
          </span>
          <InputRadio
            id="enteredAddress"
            name="verifyAddress"
            onChange={handleChange}
            label={formatMultiLineAddress(data.enteredAddress)}
            value={JSON.stringify(data.enteredAddress)}
            checked={
              JSON.stringify(selectedAddress) ===
              JSON.stringify(data.enteredAddress)
            }
          />
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={handleSuccess} disabled={!selectedAddress}>
              <Content type="Labels" id="UpdateAddress" />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAddressModal);

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword, Content } from '../../..';

type Props = {
  className?: string,
  questions: Object,
  formikProps: FormikProps,
};

/**
 * @visibleName Answer Security Questions
 */
const InputGroupAnswerSecurityQuestions = ({
  questions,
  className,
  formikProps,
  ...props
}: Props) => {
  if (!questions) return null;

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-answer-security-questions', className)}
    >
      {questions.map((question, index) => {
        const answerName = `answer${index + 1}`;

        return (
          <InputPassword
            key={question.quid}
            className="input-borderless"
            label={
              <Content
                type="SecurityQuestions"
                id={
                  question.quid
                    ? `SecurityQuestion_${question.quid}`
                    : `SecurityQuestion_empty`
                }
              />
            }
            labelPosition="above"
            placeholder={`Answer ${index + 1}`}
            name={answerName}
            formikProps={formikProps}
          />
        );
      })}
    </InputGroup>
  );
};

export default InputGroupAnswerSecurityQuestions;

// Data
import { SITE_DATA } from '../../data';

export default {
  SorryTitle: `¡Lo siento!`,
  SessionExpiredTitle: `Su sesión ha finalizado.`,
  LookFamiliarTitle: `¿Le resulta familiar?`,
  SignInExpired: `Tu signo en el código caducado, por favor intente acceder de nuevo.`,
  ForgotPasswordExpired: `Su código de restablecimiento de contraseña caducado, por favor intente de nuevo.`,
  CouldNotVerifyIdentity: `No hemos podido verificar tu identidad con la información que ya ha proporcionado, por favor intente de nuevo.`,
  SignInAgain: `Por favor inicie sesión de nuevo para acceder a su cuenta.`,
  Field_DateFormat: `Por favor introduzca una fecha valida.`,
};

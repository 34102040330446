import { SITE_DATA } from '../../data';

export default {
  Congratulations: `¡Felicidades, {{1}}!`,
  EmailCaptureTitle: `¡Genial! Empecemos. Ingrese su correo electrónico.`,
  EmailCaptureDescription: `Su correo electrónico será el principal método de comunicación. Utilice su correo electrónico personal principal.`,
  PhoneTitle: `Luego, ingrese su número de teléfono.`,
  PasswordTitle: `Por favor, establezca una contraseña segura.`,
  PasswordSubTitle:
    'Su contraseña NO debe contener su nombre de usuario, dirección de correo electrónico, nombre, apellido o cualquier otra información de identificación personal (PII). Esto incluye cualquier información que alguien pueda adivinar fácilmente sobre usted.',
  PasswordDescription: `Las contraseñas deben tener al menos ocho caracteres.`,
  IdentityChoosePathTitle: `Verifique su identidad.`,
  IdentityChoosePathDescription: `Podemos verificar su identidad con una identificación emitida por el estado, incluida una licencia de conducir, o mediante una serie de preguntas.`,
  IdentityChoosePathDescriptionForNonIdologyUsers: `Podemos verificar su identidad con una identificación de Texas o una licencia de conducir.`,
  IdentityIDologyTitle: `Genial, verificaremos su identidad con algunas preguntas.`,
  IdentityIDologyDescription: `Complete el formulario a continuación y luego le haremos algunas preguntas adicionales que solo usted podrá responder.`,
  IdentityIDologyQuestionCountQuestion: `Pregunta`,
  IdentityIDologyQuestionCountOf: `de`,
  IdentityTOASTitle: `¡Genial! Por favor, introduzca su información de identificación.`,
  IdentityTOASDescription: `La información que proporciona es solo para fines de verificación de identidad.`,
  FailedTitle: `Esto es incómodo&hellip;`,
  BlockedTitle: `Has sido bloqueado.`,
  WelcomeBack: `Bienvenido Nuevamente!`,
  AccountSuccessfullyCreated: `Su cuenta ha sido creada. Inicia sesión para acceder a tu cuenta.`,
  EnterLegalFirstName: 'Este debe ser tu nombre legal.',
  EnterLegalLastName: 'Este debe ser tu apellido legal.',
};

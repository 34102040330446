//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Date
 */
const InputDate = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    type="tel"
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    className={classnames('input-date', className)}
  />
);

InputDate.defaultProps = {
  label: 'Date',
};

export default InputDate;

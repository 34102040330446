//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { Dropdown, Content } from '../..';
import Avatar from 'texkit/dist/components/Avatar/Avatar';

// Actions
import { signOut } from '../../../reducers/auth/authActions';
import {
  goToAcount,
  goToEmployeePage,
  goToSelfService,
} from '../../../reducers/navbar/navbarActions';

// Styles
import './navbar-account-dropdown.scss';
// import { state } from '../../../lib/validation/rules/common';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

//selectors
import {
  isEnterpriseAdministrator,
  isDelegatedAdmin,
  isGovUser,
} from '../../../reducers/agency/agencySelectors';

const NavbarAccountDropdown = () => {
  const user = useSelector(state => state.user || {});
  const showStar = useSelector(isGovUser);
  const showSelfServiceOption = useSelector(isEnterpriseAdministrator);
  const showDelegatedAdmin = useSelector(isDelegatedAdmin);
  const dispatch = useDispatch();

  const selfService = {
    as: 'Link',
    label: <Content type="Labels" id="ApplicationOnBoardingSelfService" />,
    to: '/portal/self-service',
    onClick: () => dispatch(goToSelfService()),
  };
  
  const delegatedAdmin = {
    as: 'a',
    label: <Content type="Labels" id="DelegatedAdmin" />,
    to: '/#/dashboard',
    onClick: () => window.open('/#/dashboard', '_blank'),
  };

  const menuItemsOptions = [
    {
      as: 'Link',
      label: <Content type="Labels" id="AccountSettings" />,
      to: '/portal/account',
      url: '/portal/account',
      onClick: () => dispatch(goToAcount()),
    },

    {
      label: <Content type="Labels" id="SignOut" />,
      onClick: () => dispatch(signOut()),
    },
  ];
  if (showDelegatedAdmin) {
    menuItemsOptions.splice(1,0, delegatedAdmin);
  }
  if (showSelfServiceOption) {
    menuItemsOptions.splice(1,0, selfService);
  }
  

  const menuItemsStar = [
    {
      as: 'Link',
      label: <Content type="Labels" id="AccountSettings" />,
      to: '/portal/account',
      url: '/portal/account',
      onClick: () => dispatch(goToAcount()),
    },
    {
      label: <Content type="Labels" id="SignOut" />,
      onClick: () => dispatch(signOut()),
    },
  ];

  const menuItems = [
    {
      as: 'Link',
      label: <Content type="Labels" id="AccountSettings" />,
      to: '/portal/account',
      onClick: () => dispatch(goToAcount()),
    },
    {
      label: <Content type="Labels" id="SignOut" />,
      onClick: () => dispatch(signOut()),
    },
  ];

  if (!user || !user.profile || !user.profile.initials) return null;

  return (
    <Dropdown
      id="navbar-account-dropdown"
      className="navbar-account-dropdown"
      // items={
      //   showSelfServiceOption
      //     ? menuItemsOptions
      //     : showStar
      //     ? menuItemsStar
      //     : menuItems
      // }
      items={menuItemsOptions}
      toggleComponent={({ ...props }) => (
        <Avatar
          {...props}
          as="button"
          className="navbar-avatar dropdown-toggle"
          aria-label="Additional Account Settings"
        >
          {user.profile.initials}
          {showStar && <Icon name="lone-star" />}
        </Avatar>
      )}
    />
  );
};

export default withRouter(NavbarAccountDropdown);

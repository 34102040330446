import LinkService from '../LinkService/LinkService.es.js';

const AgencyName = 'Junta de Enfermería de Tejas';
const AgencyShortName = 'Junta de Enfermería';
const BNLRCriminalList =
  '<li>¿Sido detenido y tienen un cargo criminal pendiente?</li><li>¿Sido condenado por un delito menor?</li><li>¿Sido condenado por un delito grave?</li><li>¿Nolo contendere, sin concurso, o culpable?</li> <li>¿Recibido adjudicación diferida?</li><li>¿Sido colocado bajo supervisión comunitaria o libertad por orden judicial, ya sea o no encontrado culpable?</li><li>¿Sido condenado a servir a la cárcel, la pena de prisión o reclusión por orden judicial?</li><li>¿Sido otorgado antes del juzgamiento?</li><li>¿Sido citado o acusado de cualquier viliation de la ley?</li><li>¿Sido objeto de un consejo de guerra; Artículo 15 viliation; o recibido ninguna forma de juicio/castigo/acción militar?</li>';
const BNLRExpungedTitle = 'Delitos borrados y selladas';
const BNLRExpungedContent =
  'Ha ninguna autoridad de concesión de licencias cada vez que se negó a emitir una licencia o alguna vez revocada, anulada, cancelada,rendición aceptada de, suspendido, puesto en libertad condicional, se negó a renovar una licencia, certificado oprivilegio de varios estados en manos de usted ahora o antes, o alguna vez una multa, censurado, reprendido, o de otro modousted disciplinado? (Es posible excluir las acciones disciplinarias emitidas por la Junta de Enfermería de Texas ymedidas disciplinarias a conocer previamente a la Junta de Enfermería de Texas en una licencia inicial o renovaciónsolicitud.)';
const BNLRNonDisclosureTitle = 'Órdenes de no divulgación';
const BNLRNonDisclosureContent =
  'De conformidad con Tex. Código de Gobierno §552.142 (b), si tiene materia penal que son objeto de una orden deno divulgación que no están obligados a revelar los asuntos penales en este formulario. Sin embargo, un criminalmateria que es objeto de una orden de no divulgación puede convertirse en un problema de carácter y capacidad.De conformidad con el Código de Gobierno capítulo 411, la Junta de Enfermería de Texas tiene derecho a acceder a los antecedentes penalesinformación que es objeto de una orden de no divulgación. Si la Junta descubre un asunto penalque es objeto de una orden de no divulgación, incluso si bien no reveló que la materia, laJunta podrá requerir que proporcione información sobre cualquier conducta que plantea cuestiones de carácter yaptitud.';

export default {
  AgencyName,
  AgencyShortName,
  bnlr_AppName: 'Junta de Renovación de Licencia de enfermería',
  bnlr_AppShortName: 'Renovación de Licencia de enfermería',
  bnlr_LinkService_LookupTitle: LinkService.LookupTitle,
  bnlr_LinkService_ConfirmTitleDefault: 'Se ha localizado su licencia.',
  bnlr_LinkService_ConfirmDescriptionDefault:
    'Revise y confirme que esta es la licencia correcta para vincular a su cuenta.',
  bnlr_LinkService_SuccessTitle:
    '¡Éxito! Su licencia está vinculada a su cuenta.',
  bnlr_LookupLicense_Title:
    'Vamos a empezar por la búsqueda de su licencia elegible para renovar.',
  bnlr_LookupLicense_ContentA:
    'Listo para renovar su licencia? Revisar el &nbsp;',
  bnlr_LookupLicense_ContentLink: 'página de elegibilidad',
  bnlr_LinkService_ConfirmTitleLinked: `Esta licencia ya esta conectada.`,
  bnlr_LinkService_ConfirmDescriptionLinked: `Esta licencia esta conectada y puede encontrarse en su cuadro de mandos.`,
  bnlr_LookupLicense_ContentB:
    '&nbsp; para averiguar lo que necesita antes de continuar.',
  bnlr_LookupLicense_SubmitText: 'Encuentra mi licencia',
  bnlr_ConfirmLicense_Title: 'Por favor confirme su información de licencia.',
  bnlr_ConfirmLicense_NotWithinWindowTitle:
    'Hemos localizado su licencia, pero no está dentro de la ventana de renovación.',
  bnlr_ConfirmLicense_NotWithinWindowContent:
    'La ventana de renovación de la licencia está abierta durante 60 días antes de la fecha de caducidad.',
  bnlr_ConfirmLicense_LookupAnother:
    'Si esto no es la licencia correcta, mira hacia arriba a &nbsp;',
  bnlr_ConfirmLicense_LookupAnotherButton: 'uno diferente',
  bnlr_ConfirmLicense_LookupAnotherPunctuation: '.',
  bnlr_ConfirmLicense_LinkReason:
    'Vincular su cuenta para recibir recordatorios cuando su licencia está listo para ser renovado.',
  bnlr_VerifyEligibility_Title: 'Verificar la elegibilidad.',
  bnlr_VerifyEligibility_LegalAcknowledgement:
    'Reconozco que este formulario electrónico es un documento legal. Yo entiendo que nadie más podrá presentar dicha  formar en mi nombre y que soy responsable y responsable de la exactitud de cualquier respuesta o declaración sobre  esta forma. Doy fe de que me encuentro con todos los requisitos para practicar para el tipo de renovación solicitada, tal como  enumerado en 22 Código Administrativo de Texas, cap. 216 (CE). Además, entiendo que es una violación de la  22 Código Administrativo de Texas § 217.12 (6) (I) y Código Penal § 37.10, a presentar una declaración falsa a una  agencia gubernamental.',
  bnlr_VerifyEligibility_APRNRequirementNote:
    'APRNs también debe cumplir con todos los requisitos indicados 221,8 (APRN); y 222.3 (LPA).',
  bnlr_VerifyEligibility_CriminalIntro:
    'que tienen, dentro de los últimos 24 meses o desde su última renovación, por cualquier delito, incluidas las  pendiente de apelación:',
  bnlr_VerifyEligibility_CriminalList: BNLRCriminalList,
  bnlr_VerifyEligibility_Exclusion:
    'Sólo se puede excluir Clase C delito menor violaciónes de tráfico o delitos revelados previamente a la de Texas  Junta de Enfermería en una solicitud inicial o de renovación.',
  bnlr_VerifyEligibility_ExpungedTitle: BNLRExpungedTitle,
  bnlr_VerifyEligibility_ExpungedContent: BNLRExpungedContent,
  bnlr_VerifyEligibility_NonDisclosureTitle: BNLRNonDisclosureTitle,
  bnlr_VerifyEligibility_NonDisclosureContent: BNLRNonDisclosureContent,
  bnlr_VerifyEligibility_GrandJuryTarget:
    '¿Se encuentra actualmente el objetivo o tema de un gran jurado o investigación agencia gubernamental?',
  bnlr_VerifyEligibility_Licensing:
    'Ha ninguna autoridad de concesión de licencias cada vez que se negó a emitir una licencia o alguna vez revocada, anulada, cancelada,  rendición aceptada de, suspendido, puesto en libertad condicional, se negó a renovar una licencia, certificado o  privilegio de varios estados en manos de usted ahora o antes, o alguna vez una multa, censurado, reprendido, o de otro modo  usted disciplinado? (Es posible excluir las acciones disciplinarias emitidas por la Junta de Enfermería de Texas y  medidas disciplinarias a conocer previamente a la Junta de Enfermería de Texas en una licencia inicial o renovación  solicitud.)',
  bnlr_VerifyEligibility_DiagnosedDisorders:
    'En los últimos cinco (5) años, ¿le han diagnosticado o tratado u hospitalizado para la esquizofrenia  y/o trastornos psicóticos, trastorno bipolar, trastorno de personalidad paranoide, antisocial de la personalidad  , y el trastorno límite de la personalidad, que deteriora o que altera su comportamiento, juicio o  capacidad para funcionar en la escuela o el trabajo? (Usted puede contestar "No" si ha completado y / o están en el cumplimiento  con TPAPN para las enfermedades mentales o que haya comunicado previamente a la Junta de Enfermería de Texas y tienen  permanecido compatible con su régimen de tratamiento y no han tenido aún más la hospitalización ya la divulgación.)',
  bnlr_VerifyEligibility_Addiction:
    'En los últimos 5 años, ¿ha tenido adicción o tratado por el uso de alcohol o cualquier otra droga? (Puedes    responder "No" si ha completado y/o están en el cumplimiento de TPAPN).',
  bnlr_Payment_Title: 'Pago',
  bnlr_IneligibleOnline_Title:
    'Por desgracia, no somos capaces de renovar su licencia en línea.',
  bnlr_IneligibleOnline_Reason:
    'Al menos una de las respuestas en la página de elegibilidad lo descalifica para renovar en línea. Por favor, devuelva una  completado el formulario de renovación documento a la Junta de Enfermería antes de su fecha de caducidad de licencia.',
  bnlr_IneligibleOnline_RequirementsLink:
    'Vea los requisitos de elegibilidad aquí.',
  bnlr_IneligibleOnline_SendFormsTo:
    '<p><strong> Enviar a formas:</strong><br/><br />333 Guadalupe suite 3-460 <br />Austin, TX 78701 a 3944</p>',
  bnlr_IneligibleOnline_TimelyRenewalTitle: 'Renovación oportuna',
  bnlr_IneligibleOnline_TimelyRenewalContent:
    'El tiempo de procesamiento es de dos semanas. Para evitar cargos por pagos atrasados, renovaciones deben enviarse por correo a más tardar el último día del mes en que expira su licencia de enfermería.',
  bnlr_IneligibleOnline_LVNDownload:
    'LVN: licencia profesional Formulario de renovación de la enfermera',
  bnlr_IneligibleOnline_RNDownload:
    'RN: Registered Nurse Formulario de renovación',
  bnlr_IneligibleOnline_RNAPRNDownload:
    'RN/APRN: enfermera registrada con práctica avanzada de reconocimiento Formulario de renovación',
  bnlr_IneligibleOnline_RNLVNDownload:
    'RN/LVN doble licencia: vocacional con licencia y registrada Formulario de renovación de la enfermera',
  bnlr_IneligibleOnline_VolunteerDownload:
    'Retirado Voluntario Formulario de Autorización de la enfermera',
  bnlr_IneligibleOnline_GracePeriodTitle:
    'Período de gracia para licencias de enfermería que no son elegibles para la renovación en línea.',
  bnlr_IneligibleOnline_GracePeriodContent:
    'Las enfermeras que presenten la solicitud de renovación oportuna y correcta, pago mínimo correo a más tardar el último  día del mes la licencia expira están exentos de TOC § 301.301 (f) y puede seguir practicando para una  período de hasta un mes a partir de la fecha de caducidad de la licencia, mientras que la renovación está en proceso. El Departamento de Renovación  se comunicará con la enfermera en relación a las cuestiones pendientes de otorgamiento de licencias (es decir, la necesidad de una carta de liberación TGSLC, cumplimiento con la continuación de las competencias de auditoría, etc). Si todos los requisitos de renovación no se cumplen dentro de la subsecuente meses, se colocará la licencia en el estado delincuente. Durante el período de gracia, el sitio reflejará que la licencia es corriente a través de la fecha de caducidad ha pasado. La página web BON se actualizará para reflejar el futuro fecha de caducidad una vez que el proceso de renovación se efectúe. Una enfermera que no se somete la solicitud de renovación oportuna y el pago correcto, con matasellos en / antes de la fecha de caducidad de la licencia, debe seguir el proceso de renovación delincuente  y se aplicará ningún período de gracia.',
  bnlr_IneligibleOnline_DelinquentTitle: 'Renovación delincuente',
  bnlr_IneligibleOnline_DelinquentContent:
    'Con el fin de renovar una licencia en el estado delincuente, prueba de la educación continua de enfermería presentó con su  Se requiere formulario de renovación.',
  bnlr_IneligibleOnline_LVNDelinquentDownload:
    'LVN: Delincuente (caducado) Formulario de renovación de licencia Enfermera Profesional',
  bnlr_IneligibleOnline_RNDelinquentDownload:
    'LVN: Delincuente (caducado) Formulario de renovación de licencia Enfermera Profesional',
  bnlr_IneligibleOnline_RNAPRNDelinquentDownload:
    'RN/APRN: Delincuente (caducado) enfermera registrada con práctica avanzada de reconocimiento Formulario de renovación',
  bnlr_IneligibleOnline_RNLVNDelinquentDownload:
    'RN/LVN doble licencia: vocacional con licencia y registrada Formulario de renovación de la enfermera',
  bnlr_IneligibleOnline_ReactivationTitle: 'Reactivación',
  bnlr_IneligibleOnline_ReactivationContent:
    'Con el fin de renovar una licencia en el estado inactivo, se requiere prueba de la educación continua de enfermería presentado con su solicitud de renovación. Se requiere prueba de la educación continua de enfermería para renovar una licencia inactiva.',
  bnlr_IneligibleOnline_LVNReactivationDownload:
    'LVN: Reactivación forma de una enfermera con licencia profesional',
  bnlr_IneligibleOnline_RNReactivationDownload:
    'RN: forma Reactivación de una enfermera registrada',
  bnlr_IneligibleOnline_RNAPRNReactivationDownload:
    'RN/APRN: Reactivación forma de una enfermera registrada con la Práctica Autorización Avanzada',
  bnlr_IneligibleOnline_RNLVNReactivationDownload:
    'Sólo APRN: Reactivación de forma activa / delincuente Reconocimiento Avanzado de Práctica',
  bnlr_EligibilityRequirements_PageTitle:
    'Junta de licencia de enfermería requisitos de renovación de elegibilidad',
  bnlr_EligibilityRequirements_Title:
    'Requisitos de elegibilidad en línea Renovación',
  bnlr_EligibilityRequirements_Subtitle:
    'Usted puede renovar en línea a menos una de las siguientes situaciones:',
  bnlr_EligibilityRequirements_EligibilityList:
    '<li>La enfermera ha de pagar un préstamo de estudiante Texas Guaranteed.</li> <li>La enfermera no ha cumplido con la educación obligatoria continua de enfermería (CNE) los requisitos y/o se ha seleccionado para una auditoría CNE. Prueba de la educación continua de enfermería debe ser recibido en nuestra oficina con el fin de la licencia para ser lanzado o renovada. </li> <li> La enfermera ha sido seleccionado para una verificación de antecedentes penales y el proceso no se ha completado. </li> <li>La enfermera es reconocido como una práctica avanzada enfermera registrada y no ha cumplido con la auditoría obligatoria APRN requisitos. La prueba de certificación nacional actual para cada tipo de reconocimiento aprobado debe ser recibida en nuestra oficina para que la licencia para ser lanzado para la renovación.</li> <li>estado primordial de la enfermera de residencia es un estado compacto que no sea Texas. Marque aquí para ver más información sobre la enfermera de Licenciatura compacto y para comprobar si se incluye su estado.</li><li>El número de licencia y / o los últimos cuatro dígitos del número de la Seguridad Social ingresó no coinciden con la información sobre los archivos de planchar. </li> <li> sido objeto de un consejo de guerra; El artículo 15 de violación; o recibido ninguna forma de juicio / castigo / acción militar? </li>',
  bnlr_EligibilityRequirements_EligibilityIssuesTitle:
    'Problemas de elegibilidad',
  bnlr_EligibilityRequirements_EligibilityIssuesContent:
    'que tienen, dentro de los últimos 24 meses o desde su última renovación, por cualquier delito, incluidos aquellos en espera de la apelación:',
  bnlr_EligibilityRequirements_CriminalList: BNLRCriminalList,
  bnlr_EligibilityRequirements_ExpungedTitle: BNLRExpungedTitle,
  bnlr_EligibilityRequirements_ExpungedContent: BNLRExpungedContent,
  bnlr_EligibilityRequirements_NonDisclosureTitle: BNLRNonDisclosureTitle,
  bnlr_EligibilityRequirements_NonDisclosureContent: BNLRNonDisclosureContent,
  bnlr_EligibilityRequirements_GrandJuryTarget:
    '¿Se encuentra actualmente el objetivo o tema de un gran jurado o investigación agencia gubernamental?',
  bnlr_EligibilityRequirements_Licensing:
    'Ha ninguna autoridad de concesión de licencias cada vez que se negó a emitir una licencia o alguna vez revocada, anulada, cancelada,  rendición aceptada de, suspendido, puesto en libertad condicional, se negó a renovar una licencia, certificado o privilegio de varios estados en manos de usted ahora o antes, o alguna vez una multa, censurado, reprendido, o de otro modo usted disciplinado? (Es posible excluir las acciones disciplinarias emitidas por la Junta de Enfermería de Texas y medidas disciplinarias a conocer previamente a la Junta de Enfermería de Texas en una licencia inicial o renovación solicitud.)',
  bnlr_EligibilityRequirements_DiagnosedDisorders:
    '*En los últimos cinco (5) años, se le ha diagnosticado o tratado u hospitalizado para la esquizofrenia  y/o trastornos psicóticos, trastorno bipolar, trastorno de personalidad paranoide, antisocial de la personalidad  , y el trastorno límite de la personalidad, que deteriora o que altera su comportamiento, juicio o  capacidad para funcionar en la escuela o el trabajo? (Usted puede contestar "No" si ha completado y/o están en el cumplimiento con TPAPN para las enfermedades mentales o que haya comunicado previamente a la Junta de Enfermería de Texas y tienen permanecido compatible con su régimen de tratamiento y no han tenido aún más la hospitalización ya la divulgación.)',
  bnlr_EligibilityRequirements_Addiction:
    '*En los últimos 5 años, ¿ha tenido adicción o tratado por el uso de alcohol o cualquier otra droga? (Puedes responder "No" si ha completado y/o están en el cumplimiento de TPAPN).',
  bnlr_EligibilityRequirements_Footnote:
    '*De conformidad con el código de ocupaciones §301.207, la información, incluyendo el diagnóstico y el tratamiento, en relación con una condición física o mental del individuo, el uso inmoderado de drogas o alcohol, o la dependencia química y información sobre los antecedentes penales de una persona es confidencial en la misma medida que la información recoge como parte de una investigación es confidencial bajo el código de ocupaciones §301.466.',
};

/* eslint-disable react/display-name */
// @flow

// Vendor
import React from 'react';
import classnames from 'classnames';

// Components
import { Form, InputText, InputSSN, InputDOB, Content } from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { getAppDataByCodes } from '../../../../lib/helpers';
import { createLinkServiceFormValidationSchema } from '../../../../lib/validation';

// Styles
import './link-service-lookup-form.scss';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export type AppParams = {
  id: string,
  value: string | null,
  name: string,
  type: string,
  attr: Object,
};

type InputProps = {
  form: any,
  name: string,
  attr: any,
  formikProps: FormikProps,
};

const INPUTS = {
  DEFAULT: ({ form, name, attr, formikProps }: InputProps) => (
    <InputText
      key={name}
      name={name}
      label={<Content type="Labels" id={form && form.labels[name].id} />}
      labelPosition="above"
      formikProps={formikProps}
      max={attr.max}
      required={attr.required === 'true'}
    />
  ),
  // TODO: Possibly change back to "lastFourSSN" once back-end updates the params in their response.
  ssn: ({ form, name, attr, formikProps }: InputProps) => (
    <InputSSN
      key={name}
      name={name}
      label={<Content type="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      required={attr.required === 'true'}
    />
  ),
  dob: ({ form, name, attr, formikProps }: InputProps) => (
    <InputDOB
      key={name}
      name={name}
      label={<Content type="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      required={attr.required === 'true'}
    />
  ),
};

const INPUT_REDUCER = (form, { name, attr = {} }, formikProps: FormikProps) =>
  INPUTS[name]
    ? INPUTS[name]({ form, name, attr, formikProps })
    : INPUTS.DEFAULT({ form, name, attr, formikProps });

const CustomContent = ({ agencyCode }: { agencyCode: string }) => {
  if (agencyCode === '452')
    return (
      <p className="link-service-lookup-form-custom">
        <Content type="LinkService" id="LookupCustomContent_TDLR" />
      </p>
    );

  return null;
};
type Props = {
  agency: Object,
  app: Object,
  appParams: Array<AppParams>,
  onSubmit: Function,
  submitText?: Node,
  onCancel?: Function,
  className?: string,
};

const LinkServiceLookupForm = ({
  className,
  agency,
  app,
  appParams,
  onSubmit,
  onCancel,
  submitText = <Content type="Labels" id="Next" />,
}: Props) => {
  const lastName = useSelector(state => state.user.profile.lName);
  const { location: { pathname }} = useHistory();
  return (
    <Form
      className={classnames('link-service-lookup-form', className)}
      formikConfig={{
        onSubmit,
        initialValues: appParams.reduce((acc, param) => {
          acc[param.name] = '';
          acc['lastName'] = pathname.includes('cpaln') ? lastName : '';
          return acc;
        }, {}),
        validationSchema: createLinkServiceFormValidationSchema(
          agency,
          app,
          appParams
        ),
      }}
      render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
        <>
          <CustomContent agencyCode={agency.agencyCode} />
          <div className="link-service-lookup-form-body">
            {appParams.map(params =>
              INPUT_REDUCER(
                getAppDataByCodes(agency.agencyCode, app.appCode).linkService
                  .form,
                params,
                formikProps
              )
            )}
          </div>
          <FormError />
          <FormActions>
            <FormActionsItem>
              <ButtonPrimary
                type="submit"
                isLoading={formikProps.isSubmitting}
                loadingContent={<Content type="Labels" id="Submitting" />}
              >
                {submitText}
              </ButtonPrimary>
            </FormActionsItem>
            {onCancel && (
              <FormActionsItem>
                <Button onClick={onCancel} disabled={formikProps.isSubmitting}>
                  <Content type="Labels" id="Back" />
                </Button>
              </FormActionsItem>
            )}
          </FormActions>
        </>
      )}
    />
  );
};

export default LinkServiceLookupForm;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Types
type Props = {
  fullWidth: boolean,
  className: string,
};

const FormActionsItem = ({ fullWidth, className, ...props }: Props) => (
  <div
    {...props}
    className={classnames(
      'form-actions-item',
      { 'form-actions-item-full-width': fullWidth },
      className
    )}
  />
);

export default FormActionsItem;

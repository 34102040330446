//@flow

// Vendors
import React, { useState } from 'react';
import classnames from 'classnames';

// Components
import { InputWrapper, Content } from '../../..';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

// Types
type Props = {
  name: string,
  className?: string,
  onChange?: Function,
  formikProps?: FormikProps,
  autoFocus?: boolean
};

/**
 * @visibleName Password
 */
const InputPassword = ({ className, ...props }: Props) => {
  const [inputType, setInputType] = useState('password');

  function toggleInputType() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  function handleChange(event: any) {
    const { onChange, formikProps } = props;
    if (event.target.value === '') setInputType('password');
    if (onChange) return onChange(event);
    if (formikProps) return formikProps.handleChange(event);
  }

  const InputIcons =
    props.formikProps && props.formikProps.getFieldValue(props.name) ? (
      <ButtonIcon
        className="input-icon input-icon-type-toggle"
        icon={inputType === 'password' ? 'eye' : 'eye-closed'}
        onClick={toggleInputType}
        tabIndex="-1"
        aria-hidden={true}
      />
    ) : null;

  return (
    <InputWrapper
      {...props}
      type={inputType}
      className={classnames('input-password', className)}
      icons={InputIcons}
      onChange={handleChange}
      autoFocus={props.autoFocus}
    />
  );
};

InputPassword.defaultProps = {
  label: <Content type="Labels" id="Password" />,
};

export default InputPassword;

//@flow

// Vendors
import React, { type Node } from 'react';
import classnames from 'classnames';

// Components
import Hero from './Hero';

// Types
type Props = {
  className: string,
  title: string | Node,
  actions?: Array<HeroActionType> | HeroActionType,
};

const HeroDefault = ({ className, title, actions, ...props }: Props) => {

  return (
    <Hero
      {...props}
      className={classnames('hero-default', className)}
    >
      <h1 className="hero-title">{title}</h1>
    </Hero>
  );
};

export default HeroDefault;

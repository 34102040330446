//@flow

// Vendors
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Modal, FormActions, FormActionsItem, Content } from '../..';
import { Button, ButtonDanger } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { deactivateUser } from '../../../../reducers/user/deactivateUser/deactivateUserActions';

// Styles
import './deactivate-user-modal.scss';

// Types
type Props = {
  closeModal: Function,
  deactivateUser: Function,
};

const DeactivateUserModal = ({ deactivateUser, closeModal }: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Modal name="DeactivateUserModal" className="deactivate-user-modal">
      <h2 className="h1 modal-title">
        <Content type="Modals" id="DeactivateAccount" />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Content type="Modals" id="UnableToAccessAccountUntilReactivate" />
        </p>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonDanger
              onClick={() => {
                setSubmitting(true);
                deactivateUser().catch(() => setSubmitting(false));
              }}
              isLoading={isSubmitting}
              loadingContent={<Content type="Labels" id="Deactivating" />}
            >
              <Content type="Labels" id="DeactivateAccount" />
            </ButtonDanger>
          </FormActionsItem>
          <FormActionsItem>
            <Button
              onClick={() => closeModal('DeactivateUserModal')}
              disabled={isSubmitting}
            >
              <Content type="Labels" id="Cancel" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal, deactivateUser }, dispatch);

export default connect(null, mapDispatchToProps)(DeactivateUserModal);

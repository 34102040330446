//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputDate, Content } from '../../../../common';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Date of Birth
 */
const InputDOB = ({ className, ...props }: Props) => (
  <InputDate {...props} className={classnames('input-dob', className)} />
);

InputDOB.defaultProps = {
  label: <Content type="Labels" id="DOB" />,
  name: 'dob',
  autoComplete: 'dob',
};

export default InputDOB;

import LinkService from '../LinkService/LinkService.en.js';

const AgencyName = 'Texas Department of Licensing and Regulation';
const AgencyShortName = 'Department of Licensing and Regulation';

export default {
  AgencyName,
  AgencyShortName,
  tdlrlr_AppName: 'TDLR License Renewal',
  tdlrlr_AppShortName: 'TDLR License Renewal',
  tdlrlr_LicenseType_MassageTherapist: 'Massage Therapist',
  tdlrlr_LicenseType_MassageTherapistInstructor: 'Massage Therapist Instructor',
  tdlrlr_LinkService_LookupTitle: `${LinkService.LookupTitle}`,
  tdlrlr_LinkService_ConfirmTitleDefault: 'Your license has been located.',
  tdlrlr_LinkService_ConfirmDescriptionDefault:
    'Please review and confirm that this is the correct license to link to your account.',
  tdlrlr_LinkService_SuccessTitle: `Success! Your license is now linked to your account.`,
  tdlrlr_ConfirmLicense_StepTitle: 'Please confirm your license information.',
  tdlrlr_LinkService_ConfirmTitleLinked: 'License already linked.',
  tdlrlr_LinkService_ConfirmDescriptionLinked:
    'This license is already linked and can be accessed in {{Link:[your dashboard](/)}}.',
  tdlrlr_ConfirmLicense_Content_Header: `<h3>Important!</h3>`,
  tdlrlr_ConfirmLicense_Content_HT: `<p>By law you are required to complete a Human Trafficking Prevention Training course approved by HHSC for license renewing on or after September 1, 2020. You must complete your course before you can submit your renewal application. Once you complete the course, you can log back in and continue with the renewal application.</p>
    <p>At least one Human Trafficking Prevention Training course is offered for free. Go to the <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">TDLR Human Trafficking Prevention Training for Health Care Practitioners</a> page, where you can find more information and a link to the training course.</p>
    <p><em>*Please note that you may be asked to provide proof of completing an approved course and should retain your course completion documentation.</em></p>
    <hr/>`,
  tdlrlr_ConfirmLicense_Content: `<p style="font-weight:bold;">Effective September 1, 2019, all massage therapist and establishment owner applicants (including renewals) must submit fingerprints for a national criminal history record review and successfully pass a criminal history background check to be eligible for licensing.</p>
    <p>After completing this application, you will be provided with instructions for scheduling a fingerprinting appointment. Additionally, these instructions will be included with your receipt email.</p>`,
  tdlrlr_ReviewProfile_StepTitle: 'Review Profile',
  tdlrlr_ReviewProfile_PersonalInformation_Title: 'Personal Information',
  tdlrlr_EligibilityQuestions_StepTitle: 'Eligibility Questions',
  tdlrlr_EligibilityQuestions_ConvictionInformation_Title:
    'Conviction Information',
  tdlrlr_EligibilityQuestions_ContinuingEducation_Title: 'Continuing Education',
  tdlrlr_Training_Requirements_Title: 'Training Requirements',
  tdlrlr_EligibilityQuestions_Attestation_Title: 'Attestation',
  tdlrlr_Payment_StepTitle: 'Payment',
  tdlrlr_Payment_RenewalFee_Title: 'Renewal Fee',
  tdlrlr_Label_DateOfAppliction: 'Date of application',
  tdlrlr_Label_MilitaryStatus: 'Military Status',
  tdlrlr_Label_MilitaryNote:
    'You will be provided a link to download the Military Renewal form as well as instructions for submission on the last step of this process. Your renewal submission will not be processed until this form is completed and submitted.',
  tdlrlr_Label_ActiveMilitary:
    "Did your license or your spouse's license expire while you were deployed on active duty?",
  tdlrlr_Label_FelonyMisdemeanor:
    'Have you been convicted of a felony or misdemeanor since your last renewal?',
  tdlrlr_Label_CriminalOffense:
    'Have you entered a plea of nolo contendere, entered a plea of guilty, or received deferred adjudication for any crime since your last renewal?',
  tdlrlr_Label_ConvictionReason:
    'If you answered yes, please provide a brief explanation (optional):',
  tdlrlr_Label_ContinuingEducation:
    'Have you completed 12 hours of qualifying continuing education since your last renewal?',
  tdlrlr_Label_ContinuingEducationDesc:
    'If you answered no, please provide a brief explanation (optional):',
  tdlrlr_Label_HumanTraffickingCourse:
    'I have completed the required Human Trafficking Course.',
  tdlrlr_Label_Attestation:
    'I hereby attest that all information I have provided is true and correct. I understand that providing false or incorrect information may constitute a violation of state law or agency rules and may subject my license to disciplinary action, up to and including revocation.',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_Header: 'Unable to proceed',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_HumanTrafficking:
    'You must confirm the completion of the required training in order to proceed. <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">Click here</a> to find more information and a link to the training course.',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert_Attestation:
    'You must confirm the attestation by checking the box above in order to proceed.',
  tdlrlr_Label_EligibilityQuestions_Invalid_Alert:
    'You must confirm both check boxes above in order to proceed. <a href="https://www.tdlr.texas.gov/humantraffickingtraining.htm" target="_blank">Click here</a> to find more information and a link to the training course.',
  tdlrlr_Success_Header: 'Thanks for submitting your renewal application.',
  tdlrlr_Success_ThankYou: `Your renewal application was submitted successfully, however in order to complete your renewal we'll need a few more things from you.`,
  tdlrlr_Success_Survey:
    'Help us improve by taking a short survey of your license renewal experience.',
  tdlrlr_Success_NextSteps: 'Next steps',
  tdlrlr_Success_NextStepsInfo:
    'In order to complete your renewal application you must <b>download, fill out and submit</b> the following form(s) to one of the addresses below.',
  tdlrlr_Success_MilitaryRenewalForm: 'Military Renewal Form',
  tdlrlr_Success_ConvictionQuestionnaire: 'Conviction Questionnaire',
  tdlrlr_Success_Audit:
    'If you have been notified by mail that you are being audited, please submit proof of continuing education and transcripts to one of the addresses below.',
  tdlrlr_Success_SendForm_AddressHeader: 'Send forms to:',
  tdlrlr_Success_SendForm_Address: `Texas Department of Licensing and Regulation<br />P.O. Box 12157<br />Austin, Texas 78711`,
  tdlrlr_Success_DeliverForm_AddressHeader: `In person:`,
  tdlrlr_Success_DeliverForm_Address: `TDLR Main Office<br />920 Colorado St.<br />Austin, Texas 78701`,
  tdlrlr_Success_Content: `<p><h3>Important Reminder!</h3>
  <span style="font-weight: bold;">Effective September 1, 2019, all massage therapist and establishment owner applicants (including renewals) must have successfully passed a criminal history background check to be eligible for licensing.</span></p>
  <p>All applicants (including establishment owners) must submit fingerprints for the national criminal history record review.</p>`,
  tdlr_fingerprinting_instructions_link: 'Fingerprinting instructions',
  tdlr_fingerprinting_steps: `<ul style="list-style-type:none;margin:0"><li>1. Go get fingerprinted</li><li>2. Email the fingerprint receipt to massage.fingerprint@tdlr.texas.gov</li></ul>`,
  tdlr_fingerprinting_explanation: `TDLR has to approve your fingerprinted criminal history <span style="font-weight:bold;">before</span> your massage license can be issued. Your license will be issued if your criminal history is approved, otherwise TDLR will contact you if there are any questions.`,
};

// @flow

// Vendors
import React from 'react';
import { useSelector } from 'react-redux';

// Actions

// Helpers

// Components
import { Content } from '../../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Helpers

// Styles
import './payment-error.scss';

const PaymentError = () => {
  const paymentResponse = useSelector(state => state.payment.paymentResponse);

  return (
    <>
      <h3 className="payment-error-title">
        <Content type="Payment" id="ErrorTitle" />
      </h3>
      <h4 className="payment-error-info">
        <Content
          type="Payment"
          id="ErrorInfo"
          args={[paymentResponse.errors.REF[0]]}
        />
      </h4>

      <ButtonPrimary as="Link" to="/portal/">
        <Content type="Labels" id="ViewMyDashboard" />
      </ButtonPrimary>
    </>
  );
};

export default PaymentError;

//@flow

// Vendors
import React from 'react';

// Components
import { Container, Hamburger } from '..';

// Styles
import './mobile-nav.scss';

// Types
type Props = {
  mobileNav: Object,
  onClick: Function,
};

const MobileNavToggle = ({ mobileNav, onClick }: Props) => (
  <div className="mobile-nav-toggle">
    <Container className="mobile-nav-toggle-container">
      <button
        className="mobile-nav-toggle-button"
        onClick={onClick}
        aria-controls="mobile-nav"
        aria-label="Menu"
        aria-expanded={
          typeof mobileNav.isActive === 'boolean' ? mobileNav.isActive : false
        }
      >
        <Hamburger isActive={mobileNav.isActive} />
      </button>
    </Container>
  </div>
);

export default MobileNavToggle;

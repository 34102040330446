//@flow

// Vendors
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Form,
  InputGroupCode,
  FormActions,
  FormActionsItem,
  OTPSentContent,
  Content,
} from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../../reducers/modal/modalActions';
import { updateContactSubmitRequest } from '../../../../../reducers/user/updateContact/updateContactActions';

// Helpers
import { verifyOTPValidationSchema } from '../../../../../lib/validation';
import { EMAIL_CHANGE_STEPS } from '../VerifyEmailChangeModal';
import { signOutWithoutRedirect } from '../../../../../reducers/auth/authActions';

// Types
type Props = {
  channelCode: string,
  contactInfo: string,
  onSuccess: Function,
  OTP: Object,
  modalName: string,
  updateContactSubmitRequest: Function,
  signOutWithoutRedirect: Function,
  closeModal: Function,
  steps: Array<any>,
  stepId: string,
  data: Object,
};

class VerifyEmailChangeModal extends Component<Props> {
  get initialValues() {
    const { OTP } = this.props;
    const { channelCode } = OTP.data || {};

    return {
      code: '',
      channelCode: channelCode || '',
    };
  }

  handleSubmit(values, formikActions: FormikActions) {
    const step = this.props.steps.find(step => step.id === this.props.stepId);
    const { setActiveStep } = step ? step.data : {};

    this.props.updateContactSubmitRequest({
      values,
      formikActions,
      props: {
        ...this.props,
        onSuccess: () => {
          this.props.signOutWithoutRedirect();
          setActiveStep(EMAIL_CHANGE_STEPS.SUCCESS);
        },
      },
    });
  }

  render() {
    const { OTP, closeModal, data } = this.props;

    if (!OTP.data || !OTP.data.channelCode || !OTP.data.contactInfo)
      return null;
    const { channelCode, contactInfo } = OTP.data;

    return (
      <>
        <h2 className="h1 modal-title">
          {channelCode === 'SMS' ? (
            <Content type="MFA" id="VerifyNewPhone" />
          ) : (
            <Content type="MFA" id="VerifyNewEmail" />
          )}
        </h2>
        <div className="modal-content">
          <OTPSentContent channelCode={channelCode} contactInfo={contactInfo} />
          <Form
            className="modal-form"
            formikConfig={{
              validateOnBlur: false,
              enableReinitialize: true,
              onSubmit: this.handleSubmit.bind(this),
              initialValues: this.initialValues,
              validationSchema: verifyOTPValidationSchema,
            }}
            render={({ FormError, formikProps }) => (
              <>
                <InputGroupCode formikProps={formikProps} />
                <FormError />
                <FormActions align="center">
                  <FormActionsItem>
                    <ButtonPrimary
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                      loadingContent={<Content type="Labels" id="Saving" />}
                    >
                      <Content type="Labels" id="Submit" />
                    </ButtonPrimary>
                  </FormActionsItem>
                  <FormActionsItem>
                    <Button
                      onClick={() => closeModal(data.modalId)}
                      disabled={formikProps.isSubmitting}
                    >
                      <Content type="Labels" id="Cancel" />
                    </Button>
                  </FormActionsItem>
                </FormActions>
              </>
            )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ OTP, user: { verifyContact } }): Object => ({
  OTP,
  verifyContact,
});
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators(
    { closeModal, updateContactSubmitRequest, signOutWithoutRedirect },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmailChangeModal);

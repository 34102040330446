/* eslint-disable react/display-name */
//@flow

// Vendors
import React, { useRef, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';

// Components
import { FormError, FormActions, FormActionsItem } from '.';

// Helpers
import { getObjectValueByPath } from '../../../lib/helpers';

// Styles
import './form.scss';

// Types
type Props = {
  className?: string,
  render: Function,
  formikConfig: FormikConfig,
};

const Form = ({ className, render, formikConfig = {}, ...props }: Props) => {
  const useValidateOnBlur = validateOnBlurConfig => {
    const [validateOnBlur, setValidateOnBlur] = useState(validateOnBlurConfig);

    useEffect(() => {
      function shouldValidateOnBlur(event: any) {
        if (event.type === 'mouseup' && !validateOnBlur)
          return setValidateOnBlur(true);
        if (event.type === 'mousedown' && event.target.closest('a, button'))
          return setValidateOnBlur(false);
      }

      if (validateOnBlur) {
        window.addEventListener('mousedown', shouldValidateOnBlur);
        window.addEventListener('mouseup', shouldValidateOnBlur);
      }
      return () => {
        window.removeEventListener('mousedown', shouldValidateOnBlur);
        window.removeEventListener('mouseup', shouldValidateOnBlur);
      };
    }, [validateOnBlur]);

    return validateOnBlur;
  };

  const formRef = useRef();
  const validateOnBlur = useValidateOnBlur(formikConfig.validateOnBlur || true);

  const resetServerErrors = (
    eventOrName: any,
    { status, setStatus }: FormikProps
  ) => {
    const fieldName = eventOrName.target
      ? eventOrName.target.name
      : eventOrName;

    if (status && status.serverErrors) {
      delete status.serverErrors.formError;
      delete status.serverErrors[fieldName];
    }
    setStatus(status);
  };

  const focusTopError = (form: HTMLFormElement) => {
    setTimeout(errorFocus, 50); // Local error check
    const serverErrorCheck = setTimeout(errorFocus, 500);
    const slowServerErrorCheck = setTimeout(errorFocus, 1000);

    function errorFocus() {
      const topError = form.querySelector(
        '.hasError input, .hasError textarea, .input-radio-group-error input'
      );
      if (topError) {
        clearTimeout(serverErrorCheck);
        clearTimeout(slowServerErrorCheck);
        topError.focus();
      }
    }
  };

  const handleSubmit = (
    event: Event,
    form: HTMLFormElement,
    formikProps: FormikProps
  ) => {
    window.lastSubmittedForm = event.target;
    if (formRef.current) focusTopError(formRef.current);
    formikProps.setStatus({});
    formikProps.handleSubmit(event);
  };

  const handleChange = (eventOrName: any, formikProps: FormikProps) => {
    if (eventOrName instanceof Object) formikProps.handleChange(eventOrName);
    resetServerErrors(eventOrName, formikProps);
  };

  return (
    <Formik
      {...formikConfig}
      validateOnBlur={validateOnBlur}
      render={(formikProps: FormikProps) => (
        <form
          {...props}
          onSubmit={event =>
            formRef.current && handleSubmit(event, formRef.current, formikProps)
          }
          className={classnames('form', className)}
          ref={formRef}
          noValidate
        >
          {render({
            FormError: formErrorProps => (
              <FormError {...formErrorProps} formikProps={formikProps} />
            ),
            FormActions,
            FormActionsItem,
            formikProps: {
              ...formikProps,
              handleChange: eventOrName =>
                handleChange(eventOrName, formikProps),
              getFieldValue: path =>
                getObjectValueByPath(formikProps.values, path),
              getFieldTouched: path =>
                getObjectValueByPath(formikProps.touched, path),
              getFieldError: path =>
                getObjectValueByPath(formikProps.errors, path),
              getFieldStatus: path =>
                getObjectValueByPath(formikProps.status, path),
            },
          })}
        </form>
      )}
    />
  );
};

export default Form;

//@flow

// Vendors
import React, { type Node } from 'react';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

// Styles
import './input.scss';

// Types
type Props = {
  as: string,
  type?: string,
  name: string,
  id?: string,
  className?: string,
  placeholder?: string,
  mask?: Array<RegExp | string>,
  guide?: boolean,
  onBlur?: Function,
  onChange?: Function,
  value?: string,
  children?: Node,
  inputRef?: Function,
  loadOptions?: Function,
};

const Input = ({
  as,
  inputRef,
  className,
  mask,
  guide,
  name,
  id,
  ...props
}: Props) => {
  const tags = { Select };
  const T = props.loadOptions ? AsyncSelect : tags[as] || as;

  return mask ? (
    <MaskedInput
      {...props}
      ref={inputRef}
      mask={mask}
      guide={guide}
      id={id}
      name={name}
      className={className}
    />
  ) : (
    <T {...props} ref={inputRef} id={id} name={name} className={className} />
  );
};

Input.defaultProps = {
  as: 'input',
  type: 'text',
};

export default Input;

//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { Content, InputRadioGroup } from '../..';
import { LoadingDots } from '../../common';
import { PaymentCard, PaymentCardListAddButton } from '..';

// Selectors
import { selectPaymentAccounts } from '../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../reducers/route/routeSelectors';

// Actions
import { push } from 'connected-react-router';
import { getPaymentRoute } from '../../../lib/helpers';

// Types
type Props = { formikProps: FormikProps };

const PaymentSelectCardList = ({ formikProps }: Props) => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const paymentAccounts = useSelector(selectPaymentAccounts);

  if (!paymentAccounts) return <LoadingDots />;
  const inputOptions = paymentAccounts.map(
    (paymentAccount: PaymentAccount, index) => {
      return {
        className: 'payment-select-list-item',
        id: `selectPayment${index}`,
        value: paymentAccount,
        label: <PaymentCard paymentAccount={paymentAccount} />,
      };
    }
  );

  const handleUseDifferentCardClick = () =>
    dispatch(push(getPaymentRoute('/form', currentPath)));

  return (
    <div className="payment-select">
      <div className="payment-select-list">
        <InputRadioGroup
          name="selectPayment"
          optionValueKey="paymentAccountId"
          formikProps={formikProps}
          options={inputOptions}
        />
        <PaymentCardListAddButton
          onClick={handleUseDifferentCardClick}
          content={<Content type="Payment" id="UseDifferentCard" />}
        />
      </div>
    </div>
  );
};

export default PaymentSelectCardList;

//@flow

// Vendors
import React from 'react';

// Components
import { Modal } from '..';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';
import Content from '../../Content/Content';



const AccountNotEnrolled = () => (
    <Modal name="AccountNotEnrolled" className="modal-sm lockout-modal">
      <h2 className="h1 modal-title">
        Account not enrolled
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <LockoutSVG role="presentation" />
        </div>
        <p>
          Your account is not enrolled.
        </p>
        <p>
          Please click on the enrollment link sent to your work email.
        </p>
      </div>
    </Modal>
  );


export default AccountNotEnrolled;

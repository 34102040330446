import { SITE_DATA } from '../../data';

const marketingContent = {
  default_MarketingTitle: `Solución de identidad digital de Texas.gov.`,
  default_MarketingContent: `<p>Puede crear un nombre de usuario y contraseña universales para usar en múltiples agencias y servicios, acceder al tablero de su cuenta en cualquier lugar en cualquier dispositivo y recibir alertas oportunas para fechas de vencimiento importantes para que pueda preocuparse por cosas más importantes, como disfrutar de la excelente Estado de Texas.</p>
  <p>Tenemos grandes planes para TxT, así que esté atento a nuevos servicios y actualizaciones en el horizonte.</p>`,
  tdlr_MarketingTitle: `Solución de identidad digital de Texas.gov.`,
  tdlr_MarketingContent: `<p>Comenzamos con el Departamento de Licencias y Regulación de Texas para hacer que la renovación de licencias profesionales sea más simple y más eficiente.</p>
  <p>Puede crear un nombre de usuario y contraseña universales, acceder al tablero de su cuenta en cualquier lugar en cualquier dispositivo y recibir alertas oportunas para fechas de vencimiento importantes para que pueda preocuparse por cosas más importantes, como disfrutar del gran Estado de Texas. </p> <p> Tenemos grandes planes para TxT, así que esté atento a nuevos servicios y actualizaciones en el horizonte.</p>`,
  tdis_MarketingTitle: `Solución de identidad digital de Texas.gov`,
  tdis_MarketingContent: `<b>La solución de identidad digital de Texas.gov permite a los empleados autorizados del gobierno de Texas acceder a servicios y sistemas.</b>
  <p>Para comenzar, inicie sesión con su cuenta de empleado inscrito.</p>`,
};

export default {
  ...marketingContent,
  Banner: `Un sitio web oficial del gobierno del estado de Tejas`,
  BannerLearnMoreButton: `Aprende más`,
  BannerLearnMoreTitle1: `El .gov significa que es oficial.`,
  BannerLearnMoreContent1: `Los sitios web del gobierno federal a menudo terminan en .gov o .mil. Antes de compartir información confidencial, asegúrese de estar en un sitio del gobierno federal.`,
  BannerLearnMoreTitle2: `El https:// significa que es seguro.`,
  BannerLearnMoreContent2: `El https:// garantiza que se está conectando al sitio web oficial y que toda la información que proporciona está encriptada y transmitida de forma segura.`,
  SignInButton: `Iniciar Sesión`,
  RememberDevice: `Recuerda este Dispositivo`,
  CreateAccountButton: `Crear Cuenta`,
  ForgotPasswordLink: `Recuperar Contraseña`,
  VerifyMFAOptionsTitle: `Verifique por seguridad.`,
  VerifyMFAOptionsContent: `Elija su método de autenticación`,
  VerifyMFAOptionsSubmit: `Envíe`,
  Welcome: `Bienvenido(a)`,
  PageTitle: `Iniciar Sesión`,
  Success: `¡Éxito!`,
  SuccessfullyEnrolled: 'Your account is enrolled. You can now login.',
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputText, Content } from '../../../../common';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Last Name
 */
const InputLastName = ({ className, ...props }: Props) => (
  <InputText
    {...props}
    maxLength="40"
    className={classnames('input-last-name', className)}
  />
);

InputLastName.defaultProps = {
  label: <Content type="Labels" id="LastName" />,
  name: 'lName',
  autoComplete: 'lName',
};

export default InputLastName;

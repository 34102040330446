//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import Content from '../../Content/Content';

// Styles
import './form-error.scss';

// Types
type Props = {
  formikProps: FormikProps,
  hasError: boolean,
  message: string,
  className: string,
};

const FormError = ({
  className,
  hasError,
  message,
  formikProps,
  ...props
}: Props) => {
  const { status = {} } = formikProps;
  const localError = message;
  const serverError = (status.serverErrors || {}).formError;
  const errorMap = serverError || localError || {};

  hasError = !!serverError || hasError;

  if (!hasError || !errorMap.id) return null;

  return (
    <div
      {...props}
      className={classnames('form-error', className)}
      role="alert"
    >
      <Content type="Errors" id={errorMap.id} args={errorMap.args} />
    </div>
  );
};

export default FormError;

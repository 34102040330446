import { SITE_DATA } from '../../data';

export default {
  Exception_UserNotFound: `Hay un problema con su cuenta, comuníquese con el equipo de soporte de su organización para obtener ayuda.`,
  Exception_LicenseNotFound: `No pudimos encontrar su licencia, por favor revise su información y vuelve a intentarlo.`,
  Exception_AgencyNotFound: `No pudimos encontrar los registros de su agencia, verifique su información e intente nuevamente.`,
  Exception_ApplicationNotFound: `No pudimos encontrar ninguna aplicación vinculada a su cuenta`,
  Exception_ContactNotVerified: `Tiene que comprobar un teléfono móvil para realizar esta función.`,
  Exception_SecurityQuestionDupe: `Cada pregunta de seguridad debe ser diferente.`,
  Exception_IncorrectAnswers: `Uno o más de sus respuestas son incorrectas. Inténtalo de nuevo.`,
  Exception_OTPVerify: `Este código no corresponde.  Si recibió varios códigos de verificación, utilice el más reciente.`,
  Exception_OTPExpired: `El código ha caducado, haga clic en "Reenviar código" para volver a intentarlo.`,
  Exception_MessageExceeded: `Ha habido demasiados intentos de inicio de sesión fallidos. Por favor, inténtelo de nuevo más tarde.`,
  Exception_InvalidCredentials: `La contraseña introducida no coincide con nuestros registros.`,
  Exception_PasswordHistory: `Su nueva contraseña no puede ser uno que haya usado antes.`,
  Exception_PaymentFailed: `El pago no se pudo procesar. Se recomienda consultar los datos de la tarjeta y vuelva a intentarlo.`,
  Exception_OTPSendFail: `Un mensaje no pudo ser enviado a {{1}}. Por favor, intente una diferente {{2}}.`,
  Exception_VerifyAppLinkFail: `No pudimos encontrar su licencia. Inténtalo de nuevo.`,
  Exception_IDologyFail: `Error con IDology: {{1}}`,
  Exception_UserEmailExists: `Esa dirección de correo electrónico ya está en uso. Por favor utilice una dirección de correo electrónico diferente.`,
  Exception_WorkEmailInUse: `Esa dirección de correo electrónico es la misma que su direccion de correo electrónico de trabajo. Por favor utilice una dirección de correo electrónico diferente.`,
  Exception_UserRecordExists: `La información proporcionada coincide con un usuario activo. Prueba a acceder.`,
  Field_Required: `Este campo es requerido.`,
  Field_YesNoRequired: `Por favor, seleccione Sí o No.`,
  Field_Min: `La longitud mínima de caracteres no está satisfecho por este campo.`,
  Field_MinWithLength: `Por favor, introduzca al menos {{1}} caracteres.`,
  Field_Max: `Se ha superado la longitud máxima de caracteres para este campo.`,
  Field_MaxWithLength: `Por favor, no escriba más de {{1}} caracteres.`,
  Field_InvalidChars: `Ha introducido caracteres no válidos. Por favor cambia y vuelve a intentarlo.`,
  Field_Sorry: `¡Lo siento! Eso no se ve bien. Inténtalo de nuevo.`,
  Field_IncorrectMFACode: '¡Perdón! Ese es el código incorrecto.',
  Field_NoSpecialChars: `Por favor, no ingresar ningún carácter especial.`,
  Field_AlphanumericOnly: `Por favor, introduzca sólo letras o números.`,
  Field_NumericOnly: `Por favor, introduzca sólo números.`,
  Field_PasswordStrength: `La contraseña debe contener un mínimo de ocho y un máximo de 32 caracteres y también debe incluir al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.`,
  Field_Password_Invalid_Special_Char:
    'La contraseña no debe contener caracteres especiales no válidos. Utilice un carácter especial de la siguiente lista.',
  Field_Password_Invalid_Special_Char_Accounts:
    'La contraseña no debe contener caracteres especiales no válidos. Utilice un carácter especial de la siguiente lista:  (. [ @ ! # $ ^ & + = ] ( _ -  ) {  }).',
  Field_AlphabeticalOnly: `Por favor, no ingresar los números o caracteres especiales.`,
  Field_RequireLicenseOrAddress: `O bien espera que la información de licencia de conducir o la dirección.`,
  Field_IncorrectFormat: `Formato incorrecto siempre.`,
  Field_SpecialCharacters: `Sólo se permiten los caracteres especiales siguientes: {{1}}`,
  Field_ExactDigits: `Por favor, introduzca exactamente {{1}} números.`,
  Field_SelectOption: `Por favor seleccione una opción.`,
  Field_DateFormat: `Por favor introduzca una fecha valida.`,
  Field_DatePast: `La fecha debe ocurrir en el pasado.`,
  Field_AllowedList: `Sólo se permite la siguiente: {{1}}`,
  Field_EmailInvalid: `Por favor, introduce una dirección de correo electrónico válida.`,
  Field_EmailRequired: `Por favor introduzca una dirección de correo eléctronico.`,
  Field_EmailNoAccount: `¡Lo siento! Este correo electrónico no tiene una cuenta.`,
  Field_EmailMatchingRequired: `Por favor, introduzca una dirección de correo electrónico correspondiente.`,
  Field_PasswordMin: `Por favor, use al menos ocho caracteres de la contraseña.`,
  Field_PasswordMax: `Por favor, introduzca una contraseña con menos de 128 caracteres.`,
  Field_PasswordRequired: `Porfavor ingrese una contraseña.`,
  Field_PasswordIncorrect: `¡Lo siento! El correo electrónico y la contraseña introducidos no son correctos.`,
  Field_MatchingPasswordMismatch: `Por favor, introduzca una contraseña correspondiente.`,
  Field_MatchingPasswordRequired: `Por favor, confirme su contraseña.`,
  Field_NewPasswordRequired: `Por favor, introduzca una nueva contraseña.`,
  Field_NewPasswordUnique: `Su nueva contraseña no puede ser la misma que la contraseña actual.`,
  Field_CurrentPasswordRequired: `Por favor, introduzca su contraseña actual.`,
  Field_ContactCodeMin: `Por favor, introduzca un código de seis dígitos.`,
  Field_ContactCodeRequired: `Por favor, introduzca el código de seis dígitos que le enviamos.`,
  Field_PhoneValid: `Por favor ingrese un número de teléfono válido.`,
  Field_PhoneTypeRequired: `Por favor, seleccione un tipo de teléfono válido.`,
  Field_FirstNameRequired: `Por favor, introduzca su nombre de pila.`,
  Field_LastNameRequired: `Por favor ingrese su apellido.`,
  Field_CappsIdRequired: `Por favor ingrese su CAPPS ID.`,
  Field_DriverLicenseMin: `Por favor, introduzca un número de identificación o licencia de conducir estatal válida.`,
  Field_DriverLicenseRequired: `Por favor, introduzca su número de licencia de conducir o identificación del estado.`,
  Field_AuditNumberMin: `Por favor, introduzca un número válido de auditoría. Haga clic en el enlace siguiente información para aprender más.`,
  Field_AuditNumberRequired: `Por favor, introduzca su número de auditoría. Haga clic en el enlace siguiente información para aprender más.`,
  Field_DOBMin: `La fecha en la que entró cae fuera del rango aceptado.`,
  Field_DOBMax: `Debe tener al menos 18 años de edad para continuar.`,
  Field_DOBRequired: `Por favor, introduzca su fecha de nacimiento.`,
  Field_CC_NameRequired: `Por favor, introduzca el nombre de la tarjeta.`,
  Field_CC_NumberValid: `Por favor, introduzca un número de tarjeta de crédito válida.`,
  Field_CC_NumberRequired: `Por favor ingresa el número de tu tarjeta de crédito.`,
  Field_CC_ExpirationMin: `Por favor, introduzca fecha futura.`,
  Field_CC_ExpirationRequired: `Campo requerido.`,
  Field_CC_CVVValid: `Por favor, introduzca un número válido`,
  Field_CC_CVVRequired: `Campo requerido.`,
  Field_ACH_NameOnCheckRequired: `Por favor, introduzca el nombre en el cheque.`,
  Field_ACH_ABARoutingRequired: `Por favor, introduzca el número de ABA.`,
  Field_ACH_ABARoutingConfirm: `Por favor asegúrese de que ha introducido el número de ABA correctamente.`,
  Field_ACH_AccountNumberRequired: `Por favor, introduzca el número de cuenta.`,
  Field_ACH_AccountNumberConfirm: `Por favor asegúrese de que ha entrado en el número de cuenta correctamente.`,
  Field_LastFourSSNMin: `Por favor, introduzca cuatro dígitos.`,
  Field_LastFourSSNRequired: `Campo requerido.`,
  Field_TermsRequired: `Debe aceptar los términos y condiciones para continuar.`,
  Field_Address_StreetAddressRequired: `Por favor, introduzca una dirección válida.`,
  Field_Address_CityRequired: `Por favor introduzca una ciudad.`,
  Field_Address_CountryRequired: `Por favor, introduzca un país.`,
  Field_Address_StateRequired: `Por favor seleccione un estado.`,
  Field_Address_ZipFiveDigits: `Por favor, introduzca un código postal de cinco dígitos.`,
  Field_Address_ZipFiveRequired: `Por favor introduzca un código postal.`,
  Field_Address_ForeignRequired: `Por favor, introduzca una dirección en el extranjero.`,
  Field_SecurityQuestions_AnswerMin: `Por razones de seguridad, las respuestas deben tener al menos tres caracteres.`,
  Field_SecurityQuestions_AnswerRequired: `Por favor, introduzca una respuesta.`,
  Field_SecurityQuestions_QuestionRequired: `Por favor seleccione una opción.`,
  Field_IdentityQuestionRequired: `Seleccione una respuesta.`,
  Field_MFAOptionsRequired: `Por favor, seleccione un método de verificación de la cuenta.`,
  Field_ProgramSelectRequired: `Por favor selecciona un programa.`,
  Field_LicenseSelectRequired: `Por favor, seleccione una licencia.`,
  Field_LicenseNumberRequired: `Por favor, introduzca su número de licencia.`,
  Field_PaymentMethodRequired: `Por favor seleccione un método de pago.`,
  ServiceDownModal_Title: 'Houston, tenemos un problema.',
  ServiceDownModal_Content:
    'los servicios no están disponibles en este momento. Por favor intente nuevamente después',
  ServerErrorModal_Title: `Houston, tenemos un problema.`,
  SessionTimedOut: `Su sesión de autenticación ha caducado. Por favor, actualice e intenta acceder de nuevo.`,
  ProblemSendingCode: `Hubo un problema al enviar su código. Por favor, actualice e intenta acceder de nuevo.`,
  PasswordExpiryErrorTitle: 'This is awkward…',
  PasswordExpiryErrorDesc: `<p class="lead">We're sorry but your link has expired. </p>`,
  PasswordExpiryErrorDesc2: `<p class="lead">Please contact your organization's support team for assistance.</p>`,
  Not_Enrolled:
    'Su cuenta no está inscrita. Por favor, haga clic en el enlace de inscripción enviado a su correo electrónico del trabajo.',
  PasswordLessThanOneDay:  'We can’t reset your password at this time because it was changed within the last 24 hours. Please try again later or contact your Administrator.',
};

//@flow

// Vendors
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Modal,
  FormActions,
  FormActionsItem,
  Content,
  ServiceIcon,
} from '../..';
import { ButtonDanger, Button } from 'texkit/dist/components/Buttons';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import {
  unlinkAppLink,
  removeLinkFromState,
} from '../../../../reducers/agency/agencyActions';

// Styles
import './unlink-service-modal.scss';

// Types
type Props = {
  modal: Object,
  agencies: Object,
  closeModal: Function,
  unlinkAppLink: Function,
  removeLinkFromState: Function,
};

const UnlinkServiceModal = ({
  unlinkAppLink,
  closeModal,
  removeLinkFromState,
  modal = {},
}: Props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  if (!modal.data || !modal.data.link || !modal.data.agency) return null;

  const {
    data: { link, agency },
  } = modal;

  function handleRemoveLinkClick() {
    setSubmitting(true);
    unlinkAppLink(link)
      .then(() => {
        closeModal('UnlinkServiceModal');
        setTimeout(() => removeLinkFromState(link), 200);
      })
      .catch(() => setSubmitting(false));
  }

  return (
    <Modal
      name="UnlinkServiceModal"
      className="unlink-service-modal"
      onClose={() => setSubmitting(false)}
      disableOnClickOutside={true}
    >
      <h2 className="h1 modal-title">
        <Content type="Modals" id="AreYouSureYouWantToRemoveThisLink" />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Content
            type="Modals"
            agency={agency}
            id="NoMoreAgencyNotifications"
          />
          {` ${agency.agencyName}, `}
          <Content
            type="Modals"
            agency={agency}
            id="ItWillBeRemovedFromDashboard"
          />
        </p>
        <div className="unlink-service-modal-list">
          {link.licenses.map(license => (
            <SummaryCard
              key={license.licenseNumber}
              className="card-flat"
              outlined={false}
              graphic={<ServiceIcon type="professional-license" />}
            >
              <SummaryCard.List
                items={[
                  {
                    label: <Content type="Labels" id="LicenseType" />,
                    value: license.licenseType,
                  },
                  {
                    label: <Content type="Labels" id="LicenseNumber" />,
                    value: license.licenseNumber,
                  },
                ]}
              />
            </SummaryCard>
          ))}
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonDanger
              onClick={handleRemoveLinkClick}
              isLoading={isSubmitting}
              loadingContent={<Content type="Labels" id="Removing" />}
            >
              <Content type="Labels" id="RemoveLink" />
            </ButtonDanger>
          </FormActionsItem>
          <FormActionsItem>
            <Button
              onClick={() => closeModal('UnlinkServiceModal')}
              disabled={isSubmitting}
            >
              <Content type="Labels" id="Cancel" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators(
    { closeModal, unlinkAppLink, removeLinkFromState },
    dispatch
  );
const mapStateToProps = ({ modal }): Object => ({ modal });

export default connect(mapStateToProps, mapDispatchToProps)(UnlinkServiceModal);

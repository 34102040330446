//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputWrapper, Content } from '../../..';

// Types
type Props = {
  name: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Phone
 */
const InputPhone = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    type="tel"
    mask={[
      /[1-9]/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    className={classnames('input-phone', className)}
    autoFocus={true}
  />
);

InputPhone.defaultProps = {
  label: <Content type="Labels" id="PhoneNumber" />,
};

export default InputPhone;

//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { FormActions, FormActionsItem, Content } from '../../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { signOutWithoutAPICall } from '../../../../../reducers/auth/authActions';

const EmailChangeSuccess = () => {
  const dispatch = useDispatch();

  return (
    <>
      <h2 className="h1 modal-title">
        <Content type="MFA" id="EmailChangedTitle" />
      </h2>
      <p>
        <Content type="MFA" id="EmailChangedInfo" />
      </p>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(signOutWithoutAPICall())}>
            <Content type="Labels" id="SignOut" />
          </ButtonPrimary>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

export default EmailChangeSuccess;

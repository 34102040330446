import { SITE_DATA } from '../../data';

export default {
  EmailAlreadyRegistered: "The email you've entered is already registered.",
  CheckModalImageAlt:
    'Find the audit number and checking account number on the bottom of the check.',
  CVVModalImageAlt:
    'Find the 3 digit CVV security code on the back of the credit card. For AMEX cards you can find a 4 digit secuirty code on the front.',
  DeactivateAccount: 'Are you sure you want to deactive your account?',
  UnableToAccessAccountUntilReactivate:
    'You will not be able to access your account information until you reactivate.',
  FindYourID: 'Find your ID and DD/Audit numbers.',
  LicenseOrIDNumber: 'Driver License or ID number',
  LicenseDetailsImageAlt:
    'graphic showing where to locate your Driver License ID and audit numbers',
  AccountTemporarilyLocked: 'Account temporarily locked.',
  FourHourLockout:
    'To protect your personal information, your account has been locked for four hours because of too many unsuccessful log in attempts.',
  TryAgainLater: 'Please try again later.',
  SessionWillEndInTwoMinutes:
    'Due to inactivity, this session will end in two minutes. Please let us know if you need more time.',
  AreYouStillThere: 'Are you still there?',
  TermsAndConditionsSummaryHeader:
    'We want to make sure the terms and conditions for using these services are clear, so here’s a summary in simple English. ',
  FollowThisLinkForTermsAndConditions:
    'If you want to read the full agreement, simply follow this link.',
  termsAndConditionsModalSiteName: `${SITE_DATA.SITE_NAME}`,
  AreYouSureYouWantToRemoveThisLink:
    'Are you sure you want to remove this link?',
  NoMoreAgencyNotifications: "You won't receive any future notifications for",
  ItWillBeRemovedFromDashboard: 'and it will be removed from your dashboard.',
  ConfirmCorrectAddress: 'I want to make sure I have your correct address',
  UseAddressEntered: 'Use the address you entered:',
  JustAHeadsUp: 'Just a heads up.',
  YoureLeavingThe: "You're leaving the ",
  AndOpeningAnExternalWebsite: ' and opening an external website.',
  OkLetsGo: "Ok, let's go",
  BlankNoId: ' ',

  TermsAndConditionsTitle: `${SITE_DATA.SITE_NAME}`,
  TheTermsAndConditions: `The terms and conditions of use for ${SITE_DATA.SITE_NAME} state:`,
  TermsAndConditionsListItems: `<li>our services will be available to everyone, and every effort will be made to ensure accessibility.</li><li>that personal information will be kept safe and secured, and only information needed for conducting business will be collected.</li><li>that sensitive personal information will not be permanently stored. However, state organizations and agencies that provide services may need to retain your information</li><li>that any suspicious or illegal activity may be reported to law enforcement.</li><li>you can discontinue using ${SITE_DATA.SITE_NAME} at any point, but your government information will continue to be stored by the government.</li><li>that sensitive and transactional information will be protected in accordance with international, federal, and state security standards and regulations. This includes encrypting or scrambling all personal information.</li><li>that small text files, also known as “cookies,” may be placed on your computer to make transactions more efficient. They will not be used for any marketing purposes.</li><li>personal information will never be shared on Texas.gov social media sites, such as Facebook or Instagram</li><li>that ${SITE_DATA.SITE_NAME} terms and conditions don’t extend to any other site, even if it is linked from this site.</li><li>any legal dispute must be decided under the laws and in the courts of Texas.</li>`,

  cappsTermsAndConditionsTitle: 'Texas Comptroller of Public Accounts',
  cappsAcceptableUsePolicy: `Acceptable Use Policy`,
  cappsTermsAndConditionsListItems: `<li>Unauthorized access to this computer system or unauthorized use of any confidential or federal tax information may constitute a violation of federal law and give rise to civil and criminal liability and penalties. This system is subject to monitoring and stored data may be accessed and recorded. Anyone using this system consents to monitoring and should have no expectation of privacy. Evidence of criminal liability will be provided to law enforcement agencies.</li><li>This website is intended for use by State of Texas employees and others expressly authorized by State of Texas. Attempting to access this website without authorization may violate criminal laws and subject you to fines and imprisonment. Offenders will be prosecuted.</li>`,

  kclTermsAndConditionsTitle: 'Kilgore College',
  kclAcceptableUsePolicy: `Acceptable Use Policy`,
  kclTermsAndConditionsListItems: `<li>By clicking [I Agree] below you acknowledge and consent to the following: All communications and data transiting, traveling to or from, or stored on this system will be monitored. You consent to the unrestricted monitoring, interception, recording, and searching of all communications and data transiting, traveling to or from, or stored on this system at any time and for any purpose by Kilgore College and by any person or entity, including government entities, authorized by Kilgore College. You also consent to the unrestricted disclosure of all communications and data transiting, traveling to or from, or stored on this system at any time and for any purpose to any person or entity, including government entities, authorized by Kilgore College. You are acknowledging that you have no reasonable expectation of privacy regarding your use of this system. These acknowledgments and consents cover all use of the system, including work-related use and personal use without exception.</li>`,

  ResetPassword: 'Reset Password',
  PasswordExpired: 'Your password has expired',
  RegisterSecurityMethod: 'Register your Advanced Security method',
  RegisterSecurityMethodDesc:
    'Additional advanced security options are now available to be used as a second factor of authentication during the login process. Only one device can be registered per method.​',
  RegisterSelectMethod:
    'Please select one of the following methods to register.',
  ScanQRCode: 'Register your Authenticator App',
  ScanQRCodeDesc:
    'Currently Microsoft and Google Authenticator Apps are being supported. Use your preferred authenticator app to scan the QR code below. This will connect the app with your account.',
  VerifyAuthenticatorApp: 'Verify your Authenticator App',
  VerifyAuthenticatorAppDesc: 'Enter the passcode from your authenticator app',
  DeleteConfirmation: 'Are you sure you want to delete this device?',
  DeleteWarning: `You won't be able to use this device for multi-factor authentication.`,
  DeleteNotLoggedInDeviceTitle:"You won't be able to delete this device.",
  DeleteNotLoggedInDeviceWarning:"To delete this device, please sign-out and sign back in using the device. If you do not have access to the device anymore, please contact your helpdesk.",
  ReturnToAccounts:"Return to Account",
  PasswordExpiredDesc:
    'In order to gain access to your account, you will need to set a new password. Please click Reset Password.',
  Cancel: 'Cancel',
  DeleteNumber: 'Delete Number',
  DeleteNumberTitle: 'Are you sure you want to delete this phone number?',
  DeleteNumberDescription:
    "You won't be able to use this phone number for multi-factor authentication or account recovery.",
  RememberDevice: 'Remember This Device?',
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputSelect from '../InputSelect/InputSelect';

// Data
import { countries } from '../../../../../lib/data';

// Types
type Props = {
  name?: string,
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Select Country
 */
const InputCountry = ({ className, ...props }: Props) => (
  <InputSelect
    {...props}
    optionLabelKey="label"
    optionValueKey="code"
    emptyOptionLabel="Select a country..."
    isSearchable={true}
    className={classnames('input-country', className)}
  />
);

InputCountry.defaultProps = {
  label: 'Country',
  name: 'country',
  options: countries,
};

export default InputCountry;

//@flow

// Vendors
import React from 'react';

// Components
import TodoItem from './TodoItem';

// Actions

// Helpers

// Styles
import './todo-list.scss';

export type TodoItemData = {
  type: string,
  date: string,
  expired?: Boolean,
  data: { fiscalYear: string },
};

const TodoList = ({ todoItems }: { todoItems: TodoItemData[] }) => (
  <div className="todo-list">
    {todoItems.map((todoItem, i) => (
      <TodoItem key={`${todoItem.type}_${i}`} data={todoItem} />
    ))}
  </div>
);

export default TodoList;

//@flow

import { useEffect } from 'react';

type OnClickOutsideType = {
  ref: { current: HTMLElement },
  onClickOutside: Function,
  disableOnClickOutside: boolean,
};

const useOnClickOutside = ({
  ref,
  onClickOutside,
  disableOnClickOutside,
}: OnClickOutsideType) => {
  useEffect(() => {
    document.addEventListener('mousedown', onClick);

    return () => {
      document.removeEventListener('mousedown', onClick);
    };
  }, []);

  if (disableOnClickOutside) return false;

  const onClick = event => {
  

    
    if(ref.current.classList.contains('advanced-security-qr-modal')){ 
      return;
    }


    if (!ref.current.contains(event.target)) return onClickOutside();
  };
};

export default useOnClickOutside;

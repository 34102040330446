export default {
  PageTitle: 'Cybersecurity Attestation',
  AttestationFormTitle:
    'Cybersecurity Training & Awareness Program Certification',
  EmploymentInformationTitle: 'Employment Information',
  DateOfTrainingLabel: 'Date of Training Completion',
  NoOrgsFound: 'No organizations found...',
  TodoList_Attestation_Primary:
    'Completion of Cybersecurity Requirement ',
  TodoList_Attestation_Secondary: 'Public-Sector Employee',
  AttestationSectionTitle: 'Attestation',
  AttestationCheckboxLabel:
    'I have completed the cybersecurity training as required by H.B.&nbsp;3834.',
  FiscalYear: 'fiscal&nbsp;year',
  AttestationSuccessTitle:
    'Success! Thank you for completing your attestation.',
};

export default {
  PageTitle: 'Link Service',
  SelectAgencyTitle:
    'Please select the agency that issued your license, employee-id, or registration.',
  LookupTitle: 'We need a little more information to find your license/agency.',
  PreLookupInfo:
    'Please note that all licenses issued by a particular board or program will display in search results.',
  LookupCustomContent_TDLR: `Please make sure your last name matches your TDLR license including any suffix or special characters.`,
  ConfirmTitleDefault: 'Your license/agency has been located.',
  ConfirmDescriptionDefault:
    'Please review and confirm that this is the correct license/agency to link to your account.',
  MultipleLicensesConfirmLink:
    'All supported licenses/agencies, including those below, will be linked at the same time.',
  ConfirmWhyLink:
    '<h2 class="h5">Why link licenses?</h2><ul><li>Never miss a renewal with important alerts and reminders.</li><li>Complete transactions faster with stored credentials.</li></ul>',
  ConfirmTitleLinked: 'License/agency already linked.',
  ConfirmDescriptionLinked:
    'This license/agency is already linked and can be accessed in {{Link:[your dashboard](/)}}.',
};

//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

// Components
import Input from './Input';
import Label from '../Label/Label';
import Content from '../../Content/Content';

// Types
import type { Element, Node } from 'react';

type Hint = {
  yupCondition?: Function,
  content: Object,
};

type Props = {
  className: string,
  name: string,
  id?: string,
  label?: Element<any> | string,
  labelPosition?: string,
  placeholder?: string,
  type?: string,
  mask?: Array<RegExp | string>,
  guide?: boolean,
  icons?: Node,
  helpText?: string,
  hideError?: boolean,
  value: any,
  hint?: Hint,
  onBlur?: Function,
  onChange?: Function,
  formikProps?: FormikProps,
  loadOptions?: boolean,
};
type ErrorMessageProps = {
  errorMap: { id: string, args?: Array<string> },
};

const ErrorMessage = ({
  errorMap: { id: errorId, args },
}: ErrorMessageProps) => (
  <div role="alert" className="input-message input-message-error">
    <Content type="Errors" id={errorId} args={args} />
  </div>
);

const HintMessage = ({ hint: { content } }: { hint: Hint }) => (
  <div className="input-message input-message-hint">
    <Content type={content.type} id={content.id} />
  </div>
);

const InputWrapper = ({
  id,
  name,
  className,
  label,
  labelPosition = 'inside',
  icons,
  helpText,
  hideError,
  onBlur,
  onChange,
  formikProps,
  hint,
  autoFocus,
  ...props
}: Props) => {
  let hasLabel, hasError, hasSuccess, showError, errorMap;
  const value = props.value
    ? props.value
    : formikProps
    ? formikProps.getFieldValue(name) || ''
    : '';

  const handleChange = event =>
    onChange
      ? onChange(event)
      : formikProps
      ? formikProps.handleChange(event)
      : null;
  const handleBlur = event =>
    onBlur ? onBlur(event) : formikProps ? formikProps.handleBlur(event) : null;

  const defaultHintState = hint && !hint.yupCondition ? true : false;
  const [showHint, setShowHint] = useState(defaultHintState);

  useEffect(() => {
    let mounted = true;
    if (hint && hint.yupCondition) {
      hint.yupCondition
        .validate(value)
        .then(() => {
          if (mounted) {
            setShowHint(false);
          }
        })
        .catch(() => {
          setShowHint(true);                    
        });
    }
    return function() {
      mounted = false;
      return undefined;
    };
  }, [value.length]);

  if (formikProps) {
    const { getFieldTouched, getFieldError, getFieldStatus } = formikProps;
    const touched = getFieldTouched(name);
    const localError: any = getFieldError(name) || {};
    const serverError = (getFieldStatus('serverErrors') || {})[name];

    errorMap = serverError || localError;
    showError =
      (serverError && typeof serverError.id === 'string') ||
      (touched && typeof localError.id === 'string');
    hasError =
      (touched && (localError === true || localError.id)) || serverError;
    hasSuccess = !hasError && touched;
    hasLabel = label ? true : false;
  }

  const inputWrapperClasses = classnames(
    'input-wrapper',
    `input-label-position-${labelPosition}`,
    className,
    {
      hasLabel,
      hasError,
      hasSuccess,
      hasHint: showHint,
      hasHelpText: !!helpText,
    }
  );

  return (
    <div className={inputWrapperClasses}>
      {label && labelPosition === 'above' && (
        <Label label={label} name={name} id={id} />
      )}
      <div className="input-wrapper-field">
        <Input
          {...props}
          id={id || name}
          name={name}
          className="input"
          value={value}
          onBlur={handleBlur.bind(this)}
          onChange={handleChange.bind(this)}
          autoFocus={autoFocus}
          aria-label="Select an option"
        />
        {label && labelPosition === 'inside' && (
          <Label label={label} name={name} id={id} />
        )}
        {icons}
        {!hideError && showError && errorMap && (
          <ErrorMessage errorMap={errorMap} />
        )}
        {(!showError || hideError) && showHint && hint ? (
          <HintMessage hint={hint} />
        ) : null}
        {helpText && <span className="input-help">{helpText}</span>}
      </div>
    </div>
  );
};

InputWrapper.defaultProps = {
  status: {
    serverErrors: {},
  },
  value: '',
  errors: {},
  guide: false,
};

export default InputWrapper;

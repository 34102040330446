//Helpers
import { to, deleteAllCookies } from '../../lib/helpers';
import { publicRoutes } from '../../routes/RoutesPublic';
import { gaActions } from '../gaActionTypes';
import { selectSignInParamValue } from './authSelectors';

// Requests
import { logoutRequest, checkForSignInAccess } from '../../backend/requests';
import {sessionProperties} from '../../backend/loginRequests';

// Actions
import { getUser } from '../user/getUser/getUserActions';
import { clearUser } from '../user/clearUser/clearUserActions';
import { replace } from 'connected-react-router';
import { closeCurrentModal } from '../modal/modalActions';
import {oauthValidation} from '../login/loginActions';

// Consts
export const AUTH_STORE_REDIRECT_LOCATION =
  '[AUTH] Store Login Redirect Location';
export const AUTH_GETTING_SESSION = '[AUTH] Getting Session';
export const AUTH_STORE_SESSION = '[AUTH] Store Session';
export const AUTH_CLEAR_SESSION = `[AUTH] ${gaActions.session} Clear Session`;
export const AUTH_STORE_SIGNIN_PARAMVALUE = '[AUTH] Store Sign-In Param Value';
export const AUTH_CLEAR_SIGNIN_PARAMVALUE = '[AUTH] Clear Sign-In Param Value';
export const AUTH_STORE_IS_PERMITTED_TO_AUTHENTICATE_VIA_IDOLOGY =
  '[AUTH] Store Is Permitted to Authenticate Via Idology';



  

export const storeRedirectLocation = redirectLocation => dispatch =>
  dispatch({ type: AUTH_STORE_REDIRECT_LOCATION, payload: redirectLocation });
  

export const getSession = () => async (dispatch, getState) => {
  dispatch({ type: AUTH_GETTING_SESSION });
  // eslint-disable-next-line

const [user, error] = await to(dispatch(sessionProperties()));




if(user.body.tdisGuid && user.body.tdisUsername && user.body.tdisUid ){
  const guid = user.body.tdisGuid;
  const userName = user.body.tdisUsername;
  const uid = user.body.tdisUid;



  window.sessionStorage.setItem("guid", guid);
  window.sessionStorage.setItem("userName", userName);
  window.sessionStorage.setItem("_id", uid);

}

  const userId =  window.sessionStorage.getItem('guid');


  if(!userId || user.body.code){
  dispatch(redirectToSignIn());
  }

  
  const [_, err] = await to(dispatch(getUser()));
  const state = getState();
  if (err) return dispatch(clearSession());

  dispatch(storeSession());
};

export const storeSession = () => dispatch =>
  dispatch({ type: AUTH_STORE_SESSION });

export const clearSession = () => (dispatch, getState) =>
  getState().auth.isAuthenticated
    ? dispatch({ type: AUTH_CLEAR_SESSION })
    : null;

export const redirectToSignIn = () => (dispatch, getState) => {
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(clearUser());

  let { pathname, search } = getState().router.location;
  const signInParam = selectSignInParamValue(getState());

  if (!publicRoutes.includes(pathname)) {
    search = new URLSearchParams(search);
    if (signInParam) search.append('welcome', signInParam);
    search = search.toString();
    if (pathname === '/') dispatch(replace('/sign-in?' + search));
    else dispatch(replace('/sign-in?' + search, { redirectTo: pathname }));
  }
};

export const verifyAllowSignIn = paramValue => async dispatch => {
  const [{ body }, err] = await to(dispatch(checkForSignInAccess(paramValue)));
  if (err || body.status === 'ERROR') {
    return Promise.reject();
  } else {
    let validSignInParam = body.data.key;
    let isPermittedToAuthenticateViaIdology = body.data.kbVerificationStatus;
    dispatch({ type: AUTH_STORE_SIGNIN_PARAMVALUE, payload: validSignInParam });
    dispatch({
      type: AUTH_STORE_IS_PERMITTED_TO_AUTHENTICATE_VIA_IDOLOGY,
      payload: isPermittedToAuthenticateViaIdology,
    });
    return Promise.resolve(validSignInParam);
  }
};

export const storeWelcomeParam = paramValue => dispatch => {
  return dispatch({ type: AUTH_STORE_SIGNIN_PARAMVALUE, payload: paramValue });
};

export const clearWelcomeParam = () => dispatch => {
  return dispatch({ type: AUTH_CLEAR_SIGNIN_PARAMVALUE });
};

export const signOut = () => async (dispatch, getState) => {
  const signInParam = selectSignInParamValue(getState());
  await dispatch(logoutRequest());
  sessionStorage.clear();
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(closeCurrentModal());
  dispatch(clearUser());
  dispatch(replace(`/sign-in${signInParam ? `?welcome=${signInParam}` : ''}`));
};

// These sign-out functions, allow for a multi-step signout (such as when a user changes their email)

export const signOutWithoutRedirect = () => async dispatch => {
  await dispatch(logoutRequest());
  sessionStorage.clear();
  deleteAllCookies();
};

// Note: this does not sign the user out from the Forgerock session, so only use it with signOutWithoutRedirect

export const signOutWithoutAPICall = () => (dispatch, getState) => {
  const signInParam = selectSignInParamValue(getState());
  deleteAllCookies();
  dispatch(clearSession());
  dispatch(closeCurrentModal());
  dispatch(clearUser());
  dispatch(replace(`/sign-in${signInParam ? `?welcome=${signInParam}` : ''}`));
};

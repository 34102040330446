// @flow

// Vendor
import React from 'react';
import classnames from 'classnames';

// Components
import { Content, CreditCardIcon } from '../..';

// Helpers
import { paymentAccountsData } from '../../../lib/data';

// Types
type Props = {
  className?: string,
  paymentAccount: PaymentAccount,
};

const PaymentCard = ({
  className,
  paymentAccount: { paymentAccountType, cardNumber, expirationDate },
}: Props) => {
  return (
    <div className={classnames('payment-card', className)}>
      <CreditCardIcon
        name={
          paymentAccountType && paymentAccountsData[paymentAccountType]
            ? paymentAccountsData[paymentAccountType].icon
            : ''
        }
      />
      <div className="payment-card-info">
        <div className="payment-card-column">
          <span className="payment-card-name">
            {paymentAccountType && paymentAccountsData[paymentAccountType] ? (
              <Content type="Payment" id={paymentAccountType} />
            ) : (
              ''
            )}
          </span>
          <div className="payment-card-number">
            *&nbsp;*&nbsp;*&nbsp;&nbsp;{cardNumber}
          </div>
        </div>

        <div className="flex-spacer" />
        <span className="payment-card-expiration">{expirationDate}</span>
      </div>
    </div>
  );
};

export default PaymentCard;

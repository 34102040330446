//@flow

// Vendors
import React from 'react';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Content } from '../..';

// Styles
import './mfa-code-error-modal.scss';

const MFACodeErrorModal = () => {
  return (
    <Modal name="MFACodeErrorModal" className="modal-sm server-error-modal">
      <h2 className="h1 modal-title">
        <Content type="Errors" id="Field_IncorrectMFACode" />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        <p>
          <Content type="Errors" id="ServerErrorModal_Content" />
        </p>
      </div>
    </Modal>
  );
};

export default MFACodeErrorModal;

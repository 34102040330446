//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputWrapper, Content } from '../../..';

// Types
type Props = {
  name: string,
  className?: string,
  hint?: Object,
  formikProps: FormikProps,
};

/**
 * @visibleName Email
 */
const InputEmail = ({ className, hint, ...props }: Props) => {
  return (
    <InputWrapper
      {...props}
      type="email"
      autoFocus
      className={classnames('input-email', className)}
      hint={hint}
    />
  );
};

InputEmail.defaultProps = {
  label: <Content type="Labels" id="Email" />,
  name: 'email',
};

export default InputEmail;

// Data
import { SITE_DATA } from '../../data';

export default {
  PaymentTitle: `Pago`,
  CreditCard: `Tarjeta de crédito`,
  DebitCard: `Tarjeta de débito`,
  Online: 'En línea',
  Transfer: `Transferir`,
  OtherPaymentMethod: 'Otra',
  CheckingAccount: 'Cuenta de cheques',
  SavingsAccount: 'Cuenta de ahorros',
  CreditDiscover: 'Discover Credit',
  CreditVisa: 'Visa Credit',
  CreditAmericanExpress: 'AmericanExpress Credit',
  CreditMasterCard: 'Mastercard Credit',
  DebitDiscover: 'Discover Debit',
  DebitVisa: 'Visa Debit',
  DebitAmericanExpress: 'AmericanExpress Debit',
  DebitMasterCard: 'Mastercard Debit',
  DualDiscover: 'Discover Dual',
  DualVisa: 'Visa Dual',
  DualAmericanExpress: 'American Express Dual',
  AddPaymentMethod: `Agregue Método de Pago`,
  UseDifferentCard: `Utilice una tarjeta diferente`,
  RemovePaymentMethodConfirmQuestion: `¿Está seguro de que quiere eliminar esta carta?`,
  RemovePaymentMethodConfirmButton: `Si, eliminar la tarjeta`,
  EditCardTitle: `Editar detalles de la tarjeta.`,
  AddCardTitle: `Agregue una tarjeta de crédito o débito nueva.`,
  AddCardDescription: `Puede actualizar o borrar esta información en cualquier momento desde la configuración de su cuenta.`,
  PayNow: 'Envie Pago',
  Paying: 'Pagando',
  ReviewInfo: 'Revise Información',
  ErrorTitle: 'Se produjo un error con su pago.',
};

import { SITE_DATA } from '../../data';

export default {
  EmailAlreadyRegistered: `El correo electrónico que ha introducido ya está registrado.`,
  CheckModalImageAlt: `Encontrar el número de auditoría y número de cuenta corriente en la parte inferior del cheque.`,
  CVVModalImageAlt: `Encontrar el código de seguridad CVV de 3 dígitos en el reverso de la tarjeta de crédito. De las tarjetas AMEX se puede encontrar un código de 4 dígitos Secuirty en el frente.`,
  DeactivateAccount: `¿Seguro que deseas Desactivado su cuenta?`,
  UnableToAccessAccountUntilReactivate: `Usted no será capaz de acceder a la información de su cuenta hasta que se reactive.`,
  FindYourID: `Encuentra su identificación y los números / DD auditoría.`,
  LicenseOrIDNumber: `Licencia de conducir o número de identificación`,
  LicenseDetailsImageAlt: `Gráfico que muestra dónde localizar sus números de identificación y de auditoría de licencias de conducir`,
  AccountTemporarilyLocked: `Cuenta bloqueada temporalmente.`,
  FourHourLockout: `Para proteger su información personal, su cuenta ha sido bloqueada durante cuatro horas debido a demasiados intentos sin éxito en registro.`,
  TryAgainLater: `Por favor, inténtelo de nuevo más tarde.`,
  SessionWillEndInTwoMinutes: `Debido a la inactividad, esta sesión terminará en dos minutos. Por favor, háganos saber si usted necesita más tiempo.`,
  AreYouStillThere: `¿Sigues ahí?`,
  TermsAndConditionsSummaryHeader: `Queremos asegurarnos de que los términos y condiciones para el uso de estos servicios son claras, así que aquí está un resumen en Inglés sencillo.`,
  FollowThisLinkForTermsAndConditions: `Si desea leer el acuerdo completo, simplemente siga este enlace.`,
  termsAndConditionsModalSiteName: `${SITE_DATA.SITE_NAME}`,
  AreYouSureYouWantToRemoveThisLink: `¿Está seguro de que desea eliminar este enlace?`,
  NoMoreAgencyNotifications: `No recibirá ninguna notificación para futuras`,
  ItWillBeRemovedFromDashboard: `y será removido de su tablero de instrumentos.`,
  ConfirmCorrectAddress: `Quiero asegurarme de que tengo su dirección correcta`,
  UseAddressEntered: `Utilice la dirección que ha introducido:`,
  JustAHeadsUp: 'Sólo un aviso.',
  YoureLeavingThe: 'Está saliendo de la ',
  AndOpeningAnExternalWebsite: ' y abriendo un sitio web externo.',
  OkLetsGo: 'Vale, vamos',
  BlankNoId: ' ',

  TermsAndConditionsTitle: `${SITE_DATA.SITE_NAME}`,
  TheTermsAndConditions: `Los términos y condiciones de uso para el estado ${SITE_DATA.SITE_NAME}:`,
  TermsAndConditionsListItems: `<li>nuestros servicios estarán disponibles para todo el mundo, y se hará todo lo posible para garantizar la accesibilidad.</li><li>que la información personal se mantendrá seguro y garantizado, y la única información necesaria para la realización de negocios será recogido.</li><li>que la información personal no será almacenada de forma permanente. Sin embargo, las organizaciones estatales y las agencias que proporcionan servicios pueden necesitar para retener su información </li><li>que cualquier actividad sospechosa o ilegal puede ser reportado a la policía.</li><li>se puede dejar de utilizar ${SITE_DATA.SITE_NAME} en cualquier momento, pero la información de su gobierno seguirán siendo almacenados por el gobierno.</li><li> que la información sensible y transaccional será protegido de acuerdo con normas y reglamentos internacionales, federales, estatales y de seguridad. Esto incluye la encriptación o codificación toda la información personal.</li><li>que los pequeños archivos de texto, también conocidos como “cookies” pueden ser colocados en su computadora para realizar transacciones más eficiente. Ellos no serán utilizados para ningún propósito comercial.</li><li>información personal nunca será compartida en las redes sociales de Texas.gov, como Facebook o Instagram </li><li>que ${SITE_DATA.SITE_NAME} términos y condiciones no se extienden a cualquier otro sitio, incluso si está enlazada desde este sitio.</li><li>cualquier disputa legal debe decidirse conforme a las leyes y en las cortes de Tejas.</li>`,

  cappsTermsAndConditionsTitle: 'Contralor de Cuentas Públicas de Texas',
  cappsAcceptableUsePolicy: `Política de Uso Aceptable`,
  cappsTermsAndConditionsListItems: `<li>El acceso no autorizado a este sistema informático o el uso no autorizado de cualquier información fiscal confidencial o federal puede constituir una violación de la ley federal y dar lugar a responsabilidades y sanciones civiles y penales. Este sistema está sometido a vigilancia y se puede acceder a los datos almacenados y grabarlos. Cualquier persona que utilice este sistema da su consentimiento a la supervisión y no debe tener ninguna expectativa de privacidad. Las pruebas de responsabilidad penal se facilitarán a los organismos encargados de hacer cumplir la ley.</li>Este sitio web está destinado a ser utilizado por los empleados del Estado de Texas y otras personas expresamente autorizadas por el Estado de Texas. El intento de acceder a este sitio web sin autorización puede infringir las leyes penales y exponerle a multas y penas de prisión. Los infractores serán procesados.</li>`,

  kclTermsAndConditionsTitle: 'Contralor de Cuentas Públicas de Texas',
  kclAcceptableUsePolicy: `Política de Uso Aceptable`,
  kclTermsAndConditionsListItems: `<li>Al hacer clic en [Acepto] a continuación, usted reconoce y acepta lo siguiente: Todas las comunicaciones y datos que transitan, viajan hacia o desde, o se almacenan en este sistema serán monitoreados. Usted da su consentimiento a la supervisión, interceptación, grabación y búsqueda sin restricciones de todas las comunicaciones y datos que transitan, viajan hacia o desde, o se almacenan en este sistema en cualquier momento y para cualquier propósito por Kilgore College y por cualquier persona o entidad, incluidas las entidades gubernamentales, autorizadas por Kilgore College. También acepta la divulgación sin restricciones de todas las comunicaciones y datos que transitan, viajan hacia o desde, o se almacenan en este sistema en cualquier momento y para cualquier propósito a cualquier persona o entidad, incluidas las entidades gubernamentales, autorizadas por Kilgore College. Usted reconoce que no tiene ninguna expectativa razonable de privacidad con respecto a su uso de este sistema. Estos reconocimientos y consentimientos cubren todo el uso del sistema, incluyendo el uso relacionado con el trabajo y el uso personal sin excepción.</li>`,

  ResetPassword: 'Restablecer la contraseña',
  PasswordExpired: 'Tu contraseña ha expirado',
  RegisterSecurityMethod: 'Registre su método de seguridad avanzada',
  RegisterSecurityMethodDesc:
    'Ahora se encuentran disponibles opciones de seguridad avanzadas adicionales para ser utilizadas como un segundo factor de autenticación durante el proceso de inicio de sesión. Solo se puede registrar un dispositivo por método.​',
  RegisterSelectMethod:
    'Seleccione uno de los siguientes métodos para registrarse.',
  ScanQRCode: 'Registre su aplicación Authenticator.',
  ScanQRCodeDesc:
    'Actualmente, se admiten las aplicaciones Microsoft y Google Authenticator. Utilice su aplicación de autenticación preferida para escanear el código QR a continuación. Esto conectará la aplicación con su cuenta.',
  VerifyAuthenticatorApp: 'Verifique su aplicación de autenticación',
  VerifyAuthenticatorAppDesc:
    'Ingrese el código de acceso de su aplicación de autenticación',
  DeleteConfirmation: '¿Estás segura de que quieres eliminar este dispositivo?',
  DeleteWarning: `No podrá utilizar este dispositivo para la autenticación multifactor.`,
  PasswordExpiredDesc:
    'Para acceder a su cuenta, deberá establecer una nueva contraseña. Haga clic en Restablecer contraseña.',
  Cancel: `Cancelar`,
  DeleteNumber: 'Borrar Número',
  DeleteNumberTitle:
    '¿Estás seguro de que quieres borrar este número de teléfono?',
  DeleteNumberDescription:
    'No podrás utilizar este número de teléfono para la autenticación multifactor o la recuperación de la cuenta.',
  RememberDevice: 'Recuerda Este Dispositivo?',
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './container.scss';

type Props = {
  className?: string,
};

/**
 * @visibleName
 */
const Container = ({ className, ...props }: Props) => (
  <div {...props} className={classnames('container', className)} />
);

export default Container;

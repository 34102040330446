import React from 'react';
import { connect, useDispatch } from 'react-redux';

// Components
import { Modal } from '..';
import Content from '../../Content/Content';
import { Form, InputCheckbox } from '../../../../components';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { SecurityDeviceLogin } from '../../../../reducers/login/loginActions';

// Styles
import './remember-device-modal.scss';

const RememberDeviceModal = props => {

  const combined = props.modal.data;
  const dispatch = useDispatch();

  
    return (

      <Modal name="RememberDeviceModal" className="remember-device-modal">
      <div className="remember-device-options">
      <h1>
        <Content type="Modals" id="RememberDevice" />
      </h1>
      <Form
          className="login-page-mfa-code-form"
          formikConfig={{
            validateOnBlur: false,
            onSubmit: (values, formikActions: FormikActions) => {
              dispatch(SecurityDeviceLogin(combined, values, formikActions));
            },
            initialValues: {rememberThisDevice: '' },
          }}
          render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
            <>
              <FormError />
              <InputCheckbox
                name="rememberThisDevice"
                label={<Content type="SignIn" id="RememberDevice" />}
                value="rememberThisDevice"
                checked={formikProps.values.rememberThisDevice === true}
                formikProps={formikProps}
              />
              <FormActions align="center">
                <FormActionsItem fullWidth={true}>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={<Content type="Labels" id="Loading" />}
                  >
                    <Content type="Labels" id="Submit" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        /> 
      </div>
    </Modal>

    )
  }

  const mapStateToProps = ({ modal }): Object => ({ modal });

  export default connect(
    mapStateToProps
  )(RememberDeviceModal);

export default {
  SiteName: 'Texas.gov Digital Identity Solution',
  SiteNameAbbr: 'TDIS',
  TermsAndConditions: 'Terms &amp; Conditions',
  HelloSpace: 'Hello, ',
  WelcomeWithComma: 'Welcome, ',
  MoreAgenciesComingSoon: 'More agencies coming soon!',
  NoPhoneNumberListed: 'No phone number listed.',
  StandardTextMessageRatesApply: 'Standard text message rates apply.',
};

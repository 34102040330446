//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputPhone } from '../../..';

// Styles
import './input-group-phone-cell.scss';

// Types
type Props = {
  className?: string,
  inverse: boolean,
  formikProps: FormikProps,
};

/**
 * @visibleName Phone
 */
const InputGroupPhoneCell = ({
  className,
  inverse,
  formikProps,
  ...props
}: Props) => {
  return (
    <InputGroup
      {...props}
      className={classnames('input-group-phone', className)}
    >
      <InputPhone
        className="input-phone"
        name="phone"
        formikProps={formikProps}
      />
    </InputGroup>
  );
};

export default InputGroupPhoneCell;

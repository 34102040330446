export default {
  ACCOUNT: 'Actualización de perfil',
  TDLR: 'Actualización de la agencia',
  CYBER: 'Actualización cibernética',
  UPDT_ADDR: 'Dirección de correo actualizada',
  UPDT_EMAIL_PR: 'Correo electrónico principal actualizado',
  UPDT_PHONE: 'Número de teléfono actualizado',
  UPDT_PHONE_MB: 'Número de móvil actualizado',
  PDT_PHONE_OT: 'Actualizado Otro número',
  UPDT_SQ: 'Preguntas de seguridad actualizadas',
  CREAT_AC: 'Cuenta TxT creada',
  UPDT_PWD: 'Contraseña cambiada',
  RESET_PWD: 'Restablecer contraseña',
  GOV_STATUS: 'Estado de empleado modificado',
  UPDT_GOV_EMAIL: 'Correo electrónico de trabajo verificado',
  ATTST_CYBR: 'Atestación de ciberseguridad completa',
  TDLR_LR: 'Licencia renovada',
  TDLR_LR_MT: 'Terapeuta de masaje con licencia renovado',
  TDLR_LR_MI: 'Instructor de Terapia de Masaje Renovado',
  LINK_TDLR_MT: 'Terapeuta de masaje con licencia vinculado',
  LINK_TDLR_MI: 'Instructor de Terapia de Masaje Vinculado',
  UNLINK_TDLR_MT: 'Terapeuta de masaje con licencia sin vincular',
  UNLINK_TDLR_MI: 'Instructor de Terapia de Masaje UnLinked',
  UNLINK_TDLR: 'Licencia TDLR sin enlace',
};

export default {
  PageTitle: 'Dashboard',
  EmpPageTitle: 'Employee Page',
  AvailableAgencies_Title: 'Available Agencies',
  LinkedAgencies_Title: 'My Agencies',
  LinkedAgencies_AddAnother: 'Add another',
  LinkedAgencies_EmptyTitle:
    'Let’s get started by linking a license or registration.',
  LinkedAgencies_EmptyDescription: `When you link an item you’ll receive helpful renewal reminders, complete transactions faster and a shortcut to your linked item will show up here for the next time you need it.`,
  LinkedAgencies_Empty_Prompt: `<b>Click the green plus button</b> to start linking!`,
  TodoList_Title: 'To-do List',
  TodoList_DueBy: 'Due',
  History_Title: 'History',
};

//@flow

// Vendors
import React, { type Node } from 'react';
import classnames from 'classnames';

// Components
import HeroEmployee from './HeroEmployee';
//import HeroActions from './HeroActions';

// Types
type Props = {
  className: string,
  title: string | Node
};

const HeroEmployeeDefault = ({ className, title, actions, ...props }: Props) => {
  return (
    <HeroEmployee {...props} className={classnames('hero-employee-default', className)}>
      <h1 className="hero-employee-title">{title}</h1>
    </HeroEmployee>
  );
};

export default HeroEmployeeDefault;

export default {
  BackToDashboard: 'Back to Dashboard',
  ShowDashboard: 'Show Dashboard',
  ShowEmployeeDashboard: 'Employee Dashboard',
  Email: 'Email',
  EmailAddress: 'Email address',
  EnterEmail: 'Enter email',
  ConfirmEmail: 'Confirm email',
  Password: 'Password',
  EnterPassword: 'Enter password',
  ConfirmPassword: 'Confirm password',
  EnterCode: 'Enter code',
  PhoneNumber: 'Phone number',
  EnterPhoneNumber: 'Enter phone number',
  Mobile: 'Mobile',
  FirstName: 'First name',
  LastName: 'Last name',
  DLNumber: 'ID or driver license number',
  AuditNumber: 'DD/Audit number',
  HowToFindLicenseNumbers: 'How to find these numbers',
  IAcceptThe: 'I accept the',
  SSN: 'Last four digits of SSN',
  DOB: 'Date of birth',
  Other: 'Other',
  Next: 'Next',
  Back: 'Back',
  Finish: 'Finish',
  Cancel: 'Cancel',
  Verify: 'Verify',
  Save: 'Save',
  SaveExit: 'Save & Exit',
  Exit:'Exit',
  Send: 'Send',
  SendCode: 'Next',
  Submit: 'Submit',
  ReturnToLogin: 'Return to Login',
  Edit: 'Edit',
  Remove: 'Remove',
  SubmitPayment: 'Submit Payment',
  PayNow: 'Pay Now',
  UpdateAddress: 'Update Address',
  RemoveLink: 'Remove Link',
  ForgotPassword: 'Forgot password',
  CreateAccount: 'Create Account',
  SignIn: 'Sign In',
  ViewMyDashboard: 'View My Dashboard',
  ViewMyLicense: 'View My License',
  LinkToMyAccount: 'Link To My Account',
  MyAccount: 'My Account',
  LinkALicenseOrRegistration: 'Link a License or Registration',
  Confirm: 'Confirm',
  ConfirmAndContinue: 'Confirm &amp; Continue',
  INeedMoreTime: 'I need more time.',
  GetStarted: 'Get Started',
  StartLinking: 'Start Linking',
  IllDoThisLater: `No thanks, I'll do this later.`,
  DeactivateAccount: 'Deactivate Account',
  CheckSpellingAndTryAgain: 'Check spelling and try again.',
  Confirming: 'Confirming',
  Loading: 'Loading',
  Linking: 'Linking',
  Deactivating: 'Deactivating',
  Sending: 'Sending',
  Verifying: 'Verifying',
  Saving: 'Saving',
  Removing: 'Removing',
  Submitting: 'Submitting',
  BackToSignIn: 'Back to Sign In',
  VerifyWithID: 'Verify with an ID',
  VerifyWithQuestions: 'I do not have a Texas ID.',
  LookUpADifferentLicense: 'Look up a different license',
  UseMyPersonalPhone: 'Use my personal phone',
  LookUpADifferentAgency: 'Look up a different Agency',
  ServicesAvailable: 'Available Services',
  Name: 'Name',
  NameOfOrganization: 'Name of Organization',
  SearchOrganizations: 'Search organizations...',
  PreLookup_ProgramEmptySelectOption: 'Select a Board/Program',
  PreLookup_LicenseEmptySelectOption: 'Select a License',
  LicenseType: 'License type',
  LicenseNumber: 'License number',
  CAPPSID: 'CAPPS ID',
  WorkEmail: 'Work Email',
  WorkPhone: 'Work Phone',
  UserRoles: 'User Roles',
  LicenseName: 'License name',
  AppName: 'App Name',
  CreditOrDebit: 'Credit or debit card',
  ElectronicCheck: 'Electronic check',
  BillingAddress: 'Billing address',
  ForeignAddressInput:
    'Full address including street, city, state/province, postal code (if applicable), and country.',
  RenewalFee: 'Renewal fee',
  LateFee: 'Late fee',
  FeeTotal: 'Fee total',
  TotalAmountDue: 'Total amount due',
  Yes: 'Yes',
  No: 'No',
  Address: 'Address',
  DomesticAddress: 'U.S. Address',
  ForeignAddress: 'International Address',
  Required: '(required)',
  Optional: '(optional)',
  TransactionNumber: 'Transaction number',
  TransactionDate: 'Transaction date',
  AmountCharged: 'Amount charged',
  PaymentMethod: 'Payment method',
  MyServices: `My Services`,
  MyApplicationOnboardingRequests: `My Application Onboarding Requests`,
  MyApps: `My Apps`,
  Status: 'Status',
  SaveCard: 'Save card for later',
  AccountSettings: 'Account Settings',
  ApplicationOnBoardingSelfService: 'Application Onboarding Self-Service',
  DelegatedAdmin: 'Delegated Admin',
  SignOut: 'Sign Out',
  ErrorReference: 'Error Reference: ',
  LinkServiceToAgency: 'link service to agency',
  CloseLearnMoreDrawer: 'Close learn more drawer.',
  SuggestedAddress: 'Suggested address:',
  SuggestedAddresses: 'Suggested addresses:',
  DeactivateMyAccount: 'Deactivate My Account',
  PrimaryAddress: 'Primary Address',
  FullLegalName: 'Full Legal Name',
  EmployeeName: 'Employee Name',
  NoPrimaryAddressListed: 'No primary address listed.',
  CurrentPassword: `Current Password`,
  NewPassword: `New Password`,
  ConfirmNewPassword: `Confirm New Password`,
  Select: `Select&hellip;`,
  Expiration: `Expiration`,
  Renew: `Renew`,
  Launch: `Launch`,
  CreateApp: `Create App`,
  RenewLicense: `Renew License`,
  SameAsPrimaryAddress: `Same as primary address`,
  StreetAddress: `Street Address`,
  City: `City`,
  State: `State`,
  Unit: `Apt/Unit #`,
  Zip: `Zip Code`,
  Answer: `Answer`,
  LinkedLicense: `Linked license`,
  LinkedLicenseMultiple: `Linked licenses`,
  Address3: `Address Line 3`,
  Country: `Country`,
  SkipQuestion: `Skip Question`,
  SkipForNow: `Skip for now`,
  Government: `Public Sector`,
  GovernmentEmail: 'Work Email',
  TypeToSearch: 'Begin typing to search...',
  KeepTypingToSearch: 'Keep typing to search...',
  EmployeeDashboard: 'Employee Dashboard',
  Delete: 'Delete',
  DeleteDevice: 'Delete device',
  SecurityKey: 'Security Key',
  AuthenticatorApp: 'Authenticator App',
};

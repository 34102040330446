//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { Content } from '../..';

// Types
type Props = {
  className?: string,
  channelCode: string,
  contactInfo: string,
};

const OTPSentContent = ({ className, channelCode, contactInfo }: Props) => {
  let enterCodeDesc = '';
  if (channelCode === 'SMS' || channelCode === 'CALL') {
    enterCodeDesc = (
      <>
        <Content type="MFA" id="CodeSentTo" />
        <strong>
          {'*** *** ' + contactInfo.slice(contactInfo.length - 4)}
        </strong>
      </>
    );
  } else if (channelCode === 'SMTP') {
    enterCodeDesc = (
      <>
        <Content type="MFA" id="CodeSentTo" />
        <strong className="nobr">
          {contactInfo.split(' ').slice(-1)}
        </strong>. <Content type="MFA" id="CodeSentSpam" />
      </>
    );
  } else if (channelCode === 'AA') {
    enterCodeDesc = <Content type="MFA" id="CodeAuthenticator" />;
  }

  return (
    <>
      <p className={classnames(className)}>{enterCodeDesc}</p>
    </>
  );
};
OTPSentContent.defaultProps = {
  numberOnly: false,
};

export default OTPSentContent;

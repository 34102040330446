//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './input-group.scss';

// Types
type Props = {
  className?: string,
  isGrid?: boolean,
  children: Node,
};

const InputGroup = ({ className, isGrid, children, ...props }: Props) => (
  <div {...props} className={classnames('input-group', { isGrid }, className)}>
    {isGrid ? <div className="input-group-grid">{children}</div> : children}
  </div>
);

export default InputGroup;

export default {
  BackToDashboard: 'Volver al Tablero',
  ShowDashboard: 'Show Dashboard',
  ShowEmployeeDashboard: 'Panel de empleados',
  Email: `Correo electrónico`,
  EmailAddress: `Correo electrónico`,
  EnterEmail: `Introduzca su correo electrónico.`,
  ConfirmEmail: `Ingrese correo electrónico`,
  Password: `Contraseña`,
  EnterPassword: `Introduzca su contraseña`,
  ConfirmPassword: `Ingrese su contraseña`,
  EnterCode: `Introduzca el código`,
  PhoneNumber: `Número telefónico`,
  EnterPhoneNumber: `Introduzca su número telefónico`,
  Mobile: `Móvil`,
  FirstName: `Primer nombre`,
  LastName: `Apellido`,
  DLNumber: `ID o número de su licencia de conducir`,
  AuditNumber: `DD o número de auditoria`,
  HowToFindLicenseNumbers: `Como encontrar estos números`,
  IAcceptThe: `Yo acepto`,
  SSN: `Ultimos cuatro dígitos del número de seguro social`,
  DOB: `Fecha de nacimiento`,
  Other: `Otro`,
  Next: `Siguiente`,
  Back: `Atrás`,
  Finish: `Terminar`,
  Cancel: `Cancelar`,
  Verify: `Verificar`,
  Save: `Guardar`,
  SaveExit: 'Guardar la salida',
  Exit:'Salida',
  Send: `Enviar`,
  SendCode: `Enviar Código`,
  Submit: `Enviar`,
  ReturnToLogin: 'Volver al inicio de sesión',
  Edit: `Editar`,
  Remove: `Eliminar`,
  SubmitPayment: `Enviar Pago`,
  PayNow: `Envie Pago`,
  UpdateAddress: `Actualiza Dirección`,
  RemoveLink: `Eliminar Conexión`,
  ForgotPassword: `Recuperar Contraseña`,
  CreateAccount: `Crear Cuenta`,
  SignIn: `Iniciar Sesión`,
  ViewMyDashboard: `Ir a Mi Cuenta`,
  ViewMyLicense: `Ir a Mi Licencia`,
  LinkToMyAccount: `Conecte a Mi Cuenta`,
  MyAccount: `Mi Cuenta`,
  Confirm: 'Confirmar',
  ConfirmAndContinue: `Confirmar &amp; Continuar`,
  LinkALicenseOrRegistration: 'Enlazar una Licencia o Registro',
  INeedMoreTime: `Necesito más tiempo.`,
  GetStarted: `Empecemos`,
  StartLinking: `Comience conexión`,
  IllDoThisLater: `Lo hare después.`,
  DeactivateAccount: `Desactivar Cuenta`,
  CheckSpellingAndTryAgain: `Verifique ortografía, intente nuevamente.`,
  Confirming: `Confirmando`,
  Loading: `Cargando`,
  Linking: `Conectando`,
  Deactivating: `Desactivando`,
  Sending: `Enviando`,
  Verifying: `Verificando`,
  Saving: `Guardando`,
  Removing: `Eliminando`,
  Submitting: `Enviando`,
  BackToSignIn: `Volver a iniciar sesión`,
  VerifyWithID: `Verificación con ID`,
  VerifyWithQuestions: `No tengo una identificación de Texas.`,
  LookUpADifferentLicense: `Buscar una licencia diferente`,
  LookUpADifferentAgency: `Buscar una agencia diferente`,
  UseMyPersonalPhone: `Usar mi telefono personal`,
  ServicesAvailable: `Servicios Disponibles`,
  Name: `Nombre`,
  NameOfOrganization: `Nombre de Organización`,
  SearchOrganizations: `Buscar organizaciones...`,
  MyServices: `Mis Servicios`,
  MyApplicationOnboardingRequests: `Mis solicitudes de incorporación de aplicaciones`,
  MyApps: `Mis Aplicaciones`,
  PreLookup_ProgramEmptySelectOption: `Selecciona un Programa`,
  PreLookup_LicenseEmptySelectOption: `Selecciona una Licencia`,
  LicenseType: `Tipo de licencia`,
  LicenseNumber: `Numero de licencia`,
  CAPPSID: 'CAPPS ID',
  WorkEmail: 'correo electrónico del trabajo',
  WorkPhone: 'teléfono del trabajo',
  UserRoles: 'Roles del usuarios',
  LicenseName: `Nombre de licencia`,
  AppName: 'Nombre de la aplicación',
  CreditOrDebit: `Tarjeta de crédito o débito`,
  ElectronicCheck: `Cheque electrónico`,
  BillingAddress: `Domicilio Fiscal`,
  ForeignAddressInput: `Dirección completa incluyendo calle, ciudad, estado / provincia, código postal (si corresponde) y país.`,
  RenewalFee: `Precio de Renovación`,
  LateFee: `Cargo por demora`,
  FeeTotal: `Tarifa total'`,
  TotalAmountDue: `Monto Total`,
  Yes: `Sí`,
  No: `No`,
  Address: `Dirección`,
  DomesticAddress: `Dirección de EE. UU.`,
  ForeignAddress: `Dirección internacional`,
  Required: `(obligatorio)`,
  Optional: `(opcional)`,
  TransactionNumber: `Número de transacción`,
  TransactionDate: `Fecha de transacción`,
  AmountCharged: `Pago`,
  PaymentMethod: `Método de pago`,
  Status: `Estado`,
  SaveCard: `Guarde tarjeta para más tarde`,
  AccountSettings: `Configuraciones de la Cuenta`,
  SignOut: `Terminar Sesión`,
  ErrorReference: `Referencia de error:`,
  LinkServiceToAgency: `conecte servicio a una agencia`,
  CloseLearnMoreDrawer: `Cerrar cajón.`,
  SuggestedAddress: `Dirección sugerida:`,
  SuggestedAddresses: `Direcciones sugeridas:`,
  DeactivateMyAccount: `Desactivar Mi Cuenta`,
  PrimaryAddress: `Dirección Primaria`,
  FullLegalName: `Nombre Legal`,
  EmployeeName: 'Nombre de empleado',
  NoPrimaryAddressListed: `Sin dirección principal en la lista.`,
  CurrentPassword: `Contraseña`,
  NewPassword: `Nueva Contraseña`,
  ConfirmNewPassword: `Confirmar Nueva Contraseña`,
  Select: `Selecciona&hellip;`,
  Expiration: `Vencimiento`,
  Renew: `Renovar`,
  Launch: `Lanzamiento`,
  RenewLicense: `Renovar licencia`,
  SameAsPrimaryAddress: `Igual que la dirección principal`,
  StreetAddress: `Dirreción`,
  City: `Ciudad`,
  State: `Estado`,
  Unit: `Apto`,
  Zip: `Código Postal`,
  Answer: `Respuesta`,
  LinkedLicense: `Licencia vinculada`,
  LinkedLicenseMultiple: `Licencias vinculadas`,
  Country: `País`,
  Address3: `Dirreción adicional`,
  SkipQuestion: `Omita pregunta`,
  SkipForNow: 'Omitir esta pregunta',
  Government: `Sector Público`,
  GovernmentEmail: `Correo Electrónico de Trabajo`,
  TypeToSearch: 'Comience a escribir para buscar...',
  KeepTypingToSearch: 'Sigue escribiendo para buscar...',
  EmployeeDashboard: 'Tablero de Empleados',
  Delete: 'Borrar',
};

//@flow

// Vendors
import React from 'react';
import { NavLink } from 'react-router-dom';

// Styles
import './navbar-menu.scss';

// Config
const menuItems = [
  {
    label: 'Dashboard',
    url: '/',
  },
  {
    label: 'My Account',
    url: '/account',
  },
];

const NavbarMenu = () => (
  <ul className="navbar-menu">
    {menuItems.map((item, i) => (
      <li key={`navbar-menu-item-${i}`} className="navbar-menu-item">
        <NavLink to={item.url}>{item.label}</NavLink>
      </li>
    ))}
  </ul>
);

export default NavbarMenu;

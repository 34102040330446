//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Styles
import './input-textarea.scss';

// Types
type Props = {
  name: string,
  className?: string,
};

/**
 * @visibleName Textarea
 */
const InputTextarea = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    as="textarea"
    className={classnames('input-textarea', className)}
  />
);

InputTextarea.defaultProps = {
  labelPosition: 'above',
};

export default InputTextarea;

/* eslint-disable no-irregular-whitespace */
//@flow
import React from 'react';
import states from '../data/states';
import countries from '../data/countries';
type Address = {
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city?: string,
  state?: string,
  zipFive?: string,
  zipFour?: string,
  postalCode?: string,
  country?: string,
  verifySkip?: boolean,
  addressType?: string,
  addressLocation?: string,
};
type ForeignAddress = {
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city: string,
  state?: string,
  postalCode?: string,
  country: { label?: string, code: string },
};
type AddressForForm = {
  addressLocation: string,
  streetAddress: string,
  addressLine2?: string,
  addressLine3?: string,
  city: string,
  state: { label?: string, value: string },
  zipFive: string,
  zipFour?: string,
  foreignAddress: ForeignAddress,
  verifySkip?: boolean,
};
export function isCompleteAddress(address: Address) {
  if (!address) return false;
  const { streetAddress, city, state, zipFive } = address;
  return streetAddress && city && state && zipFive;
}
export function formatAddress({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  zipFive,
  zipFour,
  postalCode,
  country,
}: Address) {
  let address = streetAddress;
  if (addressLine2) address += ` ${addressLine2}`;
  if (addressLine3) address += ` ${addressLine3}`;
  if (address) address += ', ';
  if (city) address += ` ${city},`;
  if (state) address += ` ${state}`;
  if (zipFive) address += ` ${zipFive}`;
  if (zipFour) address += `-${zipFour}`;
  if (postalCode) address += ` ${postalCode}`;
  if (country) address += ` ${country}`;
  return address;
}
export function formatMultiLineAddress({
  streetAddress,
  addressLine2,
  addressLine3,
  city,
  state,
  postalCode,
  country,
  zipFive,
  zipFour,
}: Address) {
  let firstLine = streetAddress;
  let secondLine = '';
  let thirdLine = '';
  if (country) {
    if (addressLine2) secondLine += `${addressLine2}`;
    if (addressLine3) secondLine += `, ${addressLine3}`;
    if (city) thirdLine += `${city},`;
    if (state) thirdLine += ` ${state}`;
    if (postalCode) thirdLine += ` ${postalCode}`;
    if (country) thirdLine += ` ${country}`;
  } else {
    if (addressLine2) firstLine += ` ${addressLine2}`;
    if (city) secondLine += `${city},`;
    if (state) secondLine += ` ${state}`;
    if (zipFive) secondLine += ` ${zipFive}`;
    if (zipFour) secondLine += `-${zipFour}`;
  }
  return (
    <>
      <>
        {firstLine} <br />
      </>
      {secondLine && (
        <>
          {secondLine} <br />
        </>
      )}
      {thirdLine}
    </>
  );
}
export const addressIsEqual = (address1: Object, address2: Object) =>
  formatAddress(address1).toLowerCase() ===
  formatAddress(address2).toLowerCase();

export const shouldVerifyAddress: ({
  address: Address,
}) => boolean = (formValues: { address: Address }) => {
  if (formValues.address.verifySkip === true) {
    return false;
  }

  if (formValues.address.country && formValues.address.country !== '') {
    return false;
  }

  return true;
};

export const formatAddressForForm: Address => AddressForForm = (
  address: Address
) => {
  address.addressLocation = address.country ? 'foreign' : 'domestic';
  if (address.country) {
    const country = countries.find(country => country.code === address.country);
    const foreignAddress = {
      streetAddress: address.streetAddress,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      city: address.city || '',
      state: address.state || '',
      postalCode: address.postalCode || address.zipFive || '',
      country: country || { code: '' },
    };
    return {
      addressLocation: 'foreign',
      streetAddress: '',
      addressLine2: '',
      city: '',
      state: { label: 'Texas', value: 'TX' },
      zipFive: '',
      foreignAddress,
      verifySkip: address.verifySkip,
      addressType: address.addressType,
    };
  }

  // $FlowIgnore - sometimes we get state as an object
  let state: { label?: string, value: string } = address.state;
  if (typeof address.state === 'string') {
    state = address.state
      ? states.find(state => state.value === address.state) || { value: '' }
      : { value: 'TX' };
  }

  return {
    addressLocation: 'domestic',
    streetAddress: address.streetAddress,
    addressLine2: address.addressLine2,
    city: address.city || '',
    state: state,
    zipFive: address.zipFive || '',
    zipFour: address.zipFour,
    foreignAddress: {
      streetAddress: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      state: '',
      postalCode: '',
      country: { code: '' },
    },
    verifySkip: address.verifySkip,
    addressType: address.addressType,
  };
};
export const formatAddressFromForm: AddressForForm => Address = (
  address: Object
) => {
  if (
    typeof address.country === 'string' &&
    address.addressLocation === 'foreign'
  )
    return address; // address is already formatted
  if (
    address.addressLocation === 'foreign' &&
    address.foreignAddress.country.code !== ''
  )
    return {
      ...address.foreignAddress,
      addressLocation: address.addressLocation,
      country: address.foreignAddress.country.code,
    };
  if (address.state && address.state.value)
    address.state =
      typeof address.state === 'string' ? address.state : address.state.value;
  delete address.addressLine3;
  delete address.postalCode;
  delete address.country;
  delete address.foreignAddress;
  return { ...address };
};

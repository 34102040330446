import { getQueryStringParams } from './browserAndDevice';

export const shouldLog =
  (window.mgmwEnv === 'Dev' || window.mgmwEnv === 'Test') &&
  getQueryStringParams(window.location.href).log === 'true';

export const publicURL = path => `${process.env.PUBLIC_URL}/public/${path}`;

export const isDevelopmentEnvironment = () =>
  process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'DEV';

export const isAdvancedSecurityDeviceEnabled = () =>
  process.env.REACT_APP_FEATURE_FLAG_ADVANCED_SECURITY_DEVICE === 'true';

//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../..';
import Content from '../../Content/Content';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { 
    getInitiateYubiKeyDevices,
    getMyInitiateSecurityDevices,
    getCompleteYubiKeyDevices,
    getPropertieUrl,
   } from '../../../../reducers/agency/agencyActions';
import { closeModal, openModal } from '../../../../reducers/modal/modalActions';
import { InputRadio } from '../../../common';



// Styles
import './advanced-security-modal.scss';

type Props = {
  formikProps: FormikProps,
  modal: Object,
  closeModal: Function,
  setRefreshDevices: Function,
  refreshDevices: boolean,
  clearInputTrigger: Function,

};

const AdvancedSecurityModal = ({
  modal: { data },
  formikProps,
  closeModal,
  setRefreshDevices,
  refreshDevices,
  clearInputTrigger,
}) => {
  const dispatch = useDispatch();
  const [securityDevices, setSecurityDevices] = useState([]);
  const propurl = useSelector(state => state.agency);



  useEffect(() => {
    setSecurityDevices(data);
    console.log(propurl)
  }, [data]);


 
 
  const closeAdvancedSecurityModal = () => {
    setRefreshDevices(!refreshDevices);
    closeModal('AdvancedSecurityModal', {
      onClose: () => dispatch(push('/account')),
    });
  };

  const triggerInitiateCall = () => {
    clearInputTrigger(false);
    dispatch(getMyInitiateSecurityDevices('AA')).then(response => {
      const qrCodeblock = response.body.data.split('    ')[2];
      const qrCodeKey = qrCodeblock.substring(7, qrCodeblock.length - 3);
      dispatch(
        openModal('AdvancedSecurityQRModal', { qrCodeValuePassed: qrCodeKey })
      );
    });
  };

  const triggerInitiateYubikeyCall = () => {
    dispatch(getInitiateYubiKeyDevices('YK')).then(response => {



      const body = response.body;
      const publicKey = eval('(' + response.body.data.slice(12).slice(0, -3) + ')');

      navigator.credentials.create({
          publicKey
        })
        .then(function (newCredentialInfo) {
          const rawId = newCredentialInfo.id;
          let clientData = String.fromCharCode.apply(
            null,
            new Uint8Array(newCredentialInfo.response.clientDataJSON)
          );
          const keyData = new Int8Array(
            newCredentialInfo.response.attestationObject
          ).toString();
          const combined = clientData + '::' + keyData + '::' + rawId;


          dispatch(getCompleteYubiKeyDevices(combined, 'YK')).then(response => {

            closeAdvancedSecurityModal();
          });

          return Promise.resolve({
            body: body,
            response,
          });
        });
    });
  };

  return (
    <Modal
      name="AdvancedSecurityModal"
      className="modal-sm advanced-security-modal"
      disableCloseButton={true}
    >
      <h2 className="h1 modal-title">
        <Content type="Modals" id="RegisterSecurityMethod" />
      </h2>
      <p className="advanced-security-modal-desc">
        {' '}
        Additional advanced security options are now available to be used as a second factor of authentication during the login process. Only one device can be registered per method.
      </p>
      <p className="advanced-security-modal-select">
        {' '}
        Please select one of the following methods to register.
      </p>
      {Array.isArray(securityDevices) && (
        <>
          {securityDevices.slice().reverse().map((device, index) => (
            <div className="advanced-security-modal-radio-buttons">
              {device === 'YK' && (
                <div>
                  <span className="buttons-section-desc">
                    <InputRadio
                      id="yubikey"
                      label="Security key"
                      value="yubikey"
                      onClick={() => {
                        dispatch(triggerInitiateYubikeyCall);
                      }}
                    />
                  </span>
                  <span className="buttons-section-icon">
                    <Icon name="yubikey" />
                  </span>
                </div>
              )}

              {index === 1 && <hr />}
              {device === 'AA' && (
                <div className='break'>
                  <span className="buttons-section-desc2">
                    <InputRadio
                      id="authApp"
                      label="Authenticator App"
                      value="authApp"
                      onClick={triggerInitiateCall}
                    />
                  </span>
                  <span className="buttons-section-icon2">
                    <Icon name="smartPhone" />
                  </span>
                </div>
              )}


            </div>
            
          ))}
        

        </>
      )}

      {propurl && propurl.pdf && (
        <div>
          <p>Click <a href={propurl.pdf.data} target="_blank">here</a> for registration instructions.</p>
        </div>
      )}

    </Modal>
  );
};

const mapStateToProps = ({ modal }): Object => ({ modal });

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSecurityModal);

// @flow

// Vendors
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEventListener } from '../../../hooks';
import classnames from 'classnames';

// Actions
import { openModal } from '../../../reducers/modal/modalActions';

// Components
import { LoadingDots } from '../../common';

// Styles
import './payment-frame.scss';
import {
  getAddPaymentAccountFrameUrl,
  getEditPaymentAccountFrameUrl,
  getPaymentFormFrameUrl,
} from '../../../reducers/payment/paymentActions';

export const PaymentFrameTypes = {
  ADD_ACCOUNT: 'AddAccount',
  EDIT_ACCOUNT: 'EditAccount',
  PAYMENT_FORM: 'PaymentForm',
};

// Types
type Props = {
  className?: string,
  type?: string,
  src?: string,
  onSuccess?: Function,
  onCancel?: Function,
  onError?: Function,
  ignoreFrameError?: boolean,
};

const PaymentFrame = ({
  type,
  src,
  className,
  onSuccess,
  onCancel,
  onError,
  ignoreFrameError,
}: Props) => {
  const dispatch = useDispatch();

  const [url, setUrl] = useState();

  const paymentUrlRequestActions = {
    [PaymentFrameTypes.ADD_ACCOUNT]: getAddPaymentAccountFrameUrl,
    [PaymentFrameTypes.EDIT_ACCOUNT]: getEditPaymentAccountFrameUrl,
    [PaymentFrameTypes.PAYMENT_FORM]: getPaymentFormFrameUrl,
  };

  useEffect(() => {
    if (src) setUrl(src);
    if (type) dispatch(paymentUrlRequestActions[type]()).then(setUrl);
  }, [src, type]);

  const receiveFrameMessage = event => {
    // Notes: Action Statuses from PaymentFrame
    // public enum PaymentAccountActionStatus
    // {
    //   AddMode, – payment method input form displayed
    //   EditMode, – payment method edit form displayed
    //   PaymentAccountAdded, – payment method successfully added
    //   PaymentAccountCached, – payment method added to cache
    //   UpdateComplete, – payment method successfully updated
    //   Cancel, – user has canceled the add/edit operation
    //   Timeout, – the iFrame has timed out
    //   Error – an error has been returned from the iFrame host server
    //   }
    if (!event || typeof event.data !== 'string') return;
    const status = event.data.toLowerCase();
    if (
      !status &&
      (event.origin !== 'https://microservices-uat.cdc.nicusa.com' ||
        event.origin !== 'http://localhost:6060')
    )
      return;

    // eslint-disable-next-line no-console
    console.log('Status From iFrame:', status);

    if (status === 'error' || status === 'timeout') {
      if ((status === 'error' && !ignoreFrameError) || status === 'timeout')
        dispatch(openModal('ServerErrorModal'));
      if (typeof onError === 'function') onError(status);
    }

    if (typeof onCancel === 'function' && status === 'cancel') onCancel(status);

    if (
      typeof onSuccess === 'function' &&
      (status === 'updatecomplete' ||
        status === 'paymentaccountadded' ||
        status === 'paymentaccountcached')
    )
      onSuccess(status);
  };

  useEventListener('message', receiveFrameMessage);

  if (!url) return <LoadingDots />;

  return (
    <div className={classnames('payment-frame', className)}>
      <iframe
        title="NIC Payment Provider Frame"
        id="PaymentFrame"
        name="PaymentFrame"
        src={url}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  );
};

export default PaymentFrame;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as DriverLicense } from '../../../assets/service-icons/driver-license.svg';
import { ReactComponent as ProfessionalLicense } from '../../../assets/service-icons/professional-license.svg';
import { ReactComponent as agencyApp } from '../../../assets/service-icons/agency-app.svg';
import { ReactComponent as VoterRegistration } from '../../../assets/service-icons/voter-registration.svg';
import { ReactComponent as VehicleRegistration } from '../../../assets/service-icons/vehicle-registration.svg';
import { ReactComponent as BoatRegistration } from '../../../assets/service-icons/boat-registration.svg';
import { ReactComponent as Plus } from '../../../assets/icons/DIS_plus.svg';

// Styles
import './service-icon.scss';

type Props = {
  type: string,
  className?: string,
};

const icons = {
  'driver-license': DriverLicense,
  'professional-license': ProfessionalLicense,
  'agency-app': agencyApp,
  'voter-registration': VoterRegistration,
  'vehicle-registration': VehicleRegistration,
  'boat-registration': BoatRegistration,
  plus: Plus,
};

const ServiceIcon = ({ type, className }: Props) => {
  if (!type) return null;

  const Icon = icons[type];

  return (
    <div className={classnames(`service-icon service-icon-${type}`, className)}>
      <Icon role="presentation" />
    </div>
  );
};

export default ServiceIcon;

// @flow

// Data
import { SITE_DATA } from '../data';

export default {
  EXCEPTIONS: {
    DEFAULT: (ref: string) => ({ id: 'Exception_Default', args: [ref] }),
    DEFAULT_REFRESH: { id: 'Exception_RefreshPage' },
    USER_NF: { id: 'Exception_UserNotFound' },
    PROFILE_NF: { id: 'Exception_LicenseNotFound' },
    AGENCY_NF: { id: 'Exception_AgencyNotFound' },
    APPLICATION_NF: { id: 'Exception_ApplicationNotFound' },
    CONTACT_NF: 'Contact not found.', // NOT IN USE
    SUBSCRIPTION_NF: 'Subscription not found.', // NOT IN USE
    ADDRESS_NF: 'Address not found.', // NOT IN USE
    TRANSACTION_NF: 'Transaction logs not found for user.', // NOT IN USE
    QUESTION_NF: 'Question/answer not found.', // NOT IN USE
    USER_DEACTIVATED: `User is deactivated. please contact your organization’s support team for assistance.`, // NOT IN USE - Routing to "This is awkward..." page
    USER_EXISTS: { id: 'Exception_UserEmailExists' },
    USER_WORK_EMAIL_IN_USE: { id: 'Exception_WorkEmailInUse' },
    USER_RECORD_EXISTS: { id: 'Exception_UserRecordExists' },
    USER_TOAS_FAIL: 'User failed driver license validation.', // NOT IN USE - Using page-level alert and route to IDology
    USER_LOCKED: 'User is locked.', // NOT IN USE - Showing lockout modal
    USER_EXCEED_RESET: 'Password reset attempts exceeded.', // NOT IN USE - Showing lockout modal
    USER_IDOLOGY_FAIL: 'User failed IDology validation.', // NOT IN USE - Routing to "This is awkward..." page
    IDOLOGY_FAIL_ERROR: (arg: string) => ({
      id: 'Exception_IDologyFail',
      args: [arg],
    }), // TODO: Discuss with UX plan to handle IDology mismatch errors.
    USER_BLACKLISTED: `User is blacklisted. For assistance, please contact your organization’s support team for assistance.`, // NOT IN USE - Routing to "You've been blocked." page
    CONTACT_NOT_VERIFIED: { id: 'Exception_ContactNotVerified' },
    QUESTION_DUPE: { id: 'Exception_SecurityQuestionDupe' },
    INCORRECT_ANSWERS: { id: 'Exception_IncorrectAnswers' },
    OTP_VERIFY: { id: 'Exception_OTPVerify' },
    OTP_EXPIRED: { id: 'Exception_OTPExpired' },
    MESSAGE_EXCEEDED: { id: 'Exception_MessageExceeded' },
    INVALID_CREDENTIALS: { id: 'Exception_InvalidCredentials' },
    PASSWORD_HISTORY: { id: 'Exception_PasswordHistory' },
    PERSONAL_CREDENTIALS: { id: 'Exception_PersonalCredentials' },
    PAYMENT_FAILED: { id: 'Exception_PaymentFailed' },
    OTP_ADDRESS_FAIL: (arg: string) => ({
      id: 'Exception_OTPSendFail',
      args: [arg, arg.includes('@') ? 'email address' : 'phone number'],
    }),
    VERIFY_APP_PROFILE_FAIL: { id: 'Exception_VerifyAppLinkFail' },
    NOT_ENROLLED: { id: 'Not_Enrolled' },
    PWD_LESS_ONE_DAY: { id: 'PasswordLessThanOneDay' },
    PERSONAL_ATTR_ERROR: { id: 'Personal_Attr_Error' },
  },
  FIELDS: {
    REQUIRED: { id: 'Field_Required' },
    REQUIRED_YES_NO: { id: 'Field_YesNoRequired' },
    MIN: { id: 'Field_Min' },
    MAX: { id: 'Field_Max' },
    MIN_LENGTH: (arg: string) => ({ id: 'Field_MinWithLength', args: [arg] }),
    MAX_LENGTH: (arg: string) => ({ id: 'Field_MaxWithLength', args: [arg] }),
    INVALID_CHARS: { id: 'Field_InvalidChars' },
    SORRY: { id: 'Field_Sorry' },
    NO_SPEC_CHAR: { id: 'Field_NoSpecialChars' },
    ALPHANUMERIC_ONLY: { id: 'Field_AlphanumericOnly' },
    PWSTRENGTH: { id: 'Field_PasswordStrength' },
    METADATAURL: {id: 'Field_MetadataURL'},
    METADATAURLREQ: {id: 'Field_MetadataURL_Required'},
    METADATAREQ: {id: 'Field_Metadata_Required'},
    PW_INVALID_SPECIAL_CHARACTER: { id: 'Field_Password_Invalid_Special_Char' },
    PW_INVALID_SPECIAL_CHARACTER_ACCOUNTS: {
      id: 'Field_Password_Invalid_Special_Char_Accounts',
    },
    NUMERIC_ONLY: { id: 'Field_NumericOnly' },
    ALPHABETICAL_ONLY: { id: 'Field_AlphabeticalOnly' },
    REQ_DL_ADDR: { id: 'Field_RequireLicenseOrAddress' }, // NEED CONTEXT - TODO: Sync w/ back-end to determine the context for this error.
    FORMAT: { id: 'Field_IncorrectFormat' }, // NEED CONTEXT - TODO: Sync w/ back-end to determine the context for this error.
    SPEC_CHAR: (arg: string) => ({
      id: 'Field_SpecialCharacters',
      args: [arg],
    }),
    DIGITS: (arg: string) => ({ id: 'Field_ExactDigits', args: [arg] }),
    SELECT_OPTION: { id: 'Field_SelectOption' },
    DATE: {
      FORMAT: { id: 'Field_DateFormat' },
      PAST: { id: 'Field_DatePast' },
    },
    ALLOWED_LIST: (arg: string) => ({ id: 'Field_AllowedList', args: [arg] }),
    EMAIL: {
      INVALID: { id: 'Field_EmailInvalid' },
      REQUIRED: { id: 'Field_EmailRequired' },
    },
    EMAIL_MATCHING: {
      REQUIRED: { id: 'Field_EmailMatchingRequired' },
    },
    PASSWORD: {
      MIN: { id: 'Field_PasswordMin' },
      MAX: { id: 'Field_PasswordMax' },
      REQUIRED: { id: 'Field_PasswordRequired' },
    },
    PASSWORD_MATCHING: {
      MATCHES: { id: 'Field_MatchingPasswordMismatch' },
      REQUIRED: { id: 'Field_MatchingPasswordRequired' },
    },
    NEW_PASSWORD: {
      REQUIRED: { id: 'Field_NewPasswordRequired' },
      NO_MATCH: { id: 'Field_NewPasswordUnique' },
    },
    CURRENT_PASSWORD: {
      REQUIRED: { id: 'Field_CurrentPasswordRequired' },
    },
    CONTACT_CODE: {
      MIN: { id: 'Field_ContactCodeMin' },
      REQUIRED: { id: 'Field_ContactCodeRequired' },
      AUTH: {id: 'Field_ContactCodeAuthAppRequired'},
    },
    PHONE: {
      VALID: { id: 'Field_PhoneValid' },
    },
    PHONE_TYPE: {
      REQUIRED: { id: 'Field_PhoneTypeRequired' },
    },
    FIRST_NAME: {
      REQUIRED: { id: 'Field_FirstNameRequired' },
    },
    LAST_NAME: {
      REQUIRED: { id: 'Field_LastNameRequired' },
    },
    CAPPSID: {
      REQUIRED: { id: 'Field_CappsIdRequired' },
    },
    DRIVER_LICENSE: {
      MIN: { id: 'Field_DriverLicenseMin' },
      REQUIRED: { id: 'Field_DriverLicenseRequired' },
    },
    AUDIT_NUMBER: {
      MIN: { id: 'Field_AuditNumberMin' },
      REQUIRED: { id: 'Field_AuditNumberRequired' },
    },
    DOB: {
      MIN: { id: 'Field_DOBMin' },
      MAX: { id: 'Field_DOBMax' },
      REQUIRED: { id: 'Field_DOBRequired' },
    },
    CREDITCARD: {
      NAME_ON_CARD: {
        REQUIRED: { id: 'Field_CC_NameRequired' },
      },
      NUMBER: {
        VALID: { id: 'Field_CC_NumberValid' },
        REQUIRED: { id: 'Field_CC_NumberRequired' },
      },
      EXPIRATION: {
        MIN: { id: 'Field_CC_ExpirationMin' },
        REQUIRED: { id: 'Field_CC_ExpirationRequired' },
      },
      CVV: {
        VALID: { id: 'Field_CC_CVVValid' },
        REQUIRED: { id: 'Field_CC_CVVRequired' },
      },
    },
    ACH: {
      NAME_ON_CHECK: {
        REQUIRED: { id: 'Field_ACH_NameOnCheckRequired' },
      },
      ABA_ROUTING: {
        REQUIRED: { id: 'Field_ACH_ABARoutingRequired' },
        CONFIRM: { id: 'Field_ACH_ABARoutingConfirm' },
      },
      ACCOUNT_NUMBER: {
        REQUIRED: { id: 'Field_ACH_AccountNumberRequired' },
        CONFIRM: { id: 'Field_ACH_AccountNumberConfirm' },
      },
    },
    LAST_FOUR_SSN: {
      MIN: { id: 'Field_LastFourSSNMin' },
      REQUIRED: { id: 'Field_LastFourSSNRequired' },
    },
    TERMS: {
      REQUIRED: { id: 'Field_TermsRequired' },
    },
    AUTH_TYPE: {
      REQUIRED: { id: 'Field_Auth_TypeRequired' },
    },
    META_DATA_URL: {
      REQUIRED: { id: 'Field_Metadata_URLRequired' },
    },
    META_DATA_FILE: { REQUIRED: { id: 'Field_Metadata_FileRequired' } },
    ADDRESS: {
      STREET_ADDRESS: {
        REQUIRED: { id: 'Field_Address_StreetAddressRequired' },
      },
      CITY: {
        REQUIRED: { id: 'Field_Address_CityRequired' },
      },
      STATE: {
        REQUIRED: { id: 'Field_Address_StateRequired' },
      },
      ZIP_FIVE: {
        DIGITS: { id: 'Field_Address_ZipFiveDigits' },
        REQUIRED: { id: 'Field_Address_ZipFiveRequired' },
      },
      COUNTRY: {
        REQUIRED: { id: 'Field_Address_CountryRequired' },
      },
    },
    SECURITY_QUESTIONS: {
      ANSWER: {
        MIN: { id: 'Field_SecurityQuestions_AnswerMin' },
        REQUIRED: { id: 'Field_SecurityQuestions_AnswerRequired' },
      },
      QUESTION: {
        REQUIRED: { id: 'Field_SecurityQuestions_QuestionRequired' },
      },
    },
    IDENTITY_QUESTION: {
      REQUIRED: { id: 'Field_IdentityQuestionRequired' },
    },
    MFA_OPTIONS: {
      REQUIRED: { id: 'Field_MFAOptionsRequired' },
    },
    PROGRAM_SELECT: {
      REQUIRED: { id: 'Field_ProgramSelectRequired' },
    },
    LICENSE_SELECT: {
      REQUIRED: { id: 'Field_LicenseSelectRequired' },
    },
  },
};

//@flow

// Vendors
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

// Components
import * as Transitions from '../../Transitions';

// Helpers
import { focusFirstElement } from '../../../../lib/helpers';

// Types
type Props = {
  stepId: string,
  parentRoute: string,
  steps: Array<Object>,
  defaultStepId: string,
  transition: string,
  onStepChange?: Function,
  onStepChanged?: Function,
  showDefaultURLPath?: boolean,
  redirectDefaultToStep?: boolean,
};
type State = {
  activeStepId: string,
  transitionDirection: string,
};

class StepperWithRoutesStep extends Component<Props, State> {
  static defaultProps = {
    transition: 'SlideTransition',
  };

  state = {
    activeStepId: '',
    transitionDirection: 'forward',
  };

  get activeStep() {
    return this.getStepById(this.state.activeStepId);
  }

  getStepById = stepId => this.props.steps.find(({ id }) => `${id}` === stepId);

  get shouldRedirectToDefaultStep() {
    const { stepId, redirectDefaultToStep } = this.props;
    return !stepId && redirectDefaultToStep;
  }

  get shouldRedirectToParentRoute() {
    const {
      stepId,
      defaultStepId,
      showDefaultURLPath,
      redirectDefaultToStep,
    } = this.props;
    return (
      (stepId === `${defaultStepId}` &&
        !showDefaultURLPath &&
        !redirectDefaultToStep) ||
      !this.activeStep
    );
  }

  get shouldRedirectToRestrictAccessPath() {
    const { activeStep } = this;
    return (
      activeStep &&
      activeStep.restrictAccess &&
      typeof activeStep.restrictAccess === 'function'
    );
  }

  get shouldRedirect() {
    const { defaultStepId, parentRoute } = this.props;

    if (this.shouldRedirectToDefaultStep)
      return `${parentRoute}/${defaultStepId}`;
    if (this.shouldRedirectToParentRoute) return parentRoute;
    if (this.shouldRedirectToRestrictAccessPath)
      return this.activeStep.restrictAccess();

    return false;
  }

  static getDerivedStateFromProps(props, state) {
    const {
      activeStepId: prevStepId,
      transitionDirection: prevTransitionDirection,
    } = state;
    const { stepId, steps, defaultStepId } = props;

    const getStepIndexById = stepId =>
      steps.findIndex(({ id }) => id === stepId);

    const nextStepId = stepId || defaultStepId;
    const prevStepIndex = getStepIndexById(prevStepId);
    const nextStepIndex = getStepIndexById(nextStepId);
    const diffStepIndex = nextStepIndex - prevStepIndex;

    const nextTransitionDirection =
      diffStepIndex === 0
        ? prevTransitionDirection
        : diffStepIndex >= 0
        ? 'forward'
        : 'reverse';

    return {
      activeStepId: nextStepId,
      transitionDirection: nextTransitionDirection,
    };
  }

  handleStepChange() {
    const { onStepChange } = this.props;
    onStepChange && onStepChange();
  }

  handleStepChanged() {
    const { onStepChanged } = this.props;
    focusFirstElement();
    onStepChanged && onStepChanged();
  }

  render() {
    const redirect = this.shouldRedirect;
    if (redirect) return <Redirect to={redirect} />;

    const { activeStepId, transitionDirection } = this.state;
    const { transition } = this.props;
    const Transition = Transitions[transition];
    const { component: StepComponent } = this.activeStep;

    return (
      <TransitionGroup>
        <Transition
          key={activeStepId}
          className={classnames('stepper-step', transitionDirection)}
          onEnter={this.handleStepChange.bind(this)}
          onEntered={this.handleStepChanged.bind(this)}
        >
          <StepComponent
            // We must arbitrarily pass in an object (`activeStep`) to our component to avoid render blocking nested sub-routes
            steps={this.props.steps}
            activeStepId={this.activeStep.id}
          />
        </Transition>
      </TransitionGroup>
    );
  }
}

export default StepperWithRoutesStep;

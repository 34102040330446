//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Content from '../../Content/Content';

// Styles
import './cvv-modal.scss';

const CVVModal = () => (
  <Modal name="CVVModal" className="cvv-modal">
    <div className="modal-graphic">
      <Content type="Modals">
        {modalContent => (
          <img
            src={publicURL('cvv.svg')}
            alt={modalContent['CVVModalImageAlt']}
          />
        )}
      </Content>
    </div>
  </Modal>
);

export default CVVModal;

import { bodyHasError } from '../lib/validation/serverErrors';

export * from './requests';

const defaultHeaders = {
  'Content-Type': 'application/json',
};

  

 
export const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
};

export const REQUEST_TYPES_ARRAY = Object.keys(REQUEST_TYPES);

export const REQUEST_LOCATIONS = {
  IP: 'IP',
  MGMW: 'MGMW',
  FORGEROCK: 'FORGEROCK',
  WEBAGENT: 'WEBAGENT',
};

export const isServerError = ({ body, response }) => {
  let passwordExpired = false;
  if (body.callbacks) {
    body.callbacks.forEach(function(callback) {
      if (callback.type === 'TextOutputCallback') {
        callback.output.forEach(function(eachoutput) {
          if (eachoutput.value.includes('Your password has expired')) {
            passwordExpired = true;
          }
        });
      }
    });
  }
  if (passwordExpired) return true;

  if (!response || response.status >= 500 || response.status === 404)
    return true;
  if (
    bodyHasError(body, {
      errorCodes: [
        'MGMW_EX_4000',
        'MGMW_EX_4001',
        'MGMW_EX_3100',
        'MGMW_EX_3101',
        'MGMW_E_0005',
        'MGMW_EX_4003',
      ],
    })
  )
    return true;
  return false;
};

export async function request(options) {
  //get the user Oauth header
  const guid =  window.sessionStorage.getItem("guid");
  const userName = window.sessionStorage.getItem("userName");
  const uid = window.sessionStorage.getItem("_id");

  const userHeaders = {
    guid: guid,
    userName: userName,
    _id: uid
  }

  const url = getAPIURL(options.location, options.url);

  

  const requestTypes = {
    [REQUEST_TYPES.GET]: options => {
      return fetchRequest(url, {
        headers: { ...defaultHeaders, ...userHeaders, ...options.headers },
        credentials: 'include',
      });
    },
    [REQUEST_TYPES.POST]: options => {
      return fetchRequest(url, {
        method: REQUEST_TYPES.POST,
        body: JSON.stringify(options.body),
        headers: { ...defaultHeaders, ...userHeaders, ...options.headers },
        credentials: 'include',
      });
    },
  };
  const response = await requestTypes[options.APIMethod](options);

  const body = await response.json();

  if (body.errors) body.errors = mapCustomErrors(body); // TODO: have a better strategy for handling these on the backend

  if (
    isServerError({ body, response }) ||
    response.status === 403 ||
    response.status === 402
  ) {
    return Promise.reject({ response, body });
  }


  if (response.headers)
  
    response.headersObject = responseHeadersToObject(response.headers);

  return Promise.resolve({ response, body });
}

export function getAPIURL(location = REQUEST_LOCATIONS.MGMW, url) {
  if (!location) return url;
  const options = {
    [REQUEST_LOCATIONS.MGMW]: process.env.REACT_APP_API_URL,
    [REQUEST_LOCATIONS.FORGEROCK]: process.env.REACT_APP_FORGEROCK_URL,
    [REQUEST_LOCATIONS.WEBAGENT]: process.env.REACT_APP_WEBAGENT_URL,
  };
  
  return encodeURI(`${options[location] || location}${url || ''}`);
}

function fetchRequest(url, options) {
  return fetch(url, options).catch(error => {
    // eslint-disable-next-line no-console
    console.error(
      'There has been a problem with your fetch operation: ' + error.message
    );
    throw error;
  });
}

function responseHeadersToObject(headers) {
  let headersObject = {};
  for (let header of headers.entries())
    headersObject[header[0].toLowerCase()] = header[1];
  return headersObject;
}

function mapCustomErrors(body) {
  if (
    bodyHasError(body, {
      exactError:
        'MGMW_EX_0003|||Constraint Violation  The provided new password was found in the password history for the user',
    })
  )
    return { ...body.errors, ERROR: ['MGMW_EX_1002'] };

  if (
    bodyHasError(body, {
      exactError: 'MGMW_EX_1005|||cannot contain user attribute',
    })
  )
    return { ...body.errors, ERROR: ['MGMW_EX_PC'] };

  if (bodyHasError(body, { errorCodes: ['MGMW_EX_1001'] })) {
    body.errors.ERROR.splice(body.errors.ERROR.indexOf('MGMW_EX_1001'), 1);
    return { ...body.errors, password: ['MGMW_EX_1001'] };
  }

  return body.errors;
}

// @flow

import { REQUEST_TYPES } from '.';

export const  getUserPasswordExpiringDate = (
  userEmail?: string
): RequestOptions => ({
  type: `[API] Password Expiration Date`,
  url: `/user/ui/pwd/expiry/notification/${userEmail || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});
// import LinkService from '../LinkService/LinkService.es.js';

const AgencyName = 'Contralor de Cuentas Públicas de Texas';
const AgencyShortName = 'CCPA';

export default {
  AgencyName,
  AgencyShortName,
  cpaln_AppName: 'Agencia de enlaces CPA',
  cpaln_AppShortName: 'Agencia de enlaces CPA',
  cpaln_LinkService_LookupTitle: `Necesitamos un poco más de información para vincular su agencia.`,
  cpaln_LinkService_SuccessTitle: '¡Éxito! Su agencia está vinculada a su cuenta.',
  cpaln_LinkService_ConfirmTitleDefault: 'Se ha localizado su agencia.',
  cpaln_LinkService_ConfirmDescriptionDefault: `Confirme sus registros asociados con su cuenta de agencia`,
  cpaln_LinkService_AddPhoneDescription: `Para mayor seguridad, se requiere un número de teléfono válido que pueda recibir mensajes de texto (SMS) para verificar su identidad. Marque la casilla a continuación si desea utilizar su número personal.`,
  cpaln_LookupId_Title: 'Vamos a empezar por la búsqueda de su agencia elegible para renovar.',
  cpaln_LinkService_ConfirmTitleLinked: `Esta licencia ya esta conectada.`,
  cpaln_LinkService_ConfirmDescriptionLinked: `Esta licencia esta conectada y puede encontrarse en su cuadro de mandos.`
};

//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Content } from '..';
import { DateFormatter } from '../common';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { renewTDLRLicense } from '../../reducers/agency/tdlr/tdlrActions';

// Types
import { type TodoItemData } from './TodoList';
import todoTypeMap from './todoTypeMap';

const TodoItem = ({
  data: { date, data, type, expired },
}: {
  data: TodoItemData,
}) => {
  const { action, actionWord, licensingEntity } = todoTypeMap[type];

  const dispatch = useDispatch();

  // Handle renew click
  function handleRenewClick() {
    switch (licensingEntity) {
      case 'tdlr':
        dispatch(renewTDLRLicense(data));
        break;
      default:
        dispatch(push(action));
    }
  }

  return (
    <div className="todo-list-item">
      <div className="todo-list-item-content">
        <div className="todo-list-item-content-primary">
          <Content id={`TodoListAction_${actionWord}`} type="Todo" />{' '}
          {type === 'CYBER_ATTESTATION'
            ? <Content id={`TodoList_Attestation_Primary`} type="Cybersecurity" />
            : data.licenseType}
          {data && data.fiscalYear ? (
            <>
              (
              <Content id="FiscalYear" type="Cybersecurity" />
              &nbsp;{data.fiscalYear})
            </>
          ) : (
            ''
          )}
        </div>
        <div className="todo-list-item-content-secondary">
          <Content
            id={`TodoList_LicensingEntity_${licensingEntity}`}
            type="Todo"
          />
        </div>
      </div>

      <div>
        <div className="todo-list-item-due">
          <span className="todo-list-item-due-title">
            <Content
              id={expired ? 'TodoListState_Expired' : 'TodoListState_Todo'}
              type="Todo"
            />
            &nbsp;
          </span>
          <span className="todo-list-item-due-date">
            {<DateFormatter date={date} />}
          </span>
        </div>
      </div>

      <div className="todo-list-item-action">
        <ButtonPrimary onClick={() => handleRenewClick()}>
          <Content id={`TodoListAction_${actionWord}`} type="Todo" />
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default TodoItem;

//@flow

// Vendors
import React from 'react';

// Components
import { Modal } from '..';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';
import Content from '../../Content/Content';



// Styles


const AccessDenied = () => (
    <Modal name="AccessDenied" className="modal-sm lockout-modal">
      <h2 className="h1 modal-title">
        Access denied.
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <LockoutSVG role="presentation" />
        </div>
        <p>
          To protect your personal Information, access to your account has been temporarily denied.
        </p>
        <p>
          Please contact your administrator
        </p>
      </div>
    </Modal>
  );



export default AccessDenied;

//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Content from '../../Content/Content';

// Styles
import './check-modal.scss';

const CheckModal = () => (
  <Modal name="CheckModal" className="check-modal">
    <div className="modal-graphic">
      <Content type="Modals">
        {modalContent => (
          <img
            src={publicURL('check.svg')}
            alt={modalContent['CheckModalImageAlt']}
          />
        )}
      </Content>
    </div>
  </Modal>
);

export default CheckModal;

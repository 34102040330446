// @flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputRadio, InputRadioInverse, Content } from '../../..';

// Styles
import './input-radio-group.scss';

// Types
type RadioOption = {
  id: string,
  label: string,
  value: any,
  className?: string,
};
type Props = {
  className?: string,
  label?: string | Node,
  name: string,
  onChange: Function,
  options: Array<RadioOption>,
  optionValueKey?: string,
  inverse: boolean,
  inline: boolean,
  spacedEvenly: boolean,
  formikProps: FormikProps,
};

/**
 * @visibleName Radio Group
 */
const InputRadioGroup = ({
  className,
  label,
  name,
  onChange,
  options,
  optionValueKey = '',
  inverse,
  inline,
  spacedEvenly,
  formikProps,
}: Props) => {
  const {
    handleChange,
    getFieldValue,
    getFieldError,
    getFieldStatus,
    setFieldValue,
    submitCount,
  } = formikProps;
  const localError = getFieldError(name);
  const serverError = (getFieldStatus('serverErrors') || {})[name];
  const errorMap =
    serverError && typeof serverError.id === 'string'
      ? serverError
      : localError;
  const showError = errorMap && submitCount > 0;
  const InputComponent = inverse ? InputRadioInverse : InputRadio;

  const onChangeHandler = (event: any) => {
    const selectedOptionValue = event.target.value || '';

    if (handleChange) handleChange(event);
    if (onChange) onChange(event);
    if (!optionValueKey) return;

    let selectedOptionObject =
      options.find(
        option => option && option.value[optionValueKey] === selectedOptionValue
      ) || {};
    if (setFieldValue) setFieldValue(name, selectedOptionObject.value);
  };

  return (
    <div
      className={classnames('input-radio-group', className, {
        'input-radio-group-inline': inline,
        'input-radio-group-inline-spaced-evenly': spacedEvenly,
        hasError: showError,
        hasLabel: label,
      })}
    >
      {label && <p className="input-radio-group-label">{label}</p>}

      {options.map(option => {
        const valueIsObject = () =>
          optionValueKey && typeof option.value === 'object';

        const value = valueIsObject()
          ? option.value[optionValueKey]
          : option.value;

        const isChecked = () =>
          valueIsObject()
            ? getFieldValue(`${name}.${optionValueKey}`) ===
              option.value[optionValueKey]
            : getFieldValue(name) === option.value;

        return (
          <InputComponent
            id={option.id}
            key={option.id}
            className={option.className}
            name={name}
            label={option.label}
            value={value}
            onChange={onChangeHandler.bind(this)}
            checked={isChecked()}
            hideError={true}
            formikProps={formikProps}
          />
        );
      })}

      {showError && (
        <div className="input-radio-group-error">
          <span>
            <Content type="Errors" id={errorMap.id} />
          </span>
        </div>
      )}
    </div>
  );
};

export default InputRadioGroup;

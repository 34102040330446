//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputText, InputNumeric, CVVModal } from '../../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-group-credit-card-payment.scss';

// Types
type Props = {
  formikProps: FormikProps,
  openModal: Function,
  className?: string,
};

/**
 * @visibleName Credit Card Payment
 */
const InputGroupCreditCardPayment = ({
  formikProps,
  openModal,
  className,
}: Props) => {
  if (!formikProps.values.creditcard) formikProps.values.creditcard = {};

  return (
    <>
      <InputGroup
        className={classnames('input-group-credit-card-payment', className)}
      >
        <InputText
          className="input-credit-card-name"
          label="Name on card"
          labelPosition="above"
          name="creditcard.name"
          formikProps={formikProps}
        />
        <InputText
          className="input-credit-card-number"
          label="Card number"
          labelPosition="above"
          name="creditcard.number"
          mask={[...Array(19)].map(() => /\d/)}
          formikProps={formikProps}
        />
        <div className="flex-row-wrap">
          <div className="flex-item">
            <InputText
              className="input-credit-card-expiration"
              label="Exp. date"
              labelPosition="above"
              name="creditcard.expiration"
              mask={[/\d/, /\d/, '/', /\d/, /\d/]}
              formikProps={formikProps}
              placeholder="MM/YY"
            />
          </div>
          <div className="flex-item">
            <InputNumeric
              className="input-credit-card-cvv"
              label="CVV number"
              labelPosition="above"
              name="creditcard.cvv"
              mask={[/\d/, /\d/, /\d/, /\d/]}
              formikProps={formikProps}
              helpText={
                <TextButton type="button" onClick={() => openModal('CVVModal')}>
                  What&apos;s this?
                </TextButton>
              }
            />
          </div>
        </div>
      </InputGroup>
      <CVVModal />
    </>
  );
};

export default InputGroupCreditCardPayment;

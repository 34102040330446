//@flow

// Vendors
import React from 'react';

// Components
import { Modal } from '../..';
import { ButtonPrimary, ButtonDanger, ButtonOutline } from 'texkit/dist/components/Buttons';
import Content from '../../Content/Content';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { closeModal } from '../../../../reducers/modal/modalActions';
import { updateContactSubmitRequest } from '../../../../reducers/user/updateContact/updateContactActions';

// Styles
import './delete-phone-modal.scss';

type Props = {
  modal: Object,
  closeModal: Function,
};

const DeletePhoneModal = ({
  modal : { data },
  closeModal,
}) => {
  const dispatch = useDispatch();

  var myValues = (() => data && data.values ? data.values : undefined)();
  var myFormikActions = (() => data && data.formikActions ? data.formikActions : undefined)();
  var myProps = (() => data && data.props ? data.props : undefined)();
  
  const deleteClicked = () => {
    data.values.phone = "";
    dispatch(updateContactSubmitRequest({ values: myValues, formikActions: myFormikActions, props: myProps }));
    closeModal("DeletePhoneModal");
  }

  return (
  <Modal name="DeletePhoneModal" className="delete-phone-modal" disableCloseButton={true}>
    <div className="flex-row">
      <div className="flex-item delete-phone-modal-quicklook delete-phone-modal-content">
        <div
          className="delete-phone-modal-content-overflow"
          tabIndex="0"
        >
          <h2 className="delete-phone-modal-header delete-phone-modal-header-title center">
            <Content type="Modals" id="DeleteNumberTitle" />
          </h2>
          <span className="margin-top-10">
          <Content type="Modals" id="DeleteNumberDescription" />
          </span>

        </div>
      </div>
    </div>
    <div align="center">
      <ButtonOutline onClick={() => closeModal("DeletePhoneModal")} >
        <Content type="Modals" id="Cancel" />
      </ButtonOutline>
      <ButtonDanger onClick={() => deleteClicked()} className="padding-left">
        <Content type="Modals" id="DeleteNumber" />
      </ButtonDanger>
    </div>
  </Modal>
)};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeletePhoneModal);

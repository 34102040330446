//@flow

// Vendors
import React, { Component } from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';
import {
  InputSelectControl,
  InputSelectMenu,
  InputSelectOption,
  InputSelectDropdownIndicator,
  InputSelectMultiValueRemove,
} from './InputSelectComponents';

// Helpers
import {
  isMobileDevice,
  iOSVersion,
  getObjectValueByPath,
} from '../../../../../lib/helpers';

// Styles
import './input-select.scss';

// Types
type Props = {
  name: string,
  className?: string,
  optionLabelKey: string,
  optionValueKey: string,
  emptyOptionLabel: string,
  options: Array<Object>,
  onBlur?: Function,
  onChange?: Function,
  isMulti?: boolean,
  isSearchable?: boolean,
  disabled?: boolean,
  formikProps: FormikProps,
  loadOptions?: Function,
  getOptionLabel?: Function,
  getOptionValue?: Function,
  disableMobilePortal: boolean,
  autoFocus: boolean,
};
type State = {
  portalRendered: boolean,
};

/**
 * @visibleName Select
 */
class InputSelect extends Component<Props, State> {
  inputSelectMenuPortal: ?HTMLElement;
  isMobileDevice: boolean = this.props.disableMobilePortal
    ? false
    : isMobileDevice() && !(iOSVersion() && iOSVersion() < 10);

  static defaultProps = {
    label: '',
    options: [],
    optionLabelKey: 'label',
    optionValueKey: 'value',
    autoFocus: false
  };
  
  state = {
    portalRendered: false,
  };

  selectComponents = {
    Control: InputSelectControl,
    Menu: (props: Object) => (
      <InputSelectMenu {...props} isMobileDevice={this.isMobileDevice} />
    ),
    Option: InputSelectOption,
    DropdownIndicator: InputSelectDropdownIndicator,
    MultiValueRemove: InputSelectMultiValueRemove,
  };

  componentDidMount() {
    if (this.isMobileDevice) {
      this.addSelectMenuPortal();
    }
  }

  componentWillUnmount() {
    if (this.isMobileDevice) {
      this.removeSelectMenuPortal();
    }
  }

  addSelectMenuPortal() {
    if (document.getElementById('input-select-menu-root')) {
      this.inputSelectMenuPortal = document.getElementById(
        'input-select-menu-root'
      );
    } else {
      this.inputSelectMenuPortal = document.createElement('div');
      this.inputSelectMenuPortal.setAttribute('id', 'input-select-menu-root');
      if (this.inputSelectMenuPortal)
        this.inputSelectMenuPortal.classList.add(
          'input-select__menu-mobile-container'
        );
      if (document.body && this.inputSelectMenuPortal)
        document.body.appendChild(this.inputSelectMenuPortal);
    }
    this.setState({ portalRendered: true });
  }

  removeSelectMenuPortal() {
    const inputSelectElementCount = document.querySelectorAll('.input-select')
      .length;
    if (inputSelectElementCount < 2 && inputSelectElementCount > 0) {
      if (document.body && this.inputSelectMenuPortal)
        document.body.removeChild(this.inputSelectMenuPortal);
    }
  }

  get fieldName(): string {
    const { isMulti, name, optionValueKey } = this.props;
    return isMulti ? name : `${name}.${optionValueKey}`;
  }

  get emptyOption() {
    const { optionLabelKey, optionValueKey, emptyOptionLabel } = this.props;
    return {
      [optionLabelKey]: emptyOptionLabel || 'Select...',
      [optionValueKey]: '',
    };
  }

  get options(): Array<Object> {
    const { isMulti, options, optionLabelKey, optionValueKey } = this.props;
    const selectOptions =
      !isMulti && this.emptyOption ? [this.emptyOption, ...options] : options;
    return selectOptions.map(option => ({
      label: option[optionLabelKey],
      value: option[optionValueKey],
    }));
  }

  get value() {
    const {
      isMulti,
      name,
      formikProps,
      optionLabelKey,
      optionValueKey,
      options,
    } = this.props;
    const fieldValue = getObjectValueByPath(formikProps.values, name);

    if (isMulti)
      return fieldValue && fieldValue.length
        ? fieldValue.map(option => ({
            label: option[optionLabelKey],
            value: option[optionValueKey],
          }))
        : [];

    return fieldValue && typeof fieldValue === 'object' && options.length
      ? this.options.find(option => option.value === fieldValue[optionValueKey])
      : options.length
      ? this.options.find(option => option.value === '')
      : fieldValue;
  }

  handleBlur() {
    const { formikProps } = this.props;
    if (formikProps.setFieldTouched)
      formikProps.setFieldTouched(this.fieldName, true);
  }

  handleChange(selectedOptions: any) {
    const { isMulti, formikProps, onChange } = this.props;
    if (isMulti) this.handleChangeMulti(selectedOptions);
    if (!isMulti) this.handleChangeSingle(selectedOptions);
    if (formikProps.setFieldTouched)
      formikProps.setFieldTouched(this.fieldName, true);
    if (onChange) onChange(selectedOptions);
  }

  handleChangeSingle(selectedOption: Object) {
    const { name, options, formikProps, optionValueKey } = this.props;
    let selectedOptionObject: Object = options.length
      ? options.find(option => option[optionValueKey] === selectedOption.value)
      : selectedOption;

    if (!selectedOptionObject && selectedOption.value === '')
      selectedOptionObject = this.emptyOption;
    if (formikProps.setFieldValue)
      formikProps.setFieldValue(name, selectedOptionObject);
  }

  handleChangeMulti(selectedOptions: Array<Object>) {
    const { name, options, formikProps, optionValueKey } = this.props;
    const selectedOptionsArray = selectedOptions.map(selectedOption => {
      if (options.length)
        return options.find(
          option => option[optionValueKey] === selectedOption.value
        );
      return selectedOption;
    });

    if (formikProps.setFieldValue)
      formikProps.setFieldValue(name, selectedOptionsArray);
  }

  render() {
    const { className, isSearchable, ...props } = this.props;
    if (props.loadOptions) {
      props.getOptionLabel = (option: Object) =>
        option[this.props.optionLabelKey];
      props.getOptionValue = (option: Object) =>
        option[this.props.optionValueKey];
    }

    return (
      <InputWrapper
        {...props}
        name={this.fieldName} // Need this configuration for proper field validation
        value={this.value}
        as="Select"
        className={classnames('input-select', className)}
        classNamePrefix="input-select"
        components={this.selectComponents}
        onChange={this.handleChange.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        options={this.options}
        isClearable={false}
        isDisabled={props.disabled}
        tabSelectsValue={false}
        tabIndex={props.disabled ? '-1' : 0}
        isSearchable={isSearchable}
        menuPortalTarget={
          this.isMobileDevice && this.state.portalRendered
            ? this.inputSelectMenuPortal
            : null
        }
        captureMenuScroll={this.isMobileDevice}
        menuShouldBlockScroll={this.isMobileDevice}
        openMenuOnFocus={!this.isMobileDevice}
        autoFocus={props.autoFocus}
      />
    );
  }
}

export default InputSelect;

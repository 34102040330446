//@flow

// Vendors
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { resendOTP } from '../../../../../../reducers/OTP/OTPActions';
import { sendMFAOptionsAgain } from '../../../../../../reducers/login/loginActions';

// Components
import { Content } from '../../../..';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { TextButton } from 'texkit/dist/components/Buttons';

// Types
type Props = {
  sendMFAOptionsAgain: Function,
  login: Object,
};
type State = {
  resendCodeStatus: string,
};

const InputGroupBackCode = ({
  sendMFAOptionsAgain,
}: Props) =>  {

 function handleBack() {
  
  sendMFAOptionsAgain();

}



    return (
      <div
        className={`input-group-code-resend-code input-group-code-resend-code-default`}
      >
       <TextButton
          type="button"
          onClick={handleBack.bind(this)}
        >
         Try Another Method
        </TextButton>
      </div>
    );
  
}
  

const mapStateToProps = ({ login }): Object => ({ login });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ sendMFAOptionsAgain }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroupBackCode);

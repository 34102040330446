export default {
  SiteName: `Texas.gov Digital Identity Solution`,
  SiteNameAbbr: 'TDIS',
  TermsAndConditions: `Términos &amp; Condiciones`,
  HelloSpace: `Hola, `,
  WelcomeWithComma: `Bienvenido(a),`,
  MoreAgenciesComingSoon: `¡Lanzamiento de mas agencias, próximamente!`,
  NoPhoneNumberListed: `No hay número de teléfono en la lista.`,
  StandardTextMessageRatesApply: `Se aplican tarifas estándar de mensajes de texto.`,
};

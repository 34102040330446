// @flow

/**
 * Component to format a passed in date based on the users selected language.
 */

import { selectSelectedLanguage } from '../../../reducers/language/languageSelectors';
import { useSelector } from 'react-redux';
import { makeUTC, getCentralTime } from '../../../lib/datetime';

const dateOnlyOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const dateTimeOptions = {
  ...dateOnlyOptions,
  ...{
    hour: '2-digit',
    minute: '2-digit',
  },
};

type DateFormatterProps = {
  date: string,
  dateOnly?: Boolean,
  fallback?: string,
};

const DateFormatter = function({
  date,
  dateOnly = true,
  fallback = '----',
}: DateFormatterProps) {
  const language = useSelector(selectSelectedLanguage).toLowerCase();
  const centralTime = getCentralTime(makeUTC(date));

  return centralTime !== 'Invalid Date'
    ? centralTime.toLocaleString(
        language === 'es' ? 'es-US' : 'en-US',
        dateOnly ? dateOnlyOptions : dateTimeOptions
      )
    : fallback;
};

export default DateFormatter;

//@flow

// Vendors
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

// Components
import MobileNavToggle from './MobileNavToggle';
import { Container, Logo, Content } from '..';

// Actions
import { signOut } from '../../reducers/auth/authActions';
import { toggleMobileNav } from '../../reducers/mobileNav/mobileNavActions';

// Assets
import { ReactComponent as MobileNavBackgroundSVG } from '../../assets/mobile-nav-background.svg';

// Styles
import './mobile-nav.scss';

// Types
type Props = {
  toggleMobileNav: Function,
  signOut: Function,
  mobileNav: Object,
};
type State = {
  windowScrollY: number,
};

class MobileNav extends Component<Props, State> {
  state = {
    windowScrollY: 0,
  };

  primaryMenuItems = [
    {
      label: <Content type="Labels" id="MyAccount" />,
      url: '/account',
    },
  ];

  secondaryMenuItems = [
    {
      as: 'button',
      label: <Content type="Labels" id="SignOut" />,
      onClick: () => {
        this.props.signOut();
        this.props.toggleMobileNav(false);
      },
    },
  ];

  // selectMenuItems = () => {
  //   const showStar = useSelector(isGovUser);
  //   return showStar;
  // };
  open() {
    if (document.defaultView)
      this.setState({ windowScrollY: document.defaultView.scrollY });
    this.props.toggleMobileNav(true);
  }

  close() {
    this.props.toggleMobileNav(false);
    if (document.defaultView) {
      setTimeout(() => {
        window.scrollTo(0, this.state.windowScrollY);
      }, 5);
    }
  }

  handleToggleClick() {
    const { mobileNav } = this.props;
    return mobileNav.isActive ? this.close() : this.open();
  }

  handleNavItemClick() {
    setTimeout(() => {
      this.props.toggleMobileNav(false);
    }, 10);
  }

  render() {
    const { mobileNav } = this.props;

    return (
      <>
        <MobileNavToggle
          mobileNav={mobileNav}
          onClick={this.handleToggleClick.bind(this)}
        />

        <nav id="mobile-nav" className="mobile-nav" role="navigation">
          <Link
            to="/"
            className="mobile-nav-branding"
            onClick={this.handleNavItemClick.bind(this)}
            aria-label="View My Dashboard"
          >
            <Logo />
          </Link>

          <Container className="mobile-nav-container">
            <ul className="mobile-nav-primary-menu">
              {this.primaryMenuItems.map((item, i) => (
                <li
                  key={`mobile-nav-primary-menu-item-${i}`}
                  className="mobile-nav-primary-menu-item"
                >
                  <NavLink
                    to={item.url}
                    onClick={this.handleNavItemClick.bind(this)}
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>

            <ul className="mobile-nav-secondary-menu">
              {this.secondaryMenuItems.map(({ as: T, label, ...rest }, i) => (
                <li
                  key={`mobile-nav-secondary-menu-item-${i}`}
                  className="mobile-nav-secondary-menu-item"
                >
                  <T {...rest}>{label}</T>
                </li>
              ))}
            </ul>
          </Container>

          <div className="mobile-nav-background">
            <MobileNavBackgroundSVG
              className="mobile-nav-background-graphic"
              role="presentation"
            />
          </div>
        </nav>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ toggleMobileNav, signOut }, dispatch);
const mapStateToProps = ({ mobileNav }) => ({ mobileNav });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileNav));

//@flow

// Vendors
import React, { memo } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

// Components
import { Container, Content, LanguageSwitcher } from '..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Helpers
import SITE_DATA from '../../lib/data/siteData';

// Actions
import { openModal } from '../../reducers/modal/modalActions';

// Assets
import StateOfTexasLogo from '../../assets/state-of-texas-logo.png';

// Styles
import './footer.scss';

// Types
type Props = {
  className?: string,
};

const Footer = ({ className }: Props) => {
  const dispatch = useDispatch();

  return (
    <footer className={classnames('footer', className)} role="contentinfo">
      <Container>
        <div className="footer-copyright">
          <a
            href="https://texas.gov/"
            className="state-of-texas-logo"
            target="_blank"
            rel="noopener noreferrer"
            alt="State of Texas Logo"
          >
            <Content type="Footer" id="StateOfTexasLogoAlt" passIdToChildren={true} >
              {StateOfTexasLogoAlt => (
                <img src={StateOfTexasLogo} alt={StateOfTexasLogoAlt} />
              )}
            </Content>
          </a>
          <Content type="Footer" id="Copyright" />
        </div>
        <nav className="footer-nav" role="navigation">
          <ul className="footer-nav-list">
            <li className="footer-nav-list-item">
              <TextButton
                as="a"
                onClick={() => dispatch(openModal('TermsAndConditionsModal'))}
              >
                <Content type="Common" id="TermsAndConditions" />
              </TextButton>
            </li>
            <li className="footer-nav-list-item">
              <Content type="Footer" id="CallSupport" />{' '}
            </li>
            <li className="footer-nav-list-item">
              <LanguageSwitcher dropdownProps={{ menuPosition: 'top-left' }} />
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
};

export default memo(Footer);

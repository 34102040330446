//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputCode } from '../../..';
import { InputGroupCodeResendCode } from './InputGroupCodeComponents';

// Styles
import './input-group-code.scss';

// Types
type Props = {
  className?: string,
  autoFocus: boolean,
  channelCode: string,
  resendCodeActionCreator?: Function,
  onResendCodeSuccess?: Function,
  formikProps: FormikProps,
};

/**
 * @visibleName Code
 */
const InputGroupCode = ({
  className,
  autoFocus = false,
  resendCodeActionCreator,
  onResendCodeSuccess,
  formikProps,
  channelCode,
  ...props
}: Props) => (
  
  <InputGroup {...props} className={classnames('input-group-code', className)}>
    <InputCode formikProps={formikProps} autoFocus={autoFocus} />
      <InputGroupCodeResendCode
        formikProps={formikProps}
        resendCodeActionCreator={resendCodeActionCreator}
        onResendCodeSuccess={onResendCodeSuccess}
        channelCode={channelCode}
      />
  </InputGroup>
);

export default InputGroupCode;

//@flow

// Vendors
import React from 'react';
import { useSelector } from 'react-redux';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Content } from '../..';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './server-error-modal.scss';

const ServerErrorModal = () => {
  const errorReference = (useSelector(selectModalData) || {})['REF'];
  return (
    <Modal name="ServerErrorModal" className="modal-sm server-error-modal">
      <h2 className="h1 modal-title">
        <Content type="Errors" id="ServerErrorModal_Title" />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        <p>
          <Content type="Errors" id="ServerErrorModal_Content" />
        </p>
        {errorReference && (
          <p>
            <Content type="Labels" id="ErrorReference" /> {errorReference}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ServerErrorModal;

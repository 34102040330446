//@flow

// Vendors
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { toggleModal } from '../../../../reducers/modal/modalActions';
import {
  deletePaymentAccount,
  getPaymentAccounts,
} from '../../../../reducers/payment/paymentActions';

// Selectors
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Components
import { Modal, Content } from '../..';
import { Button, ButtonDanger } from 'texkit/dist/components/Buttons';
import { PaymentCard } from '../../../Payment';

// Styles
import './delete-payment-account-modal.scss';

const DeletePaymentAccountModal = () => {
  const dispatch = useDispatch();
  const { paymentAccount } = useSelector(selectModalData);
  const [removing, setRemoving] = useState(false);

  const onConfirm = async () => {
    setRemoving(true);
    await dispatch(deletePaymentAccount(paymentAccount));
    dispatch(toggleModal('DeletePaymentAccountModal'));
    dispatch(getPaymentAccounts());
    setRemoving(false);
  };

  return (
    <Modal
      name="DeletePaymentAccountModal"
      className="delete-payment-account-modal"
    >
      {paymentAccount && (
        <>
          <h2 className="h1 modal-title delete-payment-account-modal-header">
            <Content type="Payment" id="RemovePaymentMethodConfirmQuestion" />
          </h2>
          <div className="modal-content delete-payment-account-modal-content">
            <PaymentCard paymentAccount={paymentAccount} />
          </div>
          <div className="modal-footer delete-payment-account-modal-footer">
            <Button
              onClick={() => dispatch(toggleModal('DeletePaymentAccountModal'))}
            >
              <Content type="Labels" id="Cancel" />
            </Button>
            <ButtonDanger
              isLoading={removing}
              loadingContent={<Content type="Labels" id="Removing" />}
              className="delete-payment-account-modal-button"
              onClick={onConfirm}
            >
              <Content type="Payment" id="RemovePaymentMethodConfirmButton" />
            </ButtonDanger>
          </div>
        </>
      )}
    </Modal>
  );
};

export default DeletePaymentAccountModal;

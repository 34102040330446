// Helpers
import { to, toCamel } from '../../lib/helpers';
import { gaActions } from '../gaActionTypes';

// Requests
import {
  getAllAgenciesRequest,
  getAllAppsRequest,
  getMyAppsRequest,
  getMySecurityDevicesRequest,
  getMySecurityInitiateRequest,
  getMySecurityCompleteRequest,
  deleteSecurityDeviceRequest,
  getMyAppsDataRequest,
  getMyAppsDataRequestByAppId,
  lookupAppLinkRequest,
  unlinkAppLinkRequest,
  userDefaultMFAOptions,
  userUpdateDefaultMFARequest,
  userGetUserPropertiesurl
} from '../../backend/requests';

// Action
import { openModal } from '../../reducers/modal/modalActions';



// Consts
import { APP_TRANSACTION_IDS } from '../app/appActions';
import { bodyHasError } from '../../lib/validation/serverErrors';
import { storeAlertInfo } from '../alert/alertActions';
export const AGENCY_STORE_ALL_AGENCIES = '[AGENCY] Store All Agencies';
export const AGENCY_STORE_ALL_APPS = '[AGENCY] Store All Apps';
export const AGENCY_STORE_MY_APPS = '[AGENCY] Store My Apps';
export const AGENCY_STORE_AGENCY = '[AGENCY] Store Agency';
export const AGENCY_STORE_LINK = '[AGENCY] Store Link';
export const AGENCY_REMOVE_LINK_BY_USERKEY = `[AGENCY] ${gaActions.click} Remove Link By Userkey`;
export const AGENCY_UPDATE_LINK_DATA = '[AGENCY] Update Links';
export const AGENCY_CLEAR_DATA = '[AGENCY] Clear Data';
export const AGENCY_STORE_MY_SECURITY_DEVICES =
  '[AGENCY] Store My Security Devices';
export const AGENCY_STORE_DELETE_MY_SECURITY_DEVICES =
  '[AGENCY] Delete My Security Device';
export const AGENCY_STORE_MY_COMPLETE_SECURITY_DEVICES =
  '[AGENCY] Complete My Security Device';
export const AGENCY_STORE_MY_INITIATE_SECURITY_DEVICES =
  '[AGENCY] Initiate My Security Device';
export const AGENCY_STORE_MY_APPS_DATA = '[AGENCY] Get my apps data'
export const LOGIN_FAILURE = `[LOGIN] ${gaActions.failure} Login Failure`;
export const MFA_PREFERENCE_DEVICES = '[MFA] Store MFA selections';
export const PDFURL = `[URL] Url for Advance security stored`;



//url for pop up modal is here
export const getPropertieUrl = () => async dispatch => {
  const key = 'pdf.url';
  const[{response, body}, err] = await to(dispatch(userGetUserPropertiesurl(key)));
  return dispatch({type: PDFURL, payload: body})
}

export const getAllAgencies = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getAllAgenciesRequest()));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_ALL_AGENCIES, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getAllApps = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getAllAppsRequest()));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_ALL_APPS, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getMyApps = () => async (dispatch, getState) => {

  //get the user Oauth header
  const guid =  window.sessionStorage.getItem("guid");
  const userName = window.sessionStorage.getItem("userName");
  const uid = window.sessionStorage.getItem("_id");

  const userHeaders = {
    guid: guid,
    userName: userName,
    _id: uid
  }

  console.log(userHeaders);

  const [{ response, body }, err] = await to(dispatch(getMyAppsRequest(userHeaders)));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_MY_APPS, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getMySecurityDevices = () => async dispatch => {
   //get the user Oauth header
   const guid =  window.sessionStorage.getItem("guid");
   const userName = window.sessionStorage.getItem("userName");
   const uid = window.sessionStorage.getItem("_id");
 
   const userHeaders = {
     guid: guid,
     userName: userName,
     _id: uid
   }
 
   console.log(userHeaders);

  const [{ response, body }, err] = await to(
    dispatch(getMySecurityDevicesRequest(userHeaders))
  );
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_MY_SECURITY_DEVICES, payload: body.data });
  return Promise.resolve({ response, body });
};

//added for the MFA account settings page
export const getMyMFASecurityDevices = () => async dispatch => {
   //get the user Oauth header
   const guid =  window.sessionStorage.getItem("guid");
   const userName = window.sessionStorage.getItem("userName");
   const uid = window.sessionStorage.getItem("_id");
 
   const userHeaders = {
     guid: guid,
     userName: userName,
     _id: uid
   }
 
   console.log(userHeaders);
  const [{ response, body }, err] = await to(
    dispatch(getMySecurityDevicesRequest(userHeaders))
  );

  console.log(body);


  dispatch({ type: MFA_PREFERENCE_DEVICES, payload: body.data });


  if (err) return Promise.reject(err);
  dispatch({ type: MFA_PREFERENCE_DEVICES, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getMyInitiateSecurityDevices = deviceType => async dispatch => {
  const [{ response, body }, err] = await to(
    dispatch(
      getMySecurityInitiateRequest({
        deviceUuid: '',
        deviceName: '',
        deviceTypeCd: deviceType,
        deviceWebAuthData: '',
      })
    )
  );
  console.log(response);
  if (err) return Promise.reject(err);
  dispatch({
    type: AGENCY_STORE_MY_INITIATE_SECURITY_DEVICES,
    payload: body.data,
  });
  return Promise.resolve({ response, body });
};

export const getMyAppsData = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getMyAppsDataRequest()));
  if (response.status != 200) return Promise.reject(err);
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_MY_APPS_DATA, payload: body.data });
  return Promise.resolve(body.data);
};
export const getMyAppsDataByAppId = appID => async dispatch => {
  const [{ response, body }, err] = await to(
    dispatch(getMyAppsDataRequestByAppId({ appID }))
  );
  if (response.status != 200) return Promise.reject(err);
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_MY_APPS_DATA, payload: body.data });
  return Promise.resolve(body.data);
};

export const getInitiateYubiKeyDevices = deviceType => async dispatch => {
  const [{ response, body }, err] = await to(
    dispatch(
      getMySecurityInitiateRequest({
        deviceUuid: '',
        deviceName: '',
        deviceTypeCd: deviceType,
        deviceWebAuthData: '',
      })
    )
  );
  if (err) return Promise.reject(err);
  dispatch({
    type: AGENCY_STORE_MY_INITIATE_SECURITY_DEVICES,
    payload: body.data,
  });
  return Promise.resolve({ response, body });
};

export const getMyCompleteSecurityDevices = (
  otpCode,
  deviceTypeCd
) => async (dispatch) => {

  
  const [{ response, body }, err] = await to(
    dispatch(
      getMySecurityCompleteRequest({
        deviceUuid: '',
        deviceName: '',
        deviceTypeCd: deviceTypeCd,
        deviceWebAuthData: `{"otpCode":"${otpCode}"}`,
      })
    )
  );

  if (err || response.status == 400) {
    dispatch(openModal('MFACodeErrorModal'));
    return;
  }

  dispatch({
    type: AGENCY_STORE_MY_COMPLETE_SECURITY_DEVICES,
    payload: body.data,
  });
  
  return Promise.resolve({ response, body });
};

export const getCompleteYubiKeyDevices = (
  yubiKeyWebAuthData,
  deviceTypeCd
) => async dispatch => {

  console.log(deviceTypeCd);
  console.log(yubiKeyWebAuthData);
  const yubiKeyResponse = await to(
    dispatch(
      getMySecurityCompleteRequest({
        deviceUuid: '',
        deviceName: '',
        deviceTypeCd: deviceTypeCd,
        deviceWebAuthData: `${yubiKeyWebAuthData}`,
      })
    )
  );

  const response = yubiKeyResponse[0].response;
  const body = yubiKeyResponse[0].body;
  const err = yubiKeyResponse[1];

  if (err) return Promise.reject(err);
  dispatch({
    type: AGENCY_STORE_MY_COMPLETE_SECURITY_DEVICES,
    payload: { devices: [body.data] },
  });
  return Promise.resolve({ response, body });
};

export const deleteMySecurityDevices = deviceUuid => async (dispatch, getState) => {


  //deleting the mfa preference as we delete the device

  const userEmail = getState().user.profile.userEmail;
  //if MFA preference is phone number then set MFA preference to no preference
  console.log(userEmail);
  //get MFA preference
  const [abody, aerr] = await to(
    dispatch(userDefaultMFAOptions(userEmail))
  )

  console.log(abody);
if(abody.body.data === 'totp' || abody.body.data === 'yubikey'){
 
  let data = {
    userEmail: userEmail,
    newMFAOptions: ''
  }

  const [{ response, newbody }] = await to(
    dispatch(userUpdateDefaultMFARequest(data))
  );

  console.log(response);

}
  console.log("Deleting device")


  const [{ response, body }, err] = await to(
    dispatch(deleteSecurityDeviceRequest(deviceUuid))
  );
  if (err) return Promise.reject(err);
  dispatch({
    type: AGENCY_STORE_DELETE_MY_SECURITY_DEVICES,
    payload: body.data,
  });
  return Promise.resolve({ response, body });
};

export const unlinkAppLink = ({ userKey, licenses }) => async dispatch => {
  const [{ licenseNumber }] = licenses;
  const [{ response, body }, err] = await to(
    dispatch(unlinkAppLinkRequest({ userKey, licenseNumber }))
  );
  if (err) return Promise.reject(err);
  return Promise.resolve({ response, body });
};

export const removeLinkFromState = link => dispatch =>
  dispatch({ type: AGENCY_REMOVE_LINK_BY_USERKEY, payload: link });

export const storeLink = link => dispatch =>
  dispatch({ type: AGENCY_STORE_LINK, payload: link });

export const lookupBasicLinkData = links => async dispatch => {
  const results = links.map(async link => {
    const { appId, userKey } = link;
    const [{ body }, error] = await to(
      dispatch(
        lookupAppLinkRequest({
          appId,
          userKey,
          transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
        })
      )
    );
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3007'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { id: 'SorryTitle' },
          message: { id: 'LinkNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_5001'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { id: 'SorryTitle' },
          message: { id: 'AgencyNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }
    if (error) return Promise.reject(error);

    if (bodyHasError(body, { errorCodes: ['MGMW_EX_5002'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { id: 'SorryTitle' },
          message: { id: 'ApplicationNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }
    if (error) return Promise.reject(error);

    const linkData = Array.isArray(body.data)
      ? { ...link, licenses: [...body.data] }
      : { ...link, ...body.data };

    // const linkData2 = toCamel(linkData2);
    const linkData2 = toCamel(linkData);
    return Promise.resolve(linkData2);
  });

  return Promise.all(results).then(updatedLinks => {
    dispatch({ type: AGENCY_UPDATE_LINK_DATA, payload: updatedLinks });
    return Promise.resolve(updatedLinks);
  });
};

export const clearAgencyData = () => dispatch =>
  dispatch({ type: AGENCY_CLEAR_DATA });

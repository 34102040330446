//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';
import { Formik } from 'formik';

// Components
import { InputGroup, InputSelect, InputPassword } from '../..';

// Styles
import './InputGroupMFAPreference.scss';
import { Content } from '../../../..';


//helpers
import { formatPhoneNumber } from '../../../../../lib/helpers';

type Props = {
  className?: string,
  questions: Object,
  contacts: Object,
  allMFAOptions: Object,
  formikProps: FormikProps,
  autoFocus: boolean
};

/**
 * @visibleName Security Questions
 */
const InputGroupMFAPreference = ({
  questions,
  contacts,
  allMFAOptions,
  className,
  formikProps,
  autoFocus,
  ...props
}: Props) => {
 
   

  
  return (
    
    

            <InputGroup key="question" {...props}  >
                
                <InputSelect
                  name= "question"
                  options={allMFAOptions}
                  labelPosition="above"
                  optionLabelKey="question"
                  optionValueKey="quid"
                  emptyOptionLabel="Select an option"
                  formikProps={formikProps}
                  autoFocus={true}
                />
                
              </InputGroup>
   
            
        
          
    
  );
};

export default InputGroupMFAPreference;

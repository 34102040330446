//@flow

// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '../..';
import { ButtonPrimary, ButtonOutline } from 'texkit/dist/components/Buttons';
import { Download } from 'texkit/dist/components/Download';
import Content from '../../Content/Content';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { closeModal } from '../../../../reducers/modal/modalActions';
import { push } from 'connected-react-router';

// Styles
import './terms-and-conditions-modal.scss';

type Props = {
  modal: Object,
  closeModal: Function,
};

const TermsAndConditionsModal = ({
  modal : { data },
  closeModal,
}) => {
  const dispatch = useDispatch();

  const tcAccepted = () => {
    closeModal("TermsAndConditionsModal", { onClose: () => dispatch(push(`/agency/${data.agencySlug}`)) });
  } 

  

  var agencyName = (() => data && data.agencyName ? data.agencyName : undefined)();
  var agencySlug = (() => data && data.agencySlug ? data.agencySlug : undefined)();

  return (
 
  <Modal aria-labelledby="Terms and conditions modal" name="TermsAndConditionsModal" className="terms-and-conditions-modal" disableCloseButton={true}>
   
    <div className="flex-row">
      <div className="flex-item terms-and-conditions-modal-quicklook terms-and-conditions-modal-content">
        <div
          className="terms-and-conditions-modal-content-overflow"
          tabIndex="0"
        >
          <h2 className="terms-and-conditions-modal-header terms-and-conditions-modal-header-title center">
            <Content type="Modals" id={`${agencySlug || ''}TermsAndConditionsTitle`} />
          </h2>
          {(() => {
            if (agencySlug) {
              return (
                <h3 className="center terms-and-conditions-modal-header-subtitle margin-right-30">
                  <Content type="Modals" id={`${agencySlug || ''}AcceptableUsePolicy`} />
                </h3>
              )
            } else {
              return (
                <span className="bold">
                  <Content type="Modals" id="TheTermsAndConditions" />
                </span>
              )
            }
          })()}
          <ul className="margin-top-10">
            <Content type="Modals" id={`${agencySlug || ''}TermsAndConditionsListItems`} />
          </ul>
        </div>
      </div>
    </div>
    <div align="center">
      <ButtonOutline onClick={() => closeModal("TermsAndConditionsModal")} autoFocus='true'>
        <Content type="Labels" id="Cancel" />
      </ButtonOutline>
      <ButtonPrimary onClick={() => tcAccepted()} className="padding-left">
        I Agree
      </ButtonPrimary>
    </div>
  </Modal>
  
)};

const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsModal);

// import LinkService from '../LinkService/LinkService.en.js';

const AgencyName = 'Centralized Accounting and Payroll/Personnel System';
const AgencyShortName = 'CAPPS';

export default {
  AgencyName,
  AgencyShortName,
  cpaln_AppName: 'CAPPS Link Agency',
  cpaln_AppShortName: 'CAPPS Link Agency',
  cpaln_LinkService_LookupTitle: `We need a little more information to link your agency`,
  cpaln_LinkService_ConfirmTitleDefault: 'Your agency has been located.',
  cpaln_LinkService_ConfirmDescriptionDefault: `Please confirm your records associated with your agency account`,
  cpaln_LinkService_AddPhoneDescription: 
    `For enhanced security, a valid phone number that can receive text messages (SMS) is required in order to verify your identity. Check the box below if you would like to use your personal number`,
  cpaln_LinkService_ConfirmTitleLinked: 'Agency already linked.',
  cpaln_LinkService_ConfirmDescriptionLinked:
    'This agency is already linked and can be accessed in {{Link:[your dashboard](/)}}.',
  cpaln_LinkService_SuccessTitle: `Success! Your agency is now linked to your account.`
};

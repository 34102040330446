import { SITE_DATA } from '../../data';

export default {
  Congratulations: 'Congratulations, {{1}}!',
  EmailCaptureTitle: "Great! Let's get started. Please enter your email.",
  EmailCaptureDescription:
    'Your email will be the main method of communication. Please use your primary personal email.',
  PhoneTitle: 'Next, enter your phone number.',
  PasswordTitle: 'Please set a secure password.',
  PasswordSubTitle:
    'Your password must NOT contain your username, email address, first name, last name, or any other Personally Identifiable Information (PII). This includes any information that someone could easily guess about you.',
  PasswordDescription: 'Passwords must be at least eight characters.',
  IdentityChoosePathTitle: 'Please verify your identity.',
  IdentityChoosePathDescription:
    'We can verify your identity with a Texas ID, including a driver license, or through a series of questions.',
  IdentityChoosePathDescriptionForNonIdologyUsers:
    'We can verify your identity with a Texas ID or a driver license.',
  IdentityIDologyTitle: `Great, we'll verify your identity with some questions.`,
  IdentityIDologyDescription: `Please fill in the form below, and then we'll ask a few additional questions only you will be able to answer.`,
  IdentityIDologyQuestionCountQuestion: 'Question',
  IdentityIDologyQuestionCountOf: 'of',
  IdentityTOASTitle: 'Great! Please enter your ID information.',
  IdentityTOASDescription:
    'The information you provide is for identity verification purposes only.',
  FailedTitle: 'This is awkward&hellip;',
  FailedDescription: `<p class="lead">We're very sorry, but we're unable to verify your identity based on the information provided.</p><p class="lead"> Please contact your organization’s support team for assistance.</p>`,
  BlockedTitle: 'You’ve been blocked.',
  BlockedDescription: `<p class="lead">There have been too many attempts at verifying your&nbsp;identity.</p> <p class="lead"> Please contact your organization’s support team for assistance.</p>`,
  WelcomeBack: `Welcome Back!`,
  AccountSuccessfullyCreated: `Your account has been created. Sign in to access your account.`,
  EnterLegalFirstName: 'This must be your legal first name.',
  EnterLegalLastName: 'This must be your legal last name.',
};

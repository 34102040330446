//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { Content } from '../..';
import AgencyLogo from 'texkit/dist/components/AgencyLogo/AgencyLogo';
import Card from 'texkit/dist/components/Cards/Card';

// Helpers
import { getAgencyDataBySlug } from '../../../../lib/helpers';
import { logoCapps } from '../../../../../src/assets/cappslogo.png';
// Styles
import './employee-agency-card.scss';

// Types
type Props = {
  as?: string,
  className?: string,
  children: Node,
  agencySlug: string,
  hasLogo?: boolean,
};

/**
 * @visibleName Agency Card
 */
const EmployeeAgencyCard = ({
  className,
  agencySlug,
  hasLogo = true,
  ...props
}: Props) => {
  const agency = getAgencyDataBySlug(agencySlug);

  return (
    <Card {...props} className={classnames('agency-card', className)}>
      {hasLogo && <img src={logoCapps} />}
      <div className="agency-card-text">
        <h3 className="h4">
          <Content type={agencySlug} id="AgencyName" />
        </h3>
      </div>
    </Card>
  );
};

export default EmployeeAgencyCard;

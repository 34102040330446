export default [
  {
    code: 'GU',
    label: 'Guam',
  },
  {
    code: 'GT',
    label: 'Guatemala',
  },
  {
    code: 'GN',
    label: 'Guinea',
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
  },
  {
    code: 'GY',
    label: 'Guyana',
  },
  {
    code: 'HT',
    label: 'Haiti',
  },
  {
    code: 'HM',
    label: 'Heard & McDonald Isl',
  },
  {
    code: 'VA',
    label: 'Holy,Vatican City St',
  },
  {
    code: 'HN',
    label: 'Honduras',
  },
  {
    code: 'HK',
    label: 'Hong Kong',
  },
  {
    code: 'HU',
    label: 'Hungary',
  },
  {
    code: 'IS',
    label: 'Iceland',
  },
  {
    code: 'IN',
    label: 'India',
  },
  {
    code: 'NA',
    label: 'Namibia',
  },
  {
    code: 'ID',
    label: 'Indonesia',
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Rep of',
  },
  {
    code: 'IQ',
    label: 'Iraq',
  },
  {
    code: 'IE',
    label: 'Ireland',
  },
  {
    code: 'IL',
    label: 'Israel',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
  },
  {
    code: 'BB',
    label: 'Barbados',
  },
  {
    code: 'BY',
    label: 'Belarus',
  },
  {
    code: 'BE',
    label: 'Belgium',
  },
  {
    code: 'BZ',
    label: 'Belize',
  },
  {
    code: 'BJ',
    label: 'Benin',
  },
  {
    code: 'BM',
    label: 'Bermuda',
  },
  {
    code: 'BT',
    label: 'Bhutan',
  },
  {
    code: 'BO',
    label: 'Bolivia',
  },
  {
    code: 'BA',
    label: 'Bosnia & Herzegovina',
  },
  {
    code: 'BW',
    label: 'Botswana',
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
  },
  {
    code: 'BR',
    label: 'Brazil',
  },
  {
    code: 'IO',
    label: 'British Indian Ocean',
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    label: 'Bulgaria',
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
  },
  {
    code: '?2',
    label: 'Burma',
  },
  {
    code: 'BI',
    label: 'Burundi',
  },
  {
    code: 'KH',
    label: 'Cambodia',
  },
  {
    code: 'CM',
    label: 'Cameroon',
  },
  {
    code: 'CA',
    label: 'Canada',
  },
  {
    code: 'CV',
    label: 'Cape Verde',
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
  },
  {
    code: 'CF',
    label: 'Central African Rep',
  },
  {
    code: 'TD',
    label: 'Chad',
  },
  {
    code: 'CL',
    label: 'Chile',
  },
  {
    code: 'CN',
    label: 'China',
  },
  {
    code: 'CX',
    label: 'Christmas Island',
  },
  {
    code: 'CC',
    label: 'Cocos/Keeling Isl',
  },
  {
    code: 'CO',
    label: 'Colombia',
  },
  {
    code: 'KM',
    label: 'Comoros',
  },
  {
    code: 'CG',
    label: 'Congo',
  },
  {
    code: 'CD',
    label: 'Congo, Dem Rep',
  },
  {
    code: 'CK',
    label: 'Cook Islands',
  },
  {
    code: 'CR',
    label: 'Costa Rica',
  },
  {
    code: 'CI',
    label: 'Cote Divoire',
  },
  {
    code: 'HR',
    label: 'Croatia/Hrvatska',
  },
  {
    code: 'CU',
    label: 'Cuba',
  },
  {
    code: 'NR',
    label: 'Nauru',
  },
  {
    code: 'NP',
    label: 'Nepal',
  },
  {
    code: 'NL',
    label: 'Netherlands',
  },
  {
    code: 'AN',
    label: 'Netherlands Antilles',
  },
  {
    code: 'JM',
    label: 'Jamaica',
  },
  {
    code: 'NC',
    label: 'New Caledonia',
  },
  {
    code: 'NZ',
    label: 'New Zealand',
  },
  {
    code: 'NI',
    label: 'Nicaragua',
  },
  {
    code: 'NE',
    label: 'Niger',
  },
  {
    code: 'NG',
    label: 'Nigeria',
  },
  {
    code: 'NU',
    label: 'Niue',
  },
  {
    code: 'NF',
    label: 'Norkold Island',
  },
  {
    code: '?4',
    label: 'North Korea',
  },
  {
    code: 'MP',
    label: 'Northern Mariana Isl',
  },
  {
    code: 'NO',
    label: 'Norway',
  },
  {
    code: 'OM',
    label: 'Oman',
  },
  {
    code: 'PK',
    label: 'Pakistan',
  },
  {
    code: 'PW',
    label: 'Palau',
  },
  {
    code: 'PA',
    label: 'Panama',
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
  },
  {
    code: 'PY',
    label: 'Paraguay',
  },
  {
    code: 'PE',
    label: 'Peru',
  },
  {
    code: 'PH',
    label: 'Philippines',
  },
  {
    code: 'PN',
    label: 'Pitcairn',
  },
  {
    code: 'PL',
    label: 'Poland',
  },
  {
    code: 'PT',
    label: 'Portugal',
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
  },
  {
    code: 'QA',
    label: 'Qatar',
  },
  {
    code: 'RE',
    label: 'Reunion',
  },
  {
    code: 'RO',
    label: 'Romania',
  },
  {
    code: 'RU',
    label: 'Russian Federation',
  },
  {
    code: 'RW',
    label: 'Rwanda',
  },
  {
    code: 'GS',
    label: 'S Georgia Sandwich',
  },
  {
    code: 'SH',
    label: 'Saint Helena',
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
  },
  {
    code: 'WS',
    label: 'Samoa',
  },
  {
    code: 'SM',
    label: 'San Marino',
  },
  {
    code: 'ST',
    label: 'Sao Tome & Principe',
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
  },
  {
    code: 'SN',
    label: 'Senegal',
  },
  {
    code: '?3',
    label: 'Serbia & Montenegro',
  },
  {
    code: 'SC',
    label: 'Seychelles',
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
  },
  {
    code: 'SG',
    label: 'Singapore',
  },
  {
    code: 'SK',
    label: 'Slovakia/Slovak Rep',
  },
  {
    code: 'SI',
    label: 'Slovenia',
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
  },
  {
    code: 'SO',
    label: 'Somalia',
  },
  {
    code: 'ZA',
    label: 'South Africa',
  },
  {
    code: 'ES',
    label: 'Spain',
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
  },
  {
    code: 'KN',
    label: 'St Kitts & Nevis ',
  },
  {
    code: 'PM',
    label: 'St Pierre & Miquelon',
  },
  {
    code: 'JP',
    label: 'Japan',
  },
  {
    code: 'JO',
    label: 'Jordan',
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
  },
  {
    code: 'KE',
    label: 'Kenya',
  },
  {
    code: 'KI',
    label: 'Kiribati',
  },
  {
    code: 'KP',
    label: 'Korea, Peoples Rep',
  },
  {
    code: 'KR',
    label: 'Korea, Rep of',
  },
  {
    code: 'KW',
    label: 'Kuwait',
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
  },
  {
    code: 'LA',
    label: 'Lao Peoples Dem Rep',
  },
  {
    code: 'LV',
    label: 'Latvia',
  },
  {
    code: 'LB',
    label: 'Lebanon',
  },
  {
    code: 'LS',
    label: 'Lesotho',
  },
  {
    code: 'LR',
    label: 'Liberia',
  },
  {
    code: 'LY',
    label: 'Libyan Jamahiriya',
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
  },
  {
    code: 'LT',
    label: 'Lithuania',
  },
  {
    code: 'LU',
    label: 'Luxembourg',
  },
  {
    code: 'MO',
    label: 'Macau',
  },
  {
    code: 'MK',
    label: 'Macedonia,Yugoslav',
  },
  {
    code: 'MG',
    label: 'Madagascar',
  },
  {
    code: 'MW',
    label: 'Malawi',
  },
  {
    code: 'MY',
    label: 'Malaysia',
  },
  {
    code: 'MV',
    label: 'Maldives',
  },
  {
    code: 'ML',
    label: 'Mali',
  },
  {
    code: 'MT',
    label: 'Malta',
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
  },
  {
    code: 'MQ',
    label: 'Martinique',
  },
  {
    code: 'MR',
    label: 'Mauritania',
  },
  {
    code: 'MU',
    label: 'Mauritius',
  },
  {
    code: 'YT',
    label: 'Mayotte',
  },
  {
    code: 'MX',
    label: 'Mexico',
  },
  {
    code: 'FM',
    label: 'Micronesia, Fed St',
  },
  {
    code: 'MD',
    label: 'Moldova, Rep of',
  },
  {
    code: 'MC',
    label: 'Monaco',
  },
  {
    code: 'MN',
    label: 'Mongolia',
  },
  {
    code: 'MS',
    label: 'Montserrat',
  },
  {
    code: 'MA',
    label: 'Morocco',
  },
  {
    code: 'MZ',
    label: 'Mozambique',
  },
  {
    code: 'MM',
    label: 'Myanmar',
  },
  {
    code: 'AL',
    label: 'Albania',
  },
  {
    code: 'DZ',
    label: 'Algeria',
  },
  {
    code: 'AS',
    label: 'American Samoa',
  },
  {
    code: 'AD',
    label: 'Andorra',
  },
  {
    code: 'AO',
    label: 'Angola',
  },
  {
    code: 'AI',
    label: 'Anguilla',
  },
  {
    code: 'AQ',
    label: 'Antarctica',
  },
  {
    code: 'AG',
    label: 'Antigua & Barbuda',
  },
  {
    code: 'AR',
    label: 'Argentina',
  },
  {
    code: 'AM',
    label: 'Armenia',
  },
  {
    code: 'AW',
    label: 'Aruba',
  },
  {
    code: 'VC',
    label: 'St Vincent & Grenada',
  },
  {
    code: 'SD',
    label: 'Sudan',
  },
  {
    code: 'SR',
    label: 'Suriname',
  },
  {
    code: 'SJ',
    label: 'Svalbard Jan Mayen',
  },
  {
    code: 'SZ',
    label: 'Swaziland',
  },
  {
    code: 'SE',
    label: 'Sweden',
  },
  {
    code: 'CH',
    label: 'Switzerland',
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
  },
  {
    code: 'TW',
    label: 'Taiwan, Prov China',
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
  },
  {
    code: 'TZ',
    label: 'Tanzania United Rep',
  },
  {
    code: 'TH',
    label: 'Thailand',
  },
  {
    code: 'TG',
    label: 'Togo',
  },
  {
    code: 'TK',
    label: 'Tokelau',
  },
  {
    code: 'TO',
    label: 'Tonga',
  },
  {
    code: 'TT',
    label: 'Trinidad & Tobago',
  },
  {
    code: 'TN',
    label: 'Tunisia',
  },
  {
    code: 'TR',
    label: 'Turkey',
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
  },
  {
    code: 'TC',
    label: 'Turks & Caicos Isl',
  },
  {
    code: 'TV',
    label: 'Tuvalu',
  },
  {
    code: 'UM',
    label: 'US Minor Outlying Is',
  },
  {
    code: 'UG',
    label: 'Uganda',
  },
  {
    code: 'UA',
    label: 'Ukraine',
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
  },
  {
    code: 'GB',
    label: 'United Kingdom',
  },
  {
    code: 'US',
    label: 'United States',
  },
  {
    code: 'UY',
    label: 'Uruguay',
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
  },
  {
    code: 'VU',
    label: 'Vanuatu',
  },
  {
    code: 'VE',
    label: 'Venezuela',
  },
  {
    code: 'VN',
    label: 'VietNam',
  },
  {
    code: 'VG',
    label: 'Virgin Isl -British',
  },
  {
    code: 'VI',
    label: 'Virgin Isl -US',
  },
  {
    code: 'WF',
    label: 'Wallis & Futuna Isl',
  },
  {
    code: '?1',
    label: 'West Bank/Gaza Strip',
  },
  {
    code: 'EH',
    label: 'Western Sahara',
  },
  {
    code: 'YE',
    label: 'Yemen',
  },
  {
    code: 'YU',
    label: 'Yugoslavia',
  },
  {
    code: 'ZR',
    label: 'Zaire',
  },
  {
    code: 'ZM',
    label: 'Zambia',
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
  },
  {
    code: 'AF',
    label: 'Afghanistan',
  },
  {
    code: 'CY',
    label: 'Cyprus',
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
  },
  {
    code: 'DK',
    label: 'Denmark',
  },
  {
    code: 'DJ',
    label: 'Djibouti',
  },
  {
    code: 'IT',
    label: 'Italy',
  },
  {
    code: 'AU',
    label: 'Australia',
  },
  {
    code: 'AT',
    label: 'Austria',
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
  },
  {
    code: 'BS',
    label: 'Bahamas',
  },
  {
    code: 'BH',
    label: 'Bahrain',
  },
  {
    code: 'DM',
    label: 'Dominica',
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
  },
  {
    code: 'TP',
    label: 'East Timor',
  },
  {
    code: 'EC',
    label: 'Ecuador',
  },
  {
    code: 'EG',
    label: 'Egypt',
  },
  {
    code: 'SV',
    label: 'El Salvador',
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
  },
  {
    code: 'ER',
    label: 'Eritrea',
  },
  {
    code: 'EE',
    label: 'Estonia',
  },
  {
    code: 'ET',
    label: 'Ethiopia',
  },
  {
    code: 'FK',
    label: 'Falkland Isl/Malvina',
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
  },
  {
    code: 'FJ',
    label: 'Fiji',
  },
  {
    code: 'FI',
    label: 'Finland',
  },
  {
    code: 'FR',
    label: 'France',
  },
  {
    code: 'FX',
    label: 'France, Metropolitan',
  },
  {
    code: 'GF',
    label: 'French Guiana',
  },
  {
    code: 'PF',
    label: 'French Polynesia',
  },
  {
    code: 'TF',
    label: 'French S Territories',
  },
  {
    code: 'GA',
    label: 'Gabon',
  },
  {
    code: 'GM',
    label: 'Gambia',
  },
  {
    code: 'GE',
    label: 'Georgia',
  },
  {
    code: 'DE',
    label: 'Germany',
  },
  {
    code: 'GH',
    label: 'Ghana',
  },
  {
    code: 'GI',
    label: 'Gibraltar',
  },
  {
    code: 'GR',
    label: 'Greece',
  },
  {
    code: 'GL',
    label: 'Greenland',
  },
  {
    code: 'GD',
    label: 'Grenada',
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
  },
];

// Data
import { SITE_DATA } from '../../data';

export default {
  PaymentTitle: 'Payment',
  CreditCard: 'Credit Card',
  DebitCard: 'Debit Card',
  Online: 'Online',
  Transfer: 'Transfer',
  OtherPaymentMethod: 'Other',
  CheckingAccount: 'Checking Account',
  SavingsAccount: 'Savings Account',
  CreditDiscover: 'Discover Credit',
  CreditVisa: 'Visa Credit',
  CreditAmericanExpress: 'AmericanExpress Credit',
  CreditMasterCard: 'Mastercard Credit',
  DebitDiscover: 'Discover Debit',
  DebitVisa: 'Visa Debit',
  DebitAmericanExpress: 'AmericanExpress Debit',
  DebitMasterCard: 'Mastercard Debit',
  DualDiscover: 'Discover Dual',
  DualVisa: 'Visa Dual',
  DualAmericanExpress: 'American Express Dual',
  AddPaymentMethod: 'Add a payment method',
  UseDifferentCard: 'Use a different card',
  RemovePaymentMethodConfirmQuestion:
    'Are you sure you want to delete this card?',
  RemovePaymentMethodConfirmButton: 'Yes, Remove Card',
  EditCardTitle: 'Edit card details.',
  AddCardTitle: 'Add a new credit or debit card.',
  AddCardDescription:
    'You can update or remove this information at any time from your account settings.',
  PayNow: 'Pay Now',
  Paying: 'Paying',
  ReviewInfo: 'Review Information',
  ErrorTitle: 'An error occurred with your payment.',
  ErrorInfo: `Your card was not processed. If you continue to experience this issue, please contact your organization’s support team for assistance. Error Reference: {{1}}`,
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Assets
import { ReactComponent as LogoSvg } from '../../../assets/logo.svg';
import { ReactComponent as SecondaryLogoSvg } from '../../../assets/secondary-logo.svg';

// Styles
import './logo.scss';

type Props = {
  className?: string,
  isSecondary?: boolean
};

/**
 * @visibleName Default
 */
const Logo = ({ className, isSecondary }: Props) => (
  <span className={classnames('logo', className)}>
  {isSecondary ? <SecondaryLogoSvg role="presentation" /> :
    <LogoSvg role="presentation" />}
  </span>
);

export default Logo;

// @flow

import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactMarkdown from 'react-markdown';

import { ButtonIcon } from 'texkit/dist/components/Buttons';

import './MessageBanner.scss';

type Message = {|
  messageId: string,
  textEnglish: string,
  textSpanish: string,
  startTime: string,
  endTime: string,
  priority: string,
  status: string,
  author: string,
  valid: boolean,
|};

type MessageBannerProps = {|
  language: string,
  message: Message,
  dismissable: boolean,
  handleClose?: Function,
|};

type MessageBannersProps = {|
  messages: Array<Message>,
  language: string,
  dismissable?: boolean,
  handleClose?: Function,
|};

export function MessageBanner({
  language,
  message,
  dismissable,
  handleClose,
}: MessageBannerProps) {
  const text = language === 'EN' ? message.textEnglish : message.textSpanish;

  return (
    <li className={`message-banner message-banner-${message.priority}`}>
      <div>
        <div>
          <ReactMarkdown source={text} />
        </div>
        {dismissable && handleClose && (
          <ButtonIcon
            icon="x"
            aria-label="Close message"
            onClick={() => handleClose(message.messageId)}
          />
        )}
      </div>
    </li>
  );
}

export default function MessageBanners({
  messages,
  language,
  dismissable = false,
  handleClose,
}: MessageBannersProps) {
  const [dismissed, setDismissed] = useState([]);

  function onClose(messageId) {
    setDismissed([...dismissed, messageId]);
  }

  function handleOnExited(messageId) {
    if (dismissable && handleClose) {
      return function() {
        handleClose(messageId);
      };
    }
  }

  return (
    <ul className="message-banners">
      {messages.map(message => (
        <CSSTransition
          in={!dismissed.includes(message.messageId)}
          timeout={275}
          classNames="message-banner"
          onExited={handleOnExited(message.messageId)}
          unmountOnExit
          key={`message${message.messageId}`}
        >
          <MessageBanner
            message={message}
            language={language}
            dismissable={dismissable}
            handleClose={onClose}
          />
        </CSSTransition>
      ))}
    </ul>
  );
}

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputSelect from '../InputSelect/InputSelect';

// Data
import { states } from '../../../../../lib/data';

// Types
type Props = {
  className?: string,
  formikProps: FormikProps,
  name?: string,
};

/**
 * @visibleName Select State
 */
const InputState = ({ className, ...props }: Props) => (
  <InputSelect
    {...props}
    optionLabelKey="label"
    optionValueKey="value"
    emptyOptionLabel="Select a state..."
    isSearchable={true}
    className={classnames('input-state', className)}
  />
);

InputState.defaultProps = {
  label: 'State',
  name: 'state',
  options: states,
};

export default InputState;

//@flow

// Vendors
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';

// Components

import { Modal } from '../..';
import Content from '../../Content/Content';
import {
  ButtonPrimary,
  ButtonDanger,
  Button,
} from 'texkit/dist/components/Buttons';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { closeModal } from '../../../../reducers/modal/modalActions';
import { deleteMySecurityDevices } from '../../../../reducers/agency/agencyActions';

// Styles
import './advanced-security-modal.scss';

type Props = {
  formikProps: FormikProps,
  modal: Object,
  closeModal: Function,
  onReset: Function,
  setRefreshDevices: Function,
  refreshDevices: boolean,
};

const AdvancedSecurityDeleteModal = ({
  modal: { data },
  formikProps,
  closeModal,
  setRefreshDevices,
  refreshDevices,
}) => {
  const dispatch = useDispatch();
  const [deviceDelete, setDeviceDelete] = useState(true);
  const [deleteWarning, setDeleteWarning] = useState(false);

  const resetDelete = () => {
    setDeleteWarning(false);
    setDeviceDelete(true);
  };

  const closeAdvancedSecurityModal = () => {
    setRefreshDevices(!refreshDevices);
    resetDelete();
    closeModal('AdvancedSecurityDeleteModal', {
      onClose: () => dispatch(push('/account')),
    });
  };
  const triggerDeviceDelete = () => {
    dispatch(deleteMySecurityDevices(data.deviceUuid))
      .then(response => {
        closeAdvancedSecurityModal();
      })
      .catch(error => {
        triggerCannotDeleteDevice();
      });
  };
  const triggerCannotDeleteDevice = () => {
    setDeleteWarning(true);
    setDeviceDelete(false);
  };

  return (
    <Modal
      name="AdvancedSecurityDeleteModal"
      className="modal-sm advanced-security-delete-modal"
    >
      {deviceDelete && (
        <>
          <h2 className="h1 modal-title advanced-security-delete-modal-title">
            <Content type="Modals" id="DeleteConfirmation" />
          </h2>
          <div className="advanced-security-delete-modal-warning">
            <h3>
              <Content type="Modals" id="DeleteWarning" />
            </h3>
          </div>
          <Button onClick={closeAdvancedSecurityModal}>
            <Content type="Labels" id="Cancel" />
          </Button>
          <ButtonDanger
            onClick={triggerDeviceDelete}
            className="advanced-security-delete-modal-button"
          >
            <Content type="Labels" id="DeleteDevice" />
          </ButtonDanger>
        </>
      )}
      {deleteWarning && (
        <>
          <h2 className="h1 modal-title advanced-security-delete-modal-title">
            <Content type="Modals" id="DeleteNotLoggedInDeviceTitle" />
          </h2>
          <div className="advanced-security-delete-modal-warning">
            <h3>
              <Content type="Modals" id="DeleteNotLoggedInDeviceWarning" />
            </h3>
          </div>
          <ButtonPrimary onClick={closeAdvancedSecurityModal}>
            <Content type="Modals" id="ReturnToAccounts" />
          </ButtonPrimary>
        </>
      )}
    </Modal>
  );
};
const mapStateToProps = ({ modal }): Object => ({ modal });
const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSecurityDeleteModal);

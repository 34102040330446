//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputWrapper, Content } from '../../..';

// Styles
import './input-code.scss';

// Types
type Props = {
  name: string,
  label: string,
  className?: string,
  autoFocus: boolean,
};

/**
 * @visibleName Code
 */
const InputCode = ({ className, ...props }: Props) => (
  <InputWrapper
    {...props}
    mask={[...Array(6)].map(() => /\d/)}
    className={classnames('input-code', className)}
    type="tel"
    maxLength="6"
    autoComplete="off"
  />
);

InputCode.defaultProps = {
  label: <Content type="Labels" id="EnterCode" />,
  name: 'code',
  autoFocus: true,
};

export default InputCode;

//@flow

// Vendors
import React from 'react';
import classNames from 'classnames';

// Types
import type { Element } from 'react';
type Props = {
  className?: string,
  id?: string,
  name: string,
  label: Element<any> | string,
};

const Label = ({ id, name, label, className }: Props) => (
  <label className={classNames('input-label', className)} htmlFor={id || name}>
    {label}
  </label>
);

Label.defaultProps = {
  name: '',
  label: '',
};

export default Label;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputPhone, InputRadioGroup, Content } from '../../..';

// Styles
import './input-group-phone.scss';

// Types
type Props = {
  className?: string,
  inverse: boolean,
  formikProps: FormikProps,
  options?: Array<any>,
};

/**
 * @visibleName Phone
 */
const InputGroupPhone = ({
  className,
  inverse,
  formikProps,
  options = [
    {
      id: 'phoneTypeMobile',
      label: <Content type="Labels" id="Mobile" />,
      value: 'SMS',
    },
    {
      id: 'phoneTypeOther',
      label: <Content type="Labels" id="Other" />,
      value: 'TALK',
    },
  ],
  ...props
}: Props) => {
  return (
    <InputGroup
      {...props}
      className={classnames('input-group-phone', className)}
    >
      <InputPhone
        className="input-phone"
        name="phone"
        formikProps={formikProps}
      />
    </InputGroup>
  );
};

export default InputGroupPhone;

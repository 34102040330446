//@flow

// Vendors
import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Pages
import getPages from '../pages';
import PAGE_TYPES from '../pages/pageTypes';

const { NoMatchPage, ForgotPasswordPage, HomePage, SetPasswordPage, TokenError } = getPages(
  PAGE_TYPES.PUBLIC
);

// TODO: this could be an array of objects that is mapped through to create our routes below
export const publicRoutes = [
  '/sign-in',
  '/forgot-password',
  '/set-password',
  '/forgot-set-password',
  '/coming-soon',
  '/token-error'
];

const RoutesPublic = () => (
  <Switch>
    <Route path="/forgot-password" component={ForgotPasswordPage} />
    <Route path="/set-password" component={SetPasswordPage} />
    <Route path="/forgot-set-password" component={SetPasswordPage} />
    <Route path="/token-error" component={TokenError} />
    <Route component={NoMatchPage} />
  </Switch>
);

export default RoutesPublic;

import React from 'react';
import { Content } from '../../components';

export function translateStateAlert(alert) {
  if (alert.info) {
    // Destructure the parts we know we need
    const {
      type,
      info,
      info: { title, message },
    } = alert;

    // Provide translations by using the Content component
    const translated = {
      title: <Content type={type || 'Alerts'} id={title.id} />,
      message: <Content type={type || 'Alerts'} id={message.id} />,
    };

    // Return the new state with the translations
    return {
      ...alert,
      info: {
        ...info,
        ...translated,
      },
    };
  }

  // If no info, just return itself
  return alert;
}

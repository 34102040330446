// Data
import { SITE_DATA } from '../../data';

export default {
  SorryTitle: 'Sorry!',
  SessionExpiredTitle: 'Your session expired.',
  LookFamiliarTitle: 'Does this look familiar?',
  SignInExpired: 'Your sign in code has expired, please try signing in again.',
  ForgotPasswordExpired:
    'Your password reset code has expired, please try again.',
  CouldNotVerifyIdentity:
    'We couldn’t verify your identity with the information you provided, please try again.',
  CreateAccountLock: `You have attempted to create an account too many times. Please try again later or contact your organization’s support team for assistance.`,
  SignInAgain: 'Please sign in again to access your account.',
  AlreadyLinked:
    "You've already linked this license and may access it in your dashboard.",
  LinkedAnotherAccount: `This license is linked to another account. If you think this is an error, please contact your organization’s support team for assistance.`,
  LinkNotFound: `We encountered an issue while looking up your license information, please refresh the page and try again. If this continues, try again later or contact your organization’s support team for assistance.`,
  AgencyNotFound: `We couldn't find your agency-records, please check your information and try again. If this continues, try again later or contact your organization’s support team for assistance..`,
  ApplicationNotFound: `We couldn't find any applications linked to your accout, please check your information and try again. If this continues, try again later or contact your organization’s support team for assistance.`,
  Field_DateFormat: 'Please enter a valid date.',
};

//@flow

// Vendors
import React from 'react';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Styles
import './navbar-notifications.scss';

const NavbarNotifications = () => (
  <div className="navbar-notifications">
    <button className="navbar-notifications-toggle" aria-label="Notifications">
      <Icon name="bell" />
    </button>
  </div>
);

export default NavbarNotifications;

import { async } from 'regenerator-runtime';
import getDeviceDetails from './getDeviceDetails';
import {getDeviceDetailsAgain, collectGeolocationInfo} from './getSecondDeviceDetails';


// Test for IDToken inputs
// ForgeRock may change the integer used to reference the IDToken
const idTokenMatch = /^IDToken[0-9]$/;

export const getChannelTypeFromValue = value => {
  if (!value) return;
  if (value.includes('@')) return 'SMTP';
  if (value.includes('SMS')) return 'SMS';
  if (value.includes('Call')) return 'CALL';
  if (value.includes('Security')) return 'YK';
  if (value.includes('Authenticator')) return 'AA';
  return 'UNKNOWN';
};

export const requiresDeviceDetails = ({
    callbacks
  }) =>
  callbacks &&
  callbacks.find(callback => callback.type === 'HiddenValueCallback');



// callback for the MFA options back button updates value
export const requireMFABackOptions = ({
  callbacks
}) =>{

  callbacks.find(
    callback => callback.type === 'ConfirmationCallback'
  ).input.find(input => idTokenMatch.test(input.name)).value = '2';

  

  
  
    return callbacks;
} 

export const updateCaptchaScore = ({ callbacks, score }) => {
  const nameCallback = callbacks.find(callback => callback.type === 'NameCallback');

  if (nameCallback) {
    const idTokenInput = nameCallback.input.find(input => input.name === 'IDToken1');

    if (idTokenInput) {
      idTokenInput.value = `${score}`; // Adding double quotes around score
    }
  }

  return callbacks;
};


// security key back button
export const securityKeyMFABack = ({
  callbacks
}) => {
  callbacks.find(
    callback => callback.type === 'HiddenValueCallback'
  ).input.find(input => idTokenMatch.test(input.name)).value = 'ERROR::NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.';

    

    return callbacks;
}


//first callback for authenticator app back button
export const requireAuthMFABackOptions = ({
  callbacks
}) =>{
  callbacks.find(
    callback => callback.type === 'ConfirmationCallback'
  ).input.find(input => idTokenMatch.test(input.name)).value = 0;

    
  
    return callbacks;
} 

//second callback for authenticator app back button

export const officialAuthMFABackOptions = ({
  callbacks
}) => {
  

  callbacks.find(
    callback => callback.type === 'ConfirmationCallback'
  ).input.find(input => idTokenMatch.test(input.name)).value = 1;

  return callbacks;
}

export const requiresMFAOption = ({
    callbacks
  }) =>
  callbacks && callbacks.find(callback => callback.type === 'ChoiceCallback');

export const requiresMFACode = ({
    callbacks
  }) =>
  callbacks && callbacks.find(callback => callback.type === 'PasswordCallback');

export const requiresRememberThisDevice = ({
    callbacks
  }) =>
  callbacks &&
  callbacks[0] &&
  callbacks[0].output &&
  callbacks[0].output[0].value === 'Save this device?';

export const updateWithDeviceDetails = async (callbacks) => {
  callbacks
    .find(callback => callback.type === 'HiddenValueCallback')
    .input.find(input =>
      idTokenMatch.test(input.name)
    ).value = await getDeviceDetails();

  return callbacks;
};


export const updateWithDeviceDetailsAgain = async (callbacks) => {
  const deviceDetails = getDeviceDetailsAgain();

  // Collect the geolocation information asynchronously
  const geolocationInfo = await new Promise((resolve) => {
    collectGeolocationInfo((geolocationInfo) => {
      resolve(geolocationInfo);
    });


  });

  

  // Update the deviceDetails object with geolocation data
  deviceDetails.location = geolocationInfo;

  // Find and update the appropriate input value
  const deviceProfileCallback = callbacks.find(
    (callback) => callback.type === 'DeviceProfileCallback'
  );

  

  if (deviceProfileCallback) {
    const idTokenInput = deviceProfileCallback.input.find((input) =>
      idTokenMatch.test(input.name)
    );

    

    if (idTokenInput) {
      idTokenInput.value = JSON.stringify(deviceDetails);
    }
  }

  

  return callbacks;
};


export const updateWithRememberThisDevice = ({
  callbacks,
  rememberThisDevice,
}) => {
  
  if (!Array.isArray(callbacks)) {
    callbacks = [callbacks];
  }
  
  const choiceCallback = callbacks.find(callback => callback.type === 'ChoiceCallback');
  
  if (choiceCallback) {
    choiceCallback.input.find(input =>
      idTokenMatch.test(input.name)
    ).value = rememberThisDevice ? 1 : 0;
  }
  
  return callbacks;
  
};

export const getMFAOptionsFromCallbacks = callbacks => {
  

  if (!Array.isArray(callbacks)) {
    // Convert 'callbacks' into an array by pushing it into a new array
    const callbackArray = [callbacks];

    // Continue with the rest of your code using 'callbackArray'
    const choiceCallback = callbackArray.find(
      callback => callback.type === 'ChoiceCallback'
    );

    if (choiceCallback) {
      // Continue with extracting MFA options
      return choiceCallback.output
        .find(output => output.name === 'choices')
        .value.map(option => ({
          type: getChannelTypeFromValue(option),
          value: option,
        }));
    } else {
      // Handle the case where there is no ChoiceCallback found
      return null; // You can change this to an appropriate default value
    }
  } else {
    // Continue with your existing code for handling an array
    const choiceCallback = callbacks.find(
      callback => callback.type === 'ChoiceCallback'
    );

    if (choiceCallback) {
      // Continue with extracting MFA options
      return choiceCallback.output
        .find(output => output.name === 'choices')
        .value.map(option => ({
          type: getChannelTypeFromValue(option),
          value: option,
        }));
    } else {
      // Handle the case where there is no ChoiceCallback found
      return null; // You can change this to an appropriate default value
    }
  }
};


export const callbacksWithSelectedMFAIndex = ({
  selectedMFAOption,
  callbacks,
}) => {
  
  if (!Array.isArray(callbacks)) {
    callbacks = [callbacks];
  }
  
  const choiceCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'ChoiceCallback'
  );
  
  if (choiceCallbackIndex >= 0) {
    const choices = callbacks[choiceCallbackIndex].output.find(
      output => output.name === 'choices'
    ).value;
  
    const MFAInputIndex = callbacks[choiceCallbackIndex].input.findIndex(input =>
      idTokenMatch.test(input.name)
    );
  
    const selectedMFAIndex = choices.indexOf(selectedMFAOption);
  
    callbacks[choiceCallbackIndex].input[MFAInputIndex].value = selectedMFAIndex;
  }
  
  return callbacks;
  
};

export const callbacksWithSelectedSecurityKey = ({
  combined,
  callbacks,
}) => {


  
  const choiceCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'ChoiceCallback'
  );

  if (choiceCallbackIndex >= 0) {
    const choices = callbacks[choiceCallbackIndex].output.find(
      output => output.name === 'choices'
    ).value;

    const MFAInputIndex = callbacks[choiceCallbackIndex].input.findIndex(input =>
      idTokenMatch.test(input.name)
    );
  }

  const HiddenValueCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'HiddenValueCallback'
  );
  callbacks[HiddenValueCallbackIndex].input[0].value = combined;

  return callbacks;
};

export const callbacksWithMFACode = ({
  MFACode,
  callbacks
}) => {
  let otpError = false;
  if (callbacks) {
    callbacks.forEach(function (callback) {
      if (callback.type === 'TextOutputCallback') {
        callback.output.forEach(function (eachoutput) {
          if (eachoutput.value.includes('invalidOTP')) {
            otpError = true;
          }
        });
      }
    });
  }

  

  if (otpError) {
    const HiddenValueCallbackIndex = callbacks.findIndex(
      callback => callback.type === 'HiddenValueCallback'
    );
    callbacks[HiddenValueCallbackIndex].output[0].value = MFACode;
    return callbacks;
  } else {
    const resendCallbackIndex = callbacks.findIndex(
      callback => callback.type === 'ConfirmationCallback'
    );
    if (resendCallbackIndex >= 0) {
      const resendInputIndex = callbacks[
        resendCallbackIndex
      ].input.findIndex(input => idTokenMatch.test(input.name));
      callbacks[resendCallbackIndex].input[resendInputIndex].value = '0';
    }

    let passwordCallbackIndex = callbacks.findIndex(
      callback => callback.type === 'PasswordCallback'
    );
    /*To handle Auth App NameCallback instead of Password Callback */
    if (passwordCallbackIndex === -1) {
      passwordCallbackIndex = callbacks.findIndex(
        callback => callback.type === 'NameCallback'
      );
    }
    if (passwordCallbackIndex >= 0) {
      const MFAInputIndex = callbacks[
        passwordCallbackIndex
      ].input.findIndex(input => idTokenMatch.test(input.name));
      callbacks[passwordCallbackIndex].input[MFAInputIndex].value = MFACode;
    }
    return callbacks;
  }
};

export const callbacksWithMFACodeResend = ({
  callbacks
}) => {
  const resendCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'ConfirmationCallback'
  );
  const resendInputIndex = callbacks[
    resendCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));
  callbacks[resendCallbackIndex].input[resendInputIndex].value = '1';
  let passwordCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'PasswordCallback'
  );
  if (passwordCallbackIndex === -1) {
    passwordCallbackIndex = callbacks.findIndex(
      callback => callback.type === 'NameCallback'
    );
  }
  const MFAInputIndex = callbacks[
    passwordCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));
  callbacks[passwordCallbackIndex].input[MFAInputIndex].value = 'NOT_SET';
  return callbacks;
};
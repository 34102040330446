//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './form-actions.scss';

// Types
type Props = {
  actions: Array<Object>,
  className?: string,
  align?: string,
  direction?: string,
};

const FormActions = ({
  className,
  align = '',
  direction = '',
  ...props
}: Props) => (
  <div className="form-actions-wrapper">
    <div
      {...props}
      className={classnames(
        'form-actions',
        { [`form-actions-align-${align}`]: align },
        { [`form-actions-direction-${direction}`]: direction },
        className
      )}
    />
  </div>
);

export default FormActions;

//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

// Actions
import { openModal, toggleModal } from '../../../reducers/modal/modalActions';

// Components
import { PaymentCard, PaymentCardListAddButton } from '..';
import {
  Content,
  Dropdown,
  DeletePaymentAccountModal,
  AddPaymentAccountModal,
  EditPaymentAccountModal,
} from '../../common';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

// Types
type Props = {
  paymentAccounts: PaymentAccount[],
};

const toggleComponent = ({ ...props }) => (
  <ButtonIcon className="payment-list-item-icon" icon="more" {...props} />
);

const PaymentCardList = ({ paymentAccounts }: Props) => {
  const dispatch = useDispatch();

  const onEdit = paymentAccount => {
    dispatch(toggleModal('EditPaymentAccountModal', { paymentAccount }));
  };

  const onRemove = paymentAccount => {
    dispatch(toggleModal('DeletePaymentAccountModal', { paymentAccount }));
  };

  const CardList = paymentAccounts.map(
    (paymentAccount: PaymentAccount, index) => {
      const dropdownItems = [
        {
          label: <Content type="Labels" id="Edit" />,
          onClick: () => onEdit(paymentAccount),
        },
        {
          label: <Content type="Labels" id="Remove" />,
          onClick: () => onRemove(paymentAccount),
        },
      ];

      return (
        <div className="payment-list-item" key={`paymentCard${index}`}>
          <PaymentCard paymentAccount={paymentAccount} />
          <Dropdown
            className="payment-list-item-more"
            id={`payment-dropdown-${index}`}
            toggleComponent={toggleComponent}
            items={dropdownItems}
          />
        </div>
      );
    }
  );

  return (
    <div className="payment-list">
      {CardList}
      <PaymentCardListAddButton
        onClick={() => dispatch(openModal('AddPaymentAccountModal'))}
        content={<Content type="Payment" id="AddPaymentMethod" />}
      />

      <DeletePaymentAccountModal />
      <AddPaymentAccountModal />
      <EditPaymentAccountModal />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ toggleModal }, dispatch);

export default connect(null, mapDispatchToProps)(PaymentCardList);

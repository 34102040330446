// @flow

import { REQUEST_TYPES } from '.';
import { isDevelopmentEnvironment } from '../lib/helpers';





//captcha request
export const loginRequest = (
  { email, password }: LoginRequestType,
  url: string,
   {body} : Object,
): RequestOptions => {
  return {
    type: `[API] Login`,
    url,
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'X-OpenAM-Username': email,
      'X-OpenAM-Password': password,
      'Accept-API-Version': 'resource=1.0',
    },
    body
  };
};


// export const loginRequest = (
//   { email, password }: LoginRequestType,
//   url: string,
// ): RequestOptions => {
//   return {
//     type: `[API] Login`,
//     url,
    
//     APIMethod: REQUEST_TYPES.POST,
//     headers: {
//       'X-OpenAM-Username': email,
//       'X-OpenAM-Password': password,
//       'Accept-API-Version': 'resource=1.0',
//     },
//   };
// };


export const captchaRequest = (
url: string
): RequestOptions => {
  return{
    type: '[API] Captcha log ',
    url,
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'Accept-API-Version': 'resource=1.0',
    },
  };
};

export const sessionProperties = (
): RequestOptions => {
  return {
    type: '[API] single Sign on LOGIN',
    url: '/sso/json/realms/root/realms/TDIS/sessions?_action=getSessionProperties',
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json'
    }
  }
}


export const userOauthToken = (
  {email, password}: LoginRequestType,
): RequestOptions => {
  return {
    type: '[API] Ouath Token',
    url: '/sso/json/realms/root/realms/TDIS/authenticate?authIndexType=service&authIndexValue=DIS_MFA_1',
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'X-OpenAM-Username': email,
      'X-OpenAM-Password': password,
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json'
    }
  }
}



export const oauthloginRequest = (
  tokenId : string,
): RequestOptions => {
  return {
    type: `[API] oauth token`,
    url: `/sso/oauth2/realms/root/realms/TDIS/authorize?response_type=code&redirect_uri=https://dev.myaccess.texas.gov/&decision=allow&client_id=tdisportal&csrf=${tokenId}&scope=profile%20openid`,
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json',
      'Access-Control-Expose-Headers': 'location',
      'Accept': '*/*'
      
    },
  };
};


export const oauthaccessTokenRequest = (
  token : string,
  originUrl: string,
): RequestOptions => {
  return {
    type: `[API] oauth token`,
    url: `/sso/oauth2/realms/root/realms/TDIS/access_token?grant_type=authorization_code&code=${token}&client_id=tdisportal&client_secret=Password&redirect_uri=${originUrl}/`,
    location: "",
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json'
    },
  };
};


export const userInfoRequest = (
  accessToken : string,
): RequestOptions => {
  return {
    type: `[API] oauth token`,
    url: `/sso/oauth2/realms/root/realms/TDIS/userinfo`,
    location: "",
    APIMethod: REQUEST_TYPES.GET,
    headers: {
      'Accept-API-Version': 'resource=2.0, protocol=1.0',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    },
  };
}








export const loginMFARequest = (
  { body }: Object,
  url: string
): RequestOptions => ({
  type: `[API] Login MFA Request`,
  url,
  location: "",
  APIMethod: REQUEST_TYPES.POST,
  headers: {
    'Accept-API-Version': 'resource=1.0',
  },
  body,
});

export const loginSuccessRequest = (url: string) => {
  if (isDevelopmentEnvironment())
    url = url.substring(url.indexOf('/agent/custom-login-response'));
  return {
    type: `[API] Login Success Request`,
    location: "",
    url,
    APIMethod: REQUEST_TYPES.GET,
  };
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

import { InputRadioGroup, Content } from '../../../../..';

// Types
type Props = {
  fieldGroupPath?: string,
  formikProps: Object,
  className?: string,
};

/**
 * @visibleName Address Type
 */
const InputRadioGroupAddressLocation = ({
  fieldGroupPath = '',
  formikProps,
  className,
  ...props
}: Props) => {
  if (fieldGroupPath) fieldGroupPath = `${fieldGroupPath}.`;

  return (
    <InputRadioGroup
      {...props}
      className={classnames('input-radio-group-address-type', className)}
      name={`${fieldGroupPath}addressLocation`}
      formikProps={formikProps}
      inline={true}
      options={[
        {
          id: `${fieldGroupPath}domesticAddress`,
          label: <Content type="Labels" id="DomesticAddress" />,
          value: 'domestic',
        },
        {
          id: `${fieldGroupPath}foreignAddress`,
          label: <Content type="Labels" id="ForeignAddress" />,
          value: 'foreign',
        },
      ]}
    />
  );
};

export default InputRadioGroupAddressLocation;

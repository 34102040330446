//@flow

// Vendors
import React, { useState } from 'react';

// Components
import { ToggleGroup } from '../../..';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Types
type Props = {
  fieldGroupPath: string,
  formikProps: FormikProps,
  open: Node,
  closed: Node,
};

/**
 * @visibleName Editable Summary Card
 */
const SummaryCardEditGroup = ({
  fieldGroupPath,
  formikProps,
  open: OpenComponent,
  closed: ClosedComponent,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!formikProps.getFieldValue(fieldGroupPath)) return null;

  // when we get validation errors from the server this will currently open all of the summary cards
  const fieldError =
    formikProps.getFieldError(fieldGroupPath) ||
    formikProps.getFieldStatus('serverErrors');
  const hasError = fieldError && formikProps.submitCount > 0;

  if (!isOpen && hasError) setIsOpen(true);

  return (
    <SummaryCard>
      <ToggleGroup
        isOpen={isOpen}
        open={() => (
          <OpenComponent
            fieldGroupPath={fieldGroupPath}
            formikProps={formikProps}
          />
        )}
        closed={() => (
          <ClosedComponent
            fieldGroupPath={fieldGroupPath}
            formikProps={formikProps}
          />
        )}
      />
    </SummaryCard>
  );
};

export default SummaryCardEditGroup;

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import * as Transitions from '../Transitions';

// Styles
import './stepper.scss';

// Types
type Props = {
  steps: Array<{ id: string, component: Node, data?: Object }>,
  activeStep: string,
  className?: string,
  transition?: string,
  onStepChange?: Function,
  onStepChanged?: Function,
};

/**
 * @visibleName Default
 */
const Stepper = ({
  steps = [],
  activeStep,
  transition = 'SlideTransition',
  onStepChange,
  onStepChanged,
  className,
}: Props) => {
  if (!steps.length) return null;

  const Transition = Transitions[transition];

  return (
    <div className={classnames('stepper', className)}>
      {steps.map(step => {
        const { id: stepId, component: Step, data } = step;
        return (
          <Transition
            key={stepId}
            className="stepper-step"
            in={activeStep === stepId}
            onEnter={onStepChange}
            onEntered={onStepChanged}
          >
            <Step steps={steps} stepId={stepId} data={data} />
          </Transition>
        );
      })}
    </div>
  );
};

export default Stepper;

//@flow

// Vendors
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Actions
import { switchLanguage } from '../../reducers/language/languageActions';

// Components
import { Dropdown } from '..';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Styles
import './language-switcher.scss';

type Props = {
  selected: string,
  options: Array<Object>,
  switchLanguage: Function,
  dropdownProps: Object,
};

class LanguageSwitcher extends Component<Props> {
  handleLanguageSelect(language) {
    this.props.switchLanguage(language);
  }

  get languageValue() {
    const { options, selected } = this.props;
    const selectedLanguage = options.find(
      language => language.code === selected
    );
    return selectedLanguage ? selectedLanguage.value : options[0].value;
  }

  render() {
    const { options, dropdownProps } = this.props;
    const items = options.map(option => {
      const optionClassNames = classnames({
        'language-switcher-dropdown-item': true,
        isSelected: this.languageValue === option.value,
      });
      option.onClick = () => this.handleLanguageSelect(option.code);
      option.className = optionClassNames;
      return option;
    });

    return (
      <Dropdown
        {...dropdownProps}
        id="language-switcher"
        className="language-switcher"
        showCheck={true}
        toggleComponent={({ ...props }) => (
          <button {...props} className="language-switcher-toggle">
            {this.languageValue} <Icon name="chevron-down" />
          </button>
        )}
        items={items}
      />
    );
  }
}

const mapStateToProps = ({
  language: { selected, options, isDropdownOpen },
}): Object => ({
  selected,
  options,
  isDropdownOpen,
});

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ switchLanguage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Styles
import './input-checkbox.scss';

// Types
type Props = {
  name: string,
  className?: string,
};

/**
 * @visibleName Checkbox
 */
const InputCheckbox = ({ className, ...props }: Props) => {
  const InputIcons = (
    <span className="input-checkbox-check">
      <Icon name="check" />
    </span>
  );

  return (
    <InputWrapper
      {...props}
      className={classnames('input-checkbox', className)}
      type="checkbox"
      icons={InputIcons}
    />
  );
};

export default InputCheckbox;

export default {
  PageTitle: 'Certificación de ciberseguridad',
  AttestationFormTitle:
    'Capacitación en seguridad cibernética y certificación del programa de concientización',
  EmploymentInformationTitle: 'Informacion de Empleo',
  DateOfTrainingLabel: 'Fecha de Finalización del Entrenamiento',
  NoOrgsFound: 'No encontramos organizaciones...',
  TodoList_Attestation_Primary:
    'el cumplimiento del requisito de ciberseguridad ',
  TodoList_Attestation_Secondary: 'Empleado del Sector Público',
  AttestationSectionTitle: 'Certificación',
  AttestationCheckboxLabel:
    'He completado la capacitación en ciberseguridad según lo requerido por H.B.&nbsp;3834.',
  FiscalYear: 'año&nbsp;fiscal',
  AttestationSuccessTitle: '¡Éxito! Gracias por completar su certificación.',
};

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Styles
import './hamburger.scss';

// Types
type Props = {
  isActive: boolean,
  className: string,
};

const Hamburger = ({ isActive, className, ...props }: Props) => (
  <span
    {...props}
    className={classnames('hamburger', className, isActive ? 'isActive' : '')}
  />
);

export default Hamburger;

import { REQUEST_TYPES, REQUEST_LOCATIONS } from '.';

export const logoutRequest = () => ({
  type: `[API] Logout Request`,
  location: REQUEST_LOCATIONS.FORGEROCK,
  url: '/sessions/?_action=logout',
  APIMethod: REQUEST_TYPES.POST,
  headers: {
    'Accept-API-Version': 'resource=2.0,protocol=1.0',
  },
});

export const keepAliveRequest = (headers) => ({
  
  type: `[API] KeepAlive Request`,
  url: '/user/ui/keepAlive',
  headers,
  APIMethod: REQUEST_TYPES.GET,
});

export const checkForSignInAccess = value => ({
  type: `[API] Temporary Check for Sign In Param`,
  url: `/temp/allowSignIn/${value}`,
  APIMethod: REQUEST_TYPES.GET,
});

//@flow

// Vendors
import React from 'react';
import classnames from 'classnames';

// Components
import { Content, InputCountry, InputGroup, InputText } from '../../..';

// Types
type Props = {
  className?: string,
  disabled: boolean,
  fieldGroupPath?: string,
  formikProps: FormikProps,
  label: Node,
  labelPosition: string,
  name: string,
  statesOptions?: Object,
};

/**
 * @visibleName Foreign Address
 */
const InputGroupForeignAddress = ({
  className,
  disabled,
  fieldGroupPath,
  formikProps,
  labelPosition,
  ...props
}: Props) => {
  fieldGroupPath = fieldGroupPath ? `${fieldGroupPath}.` : '';

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-address', className)}
    >
      <InputText
        className="input-street"
        name={`${fieldGroupPath}foreignAddress.streetAddress`}
        label={<Content type="Labels" id="StreetAddress" />}
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-unit"
        name={`${fieldGroupPath}foreignAddress.addressLine2`}
        label={
          labelPosition === 'above' ? '' : <Content type="Labels" id="Unit" />
        }
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-unit"
        name={`${fieldGroupPath}foreignAddress.addressLine3`}
        label={
          labelPosition === 'above' ? (
            ''
          ) : (
            <Content type="Labels" id="Address3" />
          )
        }
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-city"
        name={`${fieldGroupPath}foreignAddress.city`}
        label={<Content type="Labels" id="City" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-state"
        name={`${fieldGroupPath}foreignAddress.state`}
        label={<Content type="Labels" id="State" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        name={`${fieldGroupPath}foreignAddress.postalCode`}
        label={<Content type="Labels" id="Zip" />}
        className="input-zip"
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputCountry
        name={`${fieldGroupPath}foreignAddress.country`}
        className="input-country"
        label={<Content type="Labels" id="Country" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
    </InputGroup>
  );
};

InputGroupForeignAddress.defaultProps = {
  label: <Content type="Labels" id="ForeignAddressInput" />,
  name: 'foreignAddress',
  labelPosition: 'above',
};

export default InputGroupForeignAddress;

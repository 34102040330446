export default {
  PageTitle: `Tablero`,
  EmpPageTitle: 'panel de empleados',
  AvailableAgencies_Title: 'Agencias Disponibles',
  LinkedAgencies_Title: `Mis Agencias`,
  LinkedAgencies_AddAnother: `Agrega otro`,
  LinkedAgencies_EmptyTitle: `Comience conectando una licencia o registro.`,
  LinkedAgencies_EmptyDescription: `Al vincular un elemento que recibirá recordatorios de renovación útiles, completar transacciones más rápidas y un acceso directo al elemento vinculado aparecerán aquí para la próxima vez que lo necesite.`,
  LinkedAgencies_Empty_Prompt: `<b>Haga clic en el botón verde</b> para iniciar la vinculación.`,
  TodoList_Title: 'Lista de quehaceres',
  TodoList_DueBy: 'Debido',
  History_Title: 'Historia',
};

//@flow

// Vendors
import React from 'react';

// Components
import { CreditCardIcon } from '../..';

// Teckit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const PaymentCardListAddButton = ({
  onClick,
  content,
}: {
  onClick: Function,
  content: Node,
}) => (
  <div className="payment-add-wrapper">
    <button type="button" className="payment-add" onClick={onClick}>
      <CreditCardIcon name="blank" />
      <div className="payment-add-text">{content}</div>
      <div className="flex-item" />
      <Icon className="payment-add-icon" name="plus-circle" />
    </button>
  </div>
);

export default PaymentCardListAddButton;

//@flow

// Vendors
import React, { useState } from 'react';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword, Content } from '../../..';
import InputPasswordReq from './InputPasswordReq';

import './InputGroupConfirmPassword.scss';

type Props = {
  className?: string,
  formikProps: FormikProps,
};

/**
 * @visibleName Confirm Password
 */

const InputGroupConfirmPassword = ({
  className,
  formikProps,
  ...props
}: Props) => {
  const [passwordChecklist, setPasswordChecklist] = useState(false);

  const [contains8C, setContains8C] = useState(false);
  const [containsUL, setContainsUL] = useState(false);
  const [containsLL, setContainsLL] = useState(false);
  const [containsN, setContainsN] = useState(false);
  const [containsSC, setContainsSC] = useState(false);

  const [allValid, setAllValid] = useState(false);

  const passwordReqData = [
    ['A minimum of 8 characters', contains8C],
    ['An uppercase letter', containsUL],
    ['A lowercase letter', containsLL],
    ['One number', containsN],
    ['One special character (.[@!#$^&+=](_-){})', containsSC],
  ];
  const validatePassword = updatedPassword => {
    if (updatedPassword.toLowerCase() !== updatedPassword) setContainsUL(true);
    else setContainsUL(false);
    if (updatedPassword.toUpperCase() !== updatedPassword) setContainsLL(true);
    else setContainsLL(false);
    if (/\d/.test(updatedPassword)) setContainsN(true);
    else setContainsN(false);
    if (/[!#$^&+@=\[\]._(){}-]/g.test(updatedPassword)) setContainsSC(true);
    else setContainsSC(false);
    if (updatedPassword.length >= 8) setContains8C(true);
    else setContains8C(false);
    if (containsUL && containsLL && containsN && containsSC && contains8C)
      setAllValid(true);
    else setAllValid(false);
  };

  const showFocused = () => {
    setPasswordChecklist(!passwordChecklist);
  };

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-confirm-password', className)}
    >
      <InputPassword
        name="password"
        label={<Content type="Labels" id="EnterPassword" />}
        formikProps={formikProps}
        onKeyUp={e => validatePassword(e.target.value)}
        onFocus={showFocused}
        onBlur={showFocused}
        className="input-margin"
        maxLength="32"
        />
      {passwordChecklist && (
        <div className="password-helper-container">
          {passwordReqData.map(data => (
            <InputPasswordReq data={data} />
          ))}
        </div>
      )}

      <InputPassword
        name="confirmPassword"
        label={<Content type="Labels" id="ConfirmPassword" />}
        className="input-password-confirm"
        formikProps={formikProps}
        maxLength="32"
      />
    </InputGroup>
  );
};

export default InputGroupConfirmPassword;

export const cpa = {
  APP_ERRORS: {
    cpaln: {
      linkService: {
        CAPPSID: 'params.CAPPSID',
        lastName: 'params.lastName'
      }
    }
  }
};

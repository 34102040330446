/* eslint-disable no-console */
import './lib/polyfills/IE11';
import { checkBrowserFeatures, shouldLog } from './lib/helpers';

// import the GTM Module so you can access it
import TagManager from "react-gtm-module";

const tagManagerArgs = {
    gtmId: "GTM-P8J3MKF"
  };
  
  TagManager.initialize(tagManagerArgs)

//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production' 
const env = process.env.NODE_ENV; 

if (shouldLog) require('logrocket').init('daupnu/mgmw');

if (checkBrowserFeatures()) import('./MGMW').catch(err => console.error(err));
else import('./UnsupportedBrowser').catch(err => console.error(err));

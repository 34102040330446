import React from 'react';
import './InputGroupConfirmPassword.scss';
import { Icon } from 'texkit/dist/components/Icon/Icon';

const InputPasswordReq = props => {
  const { data } = props;
  const [label, meetsReq] = data;

  return (
    <div className="password-helper-item">
      <li className="password-helper-text">
        <span
          className={
            !meetsReq ? 'password-helper-check' : 'password-helper-checked'
          }
        >
          <Icon name="check" />
        </span>

        {label}
      </li>
    </div>
  );
};

export default InputPasswordReq;
